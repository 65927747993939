import {
  FormControlLabel,
  Switch,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
  FormHeader: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "22px",
  },
}))

const FormHeader = ({
  title,
  publishParticularValue,
  publishProfessionalValue,
  publishSelfEmployedValue,
  publishOthersValue,
  handleChangePublishParticular,
  handleChangePublishProfessional,
  handleChangePublishSelfEmployed,
  handleChangePublish
}) => {
  const [publishParticular, setPublishParticular] = useState(
    publishParticularValue || false
  )
  const [publishProfessional, setPublishProfessional] = useState(
    publishProfessionalValue || false
  )
  const [publishSelfEmployed, setPublishSelfEmployed] = useState(
    publishSelfEmployedValue || false
  )
  const [publishOthers, setPublishOther] = useState(
    publishOthersValue || false
  )


  const classes = useStyles()

  const handleChangeParticular = (event) => {
    console.log(event.target.checked)
    setPublishParticular(event.target.checked)
    handleChangePublishParticular(event.target.checked)
  }

  const handleChangeProfessional = (event) => {
    console.log(event.target.checked)
    setPublishProfessional(event.target.checked)
    handleChangePublishProfessional(event.target.checked)
  }

  const handleChangeSelfEmployed = (event) => {
    console.log(event.target.checked)
    setPublishSelfEmployed(event.target.checked)
    handleChangePublishSelfEmployed(event.target.checked)
  }

  const handleChange = (event) => {
    const key = event.target.name
    const value = event.target.checked

    console.log(key)
    console.log(value)

    if(event.target.name === 'publicarOtros') {
      setPublishOther(event.target.checked)
      handleChangePublish(event.target.name, event.target.checked)
    }
  }

  return (
    <div>
      <Toolbar />
      <div className={classes.FormHeader}>
        <Typography variant='h5' component='h2'>
          <b>{title}</b>
        </Typography>
        <FormControlLabel
          style={{ marginLeft: "12px" }}
          control={
            <Switch
              checked={publishParticular}
              value={publishParticular}
              name='publicarParticular'
              color='primary'
              onChange={handleChangeParticular}
            />
          }
          label='Publicar Particular'
        />
        <FormControlLabel
          style={{ marginLeft: "12px" }}
          control={
            <Switch
              checked={publishProfessional}
              value={publishProfessional}
              name='publicarProfesional'
              color='primary'
              onChange={handleChangeProfessional}
            />
          }
          label='Publicar Profesional'
        />
        <FormControlLabel
          style={{ marginLeft: "12px" }}
          control={
            <Switch
              checked={publishSelfEmployed}
              value={publishSelfEmployed}
              name='publicarAutonomos'
              color='primary'
              onChange={handleChangeSelfEmployed}
            />
          }
          label='Publicar Autónomos'
        />
        <FormControlLabel
          style={{ marginLeft: "12px" }}
          control={
            <Switch
              checked={publishOthers}
              value={publishOthers}
              name='publicarOtros'
              color='primary'
              onChange={handleChange}
            />
          }
          label='Publicar Otros'
        />
      </div>
    </div>
  )
}

export default FormHeader
