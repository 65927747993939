const Mini = ({ width }) => {
  return (
    <img
      alt='Mini'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1635938574/Multipublicador%20GrupOlivaMotor/MINI_logo_black_hgqiel.svg'
      width={width}
    />
  )
}

export default Mini
