import { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FormHeader from "./FormHeader"
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
  Divider,
} from "@mui/material"
import PriceRentingList from "./PriceRentingList"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChildTextField from "../../ChildTextField"
import ChildTextArea from "../../ChildTextArea"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import EuroIcon from "@mui/icons-material/Euro"
import RentingService from "../../../services/renting.service"
import UploadImageButton from "../../buttons/UploadImageButton"
import SubmitButton from "../../buttons/SubmitButton"
import Snackbar from "../../Snackbar"
import VehicleImages from "./VehicleImages"
import AlertBox from "../../AlertBox"
import UploadFileService from "../../../services/upload-file.service"
import SecurityIcon from "@mui/icons-material/Security"
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import StarsIcon from "@mui/icons-material/Stars"
import SettingsBluetoothIcon from "@mui/icons-material/SettingsBluetooth"
import PersonIcon from "@mui/icons-material/Person"
import BadgeIcon from "@mui/icons-material/Badge"
import BusinessIcon from "@mui/icons-material/Business"
import Provider from "./Provider"
import GeneratedOffer from "./GenerateOffer"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text], input[type=date]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
    marginTop: "12px",
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  propTitle: {
    marginTop: "10px",
    marginBottom: "25px",
  },
  icon: {
    color: "#747474",
  },
}))

const formatArrayToTextList = (array, filter) => {
  let text = ""
  for (let i = 0; i < array.length; i++) {
    if (filter === array[i].tipo) {
      text += `${array[i].contenido}\n`
    }
  }
  return text
}

const CarRentingEditForm = ({ data }) => {
  const classes = useStyles()
  const [carData, setCarData] = useState(data)
  const [expanded, setExpanded] = useState(true)
  const [open, setOpen] = useState(false)
  const [ecoLabel, setEcoLabel] = useState(carData.etiquetaEco)
  const [recursos, setRecursos] = useState(carData.recursos)
  const [loading, setLoading] = useState(false)
  const [severityAlert, setSeverityAlert] = useState("info")
  const [alertMessage, setAlertMessage] = useState("")
  const [providers, setProviders] = useState([])
  const [provider, setProvider] = useState(
    carData.provider ? carData.provider : ""
  )
  const [immediateDelivery, setImmediateDelivery] = useState(
    carData.immediate_delivery ? carData.immediateDelivery : false
  )

  const title = `Editar ${carData.marca} ${carData.modelo}`

  useEffect(() => {
    if (providers.length === 0) {
      RentingService.providers()
        .then((result) => {
          setProviders(result)
          console.log(result)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [providers])

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const handleChangePublishParticular = (value) => {
    carData.publicarParticular = value
    setCarData(carData)
    console.log(carData)
  }

  const handleChangePublishProfessional = (value) => {
    carData.publicarEmpresa = value
    setCarData(carData)
    console.log(carData)
  }

  const handleChangePublishSelfEmployed = (value) => {
    carData.publicarAutonomos = value
    setCarData(carData)
    console.log(carData)
  }

  const handleChangePublish = (key, value) => {
    carData[key] = value
    setCarData(carData)
    console.log(carData)
  }

  const handleInputTextChange = (event) => {
    // console.log(event.target.name)
    // console.log(event.target.value)
    carData[event.target.name] = event.target.value
    // console.log(carData)
  }

  const handleChangeEcoLabel = (event) => {
    setEcoLabel(event.target.value)
    carData.etiquetaEco = ecoLabel
  }

  const handleChangeProvider = (event) => {
    setProvider(event.target.value)
  }

  const handleChangeImmediateDelivery = (value) => {
    setImmediateDelivery(value)
  }

  const handleInputPriceChange = (event) => {
    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    data.preciosParticular.map((e) => {
      if (e.tipo === event.target.name) {
        return (e.valor = formatPrice.format(event.target.value))
      }
      return 0
    })
  }

  const handleInputPriceAutonomousChange = (event) => {
    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    data.preciosAutonomos.map((e) => {
      if (e.tipo === event.target.name) {
        return (e.valor = formatPrice.format(event.target.value))
      }
      return 0
    })
    console.log(data.preciosAutonomos)
  }

  const handleInputPriceProfessionalChange = (event) => {
    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    data.preciosEmpresas.map((e) => {
      if (e.tipo === event.target.name) {
        return (e.valor = formatPrice.format(event.target.value))
      }
      return 0
    })
  }

  const handleInputTextAreaImportantEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Destacado",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    carData.equipamientoDestacado = equipamiento
  }

  const handleInputTextAreaTechnologyEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Tecnologia",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    carData.equipamientoTecnologia = equipamiento
  }

  const handleInputTextAreaConfortEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Confort",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    carData.equipamientoConfort = equipamiento
  }

  const handleInputTextAreaSecurityEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Seguridad",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    carData.equipamientoSeguridad = equipamiento
  }

  const handleInputTextAreaExteriorEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Exterior",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    carData.equipamientoExterior = equipamiento
  }

  const handleUploadImage = (image) => {
    console.log(image)
    const name = `${image.lastModified}-${image.name}`.replace(" ", "-")

    UploadFileService.uploadImage(image, name)
      .then((result) => {
        if (result.status === 200) {
          const newImage = {
            nombre: name,
            url: process.env.REACT_APP_API_URL + `uploads/images/${name}`,
            tipo: "IMAGEN",
            propiedades: [
              {
                nombre: "EsFotoPrincipal",
                valor: false,
              },
              {
                nombre: "OrdenFoto",
                valor: "",
              },
            ],
          }
          recursos.push(newImage)
          // setRecursos([...carData.recursos, newImage])
          setRecursos(recursos)
          carData.recursos = recursos
          setCarData({ ...carData })
          console.log(carData)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChangeImageProperties = (image) => {
    console.log("Propiedades de imagen cambiadas!!!")
    console.log(image)

    for (let i = 0; i < recursos.length; i++) {
      if (recursos[i].nombre === image.nombre) {
        recursos[i].propiedades = image.propiedades
      }
    }
    carData.recursos = recursos
    setRecursos([...recursos])
  }

  const handleDeleteImage = (imageName) => {
    let filterImages = recursos.filter(
      (element) => element.nombre !== imageName
    )
    RentingService.deleteImg({ name: imageName })
    setRecursos([...filterImages])
    carData.recursos = filterImages
    setCarData(carData)
  }

  const handleMinPriceChange = (event) => {
    carData.precioMinParticular = {
      ...carData.precioMinParticular,
      [event.target.name]: event.target.value,
    }

    setCarData(carData)
    console.log(carData)
  }

  const handleMinPriceFreelanceChange = (event) => {
    carData.precioMinAutonomos = {
      ...carData.precioMinAutonomos,
      [event.target.name]: event.target.value,
    }

    setCarData(carData)
    console.log(carData)
  }

  const handleMinPriceProfessionalChange = (event) => {
    carData.precioMinEmpresas = {
      ...carData.precioMinEmpresas,
      [event.target.name]: event.target.value,
    }

    setCarData(carData)
    console.log(carData)
  }

  const handleSaveButton = (event) => {
    setLoading(true)
    console.log(carData)
    event.preventDefault()
    const isFormValidate = validateForm()
    carData.recursos = recursos
    carData.etiquetaEco = ecoLabel
    carData.provider = provider
    carData.immediate_delivery = immediateDelivery
    const filter = {
      _id: carData._id,
    }
    if (isFormValidate) {
      RentingService.update(carData._id, { filter, carData })
        .then((result) => {
          console.log(result)
          setOpen(true)
          setSeverityAlert("success")
          setLoading(false)
          setAlertMessage("Vehículo actualizado con éxito")
        })
        .catch((err) => {
          setOpen(true)
          setSeverityAlert("error")
          setLoading(false)
          setAlertMessage("Ha ocurrido un error al actualizar el vehículo")
          console.log(err)
        })
    } else {
      // setOpenError(true)
      setTimeout(() => {
        // setOpenError(false)
      }, 3000)
    }
  }

  const handlePdfButton = (typeOffer) => {
    RentingService.downloadPDF(carData, typeOffer)
      .then((result) => {
        console.log(result)
        // let url = URL.createObjectURL(result);
        let a = document.createElement("a")
        a.href = `${process.env.REACT_APP_API_URL}pdf/oferta-renting-gomotor.pdf`
        a.target = "_blank"
        a.download = "oferta-renting.pdf"
        a.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const validateForm = () => {
    if (data.marca === undefined || data.marca === "") {
      return false
    } else if (data.modelo === undefined || data.modelo === "") {
      return false
    } else if (data.color === undefined || data.color === "") {
      return false
    } else if (data.cambio === undefined || data.cambio === "") {
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <FormHeader
        title={title}
        publishParticularValue={carData.publicarParticular}
        publishProfessionalValue={carData.publicarEmpresa}
        publishSelfEmployedValue={carData.publicarAutonomos}
        publishOthersValue={carData.publicarOtros}
        handleChangePublishParticular={handleChangePublishParticular}
        handleChangePublishProfessional={handleChangePublishProfessional}
        handleChangePublishSelfEmployed={handleChangePublishSelfEmployed}
        handleChangePublish={handleChangePublish}
      />
      <form
        className={classes.form}
        autoComplete='off'
        onSubmit={handleSaveButton}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Provider
              providers={providers}
              carData={carData}
              handleInputTextChange={handleInputTextChange}
              handleChangeProvider={handleChangeProvider}
              handleChangeImmediateDelivery={handleChangeImmediateDelivery}
            />
            {/* DATOS GENERALES DEL VEHÍCULO */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpand}
              >
                <Box display='flex' alignItems='center'>
                  <DirectionsCarIcon htmlColor='rgb(209, 51, 51)' />
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: "12px" }}
                  >
                    Datos generales del Vehículo
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='marca'
                  label='Marca'
                  defaultValue={carData.marca}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='modelo'
                  label='Modelo'
                  defaultValue={carData.modelo}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='version'
                  label='Versión'
                  defaultValue={carData.version}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='color'
                  label='Color'
                  defaultValue={carData.color}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='carroceria'
                  label='Tipo de Vehículo'
                  defaultValue={carData.carroceria}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='potenciaCv'
                  label='Potencia CV'
                  defaultValue={carData.potenciaCv}
                  type='number'
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='cambio'
                  label='Cambio'
                  defaultValue={carData.cambio}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='combustible'
                  label='Combustible'
                  defaultValue={carData.combustible}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='puertas'
                  label='Puertas'
                  defaultValue={carData.puertas}
                  type='number'
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='plazas'
                  label='Plazas'
                  defaultValue={carData.plazas}
                  type='number'
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='emisiones'
                  label='Emisiones'
                  defaultValue={carData.emisiones}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='franquicia'
                  label='Franquicia'
                  defaultValue={carData.franquicia}
                  type='number'
                  handleInputTextChange={handleInputTextChange}
                />
                <FormControl>
                  <InputLabel
                    style={{ fontSize: "14px", left: "10px" }}
                    id='demo-simple-select-label'
                  >
                    Etiqueta ECO
                  </InputLabel>
                  <Select
                    name='etiquetaEco'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={ecoLabel}
                    label='Etiqueta ECO'
                    size='small'
                    style={{ margin: "5px" }}
                    onChange={handleChangeEcoLabel}
                  >
                    <MenuItem value='0 emisiones'>0</MenuItem>
                    <MenuItem value='B'>B</MenuItem>
                    <MenuItem value='C'>C</MenuItem>
                    <MenuItem value='ECO'>ECO</MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            {/* PRECIOS */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display='flex' alignItems='center'>
                  <EuroIcon htmlColor='#33add1' />
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: "12px" }}
                  >
                    Precios
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column" }}>
                {/* PARTICULAR */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <PersonIcon htmlColor='#33add1' />
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: "12px" }}
                      >
                        Particulares
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Box
                      className={classes.details}
                      margin='0'
                      display='flex'
                      width='100%'
                    >
                      <ChildTextField
                        name='valorMin'
                        label='Precio mínimo particulares'
                        defaultValue={carData.precioMinParticular.valorMin}
                        type='text'
                        handleInputTextChange={handleMinPriceChange}
                      />
                      <ChildTextField
                        name='tipoPrecioMin'
                        label='Tipo precio mínimo particulares'
                        defaultValue={carData.precioMinParticular.tipoPrecioMin}
                        type='text'
                        handleInputTextChange={handleMinPriceChange}
                      />
                    </Box>
                    <PriceRentingList
                      data={carData.preciosParticular}
                      handleInputPriceChange={handleInputPriceChange}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* AUTONOMOS  */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <BadgeIcon htmlColor='#33add1' />
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: "12px" }}
                      >
                        Autónomos
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <ChildTextField
                      name='valorMin'
                      label='Precio mínimo autónomos'
                      defaultValue={carData.precioMinAutonomos.valorMin}
                      type='text'
                      handleInputTextChange={handleMinPriceFreelanceChange}
                    />
                    <ChildTextField
                      name='tipoPrecioMin'
                      label='Tipo precio mínimo autónomos'
                      defaultValue={carData.precioMinAutonomos.tipoPrecioMin}
                      type='text'
                      handleInputTextChange={handleMinPriceFreelanceChange}
                    />
                    <PriceRentingList
                      data={carData.preciosAutonomos}
                      handleInputPriceChange={handleInputPriceAutonomousChange}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* EMPRESAS */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <BusinessIcon htmlColor='#33add1' />
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: "12px" }}
                      >
                        Empresas
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <ChildTextField
                      name='valorMin'
                      label='Precio mínimo empresas'
                      defaultValue={carData.precioMinEmpresas.valorMin}
                      type='text'
                      handleInputTextChange={handleMinPriceProfessionalChange}
                    />
                    <ChildTextField
                      name='tipoPrecioMin'
                      label='Tipo precio mínimo empresas'
                      defaultValue={carData.precioMinEmpresas.tipoPrecioMin}
                      type='text'
                      handleInputTextChange={handleMinPriceProfessionalChange}
                    />
                    <PriceRentingList
                      data={carData.preciosEmpresas}
                      handleInputPriceChange={
                        handleInputPriceProfessionalChange
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            {/* EQUIPAMIENTO */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display='flex' alignItems='center'>
                  <StarsIcon htmlColor='#507fa8' />
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: "12px" }}
                  >
                    Equipamiento
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column" }}>
                <Box>
                  {/* DESTACADO */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box display='flex' alignItems='center'>
                        <WorkspacePremiumIcon htmlColor='#507fa8' />
                        <Typography
                          variant='h6'
                          component='h3'
                          style={{ marginLeft: "12px" }}
                        >
                          Destacado
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.textAreaWrapper}`}>
                      <ChildTextArea
                        name='Destacado'
                        defaultValue={formatArrayToTextList(
                          carData.equipamientoDestacado,
                          "Destacado"
                        )}
                        handleInputTextAreaChange={
                          handleInputTextAreaImportantEquipment
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/* TECNOLOGIA */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box display='flex' alignItems='center'>
                        <SettingsBluetoothIcon htmlColor='#507fa8' />
                        <Typography
                          variant='h6'
                          component='h3'
                          style={{ marginLeft: "12px" }}
                        >
                          Tecnología
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.textAreaWrapper}`}>
                      <ChildTextArea
                        name='Tecnologia'
                        defaultValue={formatArrayToTextList(
                          carData.equipamientoTecnologia,
                          "Tecnologia"
                        )}
                        handleInputTextAreaChange={
                          handleInputTextAreaTechnologyEquipment
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/* CONFORT */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box display='flex' alignItems='center'>
                        <AirlineSeatReclineExtraIcon htmlColor='#507fa8' />
                        <Typography
                          variant='h6'
                          component='h3'
                          style={{ marginLeft: "12px" }}
                        >
                          Confort
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.textAreaWrapper}`}>
                      <ChildTextArea
                        name='Confort'
                        defaultValue={formatArrayToTextList(
                          carData.equipamientoConfort,
                          "Confort"
                        )}
                        handleInputTextAreaChange={
                          handleInputTextAreaConfortEquipment
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/* SEGURIDAD */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box display='flex' alignItems='center'>
                        <SecurityIcon htmlColor='#507fa8' />
                      </Box>
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: "12px" }}
                      >
                        Seguridad
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.textAreaWrapper}`}>
                      <ChildTextArea
                        name='Seguridad'
                        defaultValue={formatArrayToTextList(
                          carData.equipamientoSeguridad,
                          "Seguridad"
                        )}
                        handleInputTextAreaChange={
                          handleInputTextAreaSecurityEquipment
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/* EXTERIOR */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box display='flex' alignItems='center'>
                        <DirectionsCarIcon htmlColor='#507fa8' />
                      </Box>
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: "12px" }}
                      >
                        Exterior
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.textAreaWrapper}`}>
                      <ChildTextArea
                        name='Exterior'
                        defaultValue={formatArrayToTextList(
                          data.equipamientoExterior,
                          "Exterior"
                        )}
                        handleInputTextAreaChange={
                          handleInputTextAreaExteriorEquipment
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box display='flex' alignItems='end' marginTop='1rem'>
              <SubmitButton text='Guardar' loading={loading} />
              <UploadImageButton
                handleUploadImage={handleUploadImage}
                text='Subir imagen'
              />
              <GeneratedOffer
                typesOffers={[
                  { name: "Particular", value: carData.publicarParticular },
                  { name: "Empresa", value: carData.publicarEmpresa },
                  { name: "Autonomos", value: carData.publicarAutonomos },
                  { name: "Otros", value: carData.publicarOtros },
                ]}
                handlePDF={handlePdfButton}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography
                variant='h6'
                component='h2'
                align='center'
                className={classes.propTitle}
              >
                Imágenes del vehículo
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
                {recursos.length === 0 ? (
                  <AlertBox
                    type='warning'
                    text='Este vehículo no tiene fotos'
                  />
                ) : (
                  recursos.map((image) => (
                    <VehicleImages
                      key={image.nombre}
                      image={image}
                      handleChangeImageProperties={handleChangeImageProperties}
                      handleDeleteImage={handleDeleteImage}
                    />
                  ))
                )}
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={open}
        severity={severityAlert}
        handleClose={() => setOpen(false)}
      >
        {alertMessage}
      </Snackbar>
    </div>
  )
}

export default CarRentingEditForm
