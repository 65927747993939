import TopHeader from '../TopHeader/TopHeader'
import Sidebar from '../Sidebar'
import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'

//** Components Imports*/
import TopActions from './components/TopActions'

//** MUI Imports */
import { Toolbar, CircularProgress } from '@mui/material'

//** Core Imports */
import PageTitle from '../../@core/components/PageTitle'
import PageTopBar from '../../@core/components/PageTopBar'
import Table from './components/Table'
import Snackbar from '../../@core/components/Snackbar'

//** Context Imports */
import { AlertContextProvider } from '../../@core/context/alertContext'
import { OFCContextProvider } from './context/dataContext'

//** Hooks Imports */
import { useData } from './hooks/useData'
import { useAlert } from '../../@core/hooks/useAlert'

import {
  StyledMain,
  StyledContent,
  StyledBackDrop,
  StyledMainSection,
} from './style'

export const StyledBox = styled.div`
  padding: 34px 24px;
`

const useStyles = makeStyles((theme) => ({}))

const CircularLoading = () => {
  const { loading } = useData()

  return (
    <StyledBackDrop open={loading}>
      <CircularProgress color='inherit' />
    </StyledBackDrop>
  )
}

const SnackBarAlert = () => {
  const { showAlert, typeAlert, setShowAlert, alertMessage } = useAlert()
  return (
    <Snackbar
      open={showAlert}
      severity={typeAlert}
      handleClose={() => setShowAlert(false)}
    >
      {alertMessage}
    </Snackbar>
  )
}

const OfertaDeCoches = () => {
  return (
    <AlertContextProvider>
      <OFCContextProvider>
        <StyledMain>
          <TopHeader />
          <Sidebar />
          <StyledContent>
            <Toolbar />
            <CircularLoading />
            <StyledMainSection>
              <PageTitle title='Stock en ofertadecoches.com' />
              <PageTopBar
                logoUrl='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1658947100/Multipublicador%20GrupOlivaMotor/logo-ofertacoches-2_s7xqfa.png'
                height={18}
              >
                <TopActions />
              </PageTopBar>
              <StyledBox>
                <Table />
              </StyledBox>
            </StyledMainSection>
          </StyledContent>
        </StyledMain>
        <SnackBarAlert />
      </OFCContextProvider>
    </AlertContextProvider>
  )
}

export default OfertaDeCoches
