import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Paper, Button, CircularProgress } from "@material-ui/core";
import AlertBox from './AlertBox'
import QuiterService from '../services/quiter.service'
import SyncRecords from '../services/syncrecords.service';

const useStyles = makeStyles((theme) => ({
  success : {
    color: 'green'
  },
  fail: {
    color: 'red'
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}))

const PortalData = ({ portalId, quiterJsonData, quiterDbData, recordsSync }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [status, setStatus] = useState('')
  const [showLoading, setShowLoading] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    if(quiterJsonData) {
      setStatus('online')
    } else {
      setStatus('Offline')
    }
  }, [quiterJsonData])


  const handleButtonSync = () => {
    setShowLoading(true)
    QuiterService.saveDataToDataBase()
      .then(data => {
        console.log(data)
        setShowAlert(true)
        setAlertMessage(`${data.message} ${data.count}`)
        setShowLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const formatDate = (date) => {
    const dateTime = {
      date: new Date(date).toLocaleDateString,
      time: new Date(date).toLocaleTimeString
    }
    return dateTime
  }

  console.log(formatDate(recordsSync[0].createdAt))

  return (
    <Grid item xs={6}>
      <Paper>
        {showAlert ? <AlertBox type="success" text={alertMessage} /> : ""}
      </Paper>
      <Paper className={classes.paper}>
        {!quiterJsonData ? (
          <CircularProgress />
        ) : (
          <div style={{ width: '100%' }}>
            <h1>
              Portal {portalId} - {' '}
              <span className={status === "online" ? classes.success : classes.fail}>
                {status} {showLoading ? <CircularProgress size={18} /> : ""}
              </span>
            </h1>
            <p>
              Fecha de la ultima importación a la BD de Quiter: 
            </p>
            <p>
              Total de vehículos enviados desde Quiter: {quiterJsonData.vehiculos.length}
            </p>
            <p>
              Total de vehículos en Base de Datos Quiter: {quiterDbData.length}
            </p>
            <p style={ { display: 'flex', width: '100%', justifyContent: 'space-between' } }>
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonSync}
              >
                Guardar datos de Quiter a la BD de Quiter
              </Button>
              <Button
                variant="contained"
                color="primary"
              >
                Registros de importaciones
              </Button>
            </p>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default PortalData;
