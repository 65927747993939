import React from "react"
import ChildTextField from "../../../ChildTextField"

const InputsEmissions = ({ handleInputChangeEmissions }) => {

  const handleInputChange = (event) => {
    handleInputChangeEmissions(event)
  }

  return (
    <React.Fragment>
      <ChildTextField
        label='NORMATIVA'
        name='normativa'
        handleInputTextChange={handleInputChange}
        defaultValue=''
      />
      <ChildTextField
        label='VALOR'
        name='valor'
        handleInputTextChange={handleInputChange}
        defaultValue=''
      />
    </React.Fragment>
  )
}

export default InputsEmissions
