import { AccordionDetails, Grid } from "@mui/material"
import Km0 from "./Km0"
import Demo from "./Demo"
import Courtesy from "./Courtesy"
import Ocasion from "./Ocasion"
import New from "./New"
import { StyledAccordion, StyledSummaryTitle, StyledSummary, CarIcon } from "./styles"

const TypeVoQuiter = ({
  dataKm0,
  dataDemo,
  dataCourtesy,
  dataOcasion,
  dataNew,
  handleClickCard,
  active,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <CarIcon size='28px' color='#1976d2' />
        <StyledSummaryTitle>Tipo de Vehículo (Quiter)</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Km0
              data={dataKm0}
              active={active}
              handleClick={() => handleClickCard("activeKm0", "km0")}
            />
          </Grid>
          <Grid item xs={3}>
            <Demo
              data={dataDemo}
              active={active}
              handleClick={() => handleClickCard("activeDemo", "demo")}
            />
          </Grid>
          <Grid item xs={3}>
            <Courtesy
              data={dataCourtesy}
              active={active}
              handleClick={() => handleClickCard("activeCourtesy", "courtesy")}
            />
          </Grid>
          <Grid item xs={3}>
            <Ocasion
              data={dataOcasion}
              active={active}
              handleClick={() => handleClickCard("activeOccasion", "ocasion")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <New
              data={dataNew}
              active={active}
              handleClick={() => handleClickCard("activeNew", "new")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default TypeVoQuiter
