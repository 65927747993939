import { Redirect, Route } from "react-router-dom"
import authService from "../services/user.service"

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (authService.checkUser()) {
          return Component ? <Component {...props} /> : render(props)
        } else {
          return <Redirect to='/login' />
        }
      }}
    />
  )
}

export default ProtectedRoute
