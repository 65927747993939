import axios from "axios"
import authHeaders from './auth-header'

const getJSON = (url) => {
  const request = axios.get(url)
  return request.then(response => response.data)
}

const secureHttp = (url, method, data=null) => {
  const http = axios.create({
    baseURL: url,
    method: method,
    headers: authHeaders(),
    data: data
  })
  const request = http.request()
  return request.then(response => response.data)
}

const secureHttpPost = (url, data) => {
  const request = axios.post(url, data, { headers: authHeaders()})
  return request.then(response => response.data)
}

const downloadFile = async (data) => {
  const myData = data

  const fileName = "file";
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const validateLoginForm = (email, password) => {
  if(email === '' || password === '') return true
}

const Utils = {
  getJSON,
  downloadFile,
  validateLoginForm,
  secureHttp,
  secureHttpPost
}

export default Utils