import { StyledPaperCard, EuroIcon } from "./styles"

const Prices = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeCheckPrices}
      onClick={() => handleClick()}
    >
      <EuroIcon color="red" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Precios</p>
      </div>
    </StyledPaperCard>
  )
}

export default Prices