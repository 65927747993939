import { useState, useEffect } from 'react'
import CampaignsService from '../services/campaign.service'

const useCampaigns = () => {
  const [campaigns, setCampaigns] = useState()

  useEffect(() => {
    CampaignsService.read()
      .then((result) => {
        setCampaigns(result)
      })
      .catch((err) => {
        alert(err)
      })
  }, [])

  const saveCampaign = (item) => {
    CampaignsService.create(item)
      .then((result) => {
        setCampaigns(campaigns.concat(result))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateCampaign = (id, data) => {
    CampaignsService.update(id, data)
      .then((result) => {
        campaigns.filter((campaign) => {
          if (campaign._id === id) {
            campaign.active = result.active
            campaign.name = result.name
            campaign.campaignText = result.campaignText
            campaign.startDate = result.startDate
            campaign.endDate = result.endDate
            campaign.campaignImage = result.campaignImage
            campaign.campaignColor = result.campaignColor
            campaign.campaignTextColor = result.campaignTextColor
            campaign.temporary = result.temporary
          }
          // campaign._id === id ? campaign.active = result.active : campaign.active
        })
        setCampaigns([...campaigns])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deleteCampaign = (id) => {
    CampaignsService.remove(id)
      .then((result) => {
        const newData = campaigns.filter((campaign) => {
          if (campaign._id !== id) return campaign
        })
        setCampaigns(newData)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return {
    campaigns,
    setCampaigns,
    saveCampaign,
    updateCampaign,
    deleteCampaign,
  }
}

export default useCampaigns
