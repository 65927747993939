import React from "react"
import ChildTextField from "../../ChildTextField"
import Data from "./utils/data"

const PriceRentingList = ({ handleInputPriceChange, data }) => {
  const getPrice = (prices, km, month) => {
    let returnPrice = ""
    prices.filter((price) => {
      if (price.km === km && price.meses === month) {
        returnPrice = parseInt(price.valor)
      }
      return 0
    })
    return returnPrice
  }

  const handleInputTextChange = (event) => {
    handleInputPriceChange(event)
  }

  return (
    <React.Fragment>
      <h4>Desde 5000 KM al año</h4>
      <br></br>
      {Data.price5000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
      <h4>Desde 10000 KM al año</h4>
      <br></br>
      {Data.price10000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
      <h4>Desde 15000 KM al año</h4>
      <br></br>
      {Data.price15000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
      <h4>Desde 20000 KM al año</h4>
      <br></br>
      {Data.price20000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
      <h4>Desde 25000 KM al año</h4>
      <br></br>
      {Data.price25000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
      <h4>Desde 30000 KM al año</h4>
      <br></br>
      {Data.price30000.map((e) => {
        return (
          <ChildTextField
            key={e.tipo}
            name={e.tipo}
            label={e.meses + " meses"}
            handleInputTextChange={handleInputTextChange}
            type='number'
            defaultValue={data ? getPrice(data, e.km, e.meses) : ""}
          />
        )
      })}
    </React.Fragment>
  )
}

export default PriceRentingList
