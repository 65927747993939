const Lexus = ({ width }) => {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 49.9 49.9'
      width={width ? width : ""}
    >
      <path
        d='M25.9,14.7c-1.9,2.3-7.1,9.7-7.7,10.6c-1.2,1.8-1.3,2.7-1.3,3.3c0,0.6,0,1.6,0.9,2.5c1.6,1.4,3.8,1.2,5.8,1.2h16.2
	c0.7,0,0.9-0.2,1.3-0.7c1.3-2,1.9-4.1,1.9-6c0-4.1-2.7-9.3-9.4-11.6l-0.8,1.1c5.4,2.5,7.6,5.9,7.6,10.5c0,1.3-0.3,2.6-1.1,3.9H23.4
	c-0.9,0-1.1-1.1-0.7-1.7c1.4-2.4,2.9-4.7,4.5-6.9c1.6-2.1,3.2-4.1,4.8-6.2c0.4-0.6,0.1-1.1-0.7-1.3c-1.8-0.4-3.8-0.7-6.1-0.7
	C12.2,12.6,7.5,20,7.5,25.5c0,6,4.8,12.9,17.7,12.9c7.2,0,11.9-2.3,14.6-5.3H37c-2.4,2.2-6.5,3.8-11.8,3.8c-10.9,0-15-6.1-15-11.4
	c0.1-4.7,4.4-11.2,15.2-11.5C25.9,13.9,26.2,14.3,25.9,14.7L25.9,14.7z'
      />
    </svg>
  )
}

export default Lexus
