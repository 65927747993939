import { Accordion, Paper, AccordionSummary } from "@mui/material"
import { LocalOffer } from "@mui/icons-material"
import styled from "@emotion/styled"

export const StyledPaperCard = styled(Paper)`
  cursor: pointer;
  display: flex;
  text-align: right;
  align-items: center;
  padding-top: 16px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 16px;
  justify-content: space-between;
  textAlign: right;
  background-color: ${props => props.active ?  '#e6e6e6' : '' };
  border-left: ${props => props.active ?  '5px solid #507fa8' : '5px solid #cdcdcd;' };
  transition: all 0.2s ease;
  & h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  & p {
    margin: 0;
    color: gray;
  }
  &:hover {
    background: #e6e6e6;
    border-left: ${props => props.active ?  '5px solid #507fa8' : '5px solid #a7a7a7' };
  }
`

export const StyledAccordion = styled(Accordion)`
  margin-top: 16px;
  border-top: 3px solid #d5d5d5;
  border-radius: 2px;
`

export const StyledSummary = styled(AccordionSummary)({
  ".MuiAccordionSummary-content": {
    alignItems: 'center'
  }
})

export const StyledSummaryTitle = styled.h4`
  margin: 0 12px;
`
export const OfferIcon = styled(LocalOffer)`
  color: ${props => props.color};
  font-size: ${props => props.size ? props.size : '38px'};
`