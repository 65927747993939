import axios from 'axios'
import authHeaders from '../../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const read = () => {
  const request = http.get('/publishing/api/v1/aramis/vehicles/')
  return request.then((response) => response.data)
}

const create = (data) => {
  const request = http.post('/aramis-francia', data)
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.put(`/aramis-francia/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/aramis-francia/${id}`)
  return request.then((response) => response.data)
}

const createCSV = () => {
  const request = http.get('/aramis-francia/csv')
  return request.then((response) => response.data)
}

const sync = () => {
  const request = http.get('/publishing/api/v1/aramis/vehicles/sync')
  return request.then((response) => response.data)
}

const dropDatabase = () => {
  const request = http.get('/publishing/api/v1/aramis/vehicles/drop')
  return request.then((response) => response.data)
}

const updateStock = () => {
  const request = http.get('/publishing/api/v1/aramis/vehicles/update-stock')
  return request.then((response) => response.data)
}

const AramisService = {
  read,
  create,
  update,
  remove,
  createCSV,
  sync,
  dropDatabase,
  updateStock
}

export default AramisService
