import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ChildTextField from "../../ChildTextField"
import DatePicker from "../../DatePicker"
import MotosService from "../../../services/motos.service"
import SnackBarAlert from "../../SnackbarAlert"
import { Paper, Divider } from "@material-ui/core"
import MotoPhoto from "./../MotoEdit/MotoPhoto"
import AlertBox from "../../AlertBox"
import GomotorEditFormEquipment from "./GomotorEditFormEquipment"
import GomotorService from "../../../services/gomotor.service"
import { Button } from "@material-ui/core"
import PageTopTitle from "../../PageTopTitle"
import ChildSwitch from "../../ChildSwicth"
import SubmitButton from '../../buttons/SubmitButton'

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text], input[type=date]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
    marginTop: "12px",
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  propTitle: {
    marginTop: "10px",
    marginBottom: "25px",
  },
}))

const GomotorEditForm = ({ data }) => {
  const [expanded, setExpanded] = useState(true)
  const [carData, setCarData] = useState(data)
  const [open, setOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const classes = useStyles()

  const handleAlert = (value) => {
    setOpen(value)
  }

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const handleDateChange = (date) => {
    carData.fechaMatriculacion = new Date(date).toISOString()
  }

  const handleInputTextChange = (event) => {
    setCarData({
      ...carData,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputTextAreaChange = (event) => {
    carData.observaciones = event.target.value
  }

  const handleSaveButton = (event) => {
    // console.log(event)
    event.preventDefault()
    const filter = {
      _id: carData._id,
    }
    const update = carData

    GomotorService.update(carData._id, {
      filter,
      update,
    })
      .then((result) => {
        console.log(result)
        setResponseMessage(result.message)
        handleAlert(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleAddEquipment = (equipments) => {
    carData.equipamiento = equipments
  }

  const handleChangeSwicth = (value, name) => {
    carData[name] = value
    setCarData({ ...carData })
  }

  const handleChangeImageProperties = (image) => {
    //console.log(image)

    const filter = {
      _id: carData._id,
      recursos: {
        $elemMatch: { _id: image._id },
      },
    }

    const update = {
      $set: {
        "recursos.$.propiedades": image.propiedades,
      },
    }

    MotosService.update(carData._id, {
      filter,
      update,
    })
      .then((result) => {
        console.log(result)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChangeConsum = (event) => {
    carData.consumos[2].valor = event.target.value
    setCarData({...carData})
  }

  const handleChangePriceFin = (event) => {
    carData.precios[0].valor =  Number(event.target.value)
    setCarData({...carData})
  }

  const handleChangePriceCon = (event) => {
    carData.precios[3].valor = Number(event.target.value)
    setCarData({...carData})
  }

  console.log(carData)

  return (
    <div>
      <PageTopTitle title={`Editar IDV: ${carData.idv}`}>
        <ChildSwitch
          value={carData.destacado}
          name='destacado'
          label='Destacado'
          handleChangeSwitch={(value) => handleChangeSwicth(value, 'destacado')}
        />
        <ChildSwitch
          value={carData.publicacionManual}
          name='publicacionManual'
          label='Publicación Manual'
          handleChangeSwitch={(value) => handleChangeSwicth(value, 'publicacionManual')}
        />
        <ChildSwitch
          value={carData.reservado}
          name='reservado'
          label='Reservado'
          handleChangeSwitch={(value) => handleChangeSwicth(value, 'reservado')}
        />
      </PageTopTitle>
      <form className={classes.form} noValidate autoComplete='off' onSubmit={handleSaveButton}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpand}
              >
                <Typography variant='h6' component='h3'>
                  Datos generales del Vehículo
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='marca'
                  label='Marca'
                  defaultValue={carData.marca}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='modelo'
                  label='Modelo'
                  defaultValue={carData.modelo}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='version'
                  label='Versión'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.version}
                />
                <ChildTextField
                  name='color'
                  label='Color'
                  defaultValue={carData.color}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='potenciaCv'
                  label='Potencia CV'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.potenciaCv}
                />
                <ChildTextField
                  name='km'
                  label='KM'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.km}
                />
                <ChildTextField
                  name='combustible'
                  label='Combustible'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.combustible}
                />
                <ChildTextField
                  name='cambio'
                  label='Cambio'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.cambio}
                />
                <ChildTextField
                  name='garantia'
                  label='Garantía (Meses)'
                  defaultValue={carData.garantia}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='tipoVO'
                  label='TipoVO'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={carData.tipoVehiculoWeb}
                />
                <DatePicker
                  label='Fecha Matriculación'
                  date={carData.fechaMatriculacion}
                  handleDateChange={handleDateChange}
                />
                <ChildTextField
                  name={carData.consumos[2].tipoConsumo}
                  label='Consumo'
                  handleInputTextChange={handleChangeConsum}
                  defaultValue={carData.consumos[2].valor}
                />
                <ChildTextField
                  name={carData.precios[0].tipo}
                  label='Precio Contado'
                  handleInputTextChange={handleChangePriceFin}
                  defaultValue={carData.precios[0].valor}
                  type='number'
                />
                <ChildTextField
                  name={carData.precios[3].tipo}
                  label='Precio Financiado'
                  handleInputTextChange={handleChangePriceCon}
                  defaultValue={carData.precios[3].valor}
                  type='number'
                />
              </AccordionDetails>
            </Accordion>
{/*             <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Observaciones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextArea
                  name='observaciones'
                  defaultValue={carData.observaciones}
                  handleInputTextAreaChange={handleInputTextAreaChange}
                />
              </AccordionDetails>
            </Accordion> */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Equipamiento
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {carData.equipamiento.map((element) => (
                  <span style={{ display: "block", width: "100%" }}>
                    <ChildTextField
                      label='CODIGO'
                      defaultValue={element.codigo}
                    />
                    <ChildTextField label='TIPO' defaultValue={element.tipo} />
                    <ChildTextField
                      label='PRECIO'
                      defaultValue={element.precio}
                    />
                    <ChildTextField
                      label='DESCRIPCIÓN'
                      defaultValue={element.descripcion}
                    />
                  </span>
                ))}
              </AccordionDetails>
            </Accordion>
            <SubmitButton text='Guardar Vehículo' />
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography
                variant='h6'
                component='h2'
                align='center'
                className={classes.propTitle}
              >
                Imágenes del vehículo
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
                {carData.recursos.length === 0 ? (
                  <AlertBox
                    type='warning'
                    text='Este vehículo no tiene fotos'
                  />
                ) : (
                  carData.recursos.map((image) => (
                    <MotoPhoto
                      image={image}
                      handleChangeImageProperties={handleChangeImageProperties}
                    />
                  ))
                )}
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <SnackBarAlert isOpen={open} text={responseMessage} handleAlert={handleAlert} />
    </div>
  )
}

export default GomotorEditForm
