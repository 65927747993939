import axios from 'axios'
import authHeaders from '../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const read = (signal) => {
  const request = http.get('/publishing/api/v1/wallapop/vehicles', { signal })
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.patch(`/publishing/api/v1/wallapop/vehicles/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/publishing/api/v1/wallapop/vehicles/${id}`)
  return request.then((response) => response.data)
}

const sync = () => {
  const request = http.get('/publishing/api/v1/wallapop/vehicles/sync')
  return request.then((response) => response.data)
}

const getVehiclesByAccount = (account) => {
  const request = http.get(
    `/publishing/api/v1/wallapop/vehicles?account=${account}`
  )
  return request.then((response) => response.data)
}

const updateXmlFiles = () => {
  const request = http.get(
    '/publishing/api/v1/wallapop/vehicles/update-xml-files'
  )
  return request.then((response) => response.data)
}

const Services = {
  read,
  update,
  remove,
  sync,
  getVehiclesByAccount,
  updateXmlFiles,
}

export default Services
