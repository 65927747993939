import { StyledPaperCard, FuelIcon } from "./styles"

const Electric = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeElectric}
      onClick={() => handleClick()}
    >
      <FuelIcon color="#3891ff" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Eléctrico</p>
      </div>
    </StyledPaperCard>
  )
}

export default Electric
