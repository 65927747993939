import TarrautoIcon from "../../../../Icons/Tarrauto"
import { StyledPaperCard } from "./styles"

const Tarrauto = ({ data, active, handleClick }) => {
  
  return (
    <StyledPaperCard
    active={active.activeLpTarrauto}
    onClick={() => handleClick()}
    >
      <TarrautoIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Tarrauto</p>
      </div>
    </StyledPaperCard>
  )
}

export default Tarrauto