import { useState, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconCar } from '../Icons'
import { validBrands, validYears, validFuels } from '../../data'
import CnetApiServices from '../../services'

const BrandAndModel = ({ context }) => {
  const {
    brand,
    setBrand,
    year,
    setYear,
    models,
    setModels,
    setFuelType,
    setModel,
    setNumOfDoors,
    setCarBody,
  } = useContext(context)

  const [expanded, setExpanded] = useState(false)
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getModel = async (event) => {
    setFuelType(event.target.value)

    const response = await CnetApiServices.getModels(
      brand,
      year,
      event.target.value
    )
    const models = response

    setModels(models)
  }

  const getCarBody = async (event) => {
    setModel(event.target.value)

    const bodyTypes = await CnetApiServices.getBodyTypes(
      brand,
      event.target.value,
      year
    )
    const numOfDoors = await CnetApiServices.getNumOfDoors(
      brand,
      event.target.value,
      year
    )

    setNumOfDoors(numOfDoors)
    setCarBody(bodyTypes)
  }

  return (
    <Accordion defaultExpanded onChange={handleExpand('panel1')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
          <IconCar color='#e10000' size={24} />
          <Typography variant='h6'>Marca y modelo</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction='row' spacing={2}>
          <FormControl
            size='small'
            variant='outlined'
            sx={{ width: '250px' }}
            required
          >
            <InputLabel id='input-brand'>Marca</InputLabel>
            <Select
              labelId='input-brand'
              id='demo-brand'
              onChange={(event) => setBrand(event.target.value)}
              label='Marca'
              defaultValue=''
            >
              <MenuItem value=''>
                <em>Sin selección</em>
              </MenuItem>
              {validBrands.map((brand) => {
                return (
                  <MenuItem key={brand.key} value={brand.key}>
                    {brand.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl
            size='small'
            variant='outlined'
            sx={{ width: '250px' }}
            required
          >
            <InputLabel id='input-year'>Año de fabricación</InputLabel>
            <Select
              labelId='input-year'
              id='demo-year'
              onChange={(event) => setYear(event.target.value)}
              label='Año de fabricación'
              defaultValue=''
            >
              <MenuItem value=''>
                <em>Sin selección</em>
              </MenuItem>
              {validYears.map((year) => {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl
            size='small'
            variant='outlined'
            sx={{ width: '250px' }}
            required
          >
            <InputLabel id='input-fuel'>Combustible</InputLabel>
            <Select
              labelId='input-fuel'
              id='demo-fuel'
              onChange={getModel}
              label='Combustible'
              defaultValue=''
            >
              <MenuItem value=''>
                <em>Sin selección</em>
              </MenuItem>
              {validFuels.map((fuel) => {
                return (
                  <MenuItem key={fuel.key} value={fuel.key}>
                    {fuel.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' required>
            <InputLabel id='input-model'>Modelo</InputLabel>
            <Select
              labelId='input-model'
              id='demo-model'
              label='Modelo'
              defaultValue=''
              onChange={getCarBody}
            >
              <MenuItem value=''>
                <em>Sin selección</em>
              </MenuItem>
              {models.map((e) => {
                return (
                  <MenuItem key={e.key} value={e.key}>
                    {e.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BrandAndModel
