import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'

const SnackBarAlert = ({ isOpen, type, text, handleAlert }) => {
  const handleClose = (event, reason) => {
    handleAlert(false)
  };
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}  
    >
      <Alert onClose={handleClose} severity={type}>
          {text}
        </Alert>
    </Snackbar>
  )
}

export default SnackBarAlert