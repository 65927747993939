import axios from 'axios'
import authHeader from './auth-header'

const authToken = authHeader()

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...authToken
  }
})
