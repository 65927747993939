import { FETCH_UNREAD_NOTIFICATIONS, MARK_READ_NOTIFICATION, FETCH_ALL_NOTIFICATIONS, DELETE, DELETE_BULK } from '../actions/constants'

const notificationReducer = (state = { notifications: [] }, action) => {
  switch (action.type) {
    case FETCH_ALL_NOTIFICATIONS:
      return {
        notifications: action.payload
      }
    case FETCH_UNREAD_NOTIFICATIONS:
      return {
        notifications: action.payload
      }

    case MARK_READ_NOTIFICATION:
      return {
        notifications: state.notifications.map((notification) => (notification._id === action.payload._id ? action.payload : notification))
      }
    case DELETE:
      return { notifications: state.notifications.filter((notification) => notification._id !== action.payload) }
    case DELETE_BULK:
      return { 
        notifications: state.notifications.filter(({ _id: a }) => !action.payload.some(({ _id: b }) => b === a))
      }
    default:
      return state
  }
}

export default notificationReducer
