//** React Imports */
import { useEffect, useState } from 'react'

//** Core components Imports */
import PageTitle from '../../../@core/components/PageTitle'
import TableDataSticky from '../../../@core/components/TableDataSticky'
import Sidebar from '../../Sidebar'
import TopHeader from '../../TopHeader/TopHeader'

/** MUI Imports */
import { Toolbar } from '@material-ui/core'

//** Styled Components Imports */
import { StyledContent, StyledMain } from '../../../@core/styles/style'

//** Services imports */
import WallapopSettingsService from './services'

const columns = [
  { field: '_id', headerName: 'ID', width: 220 },
  {
    field: 'accountId',
    headerName: 'Id de cuenta',
    width: 140,
    editable: true,
  },
  { field: 'name', headerName: 'Nombre', width: 180, editable: true },
  { field: 'ftp_user', headerName: 'Usuario FTP', width: 180, editable: true },
  {
    field: 'ftp_password',
    headerName: 'Contraseña FTP',
    width: 180,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Descripción de la cuenta',
    width: 380,
    editable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Creación',
    width: 140,
  },
]

const WallapopSettings = () => {
  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    let mounted = true
    setLoading(true)
    ;(async () => {
      const accounts = await WallapopSettingsService.read()
      if (mounted) {
        setAccounts(accounts)
        setLoading(false)
      }
    })()

    return () => (mounted = false)
  }, [])

  return (
    <StyledMain>
      <TopHeader />
      <Sidebar />
      <StyledContent>
        <Toolbar />
        <div style={{ padding: '24px' }}>
          <PageTitle title='Ajustes de la pasarela Wallapop' />
          <TableDataSticky
            data={accounts}
            defaultColumns={columns}
            loading={loading}
            title='Cuentas'
            apiService={WallapopSettingsService}
          />
        </div>
      </StyledContent>
    </StyledMain>
  )
}

export default WallapopSettings
