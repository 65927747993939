import { useState } from "react"
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material"
import { ExpandMore, Business } from "@mui/icons-material"
import { StyledAccordionDetails } from "./style"
import ChildTextField from "../../../ChildTextField"

const Provider = ({
  providers,
  carData,
  handleChangeProvider,
  handleInputTextChange,
  handleChangeImmediateDelivery,
}) => {
  const [provider, setProvider] = useState(carData ? carData.provider : "")
  const [immediateDelivery, setImmediateDelivery] = useState(
    carData.immediate_delivery ? carData.immediate_delivery : false
  )

  const handleTextChange = (event) => {
    handleInputTextChange(event)
  }

  const handleProvider = (event) => {
    setProvider(event.target.value)
    handleChangeProvider(event)
  }

  const handleChangeDelivery = (event) => {
    setImmediateDelivery(event.target.checked)
    handleChangeImmediateDelivery(event.target.checked)
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display='flex' alignItems='center'>
          <Business htmlColor='#507fa8' />
        </Box>
        <Typography variant='h6' component='h3' style={{ marginLeft: "12px" }}>
          Proveedor
        </Typography>
      </AccordionSummary>
      <StyledAccordionDetails>
        <FormControl>
          <InputLabel style={{ fontSize: "14px", left: "10px" }} id='provider'>
            Proveedor
          </InputLabel>
          <Select
            name='provider'
            labelId='provider'
            id='provider'
            value={provider}
            label='Proveedor'
            size='small'
            style={{ margin: "5px" }}
            onChange={handleProvider}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {providers.map((provider) => {
              return (
                <MenuItem key={provider.id} value={provider._id}>
                  {provider.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <ChildTextField
          name='idOferta'
          label='ID Oferta'
          defaultValue={carData ? carData.idOferta : ""}
          handleInputTextChange={handleTextChange}
        />
        <ChildTextField
          name='stock'
          label='Unidades disponibles'
          defaultValue={carData ? carData.stock : ""}
          handleInputTextChange={handleTextChange}
        />
        <FormControlLabel
          sx={{ marginLeft: "12px" }}
          control={
            <Switch
              checked={immediateDelivery}
              value={immediateDelivery}
              name='immediate_delivery'
              color='primary'
              onChange={handleChangeDelivery}
            />
          }
          label='Entrega inmediata'
        />
      </StyledAccordionDetails>
    </Accordion>
  )
}

export default Provider
