import React from "react"
import ChildTextField from "../../../ChildTextField"
import DatePicker from "../../../DatePicker"

const InputsGenerals = ({ handleInputTextChange, handleDateChange }) => {

  const handleInputChange = (event) => {
    handleInputTextChange(event)
  }

  const handleDate = (date) => {
    handleDateChange(date)
  }

  return (
    <React.Fragment>
      <ChildTextField
      name='marca'
      label='Marca'
      defaultValue=''
      handleInputTextChange={handleInputChange}
      required={true}
    />
    <ChildTextField
      name='modelo'
      label='Modelo'
      defaultValue=''
      handleInputTextChange={handleInputChange}
      required={true}
    />
    <ChildTextField
      name='codigoModelo'
      label='Cod Modelo'
      defaultValue=''
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='version'
      label='Versión'
      defaultValue=''
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='tipo'
      label='Tipo'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='tipoVehiculo'
      label='Tipo Vehículo'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='pais'
      label='País'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='idConcesion'
      label='ID Concesión'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='bastidor'
      label='Bastidor'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='carroceria'
      label='Carrocería'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField name='color' label='Color' />
    <ChildTextField
      name='codigoColor'
      label='Cod Color'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='potenciaCv'
      label='Potencia CV'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='km'
      label='KM'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='matricula'
      label='Matricula'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='combustible'
      label='Combustible'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='colorInterior'
      label='Color Interior'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='cambio'
      label='Cambio'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='transmision'
      label='Transmisión'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='potenciaKw'
      label='Potencia KW'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='aceleracion'
      label='Aceleración'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='velocidadMaxima'
      label='Velocidad MAX'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='peso'
      label='Peso'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='normativa'
      label='Normativa'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='garantia'
      label='Garantía'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='categoria'
      label='Categoría'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='ubicacion'
      label='Ubicación'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='tipoVO'
      label='TipoVO'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='nombreConcesionario'
      label='Nombre concesionario'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='impFinanciacion'
      label='Imp Financiación'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='liquidacion'
      label='Liquidación'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='actualizado'
      label='Actualizado'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='traccion'
      label='Tracción'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='presReacondicionamiento'
      label='Pres Reacondicionamiento'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='metalizado'
      label='Metalizado'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='ivaDeducible'
      label='IVA Deducible'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='carfax'
      label='CarFax'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='puertas'
      label='Puertas (Número)'
      handleInputTextChange={handleInputChange}
      type='number'
    />
    <ChildTextField
      name='plazas'
      label='Plazas (Número)'
      handleInputTextChange={handleInputChange}
      type='number'
    />
    <ChildTextField
      name='marchas'
      label='Marchas (Número)'
      handleInputTextChange={handleInputChange}
      type='number'
    />
    <ChildTextField
      name='cilindros'
      label='Cilindros (Número)'
      handleInputTextChange={handleInputChange}
      type='number'
    />
    <ChildTextField
      name='reservado'
      label='Reservado'
      handleInputTextChange={handleInputChange}
    />
    <DatePicker
      label='Fecha Matriculación'
      handleDateChange={handleDate}
    />
    <ChildTextField
      name='anyoModelo'
      label='Año Modelo'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='mesModelo'
      label='Mes Modelo'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='numPropietariosAnteriores'
      label='Nº Propietarios Anteriores'
      handleInputTextChange={handleInputChange}
    />
    <ChildTextField
      name='importacion'
      label='Importación'
      handleInputTextChange={handleInputChange}
    />
    </React.Fragment>
  )
}

export default InputsGenerals
