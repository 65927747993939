import styled from "@emotion/styled"
import { Accordion } from "@mui/material"
export const StyledRoot = styled.div`
  display: flex;
`

export const StyledContent = styled.main`
  flex-grow: 1;
  padding: 24px;
`

export const StyledAccordion = styled(Accordion)`
  margin-top: 16px;
  padding: 0 16px;
`