import AlertBox from "../../AlertBox"
import { StyledBox } from "./styles"

const Alert = ({ open, errorMessage, successMessage, handleClose }) => {
  const handleCloseAlert = () => {
    handleClose()
  }
  
  return (
    <StyledBox>
      {errorMessage ? (
        <AlertBox
          type='error'
          text={errorMessage}
          open={open}
          handleViewAlert={handleCloseAlert}
        />
      ) : null}
      {successMessage ? (
        <AlertBox
          type='success'
          text={successMessage}
          open={open}
          handleViewAlert={handleCloseAlert}
        />
      ) : null}
    </StyledBox>
  )
}

export default Alert
