import { Backdrop } from '@mui/material'
import styled from "@emotion/styled"

export const StyledMain = styled.div`
  display: flex;
  width: 100%;
  min-height: 90vh;
`

export const StyledContent = styled.main`
  flex-grow: 1;
  width: 100%;
`

export const StyledBackDrop = styled(Backdrop)`
  color: white;
  z-index: 2;
`

export const StyledMainSection = styled.div`
  display: grid;
  //place-items: center;
  min-height: 70vh;
  padding: 0 24px;
  background-color: #f5f5f5;
`