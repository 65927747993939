import { makeStyles } from "@material-ui/core/styles"
import { Toolbar, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  mainTopHeader: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: '22px'
  },
}))

const PageTopTitle = ({ title, children }) => {
  const classes = useStyles()

  return (
    <div>
      <Toolbar />
      <div className={classes.mainTopHeader}>
        <Typography variant='h5' component='h2'>
          <b>{title}</b>
        </Typography>
        {children}
      </div>
    </div>
  )
}

export default PageTopTitle
