const columns = [
  {
    title: 'IDV',
    field: 'vehicle_id'
  },
  {
    title: 'MARCA',
    field: 'make_label'
  },
  {
    title: 'MODELO',
    field: 'model_label'
  },
  {
    title: 'VERSION',
    field: 'motorisation_label',
    width: '500px'
  },
  {
    title: 'FINISH LABEL',
    field: 'finish_label',
    hidden: true
  },
  {
    title: 'CATEGORIA',
    field: 'category_label'
  },
  {
    title: 'PRECIO',
    field: 'catalog_price_included_taxes_with_options'
  },
  {
    title: 'PRECIO ARAMIS',
    field: 'precio_aramis'
  },
  {
    title: 'PRECIO EXP',
    field: 'exp_price'
  },
  {
    title: 'TIPO VEHÍCULO',
    field: 'vehicle_type',
    hidden: true
  },
  {
    title: 'BASTIDOR',
    field: 'chassis',
    hidden: true
  },
  {
    title: 'ETQ. COLOR',
    field: 'color_label',
    width: '500px',
    hidden: true
  },
  {
    title: 'ETQ. COLOR SIMPLE',
    field: 'simple_color_label',
    hidden: true
  },
  {
    title: 'COMBUSTIBLE',
    field: 'energy_label',
    hidden: true
  },
  {
    title: 'GENERO',
    field: 'gender',
    hidden: true
  },
  {
    title: 'CAMBIO',
    field: 'gearbox_label',
    hidden: true
  },
  {
    title: 'PUERTAS',
    field: 'doors',
    hidden: true
  },
  {
    title: 'MARCHAS',
    field: 'number_of_gears',
    hidden: true
  },
  {
    title: 'PLAZAS',
    field: 'seats',
    hidden: true
  },
  {
    title: 'DIN POWER',
    field: 'din_power',
    hidden: true
  },
  {
    title: 'FISCAL POWER',
    field: 'fiscal_power',
    hidden: true
  },
  {
    title: 'KWH POWER',
    field: 'kwh_power',
    hidden: true
  },
  {
    title: 'CILINDRADA',
    field: 'cylinder',
    hidden: true
  },
  {
    title: 'CO2 NEDC',
    field: 'co2_emission_nedc',
    hidden: true
  },
  {
    title: 'CO2 WLTP',
    field: 'co2_emission_wltp'
  },
  {
    title: 'GOM CO2',
    field: 'gom_co2'
  },
  {
    title: 'FECHA MATRICULACIÓN',
    field: 'first_circulation_date',
    hidden: true
  },
  {
    title: 'DISPONIBILIDAD',
    field: 'availability',
    hidden: true
  },
  {
    title: 'KM',
    field: 'mileage',
    hidden: true
  },
  {
    title: 'GARANTIA',
    field: 'warranty',
    hidden: true
  },
  {
    title: 'UBICACIÓN',
    field: 'localisation',
    hidden: true
  },
  {
    title: 'PUB MANUAL',
    field: 'publicacion_manual',
    type: 'boolean',
    hidden: true
  },
  {
    title: 'PESO EN VACIO',
    field: 'unloaded_weight'
  }
]

const dataUtils = {
  columns
}

export default dataUtils
