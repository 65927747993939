import { createContext, useEffect, useState } from 'react'
import ApiService from '../components/pages/QuiterStock/services'

export const QuiterContext = createContext()

export const QuiterContextProvider = ({ children }) => {
  const [quiterData, setQuiterData] = useState([])
  const [isError, setIsError] = useState(false)
  const [quiterFilterData, setQuiterFilterData] = useState([])

  useEffect(() => {
    ApiService.getStock()
      .then((result) => {
        setIsError(false)
        setQuiterData(result)
      })
      .catch((err) => {
        setIsError(true)
        console.error(err)
      })
  }, [])

  return (
    <QuiterContext.Provider
      value={{
        quiterData,
        setQuiterData,
        quiterFilterData,
        setQuiterFilterData,
        isError,
      }}
    >
      {children}
    </QuiterContext.Provider>
  )
}
