import styled from "@emotion/styled"
import { Paper } from '@mui/material'

export const StyledBox = styled.div`
  padding: 34px 24px;
  max-width: 100%;
`

export const StyledBoxMain = styled.div`
  padding: 0px 24px;
`

export const StyledPaper = styled(Paper)`
  padding: 14px;
  border-left: ${props => props.border ? '5px solid #ff4a3a' : 'none' }
`

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h3 {
    margin: 0;
  }
`

export const StyledLogSection = styled.div`
  margin-bottom: 16px;
`

export const StyledCheckBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`
