import { useContext } from "react"
import { StyledContainer, StyledSection, StyledLogSection } from "./style"
import { Button, Stack } from "@mui/material"
import LogSection from "./LogSection"
import CnetService from "../../services/cnet.service"

const Log = ({ context }) => {
  const { setShowLog, actions, setActions, setLoading } = useContext(context)

  const handleButton = () => {
    setShowLog(false)
  }

  const handleReloadButton = () => {
    setLoading(true)
    CnetService.readActions().then((result) => {
      setLoading(false)
      setActions(result)
    })
  }

  return (
    <StyledContainer>
      <StyledSection>
        <h1>Acciones:</h1>
        <Stack direction='row' gap={2}>
          <Button variant='contained' size='small' onClick={handleButton}>
            Volver atrás
          </Button>
          <Button variant='contained' size='small' onClick={handleReloadButton}>
            Recargar
          </Button>
        </Stack>
      </StyledSection>
      <StyledLogSection>
        <LogSection data={actions} title='Test DEMO' accountName='Test DEMO' />
      </StyledLogSection>
      <StyledLogSection>
        <LogSection
          data={actions}
          title='GOmotor TARRAGONA'
          accountName='GOmotor TARRAGONA'
        />
      </StyledLogSection>
      <StyledLogSection>
        <LogSection
          data={actions}
          title='GOmotor GIRONA'
          accountName='GOmotor GIRONA'
        />
      </StyledLogSection>
    </StyledContainer>
  )
}

export default Log
