import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const read = () => {
  const request = http.get('/notifications')
  return request.then(response => response.data)
}

const update = (id, data) => {
  const request = http.put(`/notifications/${id}`, data)
  return request.then(response => response.data)
}

const remove = (id) => {
  const request = http.delete(`/notifications/${id}`)
  return request.then(response => response.data)
}

const bulkDelete = (data) => {
  const request = http.post('/notifications/bulk-delete', data)
  return request.then(response => response.data)
}

const NotificationService = {
  read,
  update,
  remove,
  bulkDelete
}

export default NotificationService
