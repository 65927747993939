import { useEffect, useState } from 'react'
import AramisService from '../services/aramis.service'

const useAramis = () => {
  const [data, setData] = useState([])
  const [sumToPrice, setSumToPrice] = useState()
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    AramisService.read()
      .then((result) => {
        console.log(result)
        setData(result.data)
      })
      .then((err) => console.log(err))
  }, [])

  const setPriceToAramis = (value) => {
    setShowLoading(true)
    const sum = value ?? 1000

    const updateLoop = async () => {
      try {
        for (let i = 0; i < data.length; i++) {
          data[i].precio_aramis =
            parseInt(data[i].catalog_price_included_taxes_with_options / 1.21) +
            parseInt(sum)
          await AramisService.update(data[i]._id, data[i])
        }
        return data
      } catch (error) {
        return error
      }
    }

    return new Promise((resolve, reject) => {
      updateLoop()
        .then(() => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const createCSV = () => {
    setShowLoading(true)
    return new Promise((resolve, reject) => {
      AramisService.createCSV()
        .then((result) => {
          setShowLoading(false)
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const dropDatabase = async () => {
    try {
      setShowLoading(true)
      const result = await AramisService.dropDatabase()
      setShowLoading(false)
      return result
    } catch (error) {
      setShowLoading(false)
      return error
    }
  }

  const updateStock = async () => {
    try {
      setShowLoading(true)
      const result = await AramisService.updateStock()
      setShowLoading(false)
      return result
    } catch (error) {
      setShowLoading(false)
      return error
    }
  }

  return {
    data,
    setData,
    setPriceToAramis,
    sumToPrice,
    setSumToPrice,
    showLoading,
    setShowLoading,
    createCSV,
    dropDatabase,
    updateStock
  }
}

export default useAramis
