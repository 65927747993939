import axios from 'axios'
import authHeaders from './auth-header'
import jwt from "jsonwebtoken"

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const checkUser = () => {
  const user = JSON.parse(localStorage.getItem('usuario'))
  return user ? true : false
}

const verifyToken = async () => {
  const user = JSON.parse(localStorage.getItem('usuario'))
  let result
  if(user) {
    const token = user.accessToken
  
    result = await jwt.verify(token, 'quiter-json-server-local', (err, decoded) =>{
      if(err) {
        console.log('token invaldo')
        return false
      } else {
        console.log('token valido')
        return true
      }
    })
  }
  return result
}

const getUser = (id) => {
  const request = http.get(`/users/${id}`)
  return request.then(response => response.data)
}

const updateUser = (data) => {
  const request = http.put(`/users/${data.id}`, data)
  return request.then(response => response.data)
}

const UserService = {
  verifyToken,
  checkUser,
  getUser,
  updateUser
}

export default UserService