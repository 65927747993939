import CitroenIcon from "../../../../Icons/Citroen"
import { StyledPaperCard } from "./styles"

const Citroen = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      elevation={2}
      active={active.activeLpCitroen}
      onClick={() => handleClick()}
    >
      <CitroenIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Citroen</p>
      </div>
    </StyledPaperCard>
  )
}

export default Citroen
