import { makeStyles } from "@material-ui/core/styles"
import { Modal, Button } from "@material-ui/core"
import { useState, useRef } from "react"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const ConfirmModal = ({
  dataSelection,
  action,
  open,
  handleActivate,
  handleDeactivate,
  handleCloseModal,
  isActivated,
}) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id='simple-modal-title'>⚠ Atención</h2>
      <p id='simple-modal-description'>
        ¿Seguro que quiere realizar esta acción?
      </p>
      <p>
        {action}: {dataSelection.length}
      </p>
      <br />
      <br />
      <div>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={() =>
            isActivated ? handleActivate(action) : handleDeactivate(action)
          }
        >
          Aceptar
        </Button>
        <Button
          style={{ marginLeft: "12px", marginRight: "12px" }}
          variant='contained'
          color='secondary'
          size='small'
          onClick={() => handleCloseModal()}
        >
          Cancelar
        </Button>
      </div>
    </div>
  )

  return (
    <div>
      <Modal open={open}>
        {body}
      </Modal>
    </div>
  )
}

export default ConfirmModal
