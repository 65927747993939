import classNames from 'classnames'

const Card = ({ children }) => {
  return (
    <div className='relative w-full mx-auto text-left ring-1 mt-0 max-w-none bg-white shadow border-blue-400 ring-gray-200 pl-6 pr-6 pt-6 pb-6 rounded-lg'>
      {children}
    </div>
  )
}

const CardInfoText = ({ text }) => {
  return (
    <p className='shrink-0 mt-0 text-left text-gray-500 text-sm font-normal'>
      {text}
    </p>
  )
}

const CardInfoValue = ({ value }) => {
  return (
    <p className='text-elem shrink-0 text-left text-gray-700 text-3xl font-semibold'>
      {value}
    </p>
  )
}

const CardProgressBar = ({ value }) => {
  return (
    <div className='relative flex items-center w-full bg-blue-100 h-2 rounded-lg'>
      <div
        className='bg-blue-500 flex-col h-full rounded-lg'
        style={{
          width: `${value * 100}%`,
        }}
      ></div>
    </div>
  )
}

const KpiCardWithProgressBar = ({
  icon,
  title,
  value,
  total,
  filterValue,
  handleClickOffer,
  className,
}) => {
  return (
    <Card>
      <div className='flex w-full flex-row justify-start items-center space-x-4'>
        <span
          className={classNames(
            className,
            'inline-flex flex-shrink-0 items-center rounded-lg px-2.5 py-2.5 text-3xl'
          )}
        >
          {icon}
        </span>
        <div className='w-full'>
          <CardInfoText text={title} />
          <a href='#' onClick={() => handleClickOffer(filterValue)}>
            <CardInfoValue value={value} />
          </a>
        </div>
      </div>
    </Card>
  )
}

export default KpiCardWithProgressBar
