import { StyledPaperCard, VanIco } from "./styles"

const Van = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeVan}
      onClick={() => handleClick()}
    >
      <VanIco color="rgb(84 84 84)" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Furgoneta</p>
      </div>
    </StyledPaperCard>
  )
}

export default Van