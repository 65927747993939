import { FETCH_UNREAD_NOTIFICATIONS, MARK_READ_NOTIFICATION, FETCH_ALL_NOTIFICATIONS, DELETE, DELETE_BULK } from './constants'
import NotificationService from '../services/notification.service'

export const getAll = () => async (dispatch) => {
  try {
    const data = await NotificationService.read()

    dispatch({ type: FETCH_ALL_NOTIFICATIONS, payload: data })
  } catch (error) {
    console.log(error)
  }
}

export const getUnread = () => async (dispatch) => {
  try {
    const data = await NotificationService.read()

    const filterData = data.filter(element => {
      if(!element.reading) return element
    })

    dispatch({ type: FETCH_UNREAD_NOTIFICATIONS, payload: filterData })
  } catch (error) {
    console.log(error)
  }
}

export const update = (id, update) => async (dispatch) => {
  try {
    const data = await NotificationService.update(id, update)

    dispatch({ type: MARK_READ_NOTIFICATION, payload: data })
  } catch (error) {
    console.log(error)
  }
}

export const remove = (id) => async (dispatch) => {
  try {
    await NotificationService.remove(id)

    dispatch({ type: DELETE, payload: id })
  } catch (err) {
    console.log(err)
  }
}

export const bulkDelete = (data) => async (dispatch) => {
  try {
    await NotificationService.bulkDelete(data)

    dispatch({ type: DELETE_BULK, payload: data})
  } catch (error) {
    console.log(error)
  }
}