import styled from "@emotion/styled"
import { Paper } from '@mui/material'

export const StyledBox = styled.div`
  padding: 34px 24px;
`

export const StyledPaper = styled(Paper)`
  padding: 14px;
  border-left: 5px solid #ff4a3a;
`

export const StyledTopBarBox = styled.div`
  display: flex;
  align-items: center;
`
export const StyledImage = styled.img`
  margin-right: 14px;
`
