import axios from 'axios'
import authHeaders from '../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const read = () => {
  const request = http.get('/cnet/vehicles')
  return request.then((response) => response.data)
}

const findByIdv = (idv) => {
  const request = http.get(`/cnet/vehicles/${idv}`)
  return request.then((response) => response.data)
}

const create = (data) => {
  const request = http.post('/cnet/vehicles', data)
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.put(`/cnet/vehicles/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/cnet/vehicles/${id}`)
  return request.then((response) => response.data)
}

const updateDb = () => {
  const request = http.get(`/cnet/database/`)
  return request.then((response) => response.data)
}

const createApi = (data, account) => {
  const request = http.post(`/cnet/api/vehicles?account=${account}`, data)
  return request.then((response) => response.data)
}

const readApi = (account) => {
  const request = http.get(`/cnet/api/vehicles?account=${account}`)
  return request.then((response) => response.data.vehicles)
}

const updateApi = (data, account) => {
  const request = http.put(
    `/cnet/api/vehicles/${data.id}?account=${account}`,
    data
  )
  return request.then((response) => response.data)
}

const deleteApi = (id, account) => {
  const request = http.delete(`/cnet/api/vehicles/${id}?account=${account}`)
  return request.then((response) => response.data)
}

const getAccounts = () => {
  const request = http.get('/publishing/api/v1/coches-net/accounts')
  return request.then((response) => response.data)
}

const updateAccountSettings = (id, data) => {
  const request = http.patch(
    `/publishing/api/v1/coches-net/accounts/${id}`,
    data
  )
  return request.then((response) => response.data)
}

const updateAccount = (account, confirm = false) => {
  const request = http.get(
    `/cnet/update-account?account=${account}&confirm=${confirm}`
  )
  return request.then((response) => response.data)
}

const brands = () => {
  const request = http.get('/cnet/api/brands?categoryId=2500&account=1')
  return request.then((response) => response.data)
}

const readActions = () => {
  const request = http.get('/cnet/api/actions')
  return request.then((response) => response.data)
}

const createAction = (data) => {
  const request = http.post('/cnet/api/actions', data)
  return request.then((response) => response.data)
}

const CnetService = {
  brands,
  create,
  createApi,
  readApi,
  updateApi,
  deleteApi,
  findByIdv,
  read,
  remove,
  update,
  updateDb,
  readActions,
  getAccounts,
  updateAccountSettings,
  createAction,
  updateAccount,
}

export default CnetService
