import axios from 'axios'
import authHeaders from '../../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const read = () => {
  const request = http.get('/users')
  return request.then((response) => response.data)
}

const UsersService = {
  read
}

export default UsersService
