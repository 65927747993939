import { useState, useEffect } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import useStyles from "./styles"
import Sidebar from "../Sidebar"
import TopHeader from "../TopHeader/TopHeader"
import { Redirect } from "react-router-dom"
import UserService from "../../services/user.service"
import { Paper, Box, Button, Avatar, Container } from "@mui/material"
import Form from "./Form"

const MyAccount = () => {
  const [nombre, setNombre] = useState("")
  const [apellidos, setApellidos] = useState("")
  const [telefono, setTelefono] = useState("")
  const [email, setEmail] = useState("")
  const [showForm, setShowForm] = useState(false)
  const [showChangeProfile, setShowChangeProfile] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)

  const user = window.localStorage.getItem("usuario")
  const userJSON = JSON.parse(user)

  const classes = useStyles()

  console.log(userJSON)

  useEffect(() => {
    if (user) {
      UserService.getUser(userJSON.id)
        .then((response) => {
          setNombre(response.name)
          setApellidos(response.lastname)
          setTelefono(response.telephone)
          setEmail(response.email)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [userJSON, user])

  const handleShowChangeProfile = () => {
    if (showForm) {
      if (showChangePassword) {
        setShowChangePassword(false)
        setShowChangeProfile(true)
        setShowForm(true)
      }
      setShowForm(false)
      setShowChangeProfile(false)
    } else {
      setShowForm(true)
      setShowChangePassword(false)
      setShowChangeProfile(true)
    }
  }

  const handleShowChangePassword = () => {
    if (showForm) {
      if (showChangePassword) {
        setShowChangePassword(true)
        setShowChangeProfile(false)
        setShowForm(true)
      }
      setShowForm(false)
      setShowChangePassword(false)
    } else {
      setShowForm(true)
      setShowChangePassword(true)
      setShowChangeProfile(false)
    }
  }

  if (!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <div className={classes.pageContainer}>
        <main className={classes.content}>
          <Toolbar />
          <section className={classes.hero}>
            <Container maxWidth='xs'>
              <Paper>
                <Box p={3}>
                  <div className={classes.hero}>
                    <Avatar
                      style={{
                        width: "100px",
                        height: "100px",
                        margin: "30px",
                      }}
                      alt=''
                      className={classes.avatar}
                    ></Avatar>
                    <h3>
                      {nombre} {apellidos}
                    </h3>
                    <p>{email}</p>
                    <p>{telefono}</p>
                    <Button size='small' onClick={handleShowChangeProfile}>
                      Editar Perfil
                    </Button>
                    <Button size='small' onClick={handleShowChangePassword}>
                      Cambiar contraseña
                    </Button>
                    {showForm && (
                      <Form
                        user={userJSON}
                        name={nombre}
                        lastname={apellidos}
                        telephone={telefono}
                        showChangeProfile={showChangeProfile}
                        showChangePassword={showChangePassword}
                      />
                    )}
                  </div>
                </Box>
              </Paper>
            </Container>
          </section>
        </main>
      </div>
    </div>
  )
}

export default MyAccount
