import styled from "@emotion/styled"

export const NewCarStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const NewCarAdd = styled.div`
  display: flex;
  justify-content: flex-start;
`
export const FormControlLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`