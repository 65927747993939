import { Box } from '@mui/material'
import { StyledChip } from "./styles"

const ChipLabel = ({ data }) => {
  return (
    <Box display='flex' width='100%' justifyContent='end'>
      {data.publicarParticular ? (
        <StyledChip size='small' label='Particulares' />
      ) : (
        ""
      )}
      {data.publicarEmpresa ? (
        <StyledChip size='small' label='Empresas' />
      ) : (
        ""
      )}
      {data.publicarAutonomos ? (
        <StyledChip size='small' label='Autónomos' />
      ) : (
        ""
      )}
      {data.publicarOtros ? (
        <StyledChip size='small' label='Otros' />
      ) : (
        ""
      )}
    </Box>
  )
}

export default ChipLabel
