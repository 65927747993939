const bulkActions = [
  {
    id: 1,
    name: 'Asignar campaña',
    actionName: 'activarCampaña',
  },
  {
    id: 2,
    name: 'Destacar home',
    actionName: 'destacar',
  },
  {
    id: 3,
    name: 'Modificar precio',
    actionName: 'modificarPrecio',
  },
  {
    id: 4,
    name: 'Asignar tipo de anuncio', // TODO: por implementar
    actionName: 'tipoAnuncio',
  },
  {
    id: 5,
    name: 'Activar edición manual',
    actionName: 'ActivarManual',
  },
  {
    id: 6,
    name: 'Modificar importe reserva',
    actionName: 'modificarImporteReserva',
  },
  {
    id: 7,
    name: 'Sincronización Web',
    actionName: 'webSync'
  },
  {
    id: 8,
    name: 'Publicar en LP', // TODO: por implementar
    actionName: 'lpPublish',
  },
  {
    id: 9,
    name: 'Eliminar vehículo', // TODO: por implementar
    actionName: 'deleteCar',
  }
]

const Landings = [
  'Citroen',
  'Peugeot',
  'Honda',
  'BMW',
  'MINI',
  'Joaquin Oliva',
  'Tarrauto',
  'Tecnotarraco',
  'Oliva Motor'
]

const SelectActionsData = {
  bulkActions,
  Landings
}

export default SelectActionsData
