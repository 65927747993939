const Nissan = ({ width }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 49.9 49.9'
      width={width ? width : ""}
    >
      <g id='Layer_1_1_'>
        <g id='Nissan_Badge_1_'>
          <g>
            <path
              d='M28.3,26.5c0,0-0.1,0-0.2,0h-3.7v0.9h3.7c0,0,0.2,0,0.3,0c0.8-0.1,1.1-0.7,1.1-1.3c0-0.6-0.4-1.2-1.1-1.2
				c-0.1,0-0.2,0-0.3,0h-2.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.3-0.4c0.1,0,0.1,0,0.3,0h3.5v-0.8h-3.6
				c-0.1,0-0.3,0-0.4,0c-0.6,0.1-1,0.6-1,1.2c0,0.5,0.3,1.1,1,1.2c0.1,0,0.3,0,0.4,0H28c0,0,0.1,0,0.2,0c0.3,0,0.4,0.2,0.4,0.4
				C28.7,26.3,28.6,26.5,28.3,26.5z'
            />
            <path
              d='M21.4,26.5c0,0-0.1,0-0.2,0h-3.7v0.9h3.7c0,0,0.2,0,0.3,0c0.8-0.1,1.1-0.7,1.1-1.3c0-0.6-0.4-1.2-1.1-1.2
				c-0.1,0-0.2,0-0.3,0h-2.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.3-0.4c0.1,0,0.1,0,0.3,0h3.5v-0.8h-3.6
				c-0.1,0-0.3,0-0.4,0c-0.6,0.1-1,0.6-1,1.2c0,0.5,0.3,1.1,1,1.2c0.1,0,0.3,0,0.4,0h2.4c0,0,0.1,0,0.2,0c0.3,0,0.4,0.2,0.4,0.4
				C21.7,26.3,21.6,26.5,21.4,26.5z'
            />
            <rect x='14.7' y='23.2' width='0.9' height='4.2' />
            <polygon points='12.6,27.4 12.6,23.2 11.7,23.2 11.7,26.3 8.6,23.2 7.4,23.2 7.4,27.4 8.4,27.4 8.4,24.3 11.5,27.4 			' />
            <polygon points='42.4,23.2 42.4,26.3 39.3,23.2 38.1,23.2 38.1,27.4 39,27.4 39,24.3 42.1,27.4 43.3,27.4 43.3,23.2 			' />
            <path d='M33.3,23.2l-2.6,4.2h1.1l0.5-0.7h3l0.5,0.7h1.1l-2.6-4.2H33.3z M34.8,25.9h-2.1l1-1.7L34.8,25.9z' />
            <path
              d='M12.6,20.9c1.9-5.4,7-9.1,12.8-9.1s10.9,3.7,12.8,9.1l0,0h4.1v-0.5l-1.7-0.2c-1-0.1-1.3-0.6-1.6-1.2l-0.1-0.1
				c-2.4-5.2-7.8-8.6-13.6-8.6s-11.1,3.4-13.6,8.6L11.6,19c-0.3,0.6-0.5,1-1.6,1.2l-1.7,0.2v0.5H12.6L12.6,20.9z'
            />
            <path
              d='M38.2,29.7L38.2,29.7c-1.9,5.5-7,9.1-12.8,9.1l0,0c-5.8,0-10.9-3.7-12.8-9.1l0,0H8.5v0.5l1.7,0.2c1,0.1,1.3,0.6,1.6,1.2
				l0.1,0.1c2.4,5.2,7.8,8.6,13.6,8.6l0,0c5.8,0,11.1-3.4,13.6-8.6l0.1-0.1c0.3-0.6,0.5-1,1.6-1.2l1.7-0.2v-0.5H38.2z'
            />
          </g>
          <g id='Layer_2_2_'>
            <g id='Layer_3_1_'></g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Nissan
