import styled from "@emotion/styled"
import { Paper } from '@mui/material'
import { Storage, DriveEta } from "@mui/icons-material"

export const StyledBox = styled.div`
  padding: 0px 24px 24px 24px;
`

export const StyledCard = styled(Paper)`
cursor: pointer;
display: flex;
text-align: right;
align-items: center;
padding-top: 16px;
padding-left: 80px;
padding-right: 80px;
padding-bottom: 16px;
justify-content: space-between;
textAlign: right;
background-color: ${props => props.active ? '#e6e6e6' : ''};
border-left: ${props => props.active ? '5px solid #ff4a3a;' : '5px solid #cdcdcd;'};
transition: all 0.2s ease;
& h3 {
  margin: 0;
  font-size: 1.5rem;
}
& p {
  margin: 0;
  color: gray;
}
&:hover {
  background: #e6e6e6;
  
`

export const StyledStorageIcon = styled(Storage)`
  color: ${props => props.color};
  font-size: ${props => props.size ? props.size : '38px'};
`

export const StyledCarIcon = StyledStorageIcon.withComponent(DriveEta)