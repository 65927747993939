import { useContext, useState, useEffect } from 'react'
import SubmitButton from '../../../buttons/SubmitButton'
import CnetService from '../../services/cnet.service'
import Quiter from '../../../Quiter'
import { useQuiter } from '../../../../hooks/useQuiter'

const Update = ({ context }) => {
  const {
    setData,
    selectData,
    setSelectData,
    setDataFilter,
    setShowAlert,
    setTypeAlert,
    setAlertMessage,
    isCnet,
    account,
  } = useContext(context)
  const [loading, setLoading] = useState(false)
  const [responses, setResponses] = useState([])
  const [selectAccount, setSelectAccount] = useState([])
  const { quiterData } = useQuiter()

  useEffect(() => {
    if (selectAccount.length === 0) {
      CnetService.getAccounts()
        .then((result) => {
          console.log(result)
          setSelectAccount(result.filter((e) => parseInt(e.key) === account))
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [account, selectAccount])

  const updateLoop = async (data) => {
    const allResponses = []
    console.log(data)
    for (let i = 0; i < data.length; i++) {
      try {
        const quiterVehicle = quiterData.filter(
          (e) => e.idv === data[i].internalReference
        )

        console.log(quiterVehicle, 'wewewewewewewe')

        const dbVehicle = await CnetService.findByIdv(data[i].internalReference)
        console.log(dbVehicle)
        if (dbVehicle) {
          if (data[i].price.priceValue === 0) data[i].price.priceValue = null

          if (data[i].price.bestPrice === 0) data[i].price.bestPrice = null

          if (data[i].price.professionalPrice === 0)
            data[i].price.professionalPrice = null
          if (data[i].price.monthlyQuota === 0)
            data[i].price.monthlyQuota = null
          data[i].price.priceWithTaxes = dbVehicle.precios[0]?.valor
          data[i].price.financingPrice = dbVehicle.precios[3]?.valor
          data[i].information.professional = quiterVehicle[0]?.observaciones
          data[i].information.private = quiterVehicle[0]?.observaciones
          data[i].information.additional = quiterVehicle[0]?.observaciones
        }

        const response = await CnetService.updateApi(data[i], account)
        allResponses.push({
          status: 200,
          idv: response.internalReference,
          message: 'Vehicle update successfully',
        })
        setShowAlert(true)
        setTypeAlert('info')
        setAlertMessage(`Actualizando vehículo: ${i + 1} de ${data.length}`)
      } catch (error) {
        console.log(error)
        allResponses.push({
          status: error.response.status,
          idv: data[i].idv,
          message: error.response.data.error,
        })
        setShowAlert(true)
        setTypeAlert('info')
        setAlertMessage(`Actualizando: ${i + 1} de ${data.length}`)
      }
    }
    setResponses(allResponses)
    return allResponses
  }

  const handleButton = () => {
    setLoading(true)
    if (isCnet) {
      updateLoop(selectData).then((result) => {
        CnetService.readApi(account).then((response) => {
          setData(response)
          setDataFilter(response)
          setSelectData([])
        })
        setLoading(false)
        console.log(result)
      })
    }
  }

  return (
    <>
      <SubmitButton
        loading={loading}
        text='Aplicar acción'
        handleClick={handleButton}
      />
    </>
  )
}

export default Update
