import http from './http-common'

const upload = (file, onUploadProgress) => {
  let formData = new FormData()
  formData.append('file', file)

  return http.post('upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

const uploadImage = (image, name) => {
  let formData = new FormData()
  formData.append('file', image, name)

  return http.post('upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const getFiles = () => {
  const request = http.get('/files')
  return request.then(response => response.data)
}

const uploadCSV = (file, onUploadProgress) => {
  let formData = new FormData()
  formData.append('file', file)

  return http.post('files/upload-csv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

const UploadFileService = {
  upload,
  getFiles,
  uploadImage,
  uploadCSV
}

export default UploadFileService
