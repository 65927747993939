import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Chip, IconButton, CircularProgress } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import dealers from '../utils/dealers'
import { useEffect } from 'react'
import Utils from '../services/utils';
import VisibilityIcon from '@material-ui/icons/Visibility'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'

  },
  dealerMeta: {
    minWidth: '50%'
  }
}))

const CheckDealer = ({ dealer }) => {
  const [showProgress, setShowProgress] = useState(false)
  const [data, setData] = useState(null)
  const [labelChip, setLabelChip] = useState('')
  const [colorChip, setColorChip] = useState('default')

  const classes = useStyles()
  const url = process.env.REACT_APP_API_VEHICULOS_QUITER

  useEffect(() => {
    if(!data) {
      setShowProgress(true)
      Utils.secureHttp(`${url}/${dealer.portalId}`, 'GET')
        .then(result => {
          if(result.tipoError) {
            setShowProgress(false)
            setLabelChip('Error')
            setColorChip('secondary')
            console.log(result)

          } else {
            setShowProgress(false)
            setLabelChip('Correcto')
            setColorChip('primary')
            setData(result)
            console.log(result)
          }
        })
        .catch(err => {
          console.log('Ha ocurrido un error')
          console.log(err.response.status)
          
        })
    }
  }, [data, dealer.portalId, url])

  const handleSyncClick = (dealer) => {
    setShowProgress(true)
    Utils.secureHttp(`${url}/${dealer.portalId}`, 'GET')
    .then(result => {
      if(result.tipoError) {
        setShowProgress(false)
        setLabelChip('Error')
        setColorChip('secondary')
        console.log(result)

      } else {
        setShowProgress(false)
        setLabelChip('Correcto')
        setColorChip('primary')
        setData(result)
        console.log(result)
      }
    })
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
      <Paper className={classes.paper}>
        { showProgress
          ? <CircularProgress />
          : <React.Fragment>
              <div className={classes.dealerMeta}>
                <Typography variant="subtitle2">
                  <b>{dealer.portalId} - {dealer.dealer} - {dealer.name}</b> 
                </Typography>
              </div>
              <Typography variant="subtitle2">
                <b>Estado:</b> <Chip color={colorChip} size="small" label={labelChip}/>
              </Typography>
              <IconButton onClick={() => handleSyncClick(dealer)}>
                <CachedIcon />
              </IconButton>
              <IconButton href={`quiter/${dealer.portalId}`}>
                <VisibilityIcon />
              </IconButton>
            </React.Fragment>
        }
      </Paper>
    </Grid>
  )
}


const DealerCheckCard = () => {

  return (
    <Grid container spacing={3}>
      {
        dealers.map(dealer => {
          return (
            <CheckDealer key={dealer.portalId} dealer={dealer} />
          )
        })
      }
  </Grid>
  )
}

export default DealerCheckCard