import { StyledPaper } from "./styles"

const SelectedFilters = ({ filters }) => {
  return filters ? (
    <StyledPaper>
      {filters.map((filter) => {
        return <p>{filter}</p>
      })}
    </StyledPaper>
  ) : (
    ""
  )
}

export default SelectedFilters
