import OlivaMotorIcon from "../../../../Icons/OlivaMotor"
import { StyledPaperCard } from "./styles"

const OlivaMotor = ({ data, active, handleClick }) => {
  
  return (
    <StyledPaperCard
    active={active.activeLpOlivaMotor}
    onClick={() => handleClick()}
    >
      <OlivaMotorIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>OlivaMotor</p>
      </div>
    </StyledPaperCard>
  )
}

export default OlivaMotor