import BmwIcon from "../../../../Icons/Bmw"
import { StyledPaperCard } from "./styles"

const Bmw = ({ data, active, handleClick }) => {
  
  return (
    <StyledPaperCard
    active={active.activeLpBmw}
    onClick={() => handleClick()}
    >
      <BmwIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>BMW</p>
      </div>
    </StyledPaperCard>
  )
}

export default Bmw