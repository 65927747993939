import { FETCH_PROCESSES, UPDATE_PROCESS, GET_STATE_PROCESS } from './constants'
import ProcessService from '../services/process.service'

export const getProcesses = () => async (dispatch) => {
  try {
    const data = await ProcessService.read()

    dispatch({ type: FETCH_PROCESSES, payload: data })

  } catch (err) {
    console.log(err)
  }
}

export const updateProcess = (id, update) => async (dispatch) => {
  try {
    const data = await ProcessService.update(id, update)
    dispatch({ type: UPDATE_PROCESS, payload: data })
  } catch (err) {
    console.log(err)
  }
}

export const getStateProcess = (id) => async(dispatch) => {
  try {
    const data = await ProcessService.getById(id)
    dispatch({ type: GET_STATE_PROCESS, payload: data.active })
  } catch (error) {
    console.log(error)
  }
}