import { useContext } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"
import { StyledAlertModalText, StyledList } from "./style"

const Modal = ({ context, text, title, message, dataList, action }) => {
  const { openModal, setOpenModal } = useContext(context)

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <StyledAlertModalText>{message}</StyledAlertModalText>
          <br />
          {text}
          {dataList && (
            <StyledList>
              {dataList.length > 0
                ? dataList.map((e) => {
                    return (
                      <li key={e.id}>
                        {e.internalReference} - {e.title}
                      </li>
                    )
                  })
                : "No hay vehículos que eliminar"}
            </StyledList>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {dataList ? "Cancelar" : "Aceptar"}
        </Button>
        {dataList ? (
          <Button onClick={() => action()} color='primary' autoFocus>
            Aceptar
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
