const Tecnotarraco = ({ width }) => {
  return (
    <img
      alt='Peugeot'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1642686961/Multipublicador%20GrupOlivaMotor/logo-tecnotarraco-quadrat_lkuzk8.svg'
      width={width}
    />
  )
}

export default Tecnotarraco
