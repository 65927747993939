import { StyledPaperCard, CarIcon } from "./styles"

const Demo = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeDemo}
      onClick={() => handleClick()}
    >
      <CarIcon color="orange" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Demo</p>
      </div>
    </StyledPaperCard>
  )
}

export default Demo