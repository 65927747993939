export const validYears = [
  '2022',
  '2021',
]

export const validBrands = [
  {
    key: 7,
    value: 'BMW'
  },
  {
    key: 222,
    value: 'MINI'
  }
]

export const validFuels = [
  {
    key: 1,
    value: 'Diesel'
  },
  {
    key: 2,
    value: 'Gasolina'
  },
  {
    key: 3,
    value: 'Eléctrico / Híbrido'
  },
  {
    key: 4,
    value: 'Otros'
  }
]

export const listWarranty = [
  {
    key: '1',
    value: '-'
  },
  {
    key: '2',
    value: '12 meses (1 año)'
  },
  {
    key: '3',
    value: '18 meses'
  },
  {
    key: '4',
    value: '24 meses (2 años)'
  },
  {
    key: '5',
    value: '36 meses (3 años)'
  },
  {
    key: '6',
    value: '48 meses (4 años)'
  },
  {
    key: '7',
    value: '60 meses (5 años)'
  },
  {
    key: '8',
    value: '72 meses (6 años)'
  },
  {
    key: '9',
    value: '84 meses (7 años)'
  }
]

export const transmissions = [
  {
    key: '1',
    value: 'Automático'
  },
  {
    key: '2',
    value: 'Manual'
  }
]

export const listCarChange = [
  {
    key: 1,
    value: 'Cambio 1'
  },
  {
    key: 2,
    value: 'Cambio 2'
  },
  {
    key: 3,
    value: 'Cambio 3'
  },
]

export const listVersion = [
  {
    key: 1,
    value: 'version 1'
  },
  {
    key: 2,
    value: 'version 2'
  },
  {
    key: 3,
    value: 'version 3'
  },
]

export const listUniqueVersion = [
  {
    key: 1,
    value: 'version unica 1'
  },
  {
    key: 2,
    value: 'version unica 2'
  },
  {
    key: 3,
    value: 'version unica 3'
  },
]

export const listUbicationCar = [
  {
    key: 43,
    value: 'Tarragona'
  },
  {
    key: 17,
    value: 'Girona'
  }
]

export const listEnvironmentalLabel = [
  {
    key: 1,
    value: '0'
  },
  {
    key: 2,
    value: 'ECO'
  },
  {
    key: 3,
    value: 'B'
  },
  {
    key: 4,
    value: 'C'
  }
]

export const listColor = [
  {
    key: "1",
    value: "Amarillo"
  },
  {
    key: "2",
    value: "Azul"
  },
  {
    key: "3",
    value: "Beige"
  },
  {
    key: "4",
    value: "Blanco"
  },
  {
    key: "5",
    value: "Granate"
  },
  {
    key: "6",
    value: "Gris / Plata"
  },
  {
    key: "7",
    value: "Marrón"
  },
  {
    key: "8",
    value: "Naranja"
  },
  {
    key: "9",
    value: "Negro"
  },
  {
    key: "10",
    value: "Rojo"
  },
  {
    key: "11",
    value: "Rosa"
  },
  {
    key: "12",
    value: "Verde"
  },
  {
    key: "13",
    value: "Violeta / Lila"
  }
]
