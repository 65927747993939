import Form from './Form'
import SubmitButton from './SubmitButton'
import InputDate from './InputDate'
import Input from './Input'
import { useForm } from 'react-hook-form'
import Button from '../../../@core/components/Button'

const AddForm = ({ handleSubmit, handleCancel }) => {
  const methods = useForm()

  return (
    <Form methods={methods}>
      <div className='flex gap-4'>
        <div className='mb-3 pt-3'>
          <label htmlFor='name' className='text-gray-500 text-sm font-semibold'>
            Nombre del contador
          </label>
          <Input name='name' />
        </div>
        <div className='mb-3 pt-3'>
          <label
            htmlFor='startDate'
            className='text-gray-500 text-sm font-semibold'
          >
            Fecha inicio
          </label>
          <InputDate name='startDate' />
        </div>
        <div className='mb-3 pt-3'>
          <label
            htmlFor='endDate'
            className='text-gray-500 text-sm font-semibold'
          >
            Fecha fin
          </label>
          <InputDate name='endDate' />
        </div>
      </div>
      <div className='flex gap-4'>
        <div className='w-1/5 mb-3 pt-3'>
          <label
            htmlFor='badgeColor'
            className='text-gray-500 text-sm font-semibold'
          >
            Badge Color
          </label>
          <Input name='badgeColor' />
        </div>
        <div className='w-1/5 mb-3 pt-3'>
          <label
            htmlFor='textColor'
            className='text-gray-500 text-sm font-semibold'
          >
            Color del texto
          </label>
          <Input name='textColor' />
        </div>
        <div className='mb-3 pt-3'>
          <label
            htmlFor='iconUrl'
            className='text-gray-500 text-sm font-semibold'
          >
            Url del icono
          </label>
          <Input name='iconUrl' />
        </div>
      </div>
      <div className='flex justify-end gap-4 mt-4'>
        <Button
          text='Cancelar'
          size='sm'
          className='bg-red-500 focus:ring-red-400 hover:bg-red-600 hover:border-red-600'
          handleClick={handleCancel}
        >
          Cancelar
        </Button>
        <SubmitButton handleSubmitButton={handleSubmit}>Enviar</SubmitButton>
      </div>
    </Form>
  )
}

export default AddForm
