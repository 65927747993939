import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import MaterialTables from "@material-table/core"
import Sidebar from "../../Sidebar"
import TopHeader from "../../TopHeader/TopHeader"
import Toolbar from "@material-ui/core/Toolbar"
import { Redirect } from "react-router"
import AlertBox from "../../AlertBox"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import GrupOlivaMotorService from "../../../services/grupolivamotor.service"
import { Columns } from "./data"
import InfoResume from "./InfoResume"
import IndexActions from "./IndexActions"
import Actions from "./components/Actions"
import useData from "./hooks/useData"
// import CardView from "./CardView"

const user = localStorage.getItem("usuario")

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

const IndexGomotor = () => {
  const {
    data,
    setData,
    showProgress,
    setShowProgress,
    showMessage,
    typeAlert,
    alertMessage,
  } = useData()
  const [expand, setExpand] = useState(false)
  const [dataFilter, setDataFilter] = useState([])
  const [selectData, setSelectData] = useState([])
  const [showBulk, setShowBulk] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  const classes = useStyles()

  const handleExpand = (value) => {
    setExpand(value)
  }

  const handleLoading = (value) => {
    setShowProgress(value)
  }

  const handleFilter = (filter) => {
    switch (filter) {
      case "total":
        setDataFilter(data)
        setExpand(true)
        break
      case "available":
        const dataAvailable = data.filter((element) => {
          return element.reservado !== true
        })
        setDataFilter(dataAvailable)
        setExpand(true)
        break
      case "reserved":
        const dataReserved = data.filter((element) => {
          return element.reservado === true
        })
        setDataFilter(dataReserved)
        setExpand(true)
        break
      case "offer":
        const dataOffer = data.filter((element) => {
          return element.vehiculosHome === true
        })
        setDataFilter(dataOffer)
        setExpand(true)
        break
      case "new":
        const dataNew = data.filter((element) => {
          return element.tipo === "VN"
        })
        setDataFilter(dataNew)
        setExpand(true)
        break
      case "km0":
        const dataKm0 = data.filter((element) => {
          // return Data.km0Types.indexOf(element.tipoVO) !== -1
          return element.tipoVO === "KM0"
        })
        setDataFilter(dataKm0)
        setExpand(true)
        break
      case "courtesy":
        const dataCourtesy = data.filter((element) => {
          // return Data.courtesyTypes.indexOf(element.tipoVO) !== -1
          return element.tipoVO === "CORTESIA"
        })
        setDataFilter(dataCourtesy)
        setExpand(true)
        break
      case "demo":
        const dataDemo = data.filter((element) => {
          // return Data.demoTypes.indexOf(element.tipoVO) !== -1
          return element.tipoVO === "DEMO"
        })
        setDataFilter(dataDemo)
        setExpand(true)
        break
      case "ocasion":
        const dataOcasion = data.filter((element) => {
          // return Data.ocasionTypes.indexOf(element.tipoVO) !== -1
          return element.tipoVO === "OCASION"
        })
        setDataFilter(dataOcasion)
        setExpand(true)
        break
      case "gasolina":
        const dataGasolina = data.filter((element) => {
          return element.combustible === "GASOLINA"
        })
        setDataFilter(dataGasolina)
        setExpand(true)
        break
      case "diesel":
        const dataDiesel = data.filter((element) => {
          return element.combustible === "DIESEL"
        })
        setDataFilter(dataDiesel)
        setExpand(true)
        break
      case "hybrid":
        const dataHybrid = data.filter((element) => {
          return element.combustible === "HIBRIDO"
        })
        setDataFilter(dataHybrid)
        setExpand(true)
        break
      case "electric":
        const dataElectric = data.filter((element) => {
          return element.combustible === "ELECTRICO"
        })
        setDataFilter(dataElectric)
        setExpand(true)
        break
      case "citroen":
        const dataCitroen = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Citroen") !== -1
          }
          return 0
        })
        setDataFilter(dataCitroen)
        setExpand(true)
        break
      case "peugeot":
        const dataPeugeot = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Peugeot") !== -1
          }
          return 0
        })
        setDataFilter(dataPeugeot)
        setExpand(true)
        break
      case "honda":
        const dataHonda = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Honda") !== -1
          }
          return 0
        })
        setDataFilter(dataHonda)
        setExpand(true)
        break
      case "bmw":
        const dataBmw = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("BMW") !== -1
          }
          return 0
        })
        setDataFilter(dataBmw)
        setExpand(true)
        break
      case "mini":
        const dataMini = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("MINI") !== -1
          }
          return 0
        })
        setDataFilter(dataMini)
        setExpand(true)
        break
      case "olivaMotor":
        const dataOlivaMotor = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Oliva Motor") !== -1
          }
          return 0
        })
        setDataFilter(dataOlivaMotor)
        setExpand(true)
        break
      case "tarrauto":
        const dataLpTarrauto = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Tarrauto") !== -1
          }
          return 0
        })
        setDataFilter(dataLpTarrauto)
        setExpand(true)
        break
      case "joaquinOliva":
        const dataLpJoaquinOliva = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Joaquin Oliva") !== -1
          }
          return 0
        })
        setDataFilter(dataLpJoaquinOliva)
        setExpand(true)
        break
      case "tecnotarraco":
        const dataLpTecnotarraco = data.filter((element) => {
          if (element.landingPages) {
            return element.landingPages.indexOf("Tecnotarraco") !== -1
          }
          return 0
        })
        setDataFilter(dataLpTecnotarraco)
        setExpand(true)
        break
      case "newWeb":
        const dataNewWeb = data.filter((element) => {
          if (element.tipoVehiculoWeb) {
            return element.tipoVehiculoWeb === "Nuevo"
          }
          return 0
        })
        setDataFilter(dataNewWeb)
        setExpand(true)
        break
      case "km0Web":
        const dataKm0Web = data.filter((element) => {
          return element.tipoVehiculoWeb === "Km 0"
        })
        setDataFilter(dataKm0Web)
        setExpand(true)
        break
      case "ocasionWeb":
        const dataOcasionWeb = data.filter((element) => {
          return element.tipoVehiculoWeb === "Ocasión"
        })
        setDataFilter(dataOcasionWeb)
        setExpand(true)
        break
      default:
        break
    }
  }

  if (!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        {showMessage ? (
          <AlertBox type={typeAlert} text={alertMessage} open={showMessage} />
        ) : (
          ""
        )}
        {data.length === 0 ? (
          <AlertBox
            type='error'
            text='No hay vehículos en la base de datos...'
          />
        ) : (
          ""
        )}
        <Backdrop className={classes.backdrop} open={showProgress}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <IndexActions
          expand={expand}
          handleExpand={handleExpand}
          handleLoading={handleLoading}
        />
        {data.length > 0 ? (
          <InfoResume
            data={data}
            expand={expand}
            handleExpand={handleExpand}
            handleFilter={handleFilter}
          />
        ) : (
          ""
        )}
        <br />
        {selectData.length > 0 ? (
          <Actions dataSelection={selectData} campaigns={campaigns} />
        ) : (
          ""
        )}
        <br />
        {!expand ? (
          ""
        ) : (
          <MaterialTables
            title='Lista de resultados'
            columns={Columns}
            data={dataFilter ? dataFilter : data}
            options={{
              tableLayout: "auto",
              columnsButton: true,
              filtering: true,
              pageSize: 10,
              selection: true,
            }}
            onSelectionChange={(rows) => {
              showBulk ? setShowBulk(false) : setShowBulk(true)
              setSelectData(rows)
            }}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  const dataDelete = [...dataFilter]
                  const dataDeleteFilter = [...data]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  dataDeleteFilter.splice(index, 1)
                  console.log(dataDelete)
                  console.log(oldData)
                  GrupOlivaMotorService.deleteId(oldData._id)
                    .then((response) => {
                      console.log(response)
                      setData(dataDeleteFilter)
                      setDataFilter(dataDelete)
                      setTimeout(resolve, 1000)
                      window.location.reload()
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data]
                  const index = oldData.tableData.id
                  dataUpdate[index] = newData
                  const filter = { _id: newData._id }
                  const update = newData
                  console.log(filter)
                  console.log(newData)
                  GrupOlivaMotorService.update(newData._id, { filter, update })
                    .then((result) => {
                      console.log(result)
                      if (result) {
                        setData(data)
                        setTimeout(resolve, 1000)
                        window.location.reload()
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
            }}
/*             cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve, reject) => {
                  console.log(columnDef.field)
                  rowData[columnDef.field] = newValue
                  console.log("newValue: " + newValue)
                  console.log(rowData)
                  //console.log(data);
                  console.log({ [columnDef.field]: newValue })
                  const filter = {
                    _id: rowData._id,
                  }
                  const update = {
                    [columnDef.field]: newValue,
                  }
                  GrupOlivaMotorService.update(rowData._id, { filter, update })
                    .then((response) => {
                      if (response) {
                        setData(data)
                        setTimeout(resolve, 1000)
                        window.location.reload()
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
              },
            }} */
          />
        )}
      </main>
    </div>
  )
}

export default IndexGomotor
