import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import LoginPage from './components/pages/LoginPage'
import Quiter from './components/Quiter'
import Dashboard from './components/Dashboard'
import MyAccount from './components/Profile/MyAccount'
import UploadCSV from './components/pages/UploadCSV'
import MotosTable from './components/pages/MotosTable'
import MotoEdit from './components/pages/MotoEdit/MotoEdit'
import QuiterPortal from './components/pages/QuiterPortal'
import QuiterStockGeneralList from './components/pages/QuiterStock/QuiterStockGeneralList'
import UserService from './services/user.service'
import QuiterStockVehicleView from './components/pages/QuiterStock/QuiterStockVehicleView'
import RentingTable from './components/pages/Renting/RentingTable'
import AddVehicle from './components/pages/Renting/AddVehicle'
import ProtectedRoute from './router/ProtectedRoute'
import EditCarRenting from './components/pages/Renting/EditCarRentig'
import IndexGomotor from './components/pages/GomotorStock/IndexGomotor'
import GomotorEdit from './components/pages/GomotorStock/GomotorEdit'
import GomotorAddCar from './components/pages/GomotorStock/GomotorAddCar'
import IndexGrupOlivaMotor from './components/pages/GrupOlivaMotorStock/IndexGrupOlivaMotor'
import GrupOlivaMotorEdit from './components/pages/GrupOlivaMotorStock/GrupOlivaMotorEdit'
import GrupOlivaMotorAddCar from './components/pages/GrupOlivaMotorStock/GrupOlivaMotorAddCar'
import GomotorLog from './components/pages/GomotorStock/components/Log/Log'
import GrupOlivaMotorLog from './components/pages/GrupOlivaMotorStock/components/Log'
// import IndexWallapopGeneral from './components/pages/WallapopGeneralStock/IndexWallapopGeneral'
import Campaigns from './components/Campaigns'
import IndexAramis from './components/pages/Aramis/IndexAramis'
import Notifications from './components/Notifications/Notifications'
import CochesNet from './components/CochesNet'
import OfertaDeCoches from './components/OfertaDeCoches'
import Gomotor from './components/Gomotor'
import Wallapop from './components/Wallapop'
import Motoreto from "./components/Motoreto"
import UserSettings from "./components/Settings/Users"
import WallapopSettings from "./components/Settings/Wallapop"

//** Context Imports */
import { AlertContextProvider } from './@core/context/alertContext'
import { QuiterContextProvider } from './context/quiterContext'


UserService.verifyToken()
  .then(result => {
    if(!result) {
      localStorage.removeItem('usuario')
    }
  })

function App() {
  return (
    <AlertContextProvider>
      <QuiterContextProvider>
        <Router>
          <Switch>
            <Route exact path='/' component={LoginPage} />
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/dashboard' component={Dashboard} />
            <Route exact path='/json-quiter' component={Quiter} />
            <Route exact path='/mi-cuenta' component={MyAccount} />
            <Route exact path='/subir-csv' component={UploadCSV} />
            <Route exact path='/motos' component={MotosTable} />
            <Route exact path='/motos/:idv' component={MotoEdit} />
            
            <ProtectedRoute exact path='/stock-gomotor' component={IndexGomotor} />
            <ProtectedRoute exact path='/stock-gomotor/new-car' component={GomotorAddCar} />
            <Route exact path='/stock-gomotor/:idv' component={GomotorEdit} />
            <ProtectedRoute exact path='/gomotor/log' component={GomotorLog} />
            <ProtectedRoute exact path='/gomotor' component={Gomotor} />

            <ProtectedRoute exact path='/grupolivamotor/log' component={GrupOlivaMotorLog} />
            <ProtectedRoute exact path='/stock-grupoliva' component={IndexGrupOlivaMotor} />
            <ProtectedRoute exact path='/stock-grupoliva/new-car' component={GrupOlivaMotorAddCar} />
            <ProtectedRoute exact path='/stock-grupoliva/:idv' component={GrupOlivaMotorEdit} />
            <Route exact path='/quiter/:portalId' component={QuiterPortal} />
            <Route exact path='/stock-general-quiter' component={QuiterStockGeneralList} />
            <Route exact path='/stock-general-quiter/:idv' component={QuiterStockVehicleView} />
            <Route exact path='/stock-renting' component={RentingTable} />
            <ProtectedRoute exact path='/stock-renting/add-vehicle' component={AddVehicle} />
            <ProtectedRoute exact path='/stock-renting/:id' component={EditCarRenting} />
            <ProtectedRoute exact path='/campaigns' component={Campaigns} />
            <ProtectedRoute exact path='/aramis-francia' component={IndexAramis} />
            <ProtectedRoute exact path='/notificaciones' component={Notifications} />

            {/* Wallapop */}
            {/* <ProtectedRoute exact path='/wallapop' component={Wallapop} /> */}
            {/* Coches.net */}
            <ProtectedRoute exact path='/coches-net' component={CochesNet} />
            {/* ofertadecoches.com */}
            {/* <ProtectedRoute exact path='/oferta-de-coches' component={OfertaDeCoches} /> */}
            {/* Motoreto.com */}
            {/* <ProtectedRoute exact path='/motoreto' component={Motoreto} /> */}
            {/* Settings */}
            <ProtectedRoute exact path='/settings/users' component={UserSettings} />
            <ProtectedRoute exact path='/settings/wallapop' component={WallapopSettings} />

          </Switch>
        </Router>
      </QuiterContextProvider>
    </AlertContextProvider>
  )
}

export default App;
