import { CardHeader } from '@mui/material'
import styled from '@emotion/styled'

export const StyledCardId = styled.span`
  display: flex;
  justify-content: end;
  margin-right: 16px;
  font-size: 12px;
  font-style: oblique;
  color: ${props =>
    props.color ? props.color : '#939393;'
  }
  position: relative;
  top: -60px;
  text-transform: uppercase;
  font-weight: ${props => 
    props.bold ? 'bolder;' : 'normal;'
  }
`

export const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;
  }
`