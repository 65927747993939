import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import Sidebar from "./Sidebar" 
import TopHeader from "./TopHeader/TopHeader"
import { Redirect } from 'react-router-dom'
import DealerCheckCard from './DealerCheckCard'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}))

const user = localStorage.getItem('usuario')

const Dashboard = () => {
  const classes = useStyles()

  if(!user) return <Redirect to="/login" />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <DealerCheckCard />
{/*         <h1>Dashboard</h1>
        <FixedContainer /> */}
      </main>
    </div>
  );
};

export default Dashboard;
