import { Redirect } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import UserUtils from "../../../utils/User.utils"
import TopHeader from "../../TopHeader"
import Sidebar from "../../Sidebar"
import GomotorAddCarForm from "./GomotorAddCarForm"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fafafa'
  }
}))

const GomotorAddCar = () => {

  const classes = useStyles()

  if(!UserUtils.userExist) <Redirect to="/login" />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <GomotorAddCarForm />
      </main>
    </div>
  )
}

export default GomotorAddCar