import { StyledPaperCard, CarIcon } from "./styles"

const BodyWork = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeCheckBodyWork}
      onClick={() => handleClick()}
    >
      <CarIcon color="red" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Carrocería</p>
      </div>
    </StyledPaperCard>
  )
}

export default BodyWork