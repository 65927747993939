import React from "react"
import { useState } from "react"
import {
  Card,
  CardActions,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ImageCard from "../../ImageCard"
import Avatar from "@material-ui/core/Avatar"
import { Delete, Edit } from "@material-ui/icons"
import ChipLabel from "./ChipLabel"
import RentingService from "../../../services/renting.service"
import CitroenIcon from "../../Icons/Citroen"
import PeugeotIcon from "../../Icons/Peugeot"
import NissanIcon from "../../Icons/Nissan"
import SeatIcon from "../../Icons/Seat"
import AudiIcon from "../../Icons/Audi"
import FordIcon from "../../Icons/Ford"
import VolkswagenIcon from "../../Icons/Volkswagen"
import LexusIcon from "../../Icons/Lexus"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { StyledCardId, StyledCardHeader } from "./styles"
import SmallGeneratedOffer from "./GenerateOffer/SmallGeneratedOffer"

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "transparent",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
    transform: `translate(-50%, -50}%)`,
  },
}))

const brands = [
  {
    name: "Citroën",
    icon: <CitroenIcon />,
  },
  {
    name: "Peugeot",
    icon: <PeugeotIcon />,
  },
  {
    name: "Nissan",
    icon: <NissanIcon />,
  },
  {
    name: "Seat",
    icon: <SeatIcon />,
  },
  {
    name: "Audi",
    icon: <AudiIcon />,
  },
  {
    name: "Ford",
    icon: <FordIcon />,
  },
  {
    name: "Volkswagen",
    icon: <VolkswagenIcon />,
  },
  {
    name: "Lexus",
    icon: <LexusIcon />,
  },
]

const CardView = ({ data, handleRemoveCar, filterBrands, filterOffers, filterItems, setFilterItems }) => {
  const [openModal, setOpenModal] = useState(false)
  const [removeId, setRemoveId] = useState("")

  const classes = useStyles()

  const handleEdit = (id) => {
    window.location = window.location + "/" + id
  }

  const handleRemove = (id) => {
    setRemoveId(id)
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const removeCar = () => {
    const dat = {
      filter: {
        _id: removeId,
      },
      options: {},
    }
    RentingService.remove(removeId, dat)
      .then((result) => {
        const newData = data.filter((element) => {
          return element._id !== removeId
        })
        handleRemoveCar(newData)
        console.log(result)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getBrandIcon = (brand) => {
    const component = brands.filter((b) => b.name === brand)
    if (component.length) return component[0].icon
  }

  const handlePdfButton = (data, typeOffer) => {
    RentingService.downloadPDF(data, typeOffer)
      .then((result) => {
        console.log(result)
        // let url = URL.createObjectURL(result);
        let a = document.createElement("a")
        a.href = `${process.env.REACT_APP_API_URL}pdf/oferta-renting-gomotor.pdf`
        a.target = "_blank"
        a.download = "oferta-renting.pdf"
        a.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const filterBrand = (data, filter) => {
    return data.filter(e => e.marca.toLowerCase().includes(filter.toLowerCase()))
  }

  const filterOffer = (data, filter) => {
    return data.filter(e => {
      const toFilter = e.idOferta ? e.idOferta.toString() : ''
      const toSearch = filter
      return toFilter.includes(toSearch)
    })
  }

  // let filterItems = data

  setFilterItems(data)

  if (filterBrands) {
    filterItems = filterBrand(filterItems, filterBrands)
  }

  if (filterOffers) {
    filterItems = filterOffer(filterItems, filterOffers)
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {filterItems.map((element) => (
          <Grid item sm={6} md={4} lg={4} xl={3}>
            <Card key={element._id} className={classes.root}>
              <StyledCardHeader
                avatar={
                  <Avatar aria-label='recipe' className={classes.avatar}>
                    {getBrandIcon(element.marca)}
                  </Avatar>
                }
                title={`${element.marca} ${element.modelo} ${element.version}`}
                subheader={`${element.color}, ${element.potenciaCv} CV, ${element.cambio}`}
              />
              <StyledCardId color='#cd5c5c;' bold>
                {element.provider ? element.provider.name : ""}
              </StyledCardId>
              <StyledCardId color='#000000;' bold>
                {element.idOferta}
              </StyledCardId>
              <StyledCardId>{element.id}</StyledCardId>
              <ImageCard
                backgroundImage={element.recursos[0].url}
                hoverImage={element.recursos[0].url}
              />
              <CardActions disableSpacing>
                <SmallGeneratedOffer
                  typesOffers={[
                    { name: "Particular", value: element.publicarParticular },
                    { name: "Empresa", value: element.publicarEmpresa },
                    { name: "Autonomos", value: element.publicarAutonomos },
                    { name: "Otros", value: element.publicarOtros },
                  ]}
                  data={element}
                  handlePDF={handlePdfButton}
                />
                <IconButton
                  onClick={() => handleEdit(element._id)}
                  size='small'
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => handleRemove(element._id)}
                  size='small'
                >
                  <Delete />
                </IconButton>
                <ChipLabel data={element} />
              </CardActions>
              <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  {"Seguro que desea eliminar el vehículo?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Esta acción, no se puede deshacer...
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color='primary'>
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      handleClose()
                      removeCar()
                    }}
                    color='primary'
                    autoFocus
                  >
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default CardView
