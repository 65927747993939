/** React imports */
import { useState } from 'react'

/** @core imports */
import Label from '../../../../@core/components/Label'

/** services imports */
import GomotorService from '../../services'

/** to refactor */
import ConfirmModal from './ConfirmModal'

const CampaignDiscount = ({ open, dataSelection, handleCloseModal }) => {
  const [discount, setDiscount] = useState({
    active: true,
    price: null,
    financedPrice: null,
  })

  const handleChange = (event) => {
    console.log(event.target.value, 'discount')
    setDiscount({
      ...discount,
      [event.target.name]: event.target.value,
    })
  }

  const update = async () => {
    const result = await Promise.all(
      dataSelection.map((e) =>
        GomotorService.update(e.idv, { discount: discount })
      )
    )

    console.log(result)
    handleCloseModal()
  }

  return (
    <div className='flex gap-4'>
      <div className='mb-3 pt-3 w-1/3'>
        <Label htmlFor='price' value='Precio contado' />
        <input
          type='number'
          name='price'
          className='ui-input'
          placeholder='Precio contado'
          onChange={handleChange}
        />
      </div>
      <div className='mb-3 pt-3 w-1/3'>
        <Label htmlFor='financedPrice' value='Precio financiado' />
        <input
          type='number'
          name='financedPrice'
          className='ui-input'
          placeholder='Precio contado'
          onChange={handleChange}
        />
      </div>
      <div className='mb-3 pt-3 w-1/3'>
        <Label htmlFor='active' value='Estado' />
        <select className='ui-input' name='active' onChange={handleChange}>
          <option value={false}>Desactivado</option>
          <option value={true}>Activado</option>
        </select>
      </div>
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Aplicar precios de campaña`}
        handleCloseModal={handleCloseModal}
        handleActivate={update}
        handleDeactivate={update}
      />
    </div>
  )
}

export default CampaignDiscount
