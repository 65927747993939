import { Backdrop, CircularProgress, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'
import Button from '../../@core/components/Button'
import Modal from '../../@core/components/Modal'
import { AlertContextProvider } from '../../@core/context/alertContext'
import Sidebar from '../Sidebar'
import TopHeader from '../TopHeader'
import Services from './services'
import { HiX, HiPencilAlt, HiPlus } from 'react-icons/hi'
import EditForm from './components/EditForm'
import AddForm from './components/AddForm'
import { useAlert } from '../../@core/hooks/useAlert'
import CountDownKpi from './components/CountDownKpi'
import Badge from '../../@core/components/Badge'

const CircularLoading = ({ children, show }) => {
  return (
    <Backdrop open={show} sx={{ zIndex: 2 }}>
      {children}
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

const formatDateString = (date) => {
  return `${new Date(date).toLocaleDateString('es')} ${new Date(
    date
  ).toLocaleTimeString('es')}`
}

const TableRow = ({
  rowData,
  handleShowModal,
  handleClickRemoveButton,
  handleClickEditButton,
}) => (
  <tr>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      <div className='flex'>
        {rowData.active === true ? (
          <Badge color='success'>Activa</Badge>
        ) : (
          <Badge color='failure'>Inactiva</Badge>
        )}
      </div>
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      {rowData.name}
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      {formatDateString(rowData.startDate)}
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      {formatDateString(rowData.endDate)}
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      {rowData.badgeColor}
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      {rowData.textColor}
    </td>
    <td
      style={{ maxWidth: '300px' }}
      class='align-middle truncate tabular-nums text-left pl-4 pr-4 pt-4 pb-4 m-w-full'
    >
      {rowData.iconUrl}
    </td>
    <td class='align-middle whitespace-nowrap tabular-nums text-left pl-4 pr-4 pt-4 pb-4'>
      <div className='flex items-center gap-2 mt-0'>
        <Button
          text='Editar'
          size='sm'
          className='bg-blue-500 focus:ring-blue-400 hover:bg-blue-600 hover:border-blue-600'
          icon={HiPencilAlt}
          handleClick={() => handleClickEditButton(rowData)}
        >
          Editar
        </Button>
        <Button
          text='Eliminar'
          size='sm'
          className='bg-red-500 focus:ring-red-400 hover:bg-red-600 hover:border-red-600'
          icon={HiX}
          handleClick={() => handleClickRemoveButton(rowData._id)}
        >
          Editar
        </Button>
      </div>
    </td>
  </tr>
)

const Campaigns = () => {
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [campaigns, setCampaigns] = useState([])
  const [campaign, setCampaign] = useState({})
  const { setShowAlert } = useAlert()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Services.read()
        return data
      } catch (error) {
        console.log(error)
      }
    }

    if (campaigns.length === 0) {
      fetchData()
        .then((result) => {
          setCampaigns(result)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [campaigns])

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const handleShowAddModal = () => {
    setShowAddModal(!showAddModal)
  }

  const handleClickRemoveButton = async (campaignId) => {
    try {
      const result = await Services.remove(campaignId)
      const campaigns = await Services.read()

      setCampaigns(campaigns)

      console.log(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickEditButton = (campaign) => {
    setCampaign(campaign)
    handleShowModal()
  }

  const handleClickSubmitUpdate = async (campaign) => {
    await Services.update(campaign._id, campaign)
    const updatedCampaigns = await Services.read()
    setCampaigns(updatedCampaigns)
    setShowAlert(true)
    handleShowModal()
  }

  const handleClickSaveButton = async (campaign) => {
    try {
      handleShowAddModal()
      console.log(campaign)
      const result = await Services.create(campaign)
      const campaigns = await Services.read()

      console.log(result)
      setCampaigns(campaigns)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <AlertContextProvider>
      <div className='flex w-full min-h-screen'>
        <TopHeader />
        <Sidebar />
        <main className='w-full grow px-6 mt-4'>
          <CircularLoading loading={loading} />
          <Toolbar />
          <div className='py-6'>
            <h2 className='text-gray-700 text-2xl font-bold'>
              ⏳ CountDown Maker
            </h2>
          </div>

          <CountDownKpi campaigns={campaigns} />

          <div className='flex justify-end py-6'>
            <Button
              text='Añadir contador'
              size='sm'
              className='bg-blue-500 focus:ring-blue-400 hover:bg-blue-600 hover:border-blue-600'
              icon={HiPlus}
              handleClick={handleShowAddModal}
            />
          </div>

          <div className='relative w-full mx-auto text-left ring-1 mt-0 max-w-none bg-white shadow border-blue-400 ring-gray-200 pl-6 pr-6 pt-6 pb-6 rounded-lg'>
            <div className='overflow-auto'>
              <table className='w-full tabular-nums mt-0 text-gray-500 text-sm font-normal'>
                <thead class='text-left text-gray-500 font-semibold'>
                  <tr>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Estado
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Nombre
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Inicio
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Fin
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Badge color
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Text color
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Icon
                    </th>
                    <th className='sticky whitespace-nowrap text-left text-gray-500 top-0 pl-4 pr-4 pt-3.5 pb-3.5 font-semibold'>
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className='align-top overflow-x-auto divide-y divide-gray-200'>
                  {campaigns &&
                    campaigns.map((campaign) => (
                      <TableRow
                        key={campaign._id}
                        rowData={campaign}
                        handleShowModal={handleShowModal}
                        handleClickRemoveButton={handleClickRemoveButton}
                        handleClickEditButton={handleClickEditButton}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
        <Modal
          isOpen={showModal}
          title='Editar contador'
          actions={false}
          handleCloseModal={setShowModal}
        >
          <EditForm
            data={campaign}
            handleCancel={handleShowModal}
            handleSubmit={handleClickSubmitUpdate}
          />
        </Modal>
        <Modal
          isOpen={showAddModal}
          title='Nueva contador'
          actions={false}
          handleCloseModal={setShowAddModal}
        >
          <AddForm
            handleSubmit={handleClickSaveButton}
            handleCancel={handleShowAddModal}
          />
        </Modal>
      </div>
    </AlertContextProvider>
  )
}

export default Campaigns
