import { useState } from 'react'
import {
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import ConfirmModal from './ConfirmModal'
import GomotorService from '../../services'

const ActivateCampaigns = ({
  campaigns,
  open,
  dataSelection,
  handleCloseModal,
  isActivated,
}) => {
  const [value, setValue] = useState('')
  const [, setSelectCampaign] = useState([])

  const handleChange = (event) => {
    setValue(event.target.value)
    setSelectCampaign(campaigns.filter((campaign) => campaign._id === value))
  }

  const activate = async () => {
    const campaign = campaigns.filter((campaign) => campaign._id === value)

    await Promise.all(
      dataSelection.map((e) =>
        GomotorService.update(e.idv, {
          $set: {
            'marketing.counter': campaign[0],
          },
        })
      )
    )

    handleCloseModal()
  }

  const deactivate = async () => {
    await Promise.all(
      dataSelection.map((e) =>
        GomotorService.update(e.idv, {
          $set: {
            'marketing.counter': null,
          },
        })
      )
    )

    handleCloseModal()
  }

  return (
    <div>
      <FormControl component='fieldset'>
        <FormGroup row style={{ display: 'flex', alignItems: 'end' }}>
          <InputLabel>Seleccionar contador</InputLabel>
          <Select
            style={{ width: '250px', marginRight: '45px' }}
            onChange={handleChange}
            value={value}
          >
            {campaigns.map((element) =>
              element ? (
                <MenuItem value={element._id} key={element._id}>
                  {element.name}
                </MenuItem>
              ) : (
                ''
              )
            )}
          </Select>
        </FormGroup>
      </FormControl>
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Activar campaña`}
        handleCloseModal={handleCloseModal}
        handleActivate={activate}
        handleDeactivate={deactivate}
        isActivated={isActivated}
      />
    </div>
  )
}

export default ActivateCampaigns
