import { Button } from '@material-ui/core'

const downloadFile = (data, filename, fileType) => {
  const blob = new Blob([data], { type: fileType})

  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })
  a.dispatchEvent(clickEvent)
  a.remove()
}

const exportToJson = (event, data, filename) => {
  event.preventDefault()
  downloadFile(JSON.stringify(data), 'dataQuiter.json', 'text/json')
}

const ExportJsonButton = ({ data }) => {
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={(event) => exportToJson(event, data)}
      size='small'
      style={{ marginLeft: "12px", backgroundColor: "rgb(80, 127, 168)" }}
    >
      Exportar a JSON
    </Button>
  )
}

export default ExportJsonButton
