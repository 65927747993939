const dealers = [
  {
    dealer: '3893',
    portalId: 'U97',
    name: 'citroentarrauto.com',
  },
  {
    dealer: '389',
    portalId: 'U94',
    name: 'LP JoaquinOliva'
  },
  {
    dealer: '3892',
    portalId: 'U96',
    name: 'LP VN Deposito'
  },
  {
    dealer: '389',
    portalId: 'U105',
    name: 'LP OlivaMotor'
  },
  {
    dealer: '3894',
    portalId: 'U235',
    name: 'GOmotor General'
  },
  {
    dealer: '389',
    portalId: 'U257',
    name: 'GOmotor Ofertas'
  },
  {
    dealer: '3891',
    portalId: 'U360',
    name: 'LP stock1'
  },
  {
    dealer: '3893',
    portalId: 'U341',
    name: 'LP stock3'
  },
  {
    dealer: '38931',
    portalId: 'U387',
    name: 'Stock completo'
  }
]

export default dealers
