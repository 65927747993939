const Tarrauto = ({ width }) => {
  return (
    <img
      alt='Peugeot'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1642686870/Multipublicador%20GrupOlivaMotor/logo-tarrauto-quadrat_qnpgms.svg'
      width={width}
    />
  )
}

export default Tarrauto
