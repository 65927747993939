import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const read = () => {
  const request = http.get('/process')
  return request.then(response => response.data)
}

const update = (id, data) => {
  const request = http.put(`/process/${id}`, data)
  return request.then(response => response.data)
}

const getById = (id) => {
  const request = http.get(`/process/${id}`,)
  return request.then(response => response.data)
}

const ProcessService = {
  read,
  update,
  getById
}

export default ProcessService
