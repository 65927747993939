import { useState, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconAcabado } from '../Icons'
import { listColor } from '../../data'

const Color = ({ context }) => {
  const { setCarColor, setMetallic, setMetallicChecked, stock, metallic } =
    useContext(context)

  const [expanded, setExpanded] = useState(false)
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getColor = (event) => {
    const colors = event.target.value
    setCarColor(colors)
    console.log(colors)
  }

  const getMetalizado = (event) => {
    setMetallic(event.target.checked)
    if (!stock) {
      setMetallicChecked('true')
      console.log('true')
    } else {
      setMetallicChecked('false')
      console.log('false')
    }
  }

  return (
    <Accordion onChange={handleExpand('panel4')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel2a-content'
        id='panel2a-header'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
          <IconAcabado color='#e20087' size={24} />
          <Typography variant='h6'>Acabado</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction='row' spacing={2}>
          <FormControl
            size='small'
            variant='outlined'
            fullWidth
            required
            sx={{ width: '250px' }}
          >
            <InputLabel id='input-car-color'>Color</InputLabel>
            <Select
              labelId='input-car-color'
              id='demo-car-color'
              label='Color'
              onChange={(event) => getColor(event, event.target.value)}
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {listColor.map((color) => {
                return (
                  <MenuItem key={color.key} value={color.key}>
                    {color.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={metallic}
                  onChange={getMetalizado}
                  value={metallic}
                />
              }
              label='Metalizado'
            />
          </FormControl>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default Color
