import axios from 'axios'
import authHeaders from '../../../services/auth-header'

const url = process.env.REACT_APP_GOMOTOR_API
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const read = () => {
  const request = http.get('/vehicles')
  return request.then((response) => response.data)
}

const create = (data) => {
  const request = http.post(`/vehicles`, data)
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.put(`/algolia/vehicles/${id}`, data)
  return request.then((response) => response.data)
}

const deleteById = (id) => {
  const request = http.delete(`/algolia/vehicles/${id}`)
  return request.then((response) => response.data)
}

const syncQuiter = () => {
  const request = http.get('/vehicles/sync')
  return request.then((response) => response.data)
}

const syncQuiterManually = () => {
  const request = http.get('/vehicles/sync-manually')
  return request.then((response) => response.data)
}

const syncWeb = () => {
  const request = http.get('/vehicles/algolia/sync')
  return request.then((response) => response.data)
}

const getSettings = () => {
  const request = http.get('/settings')
  return request.then((response) => response.data)
}

const ApiServices = {
  read,
  create,
  deleteById,
  syncQuiter,
  syncQuiterManually,
  syncWeb,
  update,
  getSettings,
}

export default ApiServices
