import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import Button from '../Button'

const Modal = ({
  children,
  isOpen,
  title,
  handleCloseModal,
  actions = true,
}) => {
  const handleClose = () => {
    handleCloseModal(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: { overflowY: 'visible' },
      }}
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions && (
          <>
            <Button
              text='Cancelar'
              size='sm'
              className='bg-red-500 focus:ring-red-400 hover:bg-red-600 hover:border-red-600'
              handleClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              text='Aceptar'
              size='sm'
              className='bg-blue-500 focus:ring-blue-400 hover:bg-blue-600 hover:border-blue-600'
              handleClick={handleClose}
            >
              Aceptar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
