import { Snackbar as MuiSnackbar, Alert as MuiAlert, Alert } from '@mui/material'

const Snackbar = ({ children, open, severity, handleClose }) => {

  const handleCloseSnackbar = () => {
    handleClose()
  }

  return (
    <MuiSnackbar open={open} autoHideDuration={45000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <MuiAlert variant='filled' onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
        {children}
      </MuiAlert>
    </MuiSnackbar>

  )
}

export default Snackbar
