import { useState } from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

const ChildSwitch = ({ value, name, label, handleChangeSwitch }) => {
  const [state, setState] = useState(value || false)

  const handleChange = (event) => {
    console.log(event.target.checked)
    setState(event.target.checked)
    handleChangeSwitch(event.target.checked)
  }

  return (
    <FormControlLabel
    style={{ marginLeft: "12px" }}
    control={
      <Switch
        checked={state}
        value={state}
        onChange={handleChange}
        name={name}
        color='primary'
      />
    }
    label={label}
    />
  )
}

export default ChildSwitch
