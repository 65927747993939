import styled from "@emotion/styled"
import { Paper } from '@mui/material'

export const StyledBox = styled.div`
  padding: 0px 24px 24px 24px;
`
export const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  border-left: 5px solid #999999;
`