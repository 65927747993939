import { StyledPaperCard, CarIcon } from "./styles"

const Km0 = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeKm0Web}
      onClick={() => handleClick()}
    >
      <CarIcon color="green" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>KM 0</p>
      </div>
    </StyledPaperCard>
  )
}

export default Km0