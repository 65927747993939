import { DataGrid } from '@mui/x-data-grid'

const ListDataGrid = ({ data }) => {
  const columns = [
    {
      field: 'idv',
      headerName: 'IDV',
      width: 150,
    },
    {
      field: 'marca',
      headerName: 'Marca',
      width: 150,
    },
    {
      field: 'modelo',
      headerName: 'Modelo',
      width: 150,
    },
    {
      field: 'precios[0].valor',
      headerName: 'Contado',
      width: 150,
      valueGetter: (params) => params.row.precios[0].valor,
    },
    {
      field: 'precios[3].valor',
      headerName: 'Financiado',
      width: 150,
      valueGetter: (params) => params.row.precios[3].valor,
    },
    {
      field: 'garantia',
      headerName: 'Garantia',
      width: 150,
    },
    {
      field: 'ubicacion',
      headerName: 'UBICACION',
      width: 150
    }
  ]

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.idv}
      />
    </div>
  )
}

export default ListDataGrid
