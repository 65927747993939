import React, { useState } from "react"
import { Badge, IconButton, Popover, Box, Container } from "@material-ui/core"
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded"
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import CheckIcon from "@material-ui/icons/Check"
import Alert from "@material-ui/lab/Alert"
import { Link } from "react-router-dom"
import styles from './styles.module.css'
import { useEffect } from "react"

const inlineStyles = {
  spacer: {
    flex: 1,
  },
  badgeFix: {
    display: "inline-flex",
  },
}

const Notification = ({ notifications, handleMarkRead }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [ notReading, setNotReading ] = useState('')

  useEffect(() => {
    if(notifications) {
      setNotReading(notifications.filter(e => !e.reading))
    }
  }, [notifications])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRead = (data) => {
    const markRead = { ...data, reading: true }
    handleMarkRead(markRead)
  }

  // console.log(notReading)

  return (
    <div>
      <IconButton
        style={{ color: "white" }}
        id='notifications-button'
        aria-controls='fade-menu'
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge
          style={inlineStyles.badgeFix}
          badgeContent={notReading.length}
          color='secondary'
        >
          <NotificationsRoundedIcon />
        </Badge>
      </IconButton>
      <Popover
        // id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ padding: "12px" }}
      >
        <Box>
          <Container style={{ padding: "12px" }}>
            {notReading.length ? (
              notReading.map((element) => {
                return (
                  <div>
                    <Alert
                      severity={element.severity}
                      action={
                        <IconButton
                          size='small'
                          onClick={() => handleRead(element)}
                        >
                          <CheckIcon />
                        </IconButton>
                      }
                    >
                      {element.text}
                    </Alert>
                    <br />
                  </div>
                )
              })
            ) : (
              <Box className={styles.notifyBox}>
                <NotificationImportantIcon />
                <p>No hay notificaciones sin leer</p>
                <Link to={'/notificaciones'}>Ver todas las notificaciones</Link>
              </Box>
            )}
          </Container>
        </Box>
      </Popover>
    </div>
  )
}

export default Notification
