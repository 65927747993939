import axios from 'axios'
import authHeaders from '../../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const create = (data) => {
  const request = http.post('/publishing/api/v1/wallapop/accounts', data)
  return request.then((response) => response.data)
}

const read = () => {
  const request = http.get('/publishing/api/v1/wallapop/accounts')
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.patch(`/publishing/api/v1/wallapop/accounts/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/publishing/api/v1/wallapop/accounts/${id}`)
  return request.then((response) => response.data)
}

const WallapopSettingsService = {
  create,
  read,
  update,
  remove
}

export default WallapopSettingsService