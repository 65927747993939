import MaterialTables from '@material-table/core'
import useAramis from '../hooks/useAramis'
import dataUtils from '../utils/dataUtils'
import AramisService from '../services/aramis.service'

const DataTable = () => {
  const { data, setData } = useAramis()
  return (
    <MaterialTables
      title='Vehiculos en la base de datos:'
      columns={dataUtils.columns}
      data={data}
      options={{
        tableLayout: 'auto',
        columnsButton: true,
        filtering: true,
        pageSize: 10,
        selection: true,
        rowStyle: {
          fontSize: '12px',
          padding: '12px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = newData
            AramisService.update(newData._id, newData)
              .then((result) => {
                console.log(result)
                setData([...dataUpdate])
                resolve()
              })
              .catch((err) => {
                console.log(err)
                reject(err)
              })
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data]
            const index = oldData.tableData.id
            dataDelete.splice(index, 1)
            AramisService.remove(oldData._id)
              .then((response) => {
                console.log(response)
                setData(dataDelete)
                resolve()
              })
              .catch((err) => {
                console.log(err)
                reject(err)
              })
          })
      }}
    />
  )
}

export default DataTable
