import { useEffect, useState } from "react"
import useAlert from "./useAlert"
import GrupOlivaMotorService from "../../../../services/grupolivamotor.service"

const useData = () => {
  const [data, setData] = useState([])
  const [showProgress, setShowProgress] = useState(true)
  const { alertMessage, setAlertMessage, typeAlert, setTypeAlert, showMessage, setShowMessage } = useAlert()

  useEffect(() => {
    if (data.length === 0) {
      GrupOlivaMotorService.read()
        .then((result) => {
          setData(result)
          setShowProgress(false)
        })
        .catch((err) => {
          setAlertMessage("Error de conexión con la base de datos")
          setTypeAlert("error")
          setShowMessage(true)
          setShowProgress(false)
        })
    }
  }, [data, setTypeAlert, setAlertMessage, setShowMessage])

  return {
    data,
    setData,
    showProgress,
    setShowProgress,
    alertMessage,
    setAlertMessage,
    typeAlert,
    setTypeAlert,
    showMessage,
    setShowMessage  
  }
}

export default useData