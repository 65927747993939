import { makeStyles } from "@material-ui/core/styles"
import { CardMedia } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
    "&:hover": {
      backgroundImage: ({ hoverImage }) => `url(${hoverImage})`,
    },
  },
}))

const ImageCard = ({ backgroundImage, hoverImage }) => {
  const classes = useStyles({
    backgroundImage,
    hoverImage,
  })
  return (
    <CardMedia
      className={classes.media}
      backgroundImage={backgroundImage}
      hoverImage={hoverImage}
    />
  )
}

export default ImageCard
