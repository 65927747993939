import { Button, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"
import UploadFileService from "../../../services/upload-file.service"
import MotosService from "../../../services/motos.service"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none'
  }
}))

const MotoEditFormActions = ({ handleSaveButton, idv, id }) => {
  const [currentFile, setCurrentFile] = useState(undefined)
  const [progress, setProgress] = useState(0)
  const [message, setMessage] = useState('')

  const classes = useStyles()

  const selectFile = (event) => {
    console.log(event.target.value)
    setCurrentFile(event.target.files[0])
    console.log(currentFile)

    UploadFileService.upload(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
      console.log(progress)
    })
    .then(response => {
      console.log(progress)
      console.log(response.data)
      setMessage(response.data.message)
      const newImage = {
        name: `${idv}-${currentFile.name}`,
        url: process.env.REACT_APP_API_URL + 'uploads/images/' + `${currentFile.name}`,
        tipo: 'IMAGEN',
      }
      const filter = { _id: id }
      const update = { $push: { recursos: newImage } }
      MotosService.update(
        id,
        {
          filter,
          update
        }
      )
      .then(result => {
        console.log(result)
      })
      .catch(err => {
        console.log(err)
      })
    })
    .catch(err => {
      console.log(err)
    })    
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={(event) => handleSaveButton(event)}
          size='small'
        >
          Guardar
        </Button>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={selectFile}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            component="span"
            size='small'
          >
            Subir imagen
          </Button>
        </label>
      </Grid>
    </Grid>
  )
}

export default MotoEditFormActions
