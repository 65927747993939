import styled from '@emotion/styled'

export const StyledAlertModalText = styled.b`
  color: #ff6969;
`
export const StyledList = styled.ul`
  list-style: none;
  margin: 18px;
  padding: 14px;
  background-color: #ddeef9;
  border-radius: 6px;
  font-size: 12px;
`