import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import ExportJsonButton from '../../../../../components/buttons/ExportJsonButton'

const Updates = ({ data }) => {

  const [sortModel] = useState([
    {
      field: "timestamp",
      sort: "desc",
    },
  ])

  const columns = [
    {
      field: "timestamp",
      headerName: "Fecha",
      width: 200,
    },
    {
      field: "level",
      headerName: "Nivel",
      width: 150,
      // valueGetter: (value) => value.row.message.id,
    },
    {
      field: "message.type",
      width: 150,
      headerName: "Acción",
      valueGetter: (value) => value.row.message.type,
    },
    {
      field: "message.text",
      width: 350,
      headerName: "Mensaje",
      valueGetter: (value) => value.row.message.text,
    },
    {
      field: "message.numUpdates",
      width: 150,
      headerName: "Actualizados",
      valueGetter: (value) => value.row.message.numUpdates,
    },
    {
      field: "message.updateIdvs",
      width: 150,
      headerName: "IDVS",
      flex: 1,
      valueGetter: (value) => value.row.message.updateIdvs,
    },
  ]

  console.log(data)
  return (
    <Accordion>
      <AccordionSummary>
        <h3 style={{ margin: '0'}}>Actualizaciones de vehículos</h3>
      </AccordionSummary>
      <AccordionDetails>
        {data.length > 0 ? (
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rowHeight={25}
              rows={data}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              getRowId={(row) => row.message.id}
              sortingOrder={['desc', 'asc']}
              sortModel={sortModel}
              // onSortModelChange={(model) => setSortModel(model)}
            />
          </div>
        ) : (
          ""
        )}
        <br />
        <ExportJsonButton data={data} />
      </AccordionDetails>
    </Accordion>
  )
}

export default Updates
