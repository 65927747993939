import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import LoginService from "../../services/auth.service"
import CircularProgress from "@material-ui/core/CircularProgress"
import Fade from "@material-ui/core/Fade"
import Logo from '../Logo'
import Utils from '../../services/utils'
import AlertBox from '../AlertBox'
import UserUtils from '../../utils/User.utils'

const LoginPage = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setErrorLogin] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (UserUtils.userExist()) {
      const user = UserUtils.getUser()
      setUser(user);
      history.push("/dashboard");
    }
    return () => {};
  }, [history]);

  const handleLogin = async (event) => {
    setLoading(true)
    event.preventDefault();
    if(Utils.validateLoginForm(email, password)) {
      setErrorMessage('Todos los campos son necesarios')
      setErrorLogin(true)
    } else {
      try {
        const user = await LoginService.login({
          email,
          password,
        });
        setUser(user);
        window.localStorage.setItem("usuario", JSON.stringify(user));
        window.location.reload();
        setLoading(false)
      } catch (e) {
        if(e.response) {
          const message = e.response.data.error
          setErrorMessage(message)
          setErrorLogin(true);
        } else {
          setErrorMessage(e.message)
          setErrorLogin(true);
          console.log(e.message)
        }
      }
    }
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div class="grid-container">
      <div class="imagen"></div>
      <div class="login">
        <header class="login-header">
          <Logo
            url='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1624144139/login-page-template/logo-grup-oliva-1_worrvo.png'
            width='400'
          />
          {error ? (
            <AlertBox type='error' text={errorMessage} />
          ) : (
            ""
          )}
          <p>Bienvenido</p>
          <h1>Accede con tu cuenta</h1>
        </header>
        <div class="login-wrapper">
          <form>
            <label for="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              // value={email}
              onChange={handleEmail}
            />
            <label for="password">Contraseña</label>
            <input
              type="password"
              name="password"
              required
              value={password}
              onChange={handlePassword}
            />
            <input
              class="button"
              type="submit"
              value="Iniciar Sesión"
              onClick={handleLogin}
            />
          </form>
          <div>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "100ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
