import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const read = () => {
  const request = http.get('/gomotor')
  return request.then(response => response.data)
}

const create = (data) => {
  const request = http.post('/gomotor', data)
  return request.then(response => response.data)
}

const deleteId = (id) => {
  const request = http.delete(`/gomotor/${id}`)
  return request.then(response => response.data)
}

const update = (id, data) => {
  const request = http.put(`/gomotor/${id}`, data)
  return request.then(response => response.data)
}

const getIDV = (idv) => {
  const request = http.get(`/gomotor?filter={"_id": "${idv}"}`)
  return request.then(response => response.data)
}

const wooSync = () => {
  const request = http.get('/gomotor/woocommerce-sync')
  return request.then(response => response.data)
}

const bulkUpdate = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/gomotor/bulk-update', data, { headers: header})
  return request.then(response => response.data)
}

const bulkIncDre = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/gomotor/bulk-update-price', data, { headers: header})
  return request.then(response => response.data)
}

const bulkCreate = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/gomotor/bulk-create', { data }, { headers: header})
  return request.then(response => response.data)
}

const bulkUpdateWP = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/gomotor/bulk-update-wp', data, { headers: header})
  return request.then(response => response.data)
}

const bulkDelete = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/gomotor/bulk-delete', data, { headers: header})
  return request.then(response => response.data)
}

const viewLog = () => {
  const request = http.get('/gomotor/log')
  return request.then(response => response.data)
}

const updateDb = () => {
  const request = http.get('/gomotor/update-db')
  return request.then(response => response.data)
}

const GomotorService = {
  read,
  create,
  update,
  deleteId,
  getIDV,
  wooSync,
  bulkUpdate,
  bulkIncDre,
  bulkCreate,
  bulkUpdateWP,
  bulkDelete,
  viewLog,
  updateDb
}


export default GomotorService
