import { useState } from "react"
import { TextField } from "@material-ui/core"
import ConfirmModal from "./ConfirmModal"
import GomotorService from "../../../../../services/gomotor.service"

const ReservedPrice = ({ open, dataSelection, handleCloseModal }) => {
  const [price, setPrice] = useState("300")

  const handleChange = (event) => {
    setPrice(event.target.value)
  }

  const update = () => {
    GomotorService.bulkUpdate({
      dataSelection,
      update: "precioReserva",
      value: price,
    })
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => console.log(err))
  }

  return (
    <div>
      <TextField
        label='Nuevo importe'
        style={{ marginRight: "45px" }}
        type='number'
        defaultValue={300}
        inputProps={{
          min: 300,
        }}
        onChange={handleChange}
      />
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Modificar precio de reserva`}
        handleCloseModal={handleCloseModal}
        handleActivate={update}
        handleDeactivate={update}
      />
    </div>
  )
}

export default ReservedPrice
