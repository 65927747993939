import { AccordionDetails, Grid } from "@mui/material"
import { StyledAccordion, StyledSummaryTitle, StyledSummary, CarIcon } from "./styles"
import  New from './New'
import Km0 from "./Km0"
import Ocasion from "./Ocasion"

const TypeWebVehicle = ({
  dataKm0Web,
  dataOcasionWeb,
  handleClickCard,
  active,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <CarIcon size="28px" color="#1976d2" />
        <StyledSummaryTitle>Tipo de Vehículo (Web)</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Km0
              active={active}
              data={dataKm0Web}
              handleClick={() => handleClickCard('activeKm0Web', 'km0Web')}
            />
          </Grid>
          <Grid item xs={3}>
            <Ocasion
              active={active}
              data={dataOcasionWeb}
              handleClick={() => handleClickCard('activeOcasionWeb', 'ocasionWeb')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default TypeWebVehicle
