import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"
import MainTopHeader from "../../MainTopHeader"
import Grid from "@material-ui/core/Grid"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ChildTextField from "../../ChildTextField"
import ChildTextArea from "../../ChildTextArea"
import DatePicker from "../../DatePicker"
import MotoEditFormEquipment from "./MotoEditFormEquipment"
import MotoEditFormActions from "./MotoEditFormActions"
import MotosService from "../../../services/motos.service"
import SnackBarAlert from "../../SnackbarAlert"
import { Paper, Divider } from "@material-ui/core"
import MotoPhoto from "./MotoPhoto"
import AlertBox from "../../AlertBox"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text], input[type=date]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
    marginTop: '12px'
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  propTitle: {
    marginTop: '10px',
    marginBottom: '25px'
  }
}))

const MotoEditForm = ({ data }) => {
  const [expanded, setExpanded] = useState(true)
  const [motoData, setMotoData] = useState(data)
  const [open, setOpen] = useState(false)

  const classes = useStyles()
  const title = `Ficha ${motoData.marca} ${motoData.modelo} ${motoData.version}`

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const handleDateChange = (date) => {
    motoData.fechaMatriculacion = new Date(date).toISOString()
  }

  const handleInputTextChange = (event) => {
    console.log(event.target.name)
    console.log(event.target.value)
    setMotoData({
      ...motoData,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputTextAreaChange = (event) => {
    motoData.observaciones = event.target.value
  }

  const handleSaveButton = (event) => {
    // console.log(event)
    event.preventDefault()
    const filter = {
      _id: motoData._id
    }
    const update = motoData

    MotosService.update(
      motoData._id,
      {
        filter,
        update
      }
      )
      .then((result) => {
        console.log(result)
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 3000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleAddEquipment = (equipments) => {
    motoData.equipamiento = equipments
  }

  const handleChangePublished = (value) => {
    motoData.publicar = value
  }

  const handleChangeImageProperties = (image) => {
    //console.log(image)

    const filter = {
      _id: motoData._id,
      recursos: {
        $elemMatch: {'_id': image._id},
      }
    }

    const update = {
      $set: {
        'recursos.$.propiedades': image.propiedades
      }
    }

    MotosService.update(
      motoData._id,
      {
        filter,
        update,
      }
    )
    .then(result => {
      console.log(result)
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <div>
      <MainTopHeader
        title={title}
        published={motoData.publicar}
        handleChangePublished={handleChangePublished}
        optionForPublish={true}
      />
      <form className={classes.form} noValidate autoComplete='off'>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpand}
              >
                <Typography variant='h6' component='h3'>
                  Datos generales del Vehículo
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='marca'
                  label='Marca'
                  defaultValue={data.marca}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='modelo'
                  label='Modelo'
                  defaultValue={data.modelo}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='codigoModelo'
                  label='Cod Modelo'
                  defaultValue={data.codigoModelo}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='version'
                  label='Versión'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.version}
                />
                <ChildTextField
                  name='tipo'
                  label='Tipo'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.tipo}
                />
                <ChildTextField
                  name='tipoVehiculo'
                  label='Tipo Vehículo'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.tipoVehiculo}
                />
                <ChildTextField
                  name='pais'
                  label='País'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.pais}
                />
                <ChildTextField
                  name='idConcesion'
                  label='ID Concesión'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.idConcesion}
                />
                <ChildTextField
                  name='bastidor'
                  label='Bastidor'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.bastidor}
                />
                <ChildTextField
                  name='carroceria'
                  label='Carrocería'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.carroceria}
                />
                <ChildTextField
                  name='color'
                  label='Color'
                  defaultValue={data.color}
                />
                <ChildTextField
                  name='codigoColor'
                  label='Cod Color'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.codigoColor}
                />
                <ChildTextField
                  name='potenciaCv'
                  label='Potencia CV'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.potenciaCv}
                />
                <ChildTextField
                  name='km'
                  label='KM'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.km}
                />
                <ChildTextField
                  name='matricula'
                  label='Matricula'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.matricula}
                />
                <ChildTextField
                  name='combustible'
                  label='Combustible'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.combustible}
                />
                <ChildTextField
                  name='colorInterior'
                  label='Color Interior'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.colorInterior}
                />
                <ChildTextField
                  name='cambio'
                  label='Cambio'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.cambio}
                />
                <ChildTextField
                  name='transmision'
                  label='Transmisión'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.transmision}
                />
                <ChildTextField
                  name='potenciaKw'
                  label='Potencia KW'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.potenciaKw}
                />
                <ChildTextField
                  name='aceleracion'
                  label='Aceleración'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.aceleracion}
                />
                <ChildTextField
                  name='velocidadMaxima'
                  label='Velocidad MAX'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.velocidadMaxima}
                />
                <ChildTextField
                  name='peso'
                  label='Peso'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.peso}
                />
                <ChildTextField
                  name='normativa'
                  label='Normativa'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.normativa}
                />
                <ChildTextField
                  name='garantia'
                  label='Garantía'
                  defaultValue={data.garantia}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='categoria'
                  label='Categoría'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.categoria}
                />
                <ChildTextField
                  name='ubicacion'
                  label='Ubicación'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.ubicacion}
                />
                <ChildTextField
                  name='tipoVO'
                  label='TipoVO'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.tipoVO}
                />
                <ChildTextField
                  name='nombreConcesionario'
                  label='Nombre concesionario'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.nombreConcesionario}
                />
                <ChildTextField
                  name='impFinanciacion'
                  label='Imp Financiación'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.impFinanciacion}
                />
                <ChildTextField
                  name='liquidacion'
                  label='Liquidación'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.liquidacion}
                />
                <ChildTextField
                  name='actualizado'
                  label='Actualizado'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.actualizado}
                />
                <ChildTextField
                  name='traccion'
                  label='Tracción'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.traccion}
                />
                <ChildTextField
                  name='presReacondicionamiento'
                  label='Pres Reacondicionamiento'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.presReacondicionamiento}
                />
                <ChildTextField
                  name='metalizado'
                  label='Metalizado'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.metalizado}
                />
                <ChildTextField
                  name='ivaDeducible'
                  label='IVA Deducible'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.ivaDeducible}
                />
                <ChildTextField
                  name='carfax'
                  label='CarFax'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.carfax}
                />
                <ChildTextField
                  name='puertas'
                  label='Puertas'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.puertas}
                />
                <ChildTextField
                  name='plazas'
                  label='Plazas'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.plazas}
                />
                <ChildTextField
                  name='marchas'
                  label='Marchas'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.marchas}
                />
                <ChildTextField
                  name='cilindros'
                  label='Cilindros'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.cilindros}
                />
                <ChildTextField
                  name='reservado'
                  label='Reservado'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.reservado}
                />
                <DatePicker
                  label='Fecha Matriculación'
                  date={motoData.fechaMatriculacion}
                  handleDateChange={handleDateChange}
                />
                <ChildTextField
                  name='anyoModelo'
                  label='Año Modelo'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.anyoModelo}
                />
                <ChildTextField
                  name='mesModelo'
                  label='Mes Modelo'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.mesModelo}
                />
                <ChildTextField
                  name='numPropietariosAnteriores'
                  label='Nº Propietarios Anteriores'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.numPropietariosAnteriores}
                />
                <ChildTextField
                  name='importacion'
                  label='Importación'
                  handleInputTextChange={handleInputTextChange}
                  defaultValue={data.importacion}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Consumos
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='consumos'
                  label='Consumo'
                  defaultValue={motoData.consumos}
                  handleInputTextChange={handleInputTextChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Emisiones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='emisiones'
                  label='Emisiones'
                  defaultValue={motoData.emisiones}
                  handleInputTextChange={handleInputTextChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Precios
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='precioVenta'
                  label='Precio Venta'
                  defaultValue={motoData.precioVenta}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='pvpPublicacion'
                  label='PVP Publicación'
                  defaultValue={motoData.pvpPublicacion}
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='pvoNuevo'
                  label='PVP Nuevo'
                  defaultValue={motoData.pvoNuevo}
                  handleInputTextChange={handleInputTextChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Dimensiones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='dimensiones'
                  label='Dimensiones'
                  defaultValue={motoData.dimensiones}
                  handleInputTextChange={handleInputTextChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Vehículo Contacto
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='vehiculoContacto'
                  label='Nombre Vendedor'
                  defaultValue={motoData.vehiculoContacto}
                  handleInputTextChange={handleInputTextChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Observaciones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextArea
                  name='observaciones'
                  defaultValue={motoData.observaciones}
                  handleInputTextAreaChange={handleInputTextAreaChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h2'>
                  Equipamiento
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MotoEditFormEquipment
                  handleAddEquipment={handleAddEquipment}
                  equipment={motoData.equipamiento}
                />
              </AccordionDetails>
            </Accordion>
            <MotoEditFormActions
              handleSaveButton={handleSaveButton}
              idv={motoData.idv}
              id={motoData._id}
            />
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography
                variant='h6'
                component='h2'
                align='center'
                className={classes.propTitle}
              >
                Imágenes del vehículo
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
                {motoData.recursos[0] === ""
                  ? <AlertBox type='warning' text='Este vehículo no tiene fotos' />
                  : (
                    motoData.recursos.map((image) => (
                      <MotoPhoto image={image} handleChangeImageProperties={handleChangeImageProperties} />
                    ))
                  )
                }
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <SnackBarAlert isOpen={open} />
    </div>
  )
}

export default MotoEditForm
