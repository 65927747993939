import { StyledBox, StyledPaper, StyledText } from './style'
import Brand from './Brand'
import Oferta from './Offer'

const Filters = ({ context }) => {
  return (
    <StyledBox>
      <StyledPaper>
        <StyledText>
          Filtros:
        </StyledText>
        <Brand context={ context } />
        <Oferta context={ context } />
      </StyledPaper>
    </StyledBox>
  )
}

export default Filters
