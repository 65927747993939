import TecnotarracoIcon from "../../../../Icons/Tecnotarraco"
import { StyledPaperCard } from "./styles"

const Tecnotarraco = ({ data, active, handleClick }) => {
  
  return (
    <StyledPaperCard
    active={active.activeLpTecnotarraco}
    onClick={() => handleClick()}
    >
      <TecnotarracoIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Tecnotarraco</p>
      </div>
    </StyledPaperCard>
  )
}

export default Tecnotarraco