import { useState, useEffect } from "react"
import { useParams, Redirect } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import TopHeader from "../../TopHeader"
import Sidebar from "../../Sidebar"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress"
import VehicleEditForm from "../../VehicleEditForm"
import Utils from "../../../services/utils"
import UserUtils from "../../../utils/User.utils"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fafafa'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const stockCompleto = {
  dealer: "38931",
  portalId: "U387",
  name: "Stock completo",
}

const QuiterStockVehicleView = () => {
  const [data, setData] = useState(false)
  const [showProgress, setShowProgress] = useState(true)

  const classes = useStyles()
  const { idv } = useParams()
  const url = process.env.REACT_APP_API_VEHICULOS_QUITER
 
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        Utils.secureHttp(`${url}/${stockCompleto.portalId}`, "GET")
          .then((result) => {
            if (result.tipoError) {
              console.log(result)
            } else {
              const vehicle = result.vehiculos.filter(vehicle => vehicle.idv === idv)
              setData(vehicle[0])
              console.log(vehicle)
              setShowProgress(false)
            }
          })
          .catch((err) => {
            setShowProgress(false)
          })
      }
      fetchData()
    }
  }, [data, url, idv])

  if (!UserUtils.userExist()) return <Redirect to='/login' />
  return(
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
      <Backdrop className={classes.backdrop} open={showProgress}>
              <CircularProgress color="inherit" />
      </Backdrop>
        {data
          ? <VehicleEditForm data={data} optionForPublish={false} />
          : ''
        }
      </main>
    </div>
  )
}

export default QuiterStockVehicleView