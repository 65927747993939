import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Stack from "@mui/material/Stack"
import React from "react"

const SubmitButton = ({ text, loading, handleClick }) => {

  const handleButton = () => {
    if(handleClick) {
      handleClick()
    }
    else {
      return 0
    }
  }

  return (
    <Stack direction='row' spacing={2}>
      <LoadingButton
        loading={loading}
        loadingPosition='start'
        type='submit'
        variant='contained'
        color='primary'
        size='small'
        startIcon={<SaveIcon />}
        onClick={handleButton}
      >
        {text}
      </LoadingButton>
    </Stack>
  )
}

export default SubmitButton
