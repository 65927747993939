import CachedIcon from '@mui/icons-material/Cached'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const spinner = keyframes`
  from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
`

export const StyledAnimatedIcon = styled(CachedIcon)`
  cursor: pointer;
	color: ${props => props.spin === 'true' && 'gray'};
  pointer-events: ${props => props.spin === 'true' ? 'none' : 'all'};
  animation: ${props => props.spin === 'true' && spinner} 0.6s ease infinite;
`
