import React, { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'

function InputDate({ name, ...props }, ref) {
  const { field, fieldState } = useController({ name })
  const { register } = useFormContext()
  const { onChange, ...rest } = register(name)

  return (
    <>
      <DatePicker
        value={field.value || props.defaultValue}
        format='MM/DD/YYYY HH:mm:ss'
        plugins={<TimePicker position='bottom' />}
        {...props}
        {...rest}
        name={name}
        onChange={(date) =>
          onChange({ target: { name, value: date?.toDate?.() } })
        }
        ref={ref}
        inputClass='px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full'
      />
      {fieldState.error && <p>{fieldState.error.message}</p>}
    </>
  )
}

export default forwardRef(InputDate)
