import { AccordionDetails, Grid, Chip } from "@mui/material"
import {
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  EyeIcon,
  StyledCheckBox
} from "./styles"
import Prices from "./Prices"
import BodyWork from "./BodyWork"
import Warranty from "./Warranty"

const Reviews = ({ handleClickCard, active, checkPrices, checkBodyType, checkWarranty }) => {
  const checkCount = checkPrices + checkBodyType + checkWarranty
  return (
    <StyledAccordion>
      <StyledSummary>
        <EyeIcon size='28px' color='#d13333' />
        <StyledSummaryTitle>
          Revisiones
        </StyledSummaryTitle>
        <StyledCheckBox>
          { checkCount > 0 && <Chip color="error" size="small" label={checkCount} />}
        </StyledCheckBox>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Prices
              data={checkPrices}
              active={active}
              handleClick={() => handleClickCard("activeCheckPrices", "checkPrices")}
            />
          </Grid>
          <Grid item xs={3}>
            <BodyWork
              data={checkBodyType}
              active={active}
              handleClick={() => handleClickCard("activeCheckBodyWork", "checkBodyWork")}
            />
          </Grid>
          <Grid item xs={3}>
            <Warranty
              data={checkWarranty}
              active={active}
              handleClick={() => handleClickCard("activeCheckWarranty", "checkWarranty")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default Reviews
