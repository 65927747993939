import { useEffect, useState } from "react"
import { Toolbar } from "@mui/material"
import { StyledRoot, StyledContent } from "./styles"
import Sidebar from "../../../../Sidebar"
import TopHeader from "../../../../TopHeader/TopHeader"
import Imports from "./Imports"
import SyncDb from "./SyncDb"
import Updates from "./Updates"
import SyncWeb from "./SyncWeb"
import AutomaticSync from "./AutomaticSync"
import GrupOlivaMotorService from "../../../../../services/grupolivamotor.service"

const Log = () => {
  const [data, setData] = useState([])
  const [updateData, setUpdateData] = useState([])
  const [syncData, setSyncData] = useState([])
  const [autoSyncData, setAutoSyncData] = useState([])

  useEffect(() => {
    if(data.length === 0) {
      GrupOlivaMotorService.viewLog()
        .then((json) => {
          setData(json.logDataBase)
          setUpdateData(json.bulkLog)
          setSyncData(json.logSync)
          setAutoSyncData(json.automaticLogSync)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [data])

  return (
    <StyledRoot>
      <TopHeader />
      <Sidebar />
      <StyledContent>
        <Toolbar />
        <h1>Acciones en GrupOlivaMotor.com</h1>
        <SyncDb data={data} />
        <Imports data={data} />
        <SyncWeb data={syncData} />
        <Updates data={updateData} />
        <AutomaticSync data={autoSyncData} />
      </StyledContent>
    </StyledRoot>
  )
}

export default Log