import { StyledPaperCard, CarIcon } from "./styles"

const Ocasion = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeOccasion}
      onClick={() => handleClick()}
    >
      <CarIcon color="#545454" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Ocasión</p>
      </div>
    </StyledPaperCard>
  )
}

export default Ocasion