import axios from 'axios'
import authHeaders from '../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const create = (data) => {
  const request = http.post('/publishing/api/v1/motoreto/vehicles', data)
  return request.then((response) => response.data)
}

const read = () => {
  const request = http.get('/publishing/api/v1/motoreto/vehicles')
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.patch(`/publishing/api/v1/motoreto/vehicles/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/publishing/api/v1/motoreto/vehicles/${id}`)
  return request.then((response) => response.data)
}

const sync = () => {
  const request = http.get('/publishing/api/v1/motoreto/vehicles/sync')
  return request.then((response) => response.data)
}

const createXML = () => {
  const request = http.get('/publishing/api/v1/motoreto/vehicles/create-xml')
  return request.then((response) => response.data)
}

const dropCollection = () => {
  const request = http.get(
    '/publishing/api/v1/motoreto/vehicles/drop-collection'
  )
  return request.then((response) => response.data)
}

const Services = {
  create,
  read,
  update,
  remove,
  sync,
  createXML,
  dropCollection,
}

export default Services
