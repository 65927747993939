import { useState } from 'react'

export const initialFormData = Object.freeze({
  brand: '',
  year: '',
  fuelType: '',
  model: '',
  taxes: '',
  financedPrice: '',
  monthlyFee: '',
  warranty: '',
  cardBody: '',
  carChange: '',
  version: '',
  uniqueVersion: '',
  chassisNumber: '',
  carUbication: '',
  environmentalLabel: '',
  color: {
    colorId: '',
    metalized: false,
    additionalColor: ''
  },
  features: '',
  stock: '',
  metallicChecked: '',
})

export const useData = () => {
  const [formData, updateFormData] = useState(initialFormData)
  const [brand, setBrand] = useState([]); // Marcas
  const [year, setYear] = useState(''); // Años validos
  const [fuelType, setFuelType] = useState(''); // Tipo de combustible

  const [models, setModels] = useState([]); // Modelos disponibles en cnetGeneralFilter
  const [model, setModel] = useState('') // Modelo de vehículo seleccionado
  const [cardBody, setCarBody] = useState([]); // Lista de carrocerias
  const [bodyType, setBodyType] = useState('')

  const [numOfDoors, setNumOfDoors] = useState([])
  const [doors, setDoors] = useState('')
  const [transmission, setTransmission] = useState('')
  const [versions, setVersions ] = useState([]); // Lista de versiones en coches.net
  const [version, setVersion] = useState(''); // Version

  const [price, setPrice] = useState('')
  const [financedPrice, setFinancedPrice] = useState('') // Precio financiado
  
  const [checked, setChecked] = useState(false); // Taxes checked
  const [taxes, setTaxes] = useState('false'); // false, true 'string'
  const [monthlyFee, setMonthlyFee] = useState(''); // Cuota mensual
  const [warranty, setWarranty] = useState(''); // Años de garantía
  const [uniqueVersion, setUniqueVersion] = useState([]); // Version unica

  const [stock, setStock] = useState(false); // Taxes checked
  const [stockChecked, setStockChecked] = useState('false'); // false, true 'string'
  const [chassisNumber, setChassisNumber] = useState(''); // Numero de bastidor
  const [carUbication, setCarUbication] = useState([]); // Ubicacion del coche
  const [environmentalLabel, setEnvironmentalLabel] = useState([]); // Etiqueta medio ambiental
  const [carColor, setCarColor] = useState([]); // Color del coche
  const [features, setFeatures] = useState(''); // Caracteristicas del coche
  const [metallic, setMetallic] = useState(false); // Color metalico 'string'
  const [metallicChecked, setMetallicChecked] = useState('false'); // false, true 'string'
  return {
    formData,
    updateFormData,
    brand,
    setBrand,
    year,
    setYear,
    fuelType,
    setFuelType,
    models,
    setModels,
    model,
    setModel,
    bodyType,
    setBodyType,
    numOfDoors,
    setNumOfDoors,
    doors,
    setDoors,
    transmission,
    setTransmission,
    price,
    setPrice,
    taxes,
    setTaxes,
    checked,
    setChecked,
    financedPrice,
    setFinancedPrice,
    monthlyFee,
    setMonthlyFee,
    warranty,
    setWarranty,
    cardBody,
    setCarBody,
    versions,
    setVersions,
    version,
    setVersion,
    uniqueVersion,
    setUniqueVersion,
    chassisNumber,
    setChassisNumber,
    carUbication,
    setCarUbication,
    environmentalLabel,
    setEnvironmentalLabel,
    carColor,
    setCarColor,
    features,
    setFeatures,
    stock,
    setStock,
    stockChecked,
    setStockChecked,
    metallic,
    setMetallic,
    metallicChecked,
    setMetallicChecked,
  }
}

