import { Link as RouterLink } from 'react-router-dom'
import { Box, Paper, Button, Typography, Link } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import AddIcon from '@material-ui/icons/Add'
import SyncIcon from '@material-ui/icons/Sync'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ApiServices from './services'

const IndexActions = ({ expand, handleExpand, handleLoading }) => {
  const handleAddVehicle = (event) => {
    event.preventDefault()
    window.location = window.location + '/new-car'
  }

  const handleSetExpand = (event, newView) => {
    if (expand === false) {
      handleExpand(true)
    } else {
      handleExpand(false)
    }
  }

  const handleWebSync = () => {
    handleLoading(true)

    ApiServices.syncWeb()
      .then((result) => {
        handleLoading(false)
        console.log(result)
      })
      .catch((error) => {
        handleLoading(false)
        console.log(error)
      })
  }

  const handleUpdateDb = () => {
    handleLoading(true)
    ApiServices.syncQuiterManually()
      .then(() => {
        handleLoading(false)
        window.location.reload()
      })
      .catch((err) => {
        handleLoading(false)
        console.log(err)
      })
  }

  return (
    <Box style={{ paddingTop: '24px', paddingBottom: '24px' }}>
      <Paper
        elevation={3}
        style={{ padding: '24px', borderLeft: '5px solid #01cdde' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <h2 style={{ margin: '0px' }}>Stock Web en GOmotor.com</h2>
            </div>
            <Button
              variant='contained'
              color='default'
              size='small'
              startIcon={<SyncIcon />}
              style={{
                marginRight: '24px',
                backgroundColor: '#2d3e50',
                color: 'white',
              }}
              onClick={handleUpdateDb}
            >
              ACTUALIZAR DDBB
            </Button>
            <Button
              variant='contained'
              color='default'
              size='small'
              onClick={handleWebSync}
              startIcon={<SyncIcon />}
              style={{
                marginRight: '24px',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              Sincronización Web
            </Button>
            {/*             <Button
              variant='contained'
              color='default'
              size='small'
              startIcon={<AddIcon />}
              onClick={handleAddVehicle}
              style={{
                marginRight: '24px',
                backgroundColor: 'rgb(80, 127, 168)',
                color: 'white',
              }}
            >
              Crear Vehículo
            </Button> */}
            {/*             <RouterLink to='/gomotor/log'>
              <Button
                variant='contained'
                color='default'
                size='small'
                startIcon={<ListAltIcon />}
                style={{
                  marginRight: '24px',
                }}
              >
                Ver Acciones
              </Button>
            </RouterLink> */}
            <Typography>
              <Link
                href='https://gomotor.com/coches-segunda-mano/'
                target='_blank'
                rel='noreferrer'
              >
                Ver stock en Web
              </Link>
            </Typography>
          </div>
          <ToggleButtonGroup
            value={expand}
            exclusive
            onChange={handleSetExpand}
            size='small'
          >
            <ToggleButton>{expand ? 'CONTRAER' : 'EXPANDIR'}</ToggleButton>
            {/*             <ToggleButton value='list' defaultChecked='list'>
              <ViewList />
            </ToggleButton> */}
            {/*             <ToggleButton value='card'>
              <ViewModule />
            </ToggleButton> */}
          </ToggleButtonGroup>
        </div>
      </Paper>
    </Box>
  )
}

export default IndexActions
