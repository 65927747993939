import { AccordionDetails, Grid } from "@mui/material"
import {
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  FuelIcon,
} from "./styles"
import Gasoline from "./Gasoline"
import Diesel from "./Diesel"
import Hybrid from "./Hybrid"
import Electric from "./Electric"

const Fuel = ({
  handleClickCard,
  active,
  gasolina,
  diesel,
  hybrid,
  electric,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <FuelIcon size='28px' color='#1976d2' />
        <StyledSummaryTitle>Combustible</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Gasoline
              data={gasolina}
              active={active}
              handleClick={() => handleClickCard("activeGasolina", "gasolina")}
            />
          </Grid>
          <Grid item xs={3}>
            <Diesel
              data={diesel}
              active={active}
              handleClick={() => handleClickCard("activeDiesel", "diesel")}
            />
          </Grid>
          <Grid item xs={3}>
            <Hybrid
              data={hybrid}
              active={active}
              handleClick={() => handleClickCard("activeHybrid", "hybrid")}
            />
          </Grid>
          <Grid item xs={3}>
            <Electric
              data={electric}
              active={active}
              handleClick={() => handleClickCard("activeElectric", "electric")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default Fuel
