import { Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Sidebar from '../../Sidebar'
import TopHeader from '../../TopHeader/TopHeader'
import DataTable from './components/DataTable'
import IndexActions from './IndexActions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const IndexAramis = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <IndexActions />
        <DataTable />
      </main>
    </div>
  )
}

export default IndexAramis
