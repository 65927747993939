import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MSelect,
} from '@mui/material'
import SelectCnetAccount from './SelectCnetAccount'
import Update from './Update'
import Delete from './Delete'

const actions = [
  {
    key: 1,
    value: 'Enviar a Coches.net',
    actionName: 'cnet',
  },
  {
    key: 3,
    value: 'Actualizar',
    actionName: 'update',
  },
  {
    key: 2,
    value: 'Eliminar',
    actionName: 'delete',
  },
]

const Select = ({ context }) => {
  const [action, setAction] = useState('0')

  const handleChange = (event) => {
    setAction(event.target.value)
  }

  return (
    <React.Fragment>
      <FormControl style={{ width: '300px' }}>
        <InputLabel style={{ fontSize: '14px', left: '25px' }} id='action'>
          Acciones
        </InputLabel>
        <MSelect
          name='action'
          labelId='action'
          id='action'
          label='Proveedor'
          size='small'
          value={action}
          style={{ margin: '5px 25px 5px 25px' }}
          onChange={handleChange}
        >
          <MenuItem value='0'>
            <em>Acción</em>
          </MenuItem>
          {actions.map((action) => {
            return (
              <MenuItem key={action.key} value={action.actionName}>
                {action.value}
              </MenuItem>
            )
          })}
        </MSelect>
      </FormControl>
      {action === 'cnet' && <SelectCnetAccount context={context} />}
      {action === 'update' && <Update context={context} />}
      {action === 'delete' && <Delete context={context} />}
    </React.Fragment>
  )
}

export default Select
