import { makeStyles } from "@material-ui/core/styles"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import React from "react"
import { useState } from "react"
import { nanoid } from "nanoid"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    padding: 9,
  },
  equipmentList: {
    width: '100%',
    padding: 0,
    listStyle: 'none'
  },
  equipmentListElement: {
    display: 'flex',
    minWidth: '100%'
  }
}))

const equipment = [
  {
    id: nanoid(),
    tipo: "",
    descripcion: "",
  },
]

const MotoEditFormEquipment = ({ handleAddEquipment, equipment }) => {
  const [newEquipment, setNewEquipment] = useState(equipment)
  const [type, setType] = useState('')

  const classes = useStyles()

  const handleButtonAddEquipment = (event) => {
    const newItem = {
      id: nanoid(),
      tipo: '',
      descripcion: ''
    }
    event.preventDefault()
    setNewEquipment(equipment => equipment.concat(newItem))
    console.log(newEquipment)
  }

  const handleButtonRemoveEquipment = (event, id) => {
    event.preventDefault()
    if(newEquipment.length > 1) {
      setNewEquipment(newEquipment.filter(newEquipment => newEquipment.id !== id))
      handleAddEquipment(newEquipment.filter(newEquipment => newEquipment.id !== id))
    }
  }

  const handleChangeSelect = (event, id) => {
    const newType = newEquipment.filter(newEquipment => newEquipment.id === id)
    newType[0].tipo = event.target.value
    setType({...type})
  }

  const handleChangeDescription = (event, id) => {
    event.preventDefault()
    const updateEquipment =  newEquipment.filter(newEquipment => newEquipment.id === id)
    updateEquipment[0].descripcion = event.target.value
    handleAddEquipment(newEquipment)
  }
    
  return (
    <ul className={classes.equipmentList}>
      {
        (newEquipment.length === 0)
          ? <p>
              Parece que este vehículo no tiene equipamiento, añade uno
              <IconButton onClick={handleButtonAddEquipment}>
                <AddIcon />
              </IconButton>
            </p>
          : newEquipment.map((equip) => {
            return (
               <li key={equip.id} className={classes.equipmentListElement}>
                 <FormControl variant='outlined' className={classes.formControl}>
                   <InputLabel id='demo-simple-select-outlined-label' margin='dense'>
                     Tipo de Equipamiento
                   </InputLabel>
                   <Select
                     label='Tipo de Equipamiento'
                     classes={{ select: classes.select }}
                     value={equip.tipo}
                     onChange={(event) => handleChangeSelect(event, equip.id) }
                   >
                     <MenuItem value="">
                       <em>None</em>
                     </MenuItem>
                     <MenuItem value='Serie'>Serie</MenuItem>
                     <MenuItem value='Extras'>Extras</MenuItem>
                   </Select>
                 </FormControl>
                 <TextField
                   style={{ margin: "8px" }}
                   name='descripcion'
                   label='Descripción'
                   variant='outlined'
                   size='small'
                   defaultValue={equip.descripcion}
                   fullWidth={true}
                   onChange={(event) => handleChangeDescription(event, equip.id)}
                 />
                 <IconButton onClick={handleButtonAddEquipment}>
                   <AddIcon />
                 </IconButton>
                 <IconButton onClick={(event) => handleButtonRemoveEquipment(event, equip.id)}>
                   <RemoveIcon />
                 </IconButton>
               </li>
            )
        })
      }
{/*       {
        newEquipment.map((equip) => {
             return (
                <li key={equip.id} className={classes.equipmentListElement}>
                  <FormControl variant='outlined' className={classes.formControl}>
                    <InputLabel id='demo-simple-select-outlined-label' margin='dense'>
                      Tipo de Equipamiento
                    </InputLabel>
                    <Select
                      label='Tipo de Equipamiento'
                      classes={{ select: classes.select }}
                      value={equip.tipo}
                      onChange={(event) => handleChangeSelect(event, equip.id) }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Serie'>Serie</MenuItem>
                      <MenuItem value='Extras'>Extras</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ margin: "8px" }}
                    name='descripcion'
                    label='Descripción'
                    variant='outlined'
                    size='small'
                    defaultValue={equip.descripcion}
                    fullWidth={true}
                    onChange={(event) => handleChangeDescription(event, equip.id)}
                  />
                  <IconButton onClick={handleButtonAddEquipment}>
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={(event) => handleButtonRemoveEquipment(event, equip.id)}>
                    <RemoveIcon />
                  </IconButton>
                </li>
             )
         })
      } */}

    </ul>
  )
}

export default MotoEditFormEquipment
