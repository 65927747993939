import React, { useState, useEffect } from 'react'
import { UploadContent, UploadLabel, UploadImageContent, UploadIndex, UploadAlert, CloseUpload, Image, IconClose } from './styles'
import { Box, Grid } from '@mui/material'

const UploadPhoto = () => {
  
  //
  // Images States

  const [images, setImages] = useState([])
  const [invalid, setInvalid] = useState(false)

  //
  // Upload Image

  const limit = 5
  const handleUploadImage = (event) => {
    const files = event.target.files
    const selectedFiles = [...files]
    const arrayFiles = selectedFiles.map(file => {
      return URL.createObjectURL(file)
    })
    setImages(previousState => [...previousState, ...arrayFiles])
    if (selectedFiles.length > limit) {
      setInvalid(true)
      console.log('invalid mas de una imagen')
    }
  }

  //
  // Remove Image
  
  const handleRemoveImage = (index) => {
    const newImages = [...images]
    newImages.splice(index, 1)
    setImages(newImages)
  }

  //
  // Render data

  useEffect(() => {
    // updateFormData({
    //   ...formData,
    //   images: images
    // })
    if (images.length >= limit) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }
  }, [images])

  return (
    <div>
      {images.length <= 0 ? (
        <UploadLabel
          htmlFor='upload'
          className={invalid ? 'invalid' : ''}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',
          }}>
            <svg width='28' height='28' viewBox='0 0 24 24' className='icon-upload'>
              <path fill='currentColor' d='M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z' />
            </svg>
            Subir imágenes
          </Box>
          <input
            type='file'
            id='upload'
            name='file'
            multiple
            onChange={handleUploadImage}
            accept='png, jpg, webp'
            disabled={!!invalid}
          />
        </UploadLabel>
      ) : (
        <>
         <UploadContent>
          {images?.map((image, index) => (
            <UploadImageContent key={image}>
              <CloseUpload type='button' onClick={(e) => handleRemoveImage(e)}>
                <IconClose />
              </CloseUpload>
              <UploadIndex>{index + 1}</UploadIndex>
              <Image src={image} alt='Imagen' />
            </UploadImageContent>
          ))}
          <UploadLabel htmlFor='upload' className={invalid ? 'invalid' : ''}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column', 
              alignItems: 'center',
            }}>
              <svg width='28' height='28' viewBox='0 0 24 24' className='icon-upload'>
                <path fill='currentColor' d='M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z' />
              </svg>
              Subir imágenes
            </Box>
            <input
              type='file'
              id='upload'
              name='file'
              multiple
              onChange={handleUploadImage}
              accept='png, jpg, webp'
              disabled={!!invalid}
            />
          </UploadLabel>
        </UploadContent>
        {images.length > limit
          ? (
            <UploadAlert>
              Solo se permiten {limit} imagenes, por favor elimina {images.length - limit}
            </UploadAlert>
            )
          : (null)}
        </>
      )}
    </div>
  )
}

export default UploadPhoto