import { AccordionDetails, Grid } from "@mui/material"
import {
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  EventIcon
} from "./styles"
import General from "./General"

const Campaigns = ({ handleClickCard, active, campaign }) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <EventIcon size='28px' color='#2d3e50' />
        <StyledSummaryTitle>
          Campañas:
        </StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <General
              data={campaign}
              active={active}
              handleClick={() => handleClickCard("activeCampaign", "campaign")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default Campaigns
