const Volkswagen = ({ width }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 49.9 49.9'
      width={width ? width : ""}
    >
      <path
        d='M25.2,40c-8.2,0-14.9-6.8-14.9-15c0-1.7,0.3-3.3,0.9-4.9l8.7,17c0.1,0.2,0.2,0.4,0.5,0.4c0.3,0,0.4-0.2,0.5-0.4l4.1-9
	c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.2l4.1,9c0.1,0.2,0.2,0.4,0.5,0.4c0.3,0,0.4-0.2,0.5-0.4l8.7-17
	c0.5,1.5,0.8,3.2,0.8,4.9C40.2,33.2,33.4,40,25.2,40z M25.2,21.1c-0.1,0-0.1-0.1-0.2-0.2l-4.7-10.1c3.1-1.1,6.6-1.1,9.7,0l-4.7,10.1
	C25.4,21,25.4,21.1,25.2,21.1z M20.3,32c-0.1,0-0.1-0.1-0.2-0.2l-7.7-14.7c1.3-2.2,3.3-4,5.5-5.3l5.4,11.7c0.1,0.2,0.2,0.3,0.4,0.3
	h2.8c0.2,0,0.3-0.1,0.4-0.3l5.3-11.7c2.2,1.3,4.2,3.1,5.5,5.3l-7.6,14.7C30.3,32,30.2,32,30.1,32C30,32,30,31.9,30,31.9l-2.9-6.4
	c-0.1-0.2-0.2-0.3-0.4-0.3h-2.8c-0.2,0-0.3,0.1-0.4,0.3l-2.9,6.4C20.4,31.9,20.4,32,20.3,32z M25.2,42c9.4,0,17-7.6,17-17
	s-7.6-17-17-17s-17,7.6-17,17S15.8,42,25.2,42z'
      />
    </svg>
  )
}

export default Volkswagen
