import { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid, Paper } from "@material-ui/core"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import StorageIcon from "@material-ui/icons/Storage"
import ClearIcon from "@material-ui/icons/Clear"
import TypeVoQuiter from "./components/TypeVoQuiter"
import Fuel from "./components/Fuel"
import BrandsLandings from "./components/BrandsLandings"
import DistributorLandings from "./components/DistributorLandings"
import TypeWebVehicle from "./components/TypeWebVehicle"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    cursor: "pointer",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
    "&:hover": {
      background: "#f4f4f4",
    },
  },
  active: {
    backgroundColor: "#e6e6e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    cursor: "pointer",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
  },
}))

const InfoResume = ({ data, expand, handleExpand, handleFilter }) => {
  const [reserved, setReserved] = useState(0)
  const [offer, setOffer] = useState(0)
  const [gasolina, setGasolina] = useState(0)
  const [diesel, setDiesel] = useState(0)
  const [total, setTotal] = useState(0)
  const [available, setAvailable] = useState(0)
  const [km0, setKm0] = useState(0)
  const [electric, setElectric] = useState(0)
  const [hybrid, setHybrid] = useState(0)
  const [courtesy, setCourtesy] = useState(0)
  const [demo, setDemo] = useState(0)
  const [ocasion, setOcasion] = useState(0)
  const [news, setNews] = useState(0)
  const [lpCitroen, setLpCitroen] = useState(0)
  const [lpPeugeot, setLpPeugeot] = useState(0)
  const [lpHonda, setLpHonda] = useState(0)
  const [lpBmw, setLpBmw] = useState(0)
  const [lpMini, setLpMini] = useState(0)
  const [lpTarrauto, setLpTarrauto] = useState(0)
  const [lpOlivaMotor, setLpOlivaMotor] = useState(0)
  const [lpTecnotarraco, setLpTecnotarraco] = useState(0)
  const [lpJoaquinOliva, setLpJoaquinOliva] = useState(0)
  const [webNew, setWebNew] = useState(0)
  const [km0Web, setKm0Web] = useState(0)
  const [ocasionWeb, setOcasionWeb] = useState(0)
  const [active, setActive] = useState({
    activeTotal: false,
    activeAvailable: false,
    activeReserved: false,
    activeOffer: false,
    activeKm0: false,
    activeDemo: false,
    activeCourtesy: false,
    activeOccasion: false,
    activeNew: false,
    activeGasolina: false,
    activeDiesel: false,
    activeElectric: false,
    activeHybrid: false,
    activeLpCitroen: false,
    activeLpPeugeot: false,
    activeLpHonda: false,
    activeLpBmw: false,
    activeLpMini: false,
    activeLpOlivaMotor: false,
    activeLpTarrauto: false,
    activeTecnotarraco: false,
    activeJoaquinOliva: false,
    activeNewWeb: false,
    activeKm0Web: false,
    activeOcasionWeb: false, 
  })

  useEffect(() => {
    setReserved(0)
    setOffer(0)
    setGasolina(0)
    setDiesel(0)
    setTotal(0)
    setAvailable(0)
    setKm0(0)
    setElectric(0)
    setHybrid(0)
    setCourtesy(0)
    setDemo(0)
    setOcasion(0)
    setNews(0)
    setLpCitroen(0)
    setLpPeugeot(0)
    setLpHonda(0)
    setLpBmw(0)
    setLpMini(0)
    setLpOlivaMotor(0)
    setLpTarrauto(0)
    setLpTecnotarraco(0)
    setLpJoaquinOliva(0)
    setWebNew(0)
    setKm0Web(0)
    setOcasionWeb(0)

    for (const object in data) {
      setTotal((total) => total + 1)
      if (data[object].tipoVO === "KM0") {
        setKm0((km0) => km0 + 1)
      }
      if (data[object].reservado === false) {
        setAvailable((available) => available + 1)
      }
      if (data[object].reservado === true) {
        setReserved((reserved) => reserved + 1)
      }
      if (data[object].vehiculosHome === true) {
        setOffer((offer) => offer + 1)
      }
      if (data[object].combustible === "GASOLINA") {
        setGasolina((gasolina) => gasolina + 1)
      }
      if (data[object].combustible === "DIESEL") {
        setDiesel((diesel) => diesel + 1)
      }
      if (data[object].combustible === "ELECTRICO") {
        setElectric((electric) => electric + 1)
      }
      if (data[object].combustible === "HIBRIDO") {
        setHybrid((hybrid) => hybrid + 1)
      }
      if (data[object].tipoVO === "CORTESIA") {
        setCourtesy((courtesy) => courtesy + 1)
      }
      if (data[object].tipoVO === "DEMO") {
        setDemo((demo) => demo + 1)
      }
      if (data[object].tipoVO === "OCASION") {
        setOcasion((ocasion) => ocasion + 1)
      }
      if (data[object].tipo === "VN") {
        setNews((news) => news + 1)
      }
      if (data[object].tipoVehiculoWeb === "Nuevo") {
        setWebNew((webNew) => webNew + 1)
      }
      if (data[object].tipoVehiculoWeb === "Km 0") {
        setKm0Web((km0Web) => km0Web + 1)
      }
      if (data[object].tipoVehiculoWeb === "Ocasión") {
        setOcasionWeb((ocasionWeb) => ocasionWeb + 1)
      }
      if (data[object].landingPages) {
        if (data[object].landingPages.indexOf("Citroen") !== -1) {
          setLpCitroen((lpCitroen) => lpCitroen + 1)
        }
        if (data[object].landingPages.indexOf("Peugeot") !== -1) {
          setLpPeugeot((lpPeugeot) => lpPeugeot + 1)
        }
        if (data[object].landingPages.indexOf("Honda") !== -1) {
          setLpHonda((lpHonda) => lpHonda + 1)
        }
        if (data[object].landingPages.indexOf("BMW") !== -1) {
          setLpBmw((lpBmw) => lpBmw + 1)
        }
        if (data[object].landingPages.indexOf("MINI") !== -1) {
          setLpMini((lpMini) => lpMini + 1)
        }
        if (data[object].landingPages.indexOf("Oliva Motor") !== -1) {
          setLpOlivaMotor((lpOlivaMotor) => lpOlivaMotor + 1)
        }
        if (data[object].landingPages.indexOf("Tarrauto") !== -1) {
          setLpTarrauto((lpTarrauto) => lpTarrauto + 1)
        }
        if (data[object].landingPages.indexOf("Tecnotarraco") !== -1) {
          setLpTecnotarraco((lpTecnotarraco) => lpTecnotarraco + 1)
        }
        if (data[object].landingPages.indexOf("Joaquin Oliva") !== -1) {
          setLpJoaquinOliva((lpJoaquinOliva) => lpJoaquinOliva + 1)
        }
      }
    }
  }, [data])

  const activeStyle = (filter) => {
    for (let property in active) {
      if (property === filter) {
        active[property] = true
      } else {
        active[property] = false
      }
    }
    setActive({ ...active })
  }

  const handleClickTotal = () => {
    if (active.activeTotal === false) {
      setActive({
        activeOffer: false,
        activeReserved: false,
        activeAvailable: false,
        activeKm0: false,
        activeTotal: true,
        activeDemo: false,
        activeCourtesy: false,
        activeOccasion: false,
        activeNew: false,
        activeGasolina: false,
        activeDiesel: false,
        activeHybrid: false,
        activeElectric: false,
        activeLpCitroen: false,
        activeLpPeugeot: false,
        activeLpHonda: false,
        activeLpBmw: false,
        activeLpMini: false,
        activeLpOlivaMotor: false,
        activeLpTarrauto: false,
        activeTecnotarraco: false,
        activeJoaquinOliva: false,
        activeNewWeb: false,
        activeKm0Web: false,
        activeOcasionWeb: false
      })
    } else {
      setActive({
        ...active,
        activeTotal: false,
      })
    }
    handleExpand(false)
    handleFilter("total")
  }

  const handleClickAvailable = () => {
    if (active.activeAvailable === false) {
      activeStyle("activeAvailable")
    } else {
      setActive({ ...active, activeAvailable: false })
    }
    handleExpand(false)
    handleFilter("available")
  }

  const handleClickReserved = () => {
    if (active.activeReserved === false) {
      activeStyle("activeReserved")
    } else {
      setActive({ ...active, activeReserved: false })
    }
    handleExpand(false)
    handleFilter("reserved")
  }

  const handleClickOffer = () => {
    if (active.activeOffer === false) {
      activeStyle("activeOffer")
    } else {
      setActive({ ...active, activeOffer: false })
    }
    handleExpand(false)
    handleFilter("offer")
  }


  const handleClickCard = (activeValue, filter) => {
    console.log(activeValue, filter)
    if (active[activeValue] === false) {
      activeStyle(activeValue)
    } else {
      setActive({ ...active, [activeValue]: false })
    }
    handleExpand(false)
    handleFilter(filter)
  }

  const classes = useStyles()
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper
            className={active.activeTotal ? classes.active : classes.paper}
            onClick={handleClickTotal}
          >
            <StorageIcon style={{ color: "#545454", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{total}</span>
              </h3>
              <p>Total</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeAvailable ? classes.active : classes.paper}
            onClick={handleClickAvailable}
          >
            <DoneOutlineIcon style={{ color: "green", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{available}</span>
              </h3>
              <p>Disponibles</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeReserved ? classes.active : classes.paper}
            onClick={handleClickReserved}
          >
            <ClearIcon style={{ color: "red", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{reserved}</span>
              </h3>
              <p>Reservados</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeOffer ? classes.active : classes.paper}
            onClick={handleClickOffer}
          >
            <LocalOfferIcon style={{ color: "#507fa8", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{offer}</span>
              </h3>
              <p>Destacados</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <TypeVoQuiter
        active={active}
        dataKm0={km0}
        dataDemo={demo}
        dataCourtesy={courtesy}
        dataOcasion={ocasion}
        dataNew={news}
        handleClickCard={handleClickCard}
      />
      <TypeWebVehicle
        active={active}
        dataWebNew={webNew}
        dataKm0Web={km0Web}
        dataOcasionWeb={ocasionWeb}
        handleClickCard={handleClickCard}
      />
      <Fuel
        active={active}
        dataGasoline={gasolina}
        dataDiesel={diesel}
        dataHybrid={hybrid}
        dataElectric={electric}
        handleClickCard={handleClickCard}
      />
      <BrandsLandings
        dataPeugeot={lpPeugeot}
        dataCitroen={lpCitroen}
        dataHonda={lpHonda}
        dataBmw={lpBmw}
        dataMini={lpMini}
        active={active}
        handleClickCard={handleClickCard}
      />
      <DistributorLandings
        active={active}
        dataLpOlivaMotor={lpOlivaMotor}
        dataLpTarrauto={lpTarrauto}
        dataLpTecnotarraco={lpTecnotarraco}
        dataLpJoaquinOliva={lpJoaquinOliva}
        handleClickCard={handleClickCard}
      />
    </Box>
  )
}

export default InfoResume
