import React from "react"
import ChildTextField from "../../../ChildTextField"

const InputsPrices = ({ handleInputChangePrice }) => {
  const handleInputTextChange = (event) => {
    handleInputChangePrice(event)
  }
  return (
    <React.Fragment>
      <ChildTextField
        label='VENTA'
        name='VENTA'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='COMPRAVENTA'
        name='COMPRAVENTA'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='EXPORTACION'
        name='EXPORTACION'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='PUBLICACION'
        name='PUBLICACION'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='VENTA_GARANTIA'
        name='VENTA_GARANTIA'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='TRANSFERENCIA'
        name='TRANSFERENCIA'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='MERCADO'
        name='MERCADO'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='COMPRA'
        name='COMPRA'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='PVP_NUEVO'
        name='PVP_NUEVO'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
    </React.Fragment>
  )
}

export default InputsPrices
