import CnetService from "../../../services/cnet.service"

const rowUpdateCnet = (newData, oldData, account, data, setData, setDataFilter) => {
  const dataUpdate = [...data]
  const index = oldData.tableData.id

  dataUpdate[index] = newData

  newData.price.priceValue = ''
  newData.price.bestPrice = ''
  newData.price.professionalPrice = ''
  newData.price.monthlyQuota = ''

  return CnetService.updateApi(newData, account)
    .then(() => {
      return CnetService.readApi(account).then((response) => {
        console.log(response)
        setData(response)
        setDataFilter(response)
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export default rowUpdateCnet
