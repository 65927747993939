import classNames from "classnames"

const Badge = ({ children, color = 'info', size  }) => {
  
  const defaultStyle = 'flex h-fit items-center justify-center gap-1 font-semibold'

  const colorVariant = {
    info: 'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800 group-hover:bg-blue-200 dark:group-hover:bg-blue-300',
    failure: 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800 group-hover:bg-red-200 dark:group-hover:bg-red-300',
    success: 'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-800 group-hover:bg-green-200 dark:group-hover:bg-green-300'
  }

  const sizeVariant = {
    default: 'rounded px-2 py-0.5 p-1 text-xs'
  }

  return (
    <span className={classNames(
      defaultStyle,
      colorVariant[color],
      size ? sizeVariant[size] : sizeVariant.default
    )}>
      <span>
        {children}
      </span>
    </span>
  )
}

export default Badge
