class Price {
  constructor(tipo, valor, km, months) {
    this.tipo = tipo
    this.valor = valor
    this.km = km
    this.meses = months
  }
}

const months = ['36', '48', '60']

const genPrices = (months, km) => {
  return months.map(e => new Price(`${String(km).replace('.', '')}KM-${e}M`, '0,00', km, e))
}

const price5000 = genPrices(months, '5.000')
const price10000 = genPrices(months, '10.000')
const price15000 = genPrices(months, '15.000')
const price20000 = genPrices(months, '20.000')
const price25000 = genPrices(months, '25.000')
const price30000 = genPrices(months, '30.000')

const pricesParticular = [].concat(
  genPrices(months, '5.000'),
  genPrices(months, '10.000'),
  genPrices(months, '15.000'),
  genPrices(months, '20.000'),
  genPrices(months, '25.000'),
  genPrices(months, '30.000')
)

const pricesFreelance = [].concat(
  genPrices(months, '5.000'),
  genPrices(months, '10.000'),
  genPrices(months, '15.000'),
  genPrices(months, '20.000'),
  genPrices(months, '25.000'),
  genPrices(months, '30.000')
)
const pricesBusiness = [].concat(
  genPrices(months, '5.000'),
  genPrices(months, '10.000'),
  genPrices(months, '15.000'),
  genPrices(months, '20.000'),
  genPrices(months, '25.000'),
  genPrices(months, '30.000')
)

const Data = {
  price5000,
  price10000,
  price15000,
  price20000,
  price25000,
  price30000,
  pricesParticular,
  pricesFreelance,
  pricesBusiness
}

export default Data