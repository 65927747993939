import {
  Paper,
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { useState } from 'react'
import SelectActionsData from './data'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getStateProcess } from './../../../../../actions/process'
import { useEffect } from 'react'
// import ReservedPrice from "./ReservedPrice"
// import ShowInHome from "./ShowInHome"
// import ManualEdit from "./ManualEdit"
// import DeleteMany from "./DeleteMany"
// import ActivateCampaigns from "./ActivateCampaigns"
// import EditPrices from "./EditPrices"
import WebSync from './WebSync'
import ExportJsonButton from '../../../../buttons/ExportJsonButton'
import Landing from './Landing'

const SelectActions = ({ dataSelection, campaigns }) => {
  const dispatch = useDispatch()
  const isProcessActive = useSelector((state) => state.process.isActivated)
  const [action, setAction] = useState('')
  const [isActivated, setIsActivated] = useState(false)
  const [open, setOpen] = useState(false)

  const actions = SelectActionsData.bulkActions

  useEffect(() => {
    dispatch(getStateProcess('61b8bad1d5de220bf4a460e7'))
  }, [dispatch])

  const handleChange = (event) => {
    setAction(event.target.value)
  }

  const handleButton = (action, isActivated) => {
    isActivated === 'activate' ? setIsActivated(true) : setIsActivated(false)
    setAction(action)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const isActionBoolean = (action) => {
    if (
      action === 'destacar' ||
      action === 'ActivarManual' ||
      action === 'activarCampaña' ||
      action === 'lpPublish'
    ) {
      return true
    } else {
      return false
    }
  }

  console.log(dataSelection)
  console.log(action)

  return (
    <div>
      <h3>Acciones:</h3>
      <Paper elevation={3}>
        <Box p={2} marginBottom={6} display='flex' alignItems='end'>
          <FormControl component='fieldset'>
            <FormGroup row style={{ display: 'flex', alignItems: 'end' }}>
              <InputLabel>Seleccionar acción</InputLabel>
              <Select
                style={{ width: '250px', marginRight: '45px' }}
                onChange={handleChange}
                defaultValue='Selecciona'
              >
                {actions.map((action) => (
                  <MenuItem value={action.actionName} key={action.id}>
                    {action.name}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          </FormControl>
          {/* {action === 'ActivarManual' && (
            <ManualEdit
              open={open}
              dataSelection={dataSelection}
              handleCloseModal={handleCloseModal}
              isActivated={isActivated}
            />
          )}
          {action === 'modificarImporteReserva' && (
            <ReservedPrice
              open={open}
              dataSelection={dataSelection}
              handleCloseModal={handleCloseModal}
            />
          )}
          {action === 'destacar' && (
            <ShowInHome
              open={open}
              dataSelection={dataSelection}
              handleCloseModal={handleCloseModal}
              isActivated={isActivated}
            />
          )}
          {action === 'deleteCar' && (
            <DeleteMany
              open={open}
              handleCloseModal={handleCloseModal}
              dataSelection={dataSelection}
            />
          )}
          {action === 'activarCampaña' && (
            <ActivateCampaigns
              open={open}
              handleCloseModal={handleCloseModal}
              dataSelection={dataSelection}
              campaigns={campaigns}
              isActivated={isActivated}
            />
          )}
          {action === 'modificarPrecio' && (
            <EditPrices
              open={open}
              handleCloseModal={handleCloseModal}
              dataSelection={dataSelection}
            />
          )} */}
          {action === 'webSync' && (
            <WebSync
              open={open}
              handleCloseModal={handleCloseModal}
              dataSelection={dataSelection}
            />
          )}
          {action === 'lpPublish' && (
            <Landing
              open={open}
              handleCloseModal={handleCloseModal}
              dataSelection={dataSelection}
              campaigns={campaigns}
              isActivated={isActivated}
            />
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleButton(action, 'activate')}
            size='small'
            style={{
              backgroundColor: 'rgb(80, 127, 168)',
              marginRight: '12px',
            }}
            startIcon={<DoneIcon />}
            disabled={isProcessActive}
          >
            Aplicar acción
          </Button>
          {isActionBoolean(action) ? (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleButton(action, 'deactivate')}
              size='small'
              startIcon={<ClearIcon />}
              disabled={isProcessActive}
            >
              Desactivar
            </Button>
          ) : (
            ''
          )}
          <ExportJsonButton data={dataSelection} />
        </Box>
      </Paper>
    </div>
  )
}

export default SelectActions
