import React from "react"
import ChildTextField from "../../../ChildTextField"

const InputDimensions = ({ handleInputChangeDimensions }) => {
  const handleInputTextChange = (event) => {
    handleInputChangeDimensions(event)
  }

  return (
    <React.Fragment>
      <ChildTextField
        label='LARGO'
        name='largo'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='ANCHO'
        name='ancho'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='ALTO'
        name='alto'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
     <ChildTextField
        label='VOLUMEN MALETERO'
        name='volumenMaletero'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='CAPACIDAD DEPOSITO'
        name='capacidadDeposito'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='NEUMÁTICO DELANTERO'
        name='neumaticoDelantero'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='NEUMÁTICO TRASERO'
        name='neumaticoTrasero'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='MEDIDAS NEUMÁTICOS'
        name='medidasNeumaticos'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
    </React.Fragment>
  )
}

export default InputDimensions
