const Audi = ({ width }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 49.9 49.9'
      width={width ? width : ""}
    >
      <path
        d='M36.5,30.9c-1.4,0-2.8-0.5-3.9-1.4c-1,0.8-2.3,1.3-3.7,1.4c-1.5,0-2.9-0.4-4.1-1.4c-1,0.8-2.3,1.3-3.7,1.4
	c-1.5,0-2.9-0.4-4.1-1.4c-1,0.8-2.3,1.3-3.7,1.4c-1.6,0.1-3.2-0.5-4.4-1.7s-1.9-2.7-2-4.3c-0.1-3.4,2.6-6.3,6-6.4l0,0l0.4,0
	c1.4,0,2.7,0.6,3.7,1.4c1-0.8,2.3-1.3,3.7-1.4l0,0l0.4,0c1.4,0,2.7,0.6,3.7,1.4c1-0.8,2.3-1.3,3.7-1.4l0,0l0.4,0
	c1.4,0,2.7,0.6,3.7,1.4c1-0.8,2.3-1.3,3.7-1.4l0,0l0.4,0c3.2,0.1,5.9,2.7,6,6c0.1,1.6-0.5,3.2-1.7,4.4c-1.1,1.2-2.7,1.9-4.3,2
	C36.6,30.9,36.6,30.9,36.5,30.9z M33.5,28.6c0.9,0.7,2,1,3.1,1c1.3,0,2.5-0.6,3.4-1.5s1.4-2.2,1.3-3.5c-0.1-2.6-2.2-4.6-4.7-4.7
	h-0.3c-1,0-2,0.4-2.8,1c0.8,1,1.3,2.3,1.4,3.7C35,26,34.5,27.4,33.5,28.6z M25.8,28.6c0.9,0.7,2,1,3.1,1c1,0,2-0.4,2.8-1
	c-0.8-1-1.3-2.3-1.4-3.7c-0.1-1.5,0.5-3,1.4-4.1c-0.8-0.6-1.7-1-2.8-1h-0.3c-1,0-2,0.4-2.8,1c0.8,1,1.3,2.3,1.4,3.7
	C27.2,26,26.7,27.4,25.8,28.6z M18.1,28.6c0.9,0.7,2,1,3.1,1c1,0,2-0.4,2.8-1c-0.8-1-1.3-2.3-1.4-3.7c-0.1-1.5,0.5-3,1.4-4.1
	c-0.8-0.6-1.7-1-2.8-1h-0.3c-1,0-2,0.4-2.8,1c0.8,1,1.3,2.3,1.4,3.7C19.5,26,19,27.4,18.1,28.6z M13.1,19.8c-2.7,0.1-4.8,2.4-4.7,5
	c0,1.3,0.6,2.5,1.5,3.4c1,0.9,2.2,1.4,3.5,1.3c1,0,2-0.4,2.8-1c-0.8-1-1.3-2.3-1.4-3.7c-0.1-1.5,0.5-3,1.4-4.1c-0.8-0.6-1.7-1-2.8-1
	H13.1z M24.9,21.7c-0.7,0.9-1,2-1,3.1c0,1,0.4,2,1,2.8c0.7-0.9,1-2,1-3.1C25.9,23.5,25.5,22.5,24.9,21.7z M32.6,21.7
	c-0.7,0.9-1,2-1,3.1c0,1,0.4,2,1,2.8c0.7-0.9,1-2,1-3.1C33.6,23.5,33.2,22.5,32.6,21.7z M17.1,21.7c-0.7,0.9-1,2-1,3.1
	c0,1,0.4,2,1,2.8c0.7-0.9,1-2,1-3.1C18.1,23.5,17.7,22.5,17.1,21.7z'
      />
    </svg>
  )
}

export default Audi
