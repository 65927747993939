import { useContext } from 'react'
import { Grid } from '@mui/material'
import { Storage } from '@mui/icons-material'
import {
  StyledBox,
  StyledCard,
  StyledStorageIcon,
  StyledCarIcon,
} from './style'

const GeneralFilter = ({ context }) => {
  const { data, setDataFilter, active, setActive } = useContext(context)

  const activeStyle = (filter) => {
    console.log(filter)
    for (let property in active) {
      if (property === filter) {
        active[property] = true
      } else {
        active[property] = false
      }
    }
    setActive({ ...active })
  }

  const handleClickCard = (activeValue, filter) => {
    setDataFilter(data.filter((e) => e.tipoVehiculoWeb === filter))
    console.log(activeValue, filter)
    if (active[activeValue] === false) {
      activeStyle(activeValue)
    } else {
      setActive({ ...active, [activeValue]: false })
    }
  }

  return (
    <StyledBox>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <StyledCard
            active={active.activeTotal}
            onClick={() => handleClickCard('activeTotal', '')}
          >
            <StyledStorageIcon color='#545454' />
            <div>
              <h3>
                <span>{data.length}</span>
              </h3>
              <p>Total</p>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={3}>
          <StyledCard
            active={active.activeNew}
            onClick={() => handleClickCard('activeNew', 'Nuevo')}
          >
            <StyledCarIcon color='orange' />
            <div>
              <h3>
                <span>
                  {data.filter((e) => e.tipoVehiculoWeb === 'Nuevo').length}
                </span>
              </h3>
              <p>Nuevo</p>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={3}>
          <StyledCard
            active={active.activeKm0}
            onClick={() => handleClickCard('activeKm0', 'Km 0')}
          >
            <StyledCarIcon color='green' />
            <div>
              <h3>
                <span>
                  {data.filter((e) => e.tipoVehiculoWeb === 'Km 0').length}
                </span>
              </h3>
              <p>KM0</p>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={3}>
          <StyledCard
            active={active.activeOcasion}
            onClick={() => handleClickCard('activeOcasion', 'Ocasión')}
          >
            <Storage style={{ color: '#545454', fontSize: '38px' }} />
            <div>
              <h3>
                <span>
                  {data.filter((e) => e.tipoVehiculoWeb === 'Ocasión').length}
                </span>
              </h3>
              <p>Ocasión</p>
            </div>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default GeneralFilter
