import { StyledTextField } from './style'
import { useContext } from "react"

const Brand = ({ context }) => {
  const { setFilterBrands } = useContext(context)

  const handleTextChange = (event) => {
    const value = event.target.value
    setFilterBrands(value)
  }

  return (
    <StyledTextField
      label='Marca'
      variant='outlined'
      size='small'
      onChange={handleTextChange}
    />
  )
}

export default Brand

