var classNames = require('classnames')

const Button = ({ text, handleClick, size, icon, className }) => {
  const handleOnClick = () => {
    handleClick()
  }

  const defaultStyle =
    'flex-shrink-0 inline-flex gap-1 items-center group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent font-medium rounded-md border shadow-sm text-white border-transparent'

  const sizes = {
    sm: 'pl-2.5 pr-2.5 pt-1.5 pb-1.5 text-xs',
    md: '',
    lg: '',
  }

  const Icon = icon || undefined

  return (
    <button
      type='button'
      className={classNames(defaultStyle, className, sizes[size])}
      onClick={handleOnClick}
    >
      {icon && <Icon className='h-4 w-4 text-white' />}
      {text}
    </button>
  )
}

export default Button
