import { useState, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconDataInversion } from '../Icons'
import {
  listUbicationCar,
  listEnvironmentalLabel,
  transmissions,
} from '../../data'
import CnetApiServices from '../../services'

const Version = ({ context }) => {
  const {
    brand,
    model,
    fuelType,
    year,
    setBodyType,
    cardBody,
    doors,
    setDoors,
    numOfDoors,
    setTransmission,
    versions,
    setVersions,
    setVersion,
    setCarUbication,
    setEnvironmentalLabel,
    stock,
    setStock,
    setStockChecked,
  } = useContext(context)

  const [expanded, setExpanded] = useState(false)
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getDoors = (event) => {
    setDoors(event.target.value)
  }

  const getTransmission = async (event) => {
    const transmission = event.target.value
    setTransmission(transmission)

    const versions = await CnetApiServices.getVersions(
      brand,
      model,
      cardBody,
      fuelType,
      doors,
      year
    )
    setVersions(versions)
  }

  const getVersion = (event) => {
    setVersion(event.target.value)
  }

  const getEnvironmentalLabel = (event) => {
    const labels = event.target.value
    setEnvironmentalLabel(labels)
  }

  const getStock = (event) => {
    setStock(event.target.checked)
    if (!stock) {
      setStockChecked('true')
    } else {
      setStockChecked('false')
    }
  }

  return (
    <Accordion onChange={handleExpand('panel3')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel2a-content'
        id='panel2a-header'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
          <IconDataInversion color='#00c40a' size={24} />
          <Typography variant='h6'>Datos de la versión</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          gap={2}
          display='grid'
          gridTemplateColumns='1fr 1fr 1fr 1fr 1fr 1fr'
        >
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-carroseria'>Carrocería</InputLabel>
            <Select
              labelId='input-carroseria'
              id='demo-carroseria'
              label='Carrocería'
              defaultValue=''
              onChange={(event) => setBodyType(event.target.value)}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {cardBody.map((carBody) => {
                return (
                  <MenuItem key={carBody.key} value={carBody.key}>
                    {carBody.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-doors'>Nº Puertas</InputLabel>
            <Select
              labelId='input-doors'
              id='demo-doors'
              label='Nº Puertas'
              onChange={getDoors}
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {numOfDoors.map((element) => {
                return (
                  <MenuItem key={element.key} value={element.key}>
                    {element.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-car-change'>Cambio</InputLabel>
            <Select
              labelId='input-car-change'
              id='demo-car-change'
              label='Cambio'
              defaultValue=''
              onChange={getTransmission}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {transmissions.map((change) => {
                return (
                  <MenuItem key={change.key} value={change.value}>
                    {change.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-car-version'>Version</InputLabel>
            <Select
              labelId='input-car-version'
              id='demo-car-version'
              label='Version'
              onChange={getVersion}
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {versions.map((version) => {
                return (
                  <MenuItem key={version.key} value={version.key}>
                    {version.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-car-ubication'>
              Ubicación del vehículo
            </InputLabel>
            <Select
              labelId='input-car-ubication'
              id='demo-car-ubication'
              label='Ubicación del vehículo'
              onChange={(event) => setCarUbication(event.target.value)}
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {listUbicationCar.map((ubication) => {
                return (
                  <MenuItem key={ubication.key} value={ubication.key}>
                    {ubication.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id='input-car-enviroment-label'>
              Etiqueta medio ambiente
            </InputLabel>
            <Select
              labelId='input-car-enviroment-label'
              id='demo-car-enviroment-label'
              label='Etiqueta medio ambiente'
              onChange={(event) =>
                getEnvironmentalLabel(event, event.target.value)
              }
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {listEnvironmentalLabel.map((envi) => {
                return (
                  <MenuItem key={envi.key} value={envi.value}>
                    {envi.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stock}
                  onChange={getStock}
                  value={false}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='stock'
                />
              }
              label='En stock'
            />
          </FormControl>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default Version
