import { useState, useEffect } from 'react'
import CnetService from '../services/cnet.service'

import useAlert from './useAlert'

const useData = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [brands, setBrands] = useState([])
  const [selectData, setSelectData] = useState([])
  const [isCnet, setIsCnet] = useState(false)
  const [account, setAccount] = useState('0')
  const [dataFilter, setDataFilter] = useState([])
  const [showLog, setShowLog] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [actions, setActions] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [active, setActive] = useState({
    activeTotal: true,
    activeNew: false,
    activeKm0: false,
    activeOcasion: false,
    reviewsPrices: false,
    reviewsWarranties: false,
    reviewsColors: false,
  })

  const {
    showAlert,
    setShowAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage,
  } = useAlert()

  useEffect(() => {
    CnetService.read()
      .then((result) => {
        setData(result)
        setLoading(false)
        setShowAlert(true)
        setTypeAlert('success')
        setAlertMessage(
          'Se han cargado los vehículos de la base de datos correctamente'
        )
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setShowAlert(true)
        setTypeAlert('error')
        setAlertMessage(
          'Ha ocurrido un error al intentar obtener los vehículos en la base d datos.'
        )
      })
  }, [setAlertMessage, setShowAlert, setTypeAlert])

  useEffect(() => {
    CnetService.brands().then((result) => {
      setBrands(result)
    })
  }, [])

  useEffect(() => {
    CnetService.readActions()
      .then((result) => {
        setActions(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return {
    data,
    setData,
    showAlert,
    setShowAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage,
    loading,
    setLoading,
    selectData,
    setSelectData,
    isCnet,
    setIsCnet,
    dataFilter,
    setDataFilter,
    active,
    setActive,
    account,
    setAccount,
    brands,
    showLog,
    setShowLog,
    showSettings,
    setShowSettings,
    actions,
    setActions,
    openModal,
    setOpenModal,
  }
}

export default useData
