import { useState } from 'react';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles'
import MaterialTables from '@material-table/core'
import Header from './Header'
import axios from 'axios'
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import Toolbar from '@material-ui/core/Toolbar'
import AlertBox from './AlertBox';


const JSONimports = [
  {
    name: 'stock1',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/stock1'
  },
  {
    name: 'stock3',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/stock3'
  },
  {
    name: 'ofertas-joaquin-oliva',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/ofertas-joaquin-oliva'
  },
  {
    name: 'ofertas-bmw-mini',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/ofertas-bmw-mini'
  },
  {
    name: 'gomotor-ofertas',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/gomotor-ofertas'
  },
  {
    name: 'gomotor-general',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/gomotor-general'
  },
  {
    name: 'tarrauto-general',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/tarrauto-general'
  },
  {
    name: 'tarrauto-ofertas',
    url: 'https://api.multipublicador.grupolivamotor.com/download-json-quiter/tarrauto-ofertas'
  }
]

const columns = [
  {
    title: 'IDV',
    field: 'idv',
  },
  {
    title: 'MARCA',
    field: 'marca'
  },
  {
    title: 'MODELO',
    field: 'modelo'
  },
  {
    title: 'PRECIO CONTADO',
    field: 'precios[0].valor',
  },
  {
    title: 'PRECIO FINANCIADO',
    field: 'precios[3].valor'
  },
/*   {
    title: 'CUOTA',
    field: 'precios[8].valor'
  }, */
  {
    title: 'CATEGORÍA',
    field: 'categoria'
  },
  {
    title: 'TIPO VO',
    field: 'tipoVO'
  },
  {
    title: 'GARANTIA',
    field: 'garantia',
    render: rowData => (
      rowData.garantia ? rowData.garantia : rowData.garantia = '0'
    )
  },
  {
    title: "MATRICULA",
    field: "matricula",
  },
  {
    title: "BASTIDOR",
    field: "bastidor",
  },
]

const getJSON = (url) => {
  const request = axios.get(url)
  return request.then(response => response.data)
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}))

const Quiter = () => {

  const [url, setUrl] = useState()
  const [data, setData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const classes = useStyles()

  const downloadFile = async () => {
    const myData = data

    const fileName = "file";
    const json = JSON.stringify(myData);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const user = localStorage.getItem('usuario')

  const handleUrlImportChange = (event) => {
    getJSON(event.target.value)
      .then(initialData => {
        if(initialData.mensajeError) {
          setData('')
          setErrorMessage(`Error: ${initialData.tipoError} - ${initialData.mensajeError}`)
          setShowErrorMessage(true)
        } else {
          setData(initialData)
          setShowErrorMessage(false)
        }
      })
    setUrl(event.target.value)
  }
  if(!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <Header handleUrlImportChange={handleUrlImportChange} downloadFile={downloadFile} JSONimports={JSONimports} />
        {showErrorMessage ? <AlertBox type='error' text={errorMessage} /> : ''}
        <MaterialTables
          title='Selecciona Fichero de Importación'
          columns={columns}
          data={data.vehiculos}
          options={{
            exportButton: true,
            filtering: true,
            pageSize: 10
          }}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                rowData[columnDef.field] = newValue
                console.log('newValue: ' + newValue);
                console.log(columnDef)
                // console.log(rowData)
                // console.log(data)
                console.log(columnDef)
                const vehicleArr = data.vehiculos
                vehicleArr.forEach(element => {
                  
                  if(element.idv === rowData.idv) {
                    if(element[columnDef.field] === oldValue) {
                      if(columnDef.field === 'precios[0].valor') {
                        element.precios[0].valor = newValue
                      }
                      else if(columnDef.field === 'precios[3].valor') {
                        element.precios[3].valor = newValue
                      }
                      else if(columnDef.field === 'precios[8].valor') {
                        element.precios[8].valor = newValue
                      }
                      else {
                        element[columnDef.field] = newValue
                      }
                    }
                  }

                })
                console.log(vehicleArr)
                data.vehiculos = vehicleArr
                setData(data)
                setTimeout(resolve, 1000);
              });
            }
          }}
        />
      </main>
    </div>
  )
}

export default Quiter