import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../Sidebar";
import TopHeader from "../TopHeader";
import Toolbar from "@material-ui/core/Toolbar";
import { Redirect } from "react-router";
import UploadService from '../../services/upload-file.service'
import Table from '../Table'

const user = localStorage.getItem('usuario')

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const columns = [
  {
    title: 'NOMBRE DEL ARCHIVO',
    field: 'name',
  },
  {
    title: 'RUTA',
    field: 'url'
  }
]

const UploadCSV = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [files, setFiles] = useState([])

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);

    setIsSelected(true);
  };

  useEffect(() => {
    UploadService.getFiles().then(response => {
      setFiles(response.fileList)
    })

  }, [])

  
  console.log(files)
  const classes = useStyles();


  if(!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <Table 
          title="Lista de ficheros CSV en el servidor"
          data={files}
          columns={columns}
        />
      </main>
    </div>
  );
};

export default UploadCSV;
