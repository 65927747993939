import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  customTextField: {
    fontSize: 14
  }
}))

const DatePicker = ({ date, label, handleDateChange }) => {
  const [newDate, setNewDate] = useState(date)
  const classes = useStyles()

  const handleUpdateDate = (date) => {
    setNewDate(date)
    handleDateChange(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{margin: '5px'}}
        inputVariant='outlined'
        margin='dense'
        id='date-picker-dialog'
        label={label}
        format='dd/MM/yyyy'
        value={newDate}
        onChange={handleUpdateDate}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputLabelProps={{ className: classes.customTextField}}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
