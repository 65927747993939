import { useContext, useEffect, useState } from 'react'

import { StyledBox, StyledSection, StyledBoxMain, StyledPaper } from './style'

import { TextareaAutosize, TextField } from '@mui/material'
import { Save, ArrowBack } from '@mui/icons-material'

import CnetService from '../../services/cnet.service'

import { Button, Stack } from '@mui/material'

const Settings = ({ context }) => {
  const { setShowSettings, account } = useContext(context)
  const [selectAccount, setSelectAccount] = useState([])
  const [url, setUrl] = useState('')
  const [description, setDescription] = useState('')

  const handleButton = () => {
    setShowSettings(false)
  }

  useEffect(() => {
    if (selectAccount.length === 0) {
      CnetService.getAccounts()
        .then((result) => {
          console.log(result)
          setSelectAccount(result.filter((e) => parseInt(e.key) === account))
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [account, selectAccount])

  const handleChangeDescription = (event) => {
    console.log(event.target.value)
    setDescription(event.target.value)
  }

  const handleChangeUrl = (event) => {
    setUrl(event.target.value)
  }

  const handleSaveButton = (event) => {
    const data = {
      webDescription: description
        ? description
        : selectAccount[0].webDescription,
      marketingImage: url ? url : selectAccount[0].marketingImage,
    }

    CnetService.updateAccountSettings(selectAccount[0]._id, data)
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.error(error)
      })

    console.log(data)
  }

  return (
    <>
      <StyledBox>
        <StyledPaper border>
          <StyledSection>
            <h3>Ajustes de la cuenta: {selectAccount[0]?.value}</h3>
            <Stack direction='row' gap={2}>
              <Button
                variant='contained'
                size='small'
                onClick={handleButton}
                startIcon={<ArrowBack />}
              >
                Volver atrás
              </Button>
            </Stack>
          </StyledSection>
        </StyledPaper>
      </StyledBox>
      {selectAccount.length && (
        <StyledBoxMain>
          <StyledPaper>
            <StyledSection>
              <h4>Información para el comprador:</h4>
            </StyledSection>
            <StyledSection>
              <TextareaAutosize
                aria-label='minimum height'
                defaultValue={selectAccount[0]?.webDescription}
                minRows={3}
                placeholder='Información para el comprador'
                style={{ width: '100%', resize: 'vertical' }}
                onChange={handleChangeDescription}
              />
            </StyledSection>
            <br />
            <StyledSection>
              <h4>URL Imagen intercalada:</h4>
            </StyledSection>
            <StyledSection>
              <TextField
                label='URL'
                variant='standard'
                size='small'
                defaultValue={selectAccount[0].marketingImage}
                style={{ width: 500 }}
                onChange={handleChangeUrl}
              />
            </StyledSection>
          </StyledPaper>
          <br />
          <Button
            variant='contained'
            size='small'
            onClick={handleSaveButton}
            startIcon={<Save />}
          >
            Guardar
          </Button>
        </StyledBoxMain>
      )}
    </>
  )
}

export default Settings
