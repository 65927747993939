import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTables from '@material-table/core'
import Sidebar from '../../Sidebar'
import TopHeader from '../../TopHeader/TopHeader'
import Toolbar from '@material-ui/core/Toolbar'
import { Redirect } from 'react-router'
import AlertBox from '../../AlertBox'
import Backdrop from '@material-ui/core/Backdrop'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserUtils from '../../../utils/User.utils'
import ListDataGrid from './ListDataGrid'
import SendSelectors from './SendSelectors'

import GomotorStats from '../../Quiter/GomotorStats'
import GrupOlivaMotorStats from '../../Quiter/GrupOlivaMotorStats'
import CochesNetStats from '../../Quiter/CochesNetStats'
import WallapopStats from '../../Quiter/WallapopStats'
import AramisStats from '../../Quiter/AramisStats'
import OfertasDeCochesStats from '../../Quiter/OfertaDeCochesStats'
import { Grid } from '@mui/material'
import { useQuiter } from '../../../hooks/useQuiter'
import QuiterStats from '../../Quiter/QuiterStats'
import MotoretoStats from '../../Quiter/MotoretoStats'

const columns = [
  {
    title: 'IMAGEN',
    field: 'recursos',
    render: (rowData) => (
      <img src={rowData.recursos[0].url} style={{ width: 150 }} />
    ),
    /*     width: '15%',
        cellStyle: { whiteSpace: 'nowrap' }, */
    // lookup: { 'VO': 'VO', 'VN': 'VN'},
    // width: '15%',
  },
  {
    title: 'IDV',
    field: 'idv',
  },
  {
    title: 'TIPO',
    field: 'tipo',
    lookup: { VO: 'VO', VN: 'VN' },
    hiddenByColumnsButton: true,
  },
  {
    title: 'MARCA',
    field: 'marca',
  },
  {
    title: 'MODELO',
    field: 'modelo',
  },
  {
    title: 'PRECIO CONTADO',
    field: 'precios[0].valor',
  },
  {
    title: 'PRECIO FINANCIADO',
    field: 'precios[3].valor',
  },
  {
    title: 'GARANTIA',
    field: 'garantia',
    render: (rowData) =>
      rowData.garantia ? rowData.garantia : (rowData.garantia = '0'),
  },
  {
    title: 'CILINDRADA',
    field: 'cilindrada',
    hidden: true,
  },
  {
    title: 'IVA DEDUCIBLE',
    field: 'ivaDeducible',
    hidden: true,
  },
  {
    title: 'ACCIONES',
    field: 'idv',
    render: (rowData) => (
      <a href={`/stock-general-quiter/${rowData.idv}`}>
        <Button>Ver vehículo</Button>
      </a>
    ),
  },
  {
    title: 'RESERVADO',
    field: 'reservado',
    type: 'boolean',
    hidden: true,
  },
  {
    title: 'CARROCERIA',
    field: 'carroceria',
    hidden: true,
  },
  {
    title: 'FECHA MAT',
    field: 'fechaMatriculacion',
    type: 'date',
    hidden: true,
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const QuiterStockGeneralQuiterList = () => {
  const { quiterData, quiterFilterData, isError } = useQuiter()
  const [alertMessage, setAlertMessage] = useState('')
  const [viewAlert, setViewAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState('')
  const [dataSelection, setDataSelection] = useState([])

  const classes = useStyles()

  const handleViewAlert = (value) => {
    if (value === false) {
      setViewAlert(false)
    } else {
      setViewAlert(true)
    }
  }

  if (!UserUtils.userExist()) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <AlertBox
          type='error'
          text='Error en la comunicación con Quiter'
          open={isError}
          handleViewAlert={handleViewAlert}
        />
        <Backdrop
          className={classes.backdrop}
          open={quiterData.length ? false : true}
        >
          <CircularProgress color='inherit' />
        </Backdrop>

        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <QuiterStats />
          </Grid>
          <Grid item xs={12} md={3}>
            <GomotorStats />
          </Grid>
          <Grid item xs={12} md={3}>
            <GrupOlivaMotorStats />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <CochesNetStats />
          </Grid>
          <Grid item xs={12} md={3}>
            <WallapopStats />
          </Grid>
          <Grid item xs={12} md={3}>
            <AramisStats />
          </Grid>
          <Grid item xs={12} md={3}>
            <OfertasDeCochesStats />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MotoretoStats />
          </Grid>
        </Grid>
        <br />
        <MaterialTables
          title='Pasarela de Quiter stock completo'
          columns={columns}
          data={quiterFilterData.length ? quiterFilterData : quiterData}
          options={{
            filtering: true,
            pageSize: 10,
            columnsButton: true,
            cellStyle: {
              padding: '10px',
            },
            selection: true,
          }}
          onSelectionChange={(rows) => setDataSelection(rows)}
        />
        {dataSelection.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <h3>Lista de seleccionados</h3>
            {dataSelection.length > 0 ? (
              <SendSelectors data={dataSelection} />
            ) : (
              ''
            )}
            <ListDataGrid data={dataSelection} />
          </div>
        ) : (
          ''
        )}
      </main>
    </div>
  )
}

export default QuiterStockGeneralQuiterList
