const Ford = ({ width }) => {
  return (
    <img
      alt='Ford'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1643712662/Multipublicador%20GrupOlivaMotor/ford_logo_black_ncawmf.svg'
      width={width}
    />
  )
}

export default Ford
