import React from "react"
import ChildTextField from "../../../ChildTextField"

const InputsContacte = ({ handleInputChangeContacte }) => {

  const handleInputTextChange = (event) => {
    handleInputChangeContacte(event)
  }

  return (
    <React.Fragment>
      <ChildTextField
        label='NOMBRE VENDEDOR'
        name='nombreVendedor'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
      <ChildTextField
        label='TELÉFONO VENDEDOR'
        name='telefonoVendedor'
        handleInputTextChange={handleInputTextChange}
        defaultValue=''
      />
    </React.Fragment>
  )
}

export default InputsContacte
