import { useState } from "react"
import {
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  FormControl,
} from "@material-ui/core"
import ConfirmModal from "./ConfirmModal"
import GrupOlivaMotorService from "../../../../../services/grupolivamotor.service"
import data from "./data"

const Landing = ({
  open,
  dataSelection,
  handleCloseModal,
  isActivated,
}) => {
  const [value, setValue] = useState("")

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const activate = () => {
    const data = {
      dataSelection,
      update: "landingPages",
      value,
    }
    GrupOlivaMotorService.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deactivate = () => {
    const data = {
      dataSelection,
      update: "landingPages",
      value: [],
    }
    GrupOlivaMotorService.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  console.log(value)

  return (
    <div>
      <FormControl component='fieldset'>
        <FormGroup row style={{ display: "flex", alignItems: "end" }}>
          <InputLabel>Seleccionar LP</InputLabel>
          <Select
            style={{ width: "250px", marginRight: "45px" }}
            onChange={handleChange}
            value={value}
          >
            {data.Landings.map((element) => (
              <MenuItem value={element} key={element}>
                {element}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      </FormControl>
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={'Publicar en LP'}
        handleCloseModal={handleCloseModal}
        handleActivate={activate}
        handleDeactivate={deactivate}
        isActivated={isActivated}
      />
    </div>
  )
}

export default Landing
