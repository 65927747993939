import { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid, Paper } from "@material-ui/core"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import StorageIcon from "@material-ui/icons/Storage"
import ClearIcon from "@material-ui/icons/Clear"
import HomeIcon from "@material-ui/icons/Home"
import Reviews from './components/Reviews'
import Campaigns from "./components/Campaigns"
import Fuel from "./components/Fuel"
import BodyType from "./components/BodyType"
import TypeVoQuiter from "./components/TypeVoQuiter"
import TypeWebVehicle from "./components/TypeWebVehicle"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderLeft: "5px solid #cdcdcd",
    cursor: "pointer",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
    "&:hover": {
      background: "#f4f4f4",
      borderLeft: "5px solid #a7a7a7",
    },
  },
  paperSlim: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderLeft: "5px solid #cdcdcd",
    cursor: "pointer",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
    "&:hover": {
      background: "#f4f4f4",
      borderLeft: "5px solid #a7a7a7",
    },
  },
  active: {
    backgroundColor: "#e6e6e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    cursor: "pointer",
    borderLeft: "5px solid #507fa8",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
  },
  activeSlim: {
    backgroundColor: "#e6e6e6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    cursor: "pointer",
    borderLeft: "5px solid #507fa8",
    textAlign: "right",
    "& h3": {
      margin: 0,
      fontSize: "1.5rem",
    },
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
  },
}))

const InfoResume = ({ data, expand, handleExpand, handleFilter }) => {
  const [reserved, setReserved] = useState(0)
  const [offer, setOffer] = useState(0)
  const [gasolina, setGasolina] = useState(0)
  const [diesel, setDiesel] = useState(0)
  const [total, setTotal] = useState(0)
  const [available, setAvailable] = useState(0)
  const [km0, setKm0] = useState(0)
  const [electric, setElectric] = useState(0)
  const [hybrid, setHybrid] = useState(0)
  const [courtesy, setCourtesy] = useState(0)
  const [demo, setDemo] = useState(0)
  const [ocasion, setOcasion] = useState(0)
  const [campaign, setCampaign] = useState(0)
  const [compact, setCompact] = useState(0)
  const [berlina, setBerlina] = useState(0)
  const [family, setFamily] = useState(0)
  const [miniVan, setMiniVan] = useState(0)
  const [cabrio, setCabrio] = useState(0)
  const [coupe, setCoupe] = useState(0)
  const [suv, setSuv] = useState(0)
  const [van, setVan] = useState(0)
  const [km0Web, setKm0Web] = useState(0)
  const [ocasionWeb, setOcasionWeb] = useState(0)
  const [checkBodyType, setCheckBodyType] = useState(0)
  const [checkPrices, setCheckPrices] = useState(0)
  const [checkWarranty, setCheckWarranty] = useState(0)
  const [active, setActive] = useState({
    activeTotal: false,
    activeAvailable: false,
    activeReserved: false,
    activeOffer: false,
    activeKm0: false,
    activeDemo: false,
    activeCourtesy: false,
    activeOccasion: false,
    activeGasolina: false,
    activeDiesel: false,
    activeElectric: false,
    activeHybrid: false,
    activeCampaign: false,
    activeCompact: false,
    activeBerlina: false,
    activeFamily: false,
    activeMiniVan: false,
    activeCabrio: false,
    activeCoupe: false,
    activeSuv: false,
    activeVan: false,
    activeKm0Web: false,
    activeOcasionWeb: false, 
    activeCheckBodyType: false,
    activeCheckPrices: false,
    activeCheckWarranty: false
  })

  useEffect(() => {
    setReserved(0)
    setOffer(0)
    setGasolina(0)
    setDiesel(0)
    setTotal(0)
    setAvailable(0)
    setKm0(0)
    setElectric(0)
    setHybrid(0)
    setCourtesy(0)
    setDemo(0)
    setOcasion(0)
    setCampaign(0)
    setCompact(0)
    setBerlina(0)
    setFamily(0)
    setMiniVan(0)
    setCabrio(0)
    setCoupe(0)
    setSuv(0)
    setVan(0)
    setKm0Web(0)
    setOcasionWeb(0)
    setCheckBodyType(0)
    setCheckPrices(0)
    setCheckWarranty(0)

    for (const object in data) {
      setTotal((total) => total + 1)
      if (data[object].tipoVO === "KM0") {
        setKm0((km0) => km0 + 1)
      }
      if (data[object].reservado === false) {
        setAvailable((available) => available + 1)
      }
      if (data[object].reservado === true) {
        setReserved((reserved) => reserved + 1)
      }
      if (data[object].vehiculosHome === true) {
        setOffer((offer) => offer + 1)
      }
      if (data[object].combustible === "GASOLINA") {
        setGasolina((gasolina) => gasolina + 1)
      }
      if (data[object].combustible === "DIESEL") {
        setDiesel((diesel) => diesel + 1)
      }
      if (data[object].combustible === "ELECTRICO") {
        setElectric((electric) => electric + 1)
      }
      if (data[object].combustible === "HIBRIDO") {
        setHybrid((hybrid) => hybrid + 1)
      }
      if (data[object].tipoVO === "CORTESIA") {
        setCourtesy((courtesy) => courtesy + 1)
      }
      if (data[object].tipoVO === "DEMO") {
        setDemo((demo) => demo + 1)
      }
      if (data[object].tipoVO === "OCASION") {
        setOcasion((ocasion) => ocasion + 1)
      }
      if (data[object].campana) {
        setCampaign((campaign) => campaign + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Compacto") {
        setCompact((compact) => compact + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Berlina") {
        setBerlina((berlina) => berlina + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Familiar") {
        setFamily((family) => family + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Monovolumen") {
        setMiniVan((miniVan) => miniVan + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Cabrio") {
        setCabrio((cabrio) => cabrio + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Coupé") {
        setCoupe((coupe) => coupe + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Furgoneta") {
        setVan((van) => van + 1)
      }
      if (data[object].tipoCarroceriaWeb === "4x4/SUV") {
        setSuv((suv) => suv + 1)
      }
      if (data[object].tipoVehiculoWeb === "Km 0") {
        setKm0Web((km0Web) => km0Web + 1)
      }
      if (data[object].tipoVehiculoWeb === "Ocasión") {
        setOcasionWeb((ocasionWeb) => ocasionWeb + 1)
      }
      if (data[object].tipoCarroceriaWeb === "Revisar") {
        setCheckBodyType((checkBodyType) => checkBodyType + 1)
      }
      if(data[object].precios[0].valor <= 100 || data[object].precios[3].valor <= 100) {
        setCheckPrices((checkPrices) => checkPrices + 1)
      }
      if(data[object].garantia < 12 ) {
        console.log('aaaaaaaaaaaa')
        console.log(parseInt(data[object].garantia))
        setCheckWarranty((checkWarranty) => checkWarranty + 1)
      }
    }
  }, [data])

  const handleClickTotal = () => {
    if (active.activeTotal === false) {
      setActive({
        activeOffer: false,
        activeReserved: false,
        activeAvailable: false,
        activeKm0: false,
        activeTotal: true,
        activeDemo: false,
        activeCourtesy: false,
        activeOccasion: false,
        activeGasolina: false,
        activeDiesel: false,
        activeHybrid: false,
        activeElectric: false,
        activeCampaign: false,
        activeKm0Web: false,
        activeOcasionWeb: false,
        activeCheckWarranty: false
      })
    } else {
      setActive({
        ...active,
        activeTotal: false,
      })
    }
    handleExpand(false)
    handleFilter("total")
  }

  const handleClickAvailable = () => {
    if (active.activeAvailable === false) {
      setActive({
        activeTotal: false,
        activeOffer: false,
        activeKm0: false,
        activeAvailable: true,
        activeReserved: false,
        activeDemo: false,
        activeCourtesy: false,
        activeOccasion: false,
        activeGasolina: false,
        activeDiesel: false,
        activeHybrid: false,
        activeElectric: false,
        activeCampaign: false,
        activeKm0Web: false,
        activeOcasionWeb: false
      })
    } else {
      setActive({ ...active, activeAvailable: false })
    }
    handleExpand(false)
    handleFilter("available")
  }

  const handleClickReserved = () => {
    if (active.activeReserved === false) {
      setActive({
        activeTotal: false,
        activeAvailable: false,
        activeOffer: false,
        activeKm0: false,
        activeReserved: true,
        activeDemo: false,
        activeCourtesy: false,
        activeOccasion: false,
        activeGasolina: false,
        activeDiesel: false,
        activeHybrid: false,
        activeElectric: false,
        activeCampaign: false,
        activeKm0Web: false,
        activeOcasionWeb: false
      })
    } else {
      setActive({ ...active, activeReserved: false })
    }
    handleExpand(false)
    handleFilter("reserved")
  }

  const handleClickOffer = () => {
    if (active.activeOffer === false) {
      setActive({
        activeTotal: false,
        activeAvailable: false,
        activeOffer: true,
        activeKm0: false,
        activeReserved: false,
        activeDemo: false,
        activeCourtesy: false,
        activeOccasion: false,
        activeGasolina: false,
        activeDiesel: false,
        activeHybrid: false,
        activeElectric: false,
        activeCampaign: false,
        activeKm0Web: false,
        activeOcasionWeb: false
      })
    } else {
      setActive({ ...active, activeOffer: false })
    }
    handleExpand(false)
    handleFilter("offer")
  }

  const activeStyle = (filter) => {
    for (let property in active) {
      if (property === filter) {
        active[property] = true
      } else {
        active[property] = false
      }
    }
    setActive({ ...active })
  }

  const handleClickCard = (activeValue, filter) => {
    if (active[activeValue] === false) {
      activeStyle(activeValue)
    } else {
      setActive({ ...active, [activeValue]: false })
    }
    handleExpand(false)
    handleFilter(filter)
  }

  const classes = useStyles()
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper
            className={active.activeTotal ? classes.active : classes.paper}
            onClick={handleClickTotal}
          >
            <StorageIcon style={{ color: "#545454", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{total}</span>
              </h3>
              <p>Total</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeAvailable ? classes.active : classes.paper}
            onClick={handleClickAvailable}
          >
            <DoneOutlineIcon style={{ color: "green", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{available}</span>
              </h3>
              <p>Disponibles</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeReserved ? classes.active : classes.paper}
            onClick={handleClickReserved}
          >
            <ClearIcon style={{ color: "red", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{reserved}</span>
              </h3>
              <p>Reservados</p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={active.activeOffer ? classes.active : classes.paper}
            onClick={handleClickOffer}
          >
            <HomeIcon style={{ color: "#507fa8", fontSize: "38px" }} />
            <div>
              <h3>
                <span>{offer}</span>
              </h3>
              <p>Home</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <TypeVoQuiter 
        active={active}
        handleClickCard={handleClickCard}
        dataKm0={km0}
        dataDemo={demo}
        dataCourtesy={courtesy}
        dataOcasion={ocasion}
      />
      <TypeWebVehicle 
        active={active}
        handleClickCard={handleClickCard}
        dataOcasionWeb={ocasionWeb}
        dataKm0Web={km0Web}
      />
      <BodyType 
        active={active}
        handleClickCard={handleClickCard}
        compact={compact}
        berlina={berlina}
        family={family}
        miniVan={miniVan}
        cabrio={cabrio}
        coupe={coupe}
        suv={suv}
        van={van}
      />
      <Fuel 
        active={active}
        handleClickCard={handleClickCard}
        gasolina={gasolina}
        diesel={diesel}
        hybrid={hybrid}
        electric={electric}
      />
      <Campaigns 
        active={active}
        handleClickCard={handleClickCard}
        campaign={campaign}
      />
      <Reviews
        active={active}
        handleClickCard={handleClickCard}
        checkPrices={checkPrices}
        checkBodyType={checkBodyType}
        checkWarranty={checkWarranty}
      />
    </Box>
  )
}

export default InfoResume
