import { StyledPaperCard, EventIcon } from "./styles"

const General = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeCampaign}
      onClick={() => handleClick()}
    >
      <EventIcon color="#507fa8" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>En campaña</p>
      </div>
    </StyledPaperCard>
  )
}

export default General