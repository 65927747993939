import { useState } from "react"

const useAlert = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [typeAlert, setTypeAlert] = useState("")
  const [errorDb, setErrorDb] = useState(false)

  return {
    alertMessage,
    setAlertMessage,
    showMessage,
    setShowMessage,
    typeAlert,
    setTypeAlert,
    errorDb,
    setErrorDb
  }
}

export default useAlert
