import { StyledPaperCard, CoupeIco } from "./styles"

const Coupe = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeCoupe}
      onClick={() => handleClick()}
    >
      <CoupeIco color="rgb(84 84 84)" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Coupé</p>
      </div>
    </StyledPaperCard>
  )
}

export default Coupe