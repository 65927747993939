import { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TopHeader from "../TopHeader/TopHeader"
import Sidebar from "../Sidebar"
import { Toolbar, Chip, Paper, Box } from "@material-ui/core/"
import { Stack, Button } from "@mui/material/"
import { DataGrid } from "@mui/x-data-grid"
import { getAll, update, bulkDelete } from "../../actions/notification"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  notMargin: {
    margin: "0",
  },
}))

const Notifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notification.notifications)
  const [selection, setSelection] = useState([])
  const classes = useStyles()

  useEffect(() => {
    dispatch(getAll())
  }, [dispatch])

  const handleClick = (value, data) => {
    if (value) {
      const markRead = { ...data, reading: true }
      dispatch(update(data._id, markRead))
    } else {
      const markRead = { ...data, reading: false }
      dispatch(update(data._id, markRead))
    }
    dispatch(getAll())
  }

  const handleSelection = (data) => {
    dispatch(bulkDelete(data))
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      valueGetter: (value) => value.row.metaData.id,
    },
    {
      field: "reading",
      headerName: "Estado",
      width: 150,
      renderCell: (value) => {
        if (value.row.reading) {
          return (
            <Chip
              label='Leida'
              size='small'
              style={{ backgroundColor: "#b3e7b3" }}
              onClick={() => handleClick(false, value.row)}
            />
          )
        } else {
          return (
            <Chip
              label='No leida'
              color='secondary'
              size='small'
              onClick={() => handleClick(true, value.row)}
            />
          )
        }
      },
      align: "center",
    },
    {
      field: "action",
      headerName: "Acción",
      width: 150,
    },
    {
      field: "severity",
      headerName: "Tipo",
      width: 120,
      renderCell: (value) => {
        if (value.row.severity === "success") {
          return (
            <Chip
              label='success'
              size='small'
              style={{ backgroundColor: "#b3e7b3" }}
            />
          )
        } else if (value.row.severity === "info") {
          return (
            <Chip
              label='info'
              color='secondary'
              size='small'
              style={{ backgroundColor: "rgb(80, 127, 168)" }}
            />
          )
        } else {
          return <Chip label='error' color='secondary' size='small' />
        }
      },
    },
    {
      field: "site",
      headerName: "Web",
      width: 150,
    },
    {
      field: "text",
      headerName: "Texto",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Fecha",
      width: 120,
      valueGetter: (value) => {
        const date = new Date(value.row.createdAt)
        return date.toLocaleDateString("es")
      },
    },
    {
      field: "campoEditado",
      headerName: "Campo editado",
      width: 150,
      valueGetter: (value) => value.row.metaData.field,
    },
    {
      field: "value",
      headerName: "Valor",
      width: 110,
      valueGetter: (value) => value.row.metaData.value,
    },
    {
      field: "itemsUpdated",
      headerName: "Vehículos",
      width: 150,
      valueGetter: (value) => value.row.metaData.itemsUpdated,
    },
  ]

  const Actions = ({ selections }) => {
    return (
      <Paper elevation={1}>
        <Box p={2} marginBottom={2} display='flex' alignItems='center' justifyContent='space-between'>
          {selections.length === 1 ? (
            <p className={classes.notMargin}>
              {selections.length} notificación seleccionada
            </p>
          ) : (
            <p className={classes.notMargin}>
              {selections.length} notificaciones seleccionadas
            </p>
          )}
          <Stack direction='row' spacing={2}>
            <Button size="small" variant="contained" onClick={() => handleSelection(selections)}>Eliminar</Button>
          </Stack>
        </Box>
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        <h1>Notificaciones</h1>
        {selection.length > 0 ? <Actions selections={selection} /> : ""}
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={notifications}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(row) => row._id}
            onSelectionModelChange={(ids) => {
              const selectedIds = new Set(ids)
              const selectedRows = notifications.filter((row) => {
                return selectedIds.has(row._id)
              })
              setSelection(selectedRows)
            }}
          />
        </div>
      </main>
    </div>
  )
}

export default Notifications
