const BmwIcon = ({ width }) => {
  return (
    <img
      alt='BMW'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1635938781/Multipublicador%20GrupOlivaMotor/BMW_logo_black_i4iaz8.svg'
      width={width}
    />
  )
}

export default BmwIcon
