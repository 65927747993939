import { StyledTextField } from './style'
import { useContext } from "react"

const Oferta = ({ context }) => {
  const { setFilterOffers } = useContext(context)

  const handleTextChange = (event) => {
    const value = event.target.value
    setFilterOffers(value)
/*     let filter = data.filter(e => {
      const toFilter = e.idOferta ? e.idOferta.toString() : ''
      const toSearch = value
    
      return toFilter.includes(toSearch)
    })
 */
  }

  return (
    <StyledTextField
      id='outlined-basic'
      label='ID Oferta'
      variant='outlined'
      size='small'
      type="number"
      onChange={handleTextChange}
    />
  )
}

export default Oferta
