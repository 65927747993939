import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'

const Countdown = ({ settings, unixEndDate }) => {
  const [countDownTimer, setCountDownTimer] = useState({})

  useEffect(() => {
    let timer = null

    if (unixEndDate) {
      const test = new Date(unixEndDate)
      const unixTimeStamp = Math.floor(test.getTime() / 1000)
      timer = setInterval(() => playTimer(unixTimeStamp), 1000)
    }
    // localStorage.setItem('countdownDate', JSON.stringify(countdownSettings))

    return () => {
      clearInterval(timer)
      timer = null
    }
  }, [unixEndDate])

  function playTimer(currentUnixEndDate) {
    const distance = currentUnixEndDate - moment().format('X')

    if (distance > 0) {
      setCountDownTimer({
        days: parseInt(distance / (60 * 60 * 24), 10),
        hours: parseInt((distance % (60 * 60 * 24)) / (60 * 60), 10),
        mins: parseInt((distance % (60 * 60)) / 60, 10),
        secs: parseInt(distance % 60, 10),
      })
    }
  }

  return (
    <div>
      <div>
        <p className='text-gray-500'>{settings?.name}</p>
      </div>
      <div className='flex justify-center gap-4'>
        <div className='card'>
          <div className='text-elem shrink-0 text-center text-gray-700 text-3xl font-semibold'>
            {countDownTimer.days || '-'}
          </div>
          <p className='text-gray-500'>D</p>
        </div>
        <div className='card'>
          <div className='text-elem shrink-0 text-center text-gray-700 text-3xl font-semibold'>
            {countDownTimer.hours || '-'}
          </div>
          <div className='text-gray-500'>H</div>
        </div>
        <div className='card'>
          <div className='text-elem shrink-0 text-center text-gray-700 text-3xl font-semibold'>
            {countDownTimer.mins || '-'}
          </div>
          <div className='text-gray-500'>M</div>
        </div>
        <div className='card'>
          <div className='text-elem shrink-0 text-center text-gray-700 text-3xl font-semibold'>
            {countDownTimer.secs || '-'}
          </div>
          <div className='text-gray-500'>S</div>
        </div>
        <p>
          {/* Counting down on{' '}
          {moment.unix(unixEndDate).format('dddd, MMMM Do, YYYY | h:mm A')} */}
        </p>
      </div>
      <p className='py-2 text-gray-700 font-semibold'>Preview</p>
      <div
        className={classNames('inline-flex rounded-xl px-4')}
        style={{
          backgroundColor: settings?.badgeColor,
          color: settings?.textColor,
        }}
      >
        <span>{settings.iconUrl}</span>
        <span>
          {countDownTimer.days > 0 ? `${countDownTimer.days}d` : ''}{' '}
          {countDownTimer.hours}:{countDownTimer.mins}:{countDownTimer.secs}
        </span>
      </div>
    </div>
  )
}

export default Countdown
