import React, { useContext } from 'react'
import CnetApiServices from '../services'

import {
  Button,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material'

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconAcabado, IconPhoto } from './Icons'

import BrandAndModel from './BrandAndModel'
import PriceAndWarranty from './PriceAndWarranty'
import Version from './Version'
import Color from './Color'

// Upload photo
import UploadPhoto from './UploadPhoto'

//
// Forms functions

import { useData } from '../hooks/useData'
const NewCarContext = React.createContext()

const NewCarForm = ({ context }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const { data, setData } = useContext(context)

  //
  // Estados

  const {
    brand,
    setBrand,
    year,
    setYear,
    fuelType,
    setFuelType,
    models,
    setModels,
    model,
    setModel,
    bodyType,
    setNumOfDoors,
    numOfDoors,
    doors,
    setDoors,
    financedPrice,
    setFinancedPrice,
    monthlyFee,
    setMonthlyFee,
    warranty,
    setWarranty,
    cardBody,
    setCarBody,
    transmission,
    setTransmission,
    setVersions,
    version,
    setVersion,
    price,
    setPrice,
    carUbication,
    environmentalLabel,
    setEnvironmentalLabel,
    carColor,
    setCarColor,
    stock,
    setStock,
    setStockChecked,
    metallic,
    setMetallic,
    setMetallicChecked,
    setBodyType,
    setCarUbication,
    versions,
  } = useData()

  const handleSubmit = (e) => {
    e.preventDefault()

    const newCar = {
      internalReference: '',
      brand: parseInt(brand),
      year: parseInt(year),
      fuelType: parseInt(fuelType),
      model: parseInt(model),
      version: parseInt(version),
      bodyType: parseInt(bodyType),
      numberOfDoors: parseInt(doors),
      categoryId: '250025002500',
      plate: '',
      offerType: 1,
      kilometers: '',
      showCarfaxReport: false,
      certified: 0,
      transmissionType: parseInt(transmission),
      warranty: parseInt(warranty),
      officialWarranty: true,
      vin: '',
      color: {
        colorId: parseInt(carColor),
        metalized: metallic,
        additionalColor: '',
      },
      price: {
        // priceValue: parseInt(price),
        bestPrice: parseInt(price),
        professionalPrice: null,
        financingPrice: parseInt(financedPrice),
        monthlyQuota: monthlyFee ? parseInt(monthlyFee) : null,
        priceWithTaxes: parseInt(price),
      },
      information: {
        professional: 'string',
        private: 'string',
        additional: 'string',
      },
      // extras: [0],
      environmentalLabel,
      matriculationYear: '',
      provincePrefix: carUbication,
      hasStock: stock,
    }

    CnetApiServices.newVehicle(newCar)
      .then((result) => {
        console.log(result)
        setData([...data, result.data])
        // ocument.getElementById('form-new-car').reset()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <NewCarContext.Provider
      value={{
        brand,
        model,
        setBrand,
        year,
        setYear,
        models,
        setModels,
        setFuelType,
        setModel,
        setNumOfDoors,
        setCarBody,
        setPrice,
        setFinancedPrice,
        setMonthlyFee,
        setWarranty,
        setBodyType,
        cardBody,
        doors,
        setDoors,
        numOfDoors,
        setTransmission,
        versions,
        setVersions,
        setVersion,
        setCarUbication,
        setEnvironmentalLabel,
        stock,
        setStock,
        setStockChecked,
        setCarColor,
        setMetallic,
        setMetallicChecked,
        metallic,
      }}
    >
      <form onSubmit={handleSubmit} id='form-new-car' action='/'>
        <BrandAndModel context={NewCarContext} />
        <PriceAndWarranty context={NewCarContext} />
        <Version context={NewCarContext} />
        <Color context={NewCarContext} />

        {/* DESCRIPCION  */}
        <Accordion onChange={handleExpand('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
              <IconAcabado color='#e20087' size={24} />
              <Typography variant='h6'>Descripcion</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='row' spacing={2}>
              aqui va las descripcion
            </Stack>
          </AccordionDetails>
        </Accordion>

        {/* FOTOS DEL VEHICULO  */}
        <Accordion onChange={handleExpand('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
              <IconPhoto color='#0024c3' size={24} />
              <Typography variant='h6'>Fotos del vehículo</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction='row' spacing={2}>
              <UploadPhoto />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ padding: '1rem 0' }}>
          <Button variant='contained' type='submit' size='small' pb={2}>
            Guardar
          </Button>
        </Box>
      </form>
    </NewCarContext.Provider>
  )
}

export default NewCarForm
