import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import RentingService from "../../../services/renting.service"
import TopHeader from "../../TopHeader/TopHeader"
import Sidebar from "../../Sidebar"
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from "@material-ui/core/CircularProgress"
import CarRentigEditForm from "./CarRentigEditForm"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fafafa'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const EditCarRenting = () => {
  const [data, setData] = useState(false)
  const [showProgress, setShowProgress] = useState(true)
  
  const classes = useStyles()
  const { id } = useParams()

  useEffect(() => {
    if(!data) {
      RentingService.getCarById(id)
        .then(result => {
          console.log(result)
          setData(result)
          setShowProgress(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [data, id])

  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Backdrop className={classes.backdrop} open={showProgress}>
                <CircularProgress color="inherit" />
        </Backdrop>
        {data
            ? <CarRentigEditForm data={data} />
            : ''
        }
      </main>
    </div>
  )
}

export default EditCarRenting
