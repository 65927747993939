import PeugeotIcon from "../../../../Icons/Peugeot"
import { StyledPaperCard } from "./styles"

const Peugeot = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      elevation={2}
      active={active.activeLpPeugeot}
      onClick={() => handleClick()}
    >
      <PeugeotIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Peugeot</p>
      </div>
    </StyledPaperCard>
  )
}

export default Peugeot
