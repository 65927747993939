import ConfirmModal from './ConfirmModal'
import ApiServices from '../../services'

const DeleteMany = ({ open, dataSelection, handleCloseModal }) => {
  const bulkDelete = async () => {
    for (let i = 0; i < dataSelection.length; i++) {
      const result = await ApiServices.deleteById(dataSelection[i].id)
    }
    return
  }

  const remove = () => {
    console.log(dataSelection)
    bulkDelete()
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ConfirmModal
      open={open}
      dataSelection={dataSelection}
      action={'Nº de vehículos a eliminar'}
      handleCloseModal={handleCloseModal}
      handleActivate={remove}
      handleDeactivate={remove}
    />
  )
}

export default DeleteMany
