export const columnsCNET = (brands) => {

  const getValue = (data, key) => {
    let value = ''
    data.forEach(element => {
      if (parseInt(element.key) === key) value = element.value
    })

    return value
  }

  const columns = [
    /*     {
          title: 'FOTO',
          field: 'photos.0.url',
          render: (rowData) => {
            if (rowData.photos) {
              return <img src={rowData.photos[0].url} width="60" height="50" alt="img" />
            }
          },
          width: "120px",
          cellStyle: {
            justifyContent: 'center'
          },
          sorting: false
        }, */
    {
      title: 'IDV',
      field: 'internalReference',
      width: "120px",
      editable: false
    },
    {
      title: 'TITULO',
      field: 'title',
      width: "65%",
      editable: false
    },
    /*     {
          title: 'MARCA',
          field: 'brand',
          render: rowData => getValue(brands, rowData.brand),
          customFilterAndSearch: (term, rowData) => getValue(brands, rowData.brand).indexOf(term) !== -1
        }, */
    {
      title: 'MATRICULA',
      field: 'plate',
    },
    {
      title: 'BASTIDOR',
      field: 'vin',
      editable: false
    },
    {
      title: 'PRECIO FINANCIADO',
      field: 'price.financingPrice',
    },
    {
      title: 'PRECIO CONTADO',
      field: 'price.priceWithTaxes',
    },
    {
      title: 'CREADO',
      field: 'creation.date.date',
      hidden: true,
      editable: false
    },
    {
      title: 'TIPO DE VEHICULO',
      field: 'offerType',
      editable: false,
      render: (rowData) => {
        if (rowData.offerType === 0) {
          return 'Ocasión'
        } else if (rowData.offerType === 2) {
          return 'KM 0'
        }
        return rowData.offerType
      },
      hidden: true
    },
    {
      title: 'DIAS CREADO',
      field: 'creation.date.daysFrom',
      hidden: true
    },
  ]
  return columns
}

export const columns = [
  {
    title: 'IDV',
    field: 'idv',
  },
  {
    title: 'MATRICULA',
    field: 'matricula',
  },
  {
    title: 'MARCA',
    field: 'marca'
  },
  {
    title: 'MODELO',
    field: 'modelo'
  },
  {
    title: 'CAMBIO',
    field: 'cambio'
  },
  {
    title: 'CONTADO',
    field: 'precios[0].valor'
  },
  {
    title: 'FINANCIADO',
    field: 'precios[3].valor'
  },
  {
    title: 'GARANTIA',
    field: 'garantia'
  },
  {
    title: 'KM',
    field: 'km'
  },
  {
    title: 'CAT VEHICULO WEB',
    field: 'tipoVehiculoWeb',
    hidden: true
  },
  {
    title: 'COLOR',
    field: 'color',
    hidden: true
  },
  {
    title: 'ALTA',
    field: 'createdAt',
    hidden: true,
    render: rowData => new Date(rowData.createdAt).toLocaleDateString('es'),
  },
  {
    title: 'RESERVADO',
    field: 'reservado',
    hidden: true,
  },
  {
    title: 'TIPO',
    field: 'tipo',
    hidden: true,
  },
  {
    title: 'TIPO VEHICULO',
    field: 'tipoVehiculo',
    hidden: true,
  },
  {
    title: 'FECHA MAT',
    field: 'fechaMatriculacion',
    hidden: true,
  },
  {
    title: 'ID CONCESIÓN',
    field: 'idConcesion',
    hidden: true,
  }
]

export const valid_colors = [
  ['AMARILLO', 'YELLOW', 1],
  ['BLUE', 'AZUL', 'BLEU', 'KOSMOSBLAU', 'BL', 2],
  ['BEIGE', 'CREMA', 3],
  ['BLANCO', 'WHITE', 'BLANC', 'ALPINWEISS', 4],
  ['GRANATE', 5],
  ['GRIS', 'PLATA', 'METALLIC', 'GREY', 'MINERALGRAU', 'PLATINUM', 'GR', 'PLATINO', 6],
  ['MARRON', 'BROWN', 7],
  ['NARANJA', 'ORANGE', 8],
  ['NEGRO', 'BLACK', 'NOIR', 'SAPHIRSCHWARZ', 'SCHWARZ', 9],
  ['ROJO', 'RED', 'ROUGE', 'CHILLI', 'ROJO-ROUGE', 10],
  ['ROSA', 'PINK', 11],
  ['VERDE', 'GREEN', 12],
  ['VIOLETA', 'LILA', 'PURPLE', 13]
]

export const accounts = [
  {
    key: 1,
    value: 'Tests DEMO',
  },
  /* {
    key: 3,
    value: 'GOmotor Tarragona',
  },
  {
    key: 2,
    value: 'GOmotor Girona',
  }, */
  /*   {
      key: 4,
      value: 'Oliva Motor Girona VN',
    }, */
  {
    key: 5,
    value: 'Grup Oliva Motor VN',
  },
  {
    key: 6,
    value: 'Oliva Motor Tarragona'
  },
  {
    key: 7,
    value: 'Oliva Motor Girona'
  }
]