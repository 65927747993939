import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTables from '@material-table/core'
import Sidebar from '../../Sidebar'
import TopHeader from '../../TopHeader/TopHeader'
import Toolbar from '@material-ui/core/Toolbar'
import { Redirect } from 'react-router'
import AlertBox from '../../AlertBox'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import GomotorService from '../../../services/gomotor.service'
import Data from '../../../utils/data'
import InfoResume from './InfoResume'
import IndexActions from './IndexActions'
import useCampaigns from '../Campaigns/hooks/useCampaigns'
// import CardView from "./CardView"
import SelectActions from './components/BulkActions/SelectActions'
import SelectedFilters from './components/SelectedFilters'

const user = localStorage.getItem('usuario')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: 'whitesmoke',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const IndexGomotor = () => {
  const [data, setData] = useState([])
  const [alertMessage, setAlertMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)
  const [showProgress, setShowProgress] = useState(true)
  const [typeAlert, setTypeAlert] = useState('')
  const [expand, setExpand] = useState(false)
  const [dataFilter, setDataFilter] = useState([])
  const { campaigns } = useCampaigns()
  const [selectData, setSelectData] = useState([])
  const [showBulk, setShowBulk] = useState(false)
  const [selectedFilters, setSelectedFilter] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if (data.length === 0) {
      GomotorService.read()
        .then((result) => {
          setData(result)
          setShowProgress(false)
        })
        .catch((err) => {
          setAlertMessage(
            'Error Code: 1 - Parece que el servidor del multipublicador no esta respondiendo'
          )
          setTypeAlert('error')
          setShowMessage(true)
          setShowProgress(false)
        })
    }
  }, [data])

  const handleExpand = (value) => {
    setExpand(value)
  }

  const handleLoading = (value) => {
    setShowProgress(value)
  }

  const handleFilter = (filter) => {
    const setData = (data) => {
      setDataFilter(data)
      setShowBulk(false)
      setExpand(true)
    }

    const isFilterExist = (filter) => {
      return selectedFilters.find((f) => f === filter)
    }

    const setFilters = (filter) => {
      if (!isFilterExist(filter)) {
        setSelectedFilter([...selectedFilters, filter])
      } else {
        setSelectedFilter(selectedFilters.filter((e) => e !== filter))
      }
    }

    const setFilter = (type) => {
      const filters = {
        total: () => {
          setData()
        },
        available: () => {
          setData(data.filter((e) => e.reservado !== true))
        },
        reserved: () => {
          setData(data.filter((e) => e.reservado === true))
        },
        offer: () => {
          setData(data.filter((e) => e.vehiculosHome === true))
        },
        km0: () => {
          setData(data.filter((e) => e.tipoVO === 'KM0'))
          setFilters(type)
        },
        courtesy: () => {
          setData(data.filter((e) => e.tipoVO === 'CORTESIA'))
          setFilters(type)
        },
        demo: () => {
          setData(data.filter((e) => e.tipoVO === 'DEMO'))
          setFilters(type)
        },
        ocasion: () => {
          setData(data.filter((e) => e.tipoVO === 'OCASION'))
          setFilters(type)
        },
        gasolina: () => {
          setData(data.filter((e) => e.combustible === 'GASOLINA'))
        },
        diesel: () => {
          setData(data.filter((e) => e.combustible === 'DIESEL'))
        },
        hybrid: () => {
          setData(data.filter((e) => e.combustible === 'HIBRIDO'))
        },
        electric: () => {
          setData(data.filter((e) => e.combustible === 'ELECTRICO'))
        },
        campaign: () => {
          setData(data.filter((e) => e.campana))
        },
        compact: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Compacto'))
        },
        berlina: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Berlina'))
        },
        family: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Familiar'))
        },
        miniVan: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Monovolumen'))
        },
        cabrio: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Cabrio'))
        },
        coupe: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Coupé'))
        },
        suv: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === '4x4/SUV'))
        },
        van: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Furgoneta'))
        },
        checkBodyWork: () => {
          setData(data.filter((e) => e.tipoCarroceriaWeb === 'Revisar'))
        },
        checkPrices: () => {
          setData(
            data.filter(
              (e) => e.precios[0].valor <= 100 || e.precios[3].valor <= 100
            )
          )
        },
        checkWarranty: () => {
          setData(data.filter((e) => e.garantia < 12))
        },
        km0Web: () => {
          setData(data.filter((e) => e.tipoVehiculoWeb === 'Km 0'))
        },
        ocasionWeb: () => {
          setData(data.filter((e) => e.tipoVehiculoWeb === 'Ocasión'))
        },
      }

      return filters[type]()
    }

    setFilter(filter)
  }

  if (!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        {showMessage ? <AlertBox type={typeAlert} text={alertMessage} /> : ''}
        <Backdrop className={classes.backdrop} open={showProgress}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <IndexActions
          expand={expand}
          handleExpand={handleExpand}
          handleLoading={handleLoading}
        />
        {data.length > 0 ? (
          <div>
            <InfoResume
              data={data}
              expand={expand}
              handleExpand={handleExpand}
              handleFilter={handleFilter}
            />
            <SelectedFilters filters={selectedFilters} />
          </div>
        ) : (
          ''
        )}
        <br />
        {selectData.length > 0 ? (
          <SelectActions dataSelection={selectData} campaigns={campaigns} />
        ) : (
          ''
        )}
        {!expand ? (
          ''
        ) : (
          <MaterialTables
            title='Lista de resultados'
            columns={Data.columns}
            data={dataFilter ? dataFilter : data}
            options={{
              tableLayout: 'auto',
              columnsButton: true,
              filtering: true,
              pageSize: 10,
              selection: true,
            }}
            onSelectionChange={(rows) => {
              showBulk ? setShowBulk(false) : setShowBulk(true)
              setSelectData(rows)
            }}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar vehiculo',
                onClick: (event, rowData) =>
                  (window.location = window.location + '/' + rowData._id),
              },
            ]}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  console.log(oldData)
                  GomotorService.deleteId(oldData._id)
                    .then((response) => {
                      console.log(response)
                      setTimeout(resolve, 1000)
                      window.location.reload()
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data]
                  const index = oldData.tableData.id
                  dataUpdate[index] = newData
                  const filter = { _id: newData._id }
                  const update = newData
                  console.log(filter)
                  console.log(newData)
                  GomotorService.update(newData._id, { filter, update })
                    .then((result) => {
                      console.log(result)
                      if (result) {
                        setData(data)
                        setTimeout(resolve, 1000)
                        window.location.reload()
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
            }}
          />
        )}
      </main>
    </div>
  )
}

export default IndexGomotor
