import { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'

function Input({ name, ...props }) {
  const { fieldState } = useController({ name })
  const { register } = useFormContext()

  return (
    <>
      <input
        {...register(name, { required: true })}
        className='px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full'
      />
      {fieldState.error && <p>{fieldState.error.message}</p>}
    </>
  )
}

export default forwardRef(Input)
