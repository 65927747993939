import Countdown from './CountDown'

const CountDownKpi = ({ campaigns }: any) => {
  return (
    <div className='flex gap-16'>
      {campaigns &&
        campaigns.map((campaign: any) => (
          <div className='relative w-full mx-auto text-left ring-1 mt-0 max-w-none bg-white shadow border-blue-400 ring-gray-200 pl-6 pr-6 pt-6 pb-6 rounded-lg'>
            <div className='w-full max-w-none text-center mt-0'>
              <div className='mt-2'>
                <Countdown settings={campaign} unixEndDate={campaign.endDate} />
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CountDownKpi
