import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import ExportJsonButton from "../../../buttons/ExportJsonButton"

const logClasses = [
  {
    code: 400,
    class: "cell-error",
  },
  {
    code: 422,
    class: "cell-info",
  },
  {
    code: 429,
    class: "cell-warning",
  },
  {
    code: 200,
    class: "cell-success",
  },
]

const LogSection = ({ data, title, accountName }) => {
  const getResponses = (data, accountName) => {
    const responses = []
    const filterAccount = data.filter((e) => e.account.value === accountName)
    for (let i = 0; i < filterAccount.length; i++) {
      if (filterAccount[i].responses) {
        for (let y = 0; y < filterAccount[i].responses.length; y++) {
          responses.push(filterAccount[i].responses[y])
        }
      }
    }
    return responses
  }

  const columns = [
    {
      field: "createdAt",
      headerName: "Fecha",
      width: 200,
      valueGetter: (value) =>
        new Date(value.row.createdAt).toLocaleString("es"),
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      cellClassName: (params) => {
        const styleClass = logClasses.filter(
          (e) => e.code === params.value && e.class
        )
        if (styleClass.length) {
          return styleClass[0].class
        } else {
          return ""
        }
      },
    },
    {
      field: "idv",
      headerName: "IDV",
      width: 150,
    },
    {
      field: "message",
      headerName: "Mensaje",
      flex: 1,
    },
  ]

  return (
    <Accordion>
      <AccordionSummary>
        <h3 style={{ margin: "0" }}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>
        {data.length > 0 ? (
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rowHeight={25}
              rows={getResponses(data, accountName)}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              getRowId={(row) => row._id}
              initialState={{
                sorting: {
                  sortModel: [{ field: "createdAt", sort: "desc" }],
                },
              }}
            />
          </div>
        ) : (
          ""
        )}
        <br />
        <ExportJsonButton data={data} />
      </AccordionDetails>
    </Accordion>
  )
}

export default LogSection
