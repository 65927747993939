import { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material"
import ChildTextField from "../../ChildTextField"
import ChildTextArea from "../../ChildTextArea"
import PriceRentingList from "./PriceRentingList"
import PageTopTitle from "../../PageTopTitle"
import ChildSwitch from "../../ChildSwicth"
import RentingService from "../../../services/renting.service"
import { Paper, Divider } from "@material-ui/core"
import VehicleImages from "./VehicleImages"
import AlertBox from "../../AlertBox"
import SnackBarAlert from "../../SnackbarAlert"
import Snackbar from "../../Snackbar"
import UploadImageButton from "../../buttons/UploadImageButton"
import SubmitButton from "../../buttons/SubmitButton"
import UploadFileService from "../../../services/upload-file.service"
import SecurityIcon from "@mui/icons-material/Security"
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import StarsIcon from "@mui/icons-material/Stars"
import SettingsBluetoothIcon from "@mui/icons-material/SettingsBluetooth"
import PersonIcon from "@mui/icons-material/Person"
import BadgeIcon from "@mui/icons-material/Badge"
import BusinessIcon from "@mui/icons-material/Business"
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import EuroIcon from "@mui/icons-material/Euro"
import Provider from "./Provider"
import Data from "./utils/data"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text], input[type=date]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
    marginTop: "12px",
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  propTitle: {
    marginTop: "10px",
    marginBottom: "25px",
  },
  textAreaWrapper: {
    width: "90%",
    margin: "0 auto",
  },
}))

const AddVehicleForm = () => {
  const [data, setData] = useState({})
  const pricesList = Data.pricesParticular
  const pricesListBusiness = Data.pricesBusiness
  const pricesListAutonomous = Data.pricesFreelance
  // const [pricesListBusiness, setPricesListBusiness] = useState([])
  // const [pricesListAutonomous, setPricesListAutonomous] = useState([])
  const [equipmentSerie, setEquipmentSerie] = useState([])
  const [equipmentExtra, setEquipmentExtra] = useState([])
  const [expanded, setExpanded] = useState(true)
  const [open, setOpen] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [recursos, setRecursos] = useState([])
  const [ecoLabel, setEcoLabel] = useState("")
  const [loading, setLoading] = useState(false)
  const [providers, setProviders] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if (providers.length === 0) {
      RentingService.providers()
        .then((result) => {
          setProviders(result)
          console.log(result)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [providers])

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const handleInputTextChange = (event) => {
    // console.log(event.target.name)
    // console.log(event.target.value)
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    /*     data[key] = value
    console.log(data) */
  }

  const handleInputPriceChange = (event) => {
    // console.log(price)

    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    pricesList.map((e) => {
      if (e.tipo === event.target.name) {
        e.valor = formatPrice.format(event.target.value)
        // return e.valor = formatPrice.format(event.target.value)
      }
      return e
    })
    /* pricesList.concat(price)
    if (pricesList.filter((e) => e.tipo === price.tipo).length > 0) {
      pricesList.pop(price)
      pricesList.push(price)
      setPricesList([...pricesList])
    } else {
      pricesList.push(price)
      setPricesList([...pricesList])
      //setPricesList(pricesList.concat(price))
    } */
    data.preciosParticular = pricesList
    console.log(pricesList)
    console.log(pricesListAutonomous)
    setData({ ...data })
  }

  const handleInputPriceProfessionalChange = (event) => {
    // console.log(price)
    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    pricesListBusiness.map((e) => {
      if (e.tipo === event.target.name) {
        return (e.valor = formatPrice.format(event.target.value))
      }
      return e
    })

    /*     price.valor = formatPrice.format(price.valor)

    if (pricesListBusiness.filter((e) => e.tipo === price.tipo).length > 0) {
      pricesListBusiness.pop(price)
      pricesListBusiness.push(price)
      setPricesListBusiness([...pricesListBusiness])
    } else {
      pricesListBusiness.push(price)
      setPricesListBusiness([...pricesListBusiness])
      // setPricesListBusiness(pricesListBusiness.concat(price))
    } */
    data.preciosEmpresas = pricesListBusiness
    setData({ ...data })
  }

  const handleInputPriceProfessionalChangeAutonomous = (event) => {
    const formatPrice = new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
    })

    pricesListAutonomous.map((e) => {
      if (e.tipo === event.target.name) {
        return (e.valor = formatPrice.format(event.target.value))
      }
      return e
    })

    /*     price.valor = formatPrice.format(price.valor)

    if (pricesListAutonomous.filter((e) => e.tipo === price.tipo).length > 0) {
      pricesListAutonomous.pop(price)
      pricesListAutonomous.push(price)
      setPricesListAutonomous([...pricesListAutonomous])
    } else {
      pricesListAutonomous.push(price)
      setPricesListAutonomous([...pricesListAutonomous])
      // setPricesListBusiness(pricesListBusiness.concat(price))
    } */
    data.preciosAutonomos = pricesListAutonomous
    setData({ ...data })
  }

  const handleInputTextAreaChange = (event) => {
    // console.log(event.target.value)
    data.observaciones = event.target.value
    console.log(data.observaciones)
    setData({ ...data })
  }

  const handleChangeEcoLabel = (event) => {
    setEcoLabel(event.target.value)
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleChangeProvider = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleChangeImmediateDelivery = (value) => {
    setData({
      ...data,
      immediate_delivery: value,
    })
  }

  const handleInputTextAreaImportantEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Destacado",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    data.equipamientoDestacado = equipamiento
    setData({ ...data })
  }

  const handleInputTextAreaTechnologyEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Tecnologia",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    data.equipamientoTecnologia = equipamiento
    setData({ ...data })
  }

  const handleInputTextAreaConfortEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Confort",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    data.equipamientoConfort = equipamiento
    setData({ ...data })
  }

  const handleInputTextAreaSecurityEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Seguridad",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    data.equipamientoSeguridad = equipamiento
    setData({ ...data })
  }

  const handleInputTextAreaExteriorEquipment = (event) => {
    const equipment = event.target.value
    const equipamiento = []
    const arrayEquipment = equipment.split("\n")

    arrayEquipment.map((item) => {
      const newEquipment = {
        tipo: "Exterior",
        descripcion: "",
        contenido: item,
      }
      return equipamiento.push(newEquipment)
    })
    data.equipamientoExterior = equipamiento
    setData({ ...data })
  }

  const handleChangePublished = (value) => {
    data.publicarAutonomos = value
    setData({ ...data })
  }

  const handleChangePublishedForPersonal = (value) => {
    data.publicarParticular = value
    setData({ ...data })
  }

  const handleChangePublishedForProfessional = (value) => {
    data.publicarEmpresa = value
    setData({ ...data })
  }

  const handleMinPriceChange = (event) => {
    data.precioMinParticular = {
      ...data.precioMinParticular,
      [event.target.name]: event.target.value,
    }

    setData(data)
    console.log(data)
  }

  const handleMinPriceFreelanceChange = (event) => {
    data.precioMinAutonomos = {
      ...data.precioMinAutonomos,
      [event.target.name]: event.target.value,
    }

    setData(data)
    console.log(data)
  }

  const handleMinPriceProfessionalChange = (event) => {
    data.precioMinEmpresas = {
      ...data.precioMinEmpresas,
      [event.target.name]: event.target.value,
    }

    setData(data)
    console.log(data)
  }

  const handleSaveButton = (event) => {
    event.preventDefault()
    data.preciosParticular = pricesList
    data.preciosAutonomos = pricesListAutonomous
    data.preciosEmpresas = pricesListBusiness
    const isFormValidate = validateForm()
    console.log(data)
    data.equipamiento = equipmentSerie.concat(equipmentExtra)
    if (isFormValidate) {
      RentingService.create(data)
        .then((result) => {
          console.log(result)
          setOpen(true)
          setLoading(true)
          setTimeout(() => {
            setOpen(false)
            window.location.replace("/stock-renting")
          }, 3000)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    } else {
      setOpenError(true)
    }
  }

  const handleUploadImage = (image) => {
    const name = `${image.lastModified}-${image.name}`.replace(" ", "-")

    UploadFileService.uploadImage(image, name)
      .then((result) => {
        if (result.status === 200) {
          const newImage = {
            nombre: name,
            url: process.env.REACT_APP_API_URL + `uploads/images/${name}`,
            tipo: "IMAGEN",
            propiedades: [
              {
                nombre: "EsFotoPrincipal",
                valor: false,
              },
              {
                nombre: "OrdenFoto",
                valor: "",
              },
            ],
          }
          setRecursos([...recursos, newImage])

          setData({ ...data, recursos })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChangeImageProperties = (image) => {
    console.log("Propiedades de imagen cambiadas!!!")
    console.log(image)

    for (let i = 0; i < recursos.length; i++) {
      if (recursos[i].nombre === image.nombre) {
        recursos[i].propiedades = image.propiedades
      }
    }
    data.recursos = recursos
    setData({ ...data })
  }

  const handleDeleteImage = (imageName) => {
    let filterImages = recursos.filter(
      (element) => element.nombre !== imageName
    )

    RentingService.deleteImg({ name: imageName })

    setRecursos(filterImages)
  }

  const validateForm = () => {
    if (data.marca === undefined || data.marca === "") {
      return false
    } else if (data.modelo === undefined || data.modelo === "") {
      return false
    } else if (data.color === undefined || data.color === "") {
      return false
    } else if (data.cambio === undefined || data.cambio === "") {
      return false
    } else if (
      data.precioMinParticular === undefined ||
      data.precioMinParticular.valorMin === ""
    ) {
      return false
    } else if (
      data.precioMinParticular === undefined ||
      data.precioMinParticular.tipoPrecioMin === ""
    ) {
      return false
    } else if (
      data.precioMinAutonomos === undefined ||
      data.precioMinAutonomos.valorMin === ""
    ) {
      return false
    } else if (
      data.precioMinAutonomos === undefined ||
      data.precioMinAutonomos.tipoPrecioMin === ""
    ) {
      return false
    } else if (
      data.precioMinEmpresas === undefined ||
      data.precioMinEmpresas.valorMin === ""
    ) {
      return false
    } else if (
      data.precioMinEmpresas === undefined ||
      data.precioMinEmpresas.tipoPrecioMin === ""
    ) {
      return false
    } else {
      return true
    }
  }

  console.log("componente renderizado!!!")
  // console.log(recursos)
  console.log(data)

  return (
    <div>
      <PageTopTitle title='Crear vehículo de renting'>
        <ChildSwitch
          value={data.publicarParticular}
          name='publicarParticular'
          label='Publicar para Particular'
          handleChangeSwitch={handleChangePublishedForPersonal}
        />
        <ChildSwitch
          value={data.publicarAutonomos}
          name='publicarAutonomos'
          label='Publicar para Autónomos'
          handleChangeSwitch={handleChangePublished}
        />
        <ChildSwitch
          value={data.publicarProfesional}
          name='publicarEmpresa'
          label='Publicar para Empresa'
          handleChangeSwitch={handleChangePublishedForProfessional}
        />
      </PageTopTitle>
      <form
        className={classes.form}
        autoComplete='off'
        onSubmit={handleSaveButton}
        noValidate
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Provider
              providers={providers}
              carData={{}}
              handleInputTextChange={handleInputTextChange}
              handleChangeProvider={handleChangeProvider}
              handleChangeImmediateDelivery={handleChangeImmediateDelivery}
            />
            {/* DATOS GENERALES DEL VEHÍCULO */}
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpand}
              >
                <Box display='flex' alignItems='center'>
                  <DirectionsCarIcon htmlColor='rgb(209, 51, 51)' />
                </Box>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: "12px" }}
                >
                  Datos generales del vehículo
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ChildTextField
                  name='marca'
                  label='Marca'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  required={true}
                />
                <ChildTextField
                  name='modelo'
                  label='Modelo'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  required={true}
                />
                <ChildTextField
                  name='version'
                  label='Versión'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='color'
                  label='Color'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  required={true}
                />
                <ChildTextField
                  name='carroceria'
                  label='Tipo de Vehiculo'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                />
                <ChildTextField
                  name='potenciaCv'
                  label='Potencia CV (Número)'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='number'
                />
                <ChildTextField
                  name='cambio'
                  label='Cambio'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  required={true}
                />
                <ChildTextField
                  name='combustible'
                  label='Combustible'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  required={true}
                />
                <ChildTextField
                  name='puertas'
                  label='Puertas (Número)'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='number'
                />
                <ChildTextField
                  name='plazas'
                  label='Plazas (Número)'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='number'
                />
                <ChildTextField
                  name='emisiones'
                  label='Emisiones'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='text'
                />
                <ChildTextField
                  name='consumoExtUrbano'
                  label='Consumo (Extra urbano)'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='text'
                />
                <ChildTextField
                  name='franquicia'
                  label='Franquicia'
                  defaultValue=''
                  handleInputTextChange={handleInputTextChange}
                  type='number'
                />
                <FormControl>
                  <InputLabel
                    style={{ fontSize: "14px", left: "10px" }}
                    id='demo-simple-select-label'
                  >
                    Etiqueta ECO
                  </InputLabel>
                  <Select
                    name='etiquetaEco'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={ecoLabel}
                    label='Etiqueta ECO'
                    size='small'
                    style={{ margin: "5px" }}
                    onChange={handleChangeEcoLabel}
                  >
                    <MenuItem value='0 emisiones'>0</MenuItem>
                    <MenuItem value='B'>B</MenuItem>
                    <MenuItem value='C'>C</MenuItem>
                    <MenuItem value='ECO'>ECO</MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>

            {/* PRECIOS */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display='flex' alignItems='center'>
                  <EuroIcon htmlColor='#33add1' />
                </Box>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: "12px" }}
                >
                  Precios
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column" }}>
                {/* PARTICULAR */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <PersonIcon htmlColor='#33add1' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Particulares
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Box
                      className={classes.details}
                      margin='0'
                      display='flex'
                      width='100%'
                      alignItems='center'
                    >
                      <ChildTextField
                        name='valorMin'
                        label='Precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceChange}
                      />
                      <ChildTextField
                        name='tipoPrecioMin'
                        label='Tipo precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceChange}
                      />
                      <p style={{ background: "#ebebeb", padding: "6px" }}>
                        Ej: 10.000 km/año · 48 meses
                      </p>
                    </Box>
                    <PriceRentingList
                      data={[]}
                      handleInputPriceChange={handleInputPriceChange}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* AUTONOMO */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <BadgeIcon htmlColor='#33add1' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Autónomos
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Box
                      className={classes.details}
                      margin='0'
                      display='flex'
                      width='100%'
                      alignItems='center'
                    >
                      <ChildTextField
                        name='valorMin'
                        label='Precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceFreelanceChange}
                      />
                      <ChildTextField
                        name='tipoPrecioMin'
                        label='Tipo precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceFreelanceChange}
                      />
                      <p style={{ background: "#ebebeb", padding: "6px" }}>
                        Ej: 10.000 km/año · 48 meses
                      </p>
                    </Box>
                    <PriceRentingList
                      data={[]}
                      handleInputPriceChange={
                        handleInputPriceProfessionalChangeAutonomous
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/* EMPRESA */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <BusinessIcon htmlColor='#33add1' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Empresa
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Box
                      className={classes.details}
                      margin='0'
                      display='flex'
                      width='100%'
                      alignItems='center'
                    >
                      <ChildTextField
                        name='valorMin'
                        label='Precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceProfessionalChange}
                      />
                      <ChildTextField
                        name='tipoPrecioMin'
                        label='Tipo precio mínimo'
                        type='text'
                        required={true}
                        handleInputTextChange={handleMinPriceProfessionalChange}
                      />
                      <p style={{ background: "#ebebeb", padding: "6px" }}>
                        Ej: 10.000 km/año · 48 meses
                      </p>
                    </Box>
                    <PriceRentingList
                      data={[]}
                      handleInputPriceChange={
                        handleInputPriceProfessionalChange
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>

            {/* EQUIPAMIENTO */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display='flex' alignItems='center'>
                  <StarsIcon htmlColor='#507fa8' />
                </Box>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: "12px" }}
                >
                  Equipamiento
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column" }}>
                {/* DESTACADO */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <WorkspacePremiumIcon htmlColor='#507fa8' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Destacado
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={`${classes.textAreaWrapper}`}>
                    <ChildTextArea
                      name='Destacado'
                      defaultValue=''
                      handleInputTextAreaChange={
                        handleInputTextAreaImportantEquipment
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/* TECNOLOGIA */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <SettingsBluetoothIcon htmlColor='#507fa8' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Tecnología
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={`${classes.textAreaWrapper}`}>
                    <ChildTextArea
                      name='Tecnologia'
                      defaultValue=''
                      handleInputTextAreaChange={
                        handleInputTextAreaTechnologyEquipment
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/* CONFORT */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <AirlineSeatReclineExtraIcon htmlColor='#507fa8' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Confort
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={`${classes.textAreaWrapper}`}>
                    <ChildTextArea
                      name='Confort'
                      defaultValue=''
                      handleInputTextAreaChange={
                        handleInputTextAreaConfortEquipment
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/* SEGURIDAD */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <SecurityIcon htmlColor='#507fa8' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Seguridad
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={`${classes.textAreaWrapper}`}>
                    <ChildTextArea
                      name='Seguridad'
                      defaultValue=''
                      handleInputTextAreaChange={
                        handleInputTextAreaSecurityEquipment
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                {/* EXTERIOR */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display='flex' alignItems='center'>
                      <DirectionsCarIcon htmlColor='#507fa8' />
                    </Box>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: "12px" }}
                    >
                      Exterior
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={`${classes.textAreaWrapper}`}>
                    <ChildTextArea
                      name='Exterior'
                      defaultValue=''
                      handleInputTextAreaChange={
                        handleInputTextAreaExteriorEquipment
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            {/* ACTIONS BUTTONS */}
            <Box display='flex' alignItems='end' marginTop={2}>
              <SubmitButton text='Guardar Vehículo' loading={loading} />
              <UploadImageButton
                handleUploadImage={handleUploadImage}
                text='Subir imagen'
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography
                variant='h6'
                component='h2'
                align='center'
                className={classes.propTitle}
              >
                Imágenes del vehículo
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
                {recursos.length === 0 ? (
                  <AlertBox
                    type='warning'
                    text='Este vehículo no tiene fotos'
                  />
                ) : (
                  recursos.map((image) => (
                    <VehicleImages
                      key={image.name}
                      image={image}
                      handleChangeImageProperties={handleChangeImageProperties}
                      handleDeleteImage={handleDeleteImage}
                    />
                  ))
                )}
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <SnackBarAlert isOpen={open} type='success' text='Vehículo guardado 😄' />
      <Snackbar
        open={openError}
        severity='error'
        handleClose={() => setOpenError(false)}
      >
        Todos los campos con * son necesarios 😒
      </Snackbar>
      {/*       <SnackBarAlert
        isOpen={openError}
        type='error'
        text='Todos los campos con * son necesarios 😒'
      /> */}
    </div>
  )
}
export default AddVehicleForm
