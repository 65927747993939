const Honda = ({ width }) => {
  return (
    <img
      alt='Honda'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1635938258/Multipublicador%20GrupOlivaMotor/Honda_logo_black_lppbhu.svg'
      width={width}
    />
  )
}

export default Honda
