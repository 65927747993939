import styled from "@emotion/styled"
import { Paper, TextField } from '@mui/material'

export const StyledBox = styled.div`
  padding: 0px 0px 24px 0px;
`
export const StyledPaper = styled(Paper)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  border-left: 5px solid #999999;
`

export const StyledText = styled.b`
  margin-right: 14px;
`

export const StyledTextField = styled(TextField)`
  margin-right: 14px;
`