import { useFormContext } from 'react-hook-form'
import { forwardRef } from 'react'
import Button from '../../../@core/components/Button'

function SubmitButton({ children, handleSubmitButton, ...props }, ref) {
  const { handleSubmit } = useFormContext()

  const submitForm = (data) => {
    console.log(data)
    handleSubmitButton(data)
  }

  return (
    <Button
      ref={ref}
      {...props}
      text='Aceptar'
      size='sm'
      className='bg-blue-500 focus:ring-blue-400 hover:bg-blue-600 hover:border-blue-600'
      handleClick={handleSubmit(submitForm)}
    >
      Aceptar
    </Button>
  )
}

export default forwardRef(SubmitButton)
