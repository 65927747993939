import React, { useState, useContext } from 'react'
import { NewCarAdd, NewCarStyled } from './style'
// import { Button, Box } from '@material-ui/core'
import { Button, Box } from '@mui/material'
import NewCarForm from './components/NewCarForm'

const NewCar = ({ children, context }) => {
  const [carForm, setCarForm] = useState(false)
  const handleNewCar = () => {
    setCarForm(true)
  }
  return (
    <NewCarStyled>
      <NewCarAdd>
        <div style={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={handleNewCar}
            >
              Crear vehículo manual
            </Button>
            {carForm && (
              <Button
                variant='contained'
                color='secondary'
                size='small'
                onClick={() => setCarForm(false)}
              >
                Reset
              </Button>
            )}
          </Box>
        </div>
        {children}
      </NewCarAdd>
      {carForm && <NewCarForm context={context} />}
    </NewCarStyled>
  )
}

export default NewCar
