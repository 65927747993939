import React from 'react'
import { makeStyles } from '@mui/styles'
import TopHeader from '../TopHeader/TopHeader'
import Sidebar from '../Sidebar'
import TopBar from './components/TopBar'
import Table from './components/Table'
import ActionsBar from './components/ActionsBar'
import Snackbar from '../Snackbar'
import GeneralFilter from './components/GeneralFilter'
import Reviews from './components/Reviews'
import Log from './components/Log'
import Settings from './components/Settings'
import CnetGeneralFilter from './components/CnetGeneralFilter'
// import Box from '@material-ui/core/Box'
// import Button from '@mui/material/Button'

import useData from './hooks/useData'

import { Toolbar, CircularProgress } from '@mui/material'
import {
  StyledMain,
  StyledContent,
  StyledBackDrop,
  StyledMainSection,
} from './style'
import CnetService from './services/cnet.service'
import NewCar from './components/NewCar'

const useStyles = makeStyles((theme) => ({}))
const CNETContext = React.createContext()

const CochesNet = () => {
  const {
    data,
    setData,
    showAlert,
    setShowAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage,
    loading,
    setLoading,
    selectData,
    setSelectData,
    isCnet,
    setIsCnet,
    dataFilter,
    setDataFilter,
    active,
    setActive,
    account,
    setAccount,
    brands,
    showLog,
    setShowLog,
    showSettings,
    setShowSettings,
    actions,
    setActions,
    openModal,
    setOpenModal,
  } = useData()

  useStyles()

  const changeAccount = () => {
    setLoading(true)
    CnetService.updateDb()
      .then((result) => {
        setData(result)
        setLoading(false)
        setShowAlert(true)
        setTypeAlert('success')
        setAlertMessage('La base de datos se ha actualizado. 😎')
      })
      .catch((err) => {
        console.log(err)
      })
  }
  if (showLog) {
    return (
      <CNETContext.Provider
        value={{ setShowLog, actions, setActions, setLoading }}
      >
        <StyledMain>
          <TopHeader />
          <Sidebar />
          <StyledContent>
            <Toolbar />
            <StyledBackDrop open={loading}>
              <CircularProgress color='inherit' />
            </StyledBackDrop>
            <Log context={CNETContext} />
          </StyledContent>
        </StyledMain>
      </CNETContext.Provider>
    )
  }

  if (showSettings) {
    return (
      <CNETContext.Provider value={{ setShowSettings, account }}>
        <StyledMain>
          <TopHeader />
          <Sidebar />
          <StyledContent>
            <Toolbar />
            <Settings context={CNETContext} />
          </StyledContent>
        </StyledMain>
      </CNETContext.Provider>
    )
  }

  return (
    <CNETContext.Provider
      value={{
        data,
        setData,
        setLoading,
        setShowAlert,
        setTypeAlert,
        setAlertMessage,
        selectData,
        setSelectData,
        isCnet,
        setIsCnet,
        dataFilter,
        setDataFilter,
        active,
        setActive,
        account,
        setAccount,
        setShowLog,
        setShowSettings,
        openModal,
        setOpenModal,
      }}
    >
      <StyledMain>
        <TopHeader />
        <Sidebar />
        <StyledContent>
          <Toolbar />
          <StyledBackDrop open={loading}>
            <CircularProgress color='inherit' />
          </StyledBackDrop>
          <TopBar changeAccount={changeAccount} context={CNETContext} />
          {isCnet ? <CnetGeneralFilter context={CNETContext} /> : ''}
          {!isCnet ? <GeneralFilter context={CNETContext} /> : ''}
          {!isCnet ? <Reviews context={CNETContext} /> : ''}
          {selectData.length > 0 && <ActionsBar context={CNETContext} />}
          <StyledMainSection>
            {account === 5 && <NewCar context={CNETContext} />}
            <Table data={data} brands={brands} context={CNETContext} />
          </StyledMainSection>
        </StyledContent>
      </StyledMain>
      <Snackbar
        open={showAlert}
        severity={typeAlert}
        handleClose={() => setShowAlert(false)}
      >
        {alertMessage}
      </Snackbar>
    </CNETContext.Provider>
  )
}

export default CochesNet
