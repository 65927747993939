const Seat = ({ width }) => {
  return (
    <svg
      version='1.0'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 49.9 49.9'
      width={width ? width : ""}
    >
      <g>
        <path
          d='M19.4,10.7c6.4-0.2,12.7,0,19.1,0.4c0.7,0.1,1.3,0.1,2,0.2c0.4,0.1,0.7,0.2,0.9,0.5c0.2,0.4,0.2,0.8,0.2,1.2
		c0.2,1.9,0.2,3.8,0.3,5.7c-0.3,0-0.5,0-0.8,0c-4.9-0.3-9.8-0.5-14.7-0.5c-2.5,0-5.1,0-7.6,0.1c-0.6,0.1-1.3,0.3-1.7,0.8
		c1.9,0.3,3.8,0.7,5.8,1.1c6.4,1.2,12.7,2.4,19.1,3.5c0,1.2,0,2.4,0,3.6c-3.6-0.6-7.1-1.2-10.7-1.8c-7.7-1.3-15.4-2.7-23.1-4
		c0-1.3,0.1-2.7,0.1-4c0-1.1,0-2.1,0.3-3.2c0.2-0.7,0.5-1.5,1-2c1-1,2.4-1.2,3.6-1.4C15.3,10.8,17.3,10.8,19.4,10.7L19.4,10.7z'
        />
        <path
          d='M8.2,23.3c11,2,22,4.1,33.1,6.1c0.2,0.1,0.5,0.1,0.7,0.2l0,0.1c-0.1,1.8-0.1,3.6-0.2,5.4c0,1.1-0.2,2.3-0.7,3.3
		c-0.6,1-1.8,1.6-2.9,1.8c-1.7,0.3-3.4,0.3-5.1,0.4c-7.3,0.3-14.6,0.1-21.9-0.4c-0.6,0-1.2-0.1-1.7-0.2c-0.3-0.1-0.6-0.2-0.8-0.5
		c-0.2-0.4-0.2-0.8-0.2-1.1c-0.2-2.1-0.2-4.2-0.3-6.3c3.8,0.2,7.5,0.4,11.3,0.5c3.6,0.1,7.3,0.1,10.9,0.1c0.8,0,1.6-0.1,2.2-0.7
		c0.1-0.1,0.2-0.2,0.2-0.3c-1.6-0.2-3.3-0.6-4.9-0.8c-6.6-1.1-13.1-2.2-19.7-3.4C8.2,26,8.2,24.7,8.2,23.3L8.2,23.3z'
        />
      </g>
    </svg>
  )
}

export default Seat
