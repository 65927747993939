import React, { useState } from "react"
import { StyledBox } from "./styles"
import Alert from "../Alert/"
import { TextField } from "@mui/material"
import Button from "./Button"
import UserService from "../../../services/user.service"

const Form = ({
  user,
  name,
  lastname,
  telephone,
  showChangePassword,
  showChangeProfile,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateMessage, setUpdateMessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")

  const handleClose = () => {
    setOpenAlert(false)
  }

  const handleChangePassword = (event) => {
    event.preventDefault()
    setPassword(event.target.value)
  }

  const handleChangeNewPassword = (event) => {
    event.preventDefault()
    setNewPassword(event.target.value)
  }

  const handleChangeUserForm = (event) => {
    event.preventDefault()
    console.log(event.target.form[3])
    const name = event.target.form[0].value
    const lastname = event.target.form[2].value
    const telephone = event.target.form[4].value
    UserService.updateUser({
      id: user.id,
      name,
      lastname,
      telephone,
    })
      .then((response) => {
        setOpenAlert(true)
        setPassword("")
        setNewPassword("")
        setErrorMessage("")
        setUpdateMessage("Datos actualizados con éxito")
      })
      .catch((err) => {
        setOpenAlert(true)
        setErrorMessage("Error al actualizar el perfil")
        console.log(err)
      })
  }

  const handleSubmitPassword = (event) => {
    event.preventDefault()
    if (password !== newPassword) {
      setOpenAlert(true)
      setErrorMessage("Las contraseñas no coinciden")
    } else {
      UserService.updateUser({
        id: user.id,
        password: newPassword,
        name,
        lastname,
        telephone,
      })
        .then((response) => {
          console.log(response)
          setOpenAlert(true)
          setPassword("")
          setNewPassword("")
          setErrorMessage("")
          setUpdateMessage("La contraseña se ha cambiado")
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div>
      <Alert
        open={openAlert}
        errorMessage={errorMessage}
        successMessage={updateMessage}
        handleClose={handleClose}
      />
      {showChangeProfile && (
        <StyledBox
          component='form'
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete='off'
        >
          <React.Fragment>
            <TextField
              id='name'
              label='Nombre'
              variant='outlined'
              type='text'
              size='small'
              defaultValue={name}
            />
            <TextField
              id='lastname'
              label='Apellidos'
              variant='outlined'
              type='text'
              size='small'
              defaultValue={lastname}
            />
            <TextField
              id='telephone'
              label='Teléfono'
              variant='outlined'
              type='text'
              size='small'
              defaultValue={telephone}
            />
          </React.Fragment>
          <Button handleSubmit={handleChangeUserForm} />
        </StyledBox>
      )}
      {showChangePassword && (
        <StyledBox
          component='form'
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmitPassword}
        >
          <React.Fragment>
            <TextField
              id='standard-basic'
              label='Nueva contraseña'
              variant='outlined'
              type='password'
              size='small'
              value={password}
              onChange={handleChangePassword}
            />
            <TextField
              id='standard-basic'
              label='Repite tu contraseña'
              variant='outlined'
              type='password'
              size='small'
              value={newPassword}
              onChange={handleChangeNewPassword}
            />
          </React.Fragment>
          <Button handleSubmit={handleSubmitPassword} />
        </StyledBox>
      )}
    </div>
  )
}

export default Form
