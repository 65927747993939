import { StyledPaperCard, MiniVanIco } from "./styles"

const MiniVan = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeMiniVan}
      onClick={() => handleClick()}
    >
      <MiniVanIco color="rgb(84 84 84)" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Monovolumen</p>
      </div>
    </StyledPaperCard>
  )
}

export default MiniVan