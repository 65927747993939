import { makeStyles, Typography} from '@material-ui/core'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

const EditVehicleInfoBox = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div>
        <Typography variant='h6' component='h2'>
          {title}
        </Typography>
      </div>
      <div>
        {children}
      </div>
    </Paper>
  )
}

export default EditVehicleInfoBox