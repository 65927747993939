import { useState } from "react"
import { useParams, Redirect } from "react-router-dom"
import UserUtils from '../../utils/User.utils'
import { makeStyles } from "@material-ui/core/styles"
import TopHeader from '../TopHeader'
import Sidebar from '../Sidebar'
import { Toolbar } from '@material-ui/core/'
import Grid from '@material-ui/core/Grid'
import PortalData from '../PortalData'
import PortalChanges from "../PortalChanges"
import { useEffect } from "react"
import Utils from "../../services/utils"
import React from "react"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import QuiterService from '../../services/quiter.service'
import VehiculosService from "../../services/vehiculos.service"
import SyncRecords from '../../services/syncrecords.service'
import AppCrash from '../AppCrash'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fafafa'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const QuiterPortal = () => {
  const [quiterJsonData, setQuiterJsonData] = useState(null)
  const [quiterDbData, setQuiterDbData] = useState(null)
  const [recordsSync, setRecordsSync] = useState(null)
  const [generalDbData, setGeneralDbData] = useState(null)
  const [serverError, setErrorServer] = useState(false)
  const { portalId } = useParams()

  const classes = useStyles()
  const url = process.env.REACT_APP_API_VEHICULOS_QUITER

  useEffect(() => {
    if(!quiterJsonData) {
      Utils.secureHttp(`${url}/${portalId}`, 'GET')
        .then(result => {
          if(result.tipoError) {
            console.log(result)
          } else {
            console.log(result)
            setQuiterJsonData(result)
            return QuiterService.getDataToQuiterDataBase()
          }
        })
        .then(result => {
          console.log(result)
          setQuiterDbData(result)
          return VehiculosService.getAll()
        })
        .then(result => {
          console.log(result)
          setGeneralDbData(result)
          return SyncRecords.getRecordsForDbName('vehiculoquiters')
        })
        .then(result => {
          console.log(result)
          setRecordsSync(result)
        })
        .catch(err => {
          console.log(err)
          setErrorServer(true)
        })
    }
  }, [portalId, quiterJsonData, url])

  if (!UserUtils.userExist()) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        {
          serverError
          ? (
              <AppCrash />
          )
          : (
            <Grid container spacing={3}>
              { !quiterJsonData || !quiterDbData || !generalDbData || !recordsSync
                ? (

                  <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )
                : (
                  <React.Fragment>
                    <PortalData portalId={portalId} quiterJsonData={quiterJsonData} quiterDbData={quiterDbData} recordsSync={recordsSync} />
                    <PortalChanges quiterDbData={quiterDbData} generalDbData={generalDbData} />
                  </React.Fragment>
                ) 
              }
            </Grid>
          )  
        }
      </main>
    </div>
  )
}

export default QuiterPortal