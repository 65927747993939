import { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import Reviews from './components/Reviews'
import Fuel from './components/Fuel'
import BodyType from './components/BodyType'
import TypeWebVehicle from './components/TypeWebVehicle'

// New and refactoring
import KpiCardWithProgressBar from '../../@core/components/Card/KpiCardWithProgressBar'

/** Icons */
import {
  HiSpeakerphone,
  HiTag,
  HiInformationCircle,
  HiHome,
  HiShoppingCart,
  HiKey,
  HiHeart,
  HiQuestionMarkCircle,
} from 'react-icons/hi'
import Badge from '../../@core/components/Badge'

const InfoResume = ({ data, expand, handleExpand, handleFilter }) => {
  const [reserved, setReserved] = useState(0)
  const [offer, setOffer] = useState(0)
  const [gasolina, setGasolina] = useState(0)
  const [diesel, setDiesel] = useState(0)
  const [total, setTotal] = useState(0)
  const [available, setAvailable] = useState(0)
  const [km0, setKm0] = useState(0)
  const [electric, setElectric] = useState(0)
  const [hybrid, setHybrid] = useState(0)
  const [compact, setCompact] = useState(0)
  const [berlina, setBerlina] = useState(0)
  const [family, setFamily] = useState(0)
  const [miniVan, setMiniVan] = useState(0)
  const [cabrio, setCabrio] = useState(0)
  const [coupe, setCoupe] = useState(0)
  const [suv, setSuv] = useState(0)
  const [van, setVan] = useState(0)
  const [km0Web, setKm0Web] = useState(0)
  const [ocasionWeb, setOcasionWeb] = useState(0)
  const [checkBodyType, setCheckBodyType] = useState(0)
  const [checkPrices, setCheckPrices] = useState(0)
  const [checkWarranty, setCheckWarranty] = useState(0)
  const [counterActivate, setCounterActivate] = useState(0)
  const [discountActivate, setDiscountActivate] = useState(0)
  const [disclaimerActivate, setDisclaimerActivate] = useState(0)
  const [activeFilter, setActiveFilter] = useState('Todos')
  const [active, setActive] = useState({
    activeTotal: false,
    activeAvailable: false,
    activeReserved: false,
    activeOffer: false,
    activeKm0: false,
    activeDemo: false,
    activeCourtesy: false,
    activeOccasion: false,
    activeGasolina: false,
    activeDiesel: false,
    activeElectric: false,
    activeHybrid: false,
    activeCampaign: false,
    activeCompact: false,
    activeBerlina: false,
    activeFamily: false,
    activeMiniVan: false,
    activeCabrio: false,
    activeCoupe: false,
    activeSuv: false,
    activeVan: false,
    activeKm0Web: false,
    activeOcasionWeb: false,
    activeCheckBodyType: false,
    activeCheckPrices: false,
    activeCheckWarranty: false,
    activeCounter: false,
  })

  useEffect(() => {
    setReserved(0)
    setOffer(0)
    setGasolina(0)
    setDiesel(0)
    setTotal(0)
    setAvailable(0)
    setKm0(0)
    setElectric(0)
    setHybrid(0)
    setCompact(0)
    setBerlina(0)
    setFamily(0)
    setMiniVan(0)
    setCabrio(0)
    setCoupe(0)
    setSuv(0)
    setVan(0)
    setKm0Web(0)
    setOcasionWeb(0)
    setCheckBodyType(0)
    setCheckPrices(0)
    setCheckWarranty(0)
    setCounterActivate(0)
    setDiscountActivate(0)
    setDisclaimerActivate(0)

    for (const object in data) {
      setTotal((total) => total + 1)
      if (data[object].offerType === 'KM0') {
        setKm0((km0) => km0 + 1)
      }
      if (data[object].reserved === false) {
        setAvailable((available) => available + 1)
      }
      if (data[object].reserved === true) {
        setReserved((reserved) => reserved + 1)
      }
      if (data[object].featuredHome === true) {
        setOffer((offer) => offer + 1)
      }
      if (data[object].fuel === 'gasolina') {
        setGasolina((gasolina) => gasolina + 1)
      }
      if (data[object].fuel === 'diesel') {
        setDiesel((diesel) => diesel + 1)
      }
      if (data[object].fuel === 'electrico') {
        setElectric((electric) => electric + 1)
      }
      if (data[object].fuel === 'hibrido') {
        setHybrid((hybrid) => hybrid + 1)
      }
      if (data[object].bodyType === 'compacto') {
        setCompact((compact) => compact + 1)
      }
      if (data[object].bodyType === 'berlina') {
        setBerlina((berlina) => berlina + 1)
      }
      if (data[object].bodyType === 'familiar') {
        setFamily((family) => family + 1)
      }
      if (data[object].bodyType === 'monovolumen') {
        setMiniVan((miniVan) => miniVan + 1)
      }
      if (data[object].bodyType === 'cabrio') {
        setCabrio((cabrio) => cabrio + 1)
      }
      if (data[object].bodyType === 'coupe') {
        setCoupe((coupe) => coupe + 1)
      }
      if (data[object].bodyType === 'furgoneta') {
        setVan((van) => van + 1)
      }
      if (data[object].bodyType === 'suv-4x4') {
        setSuv((suv) => suv + 1)
      }
      if (data[object].offerType === 'KM0') {
        setKm0Web((km0Web) => km0Web + 1)
      }
      if (data[object].offerType === 'Ocasión') {
        setOcasionWeb((ocasionWeb) => ocasionWeb + 1)
      }
      if (data[object].marketing?.counter) {
        setCounterActivate((counterActivate) => counterActivate + 1)
      }
      if (
        data[object].discount?.active === 'true' ||
        data[object].discount?.active === true
      ) {
        setDiscountActivate((counterActivate) => counterActivate + 1)
      }
      if (data[object].marketing?.disclaimerMessage?.length > 0) {
        setDisclaimerActivate((counterActivate) => counterActivate + 1)
      }
      /*       if (data[object].tipoCarroceriaWeb === 'Revisar') {
        setCheckBodyType((checkBodyType) => checkBodyType + 1)
      }
      if (
        data[object].precios[0].valor <= 100 ||
        data[object].precios[3].valor <= 100
      ) {
        setCheckPrices((checkPrices) => checkPrices + 1)
      } */
      if (data[object].warranty < 12) {
        console.log(parseInt(data[object].warranty))
        setCheckWarranty((checkWarranty) => checkWarranty + 1)
      }
    }
  }, [data])

  const handleClickCard = (filterValue) => {
    handleExpand(false)
    handleFilter(filterValue)
    setActiveFilter(filterValue)
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <div className='w-full flex gap-4 p-4'>
          <KpiCardWithProgressBar
            className='bg-green-100 text-green-500'
            icon={<HiShoppingCart />}
            title='Stock total'
            value={total}
            filterValue='total'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-red-100 text-red-500'
            icon={<HiKey />}
            title='Disponibles'
            value={available}
            filterValue='available'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-pink-100 text-pink-500'
            icon={<HiHeart />}
            title='Reservados'
            value={reserved}
            filterValue='reserved'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-blue-100 text-blue-500'
            icon={<HiHome />}
            title='Destacado en la home'
            value={offer}
            filterValue='offer'
            handleClickOffer={handleClickCard}
          />
        </div>
        <div className='w-full flex gap-4 p-4'>
          <KpiCardWithProgressBar
            className='bg-red-100 text-red-500'
            icon={<HiTag />}
            title='Contador activado'
            value={counterActivate}
            filterValue='counterActivated'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-indigo-100 text-indigo-500'
            icon={<HiSpeakerphone />}
            title='Descuento de campaña activado'
            value={discountActivate}
            filterValue='discountActivated'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-yellow-100 text-yellow-500'
            icon={<HiInformationCircle />}
            title='Disclaimer activado'
            value={disclaimerActivate}
            filterValue='disclaimerActivated'
            handleClickOffer={handleClickCard}
          />
          <KpiCardWithProgressBar
            className='bg-yellow-100 text-yellow-500'
            icon={<HiQuestionMarkCircle />}
            title='¿?'
            value={0}
            filterValue='disclaimerActivated'
            handleClickOffer={handleClickCard}
          />
        </div>
      </Grid>
      {/*       <TypeVoQuiter
        active={active}
        handleClickCard={handleClickCard}
        dataKm0={km0}
        dataDemo={demo}
        dataCourtesy={courtesy}
        dataOcasion={ocasion}
      /> */}
      <TypeWebVehicle
        active={active}
        handleClickCard={handleClickCard}
        dataOcasionWeb={ocasionWeb}
        dataKm0Web={km0Web}
      />
      <BodyType
        active={active}
        handleClickCard={handleClickCard}
        compact={compact}
        berlina={berlina}
        family={family}
        miniVan={miniVan}
        cabrio={cabrio}
        coupe={coupe}
        suv={suv}
        van={van}
      />
      <Fuel
        active={active}
        handleClickCard={handleClickCard}
        gasolina={gasolina}
        diesel={diesel}
        hybrid={hybrid}
        electric={electric}
      />
      {/*       <Campaigns
        active={active}
        handleClickCard={handleClickCard}
        campaign={campaign}
      /> */}
      <Reviews
        active={active}
        handleClickCard={handleClickCard}
        checkPrices={checkPrices}
        checkBodyType={checkBodyType}
        checkWarranty={checkWarranty}
      />
      <div className='flex mt-8'>
        <Badge>{activeFilter}</Badge>
      </div>
    </Box>
  )
}

export default InfoResume
