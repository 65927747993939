import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"
import { FormControlLabel, Checkbox, Divider } from "@material-ui/core"
import ChildTextField from '../../ChildTextField'

const useStyles = makeStyles((theme) => ({
  imageSection: {
    padding: '12px',
    margin: '12px',
    background: '#fafafa',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  }
}))

const searchValueInObject = (object, query) => {
  for(let i in object) {
    if(object[i]['nombre'] === query) {
      return object[i]['valor']
    }
  }
}

const isTrueSet = (value) => {
  if(value === 'true' || value === true) {
    return true
  } else {
    return false
  }
}

const MotoPhoto = ({ image, handleChangeImageProperties }) => {
 // const [isPrinciple, setIsPrinciple] = useState(image.propiedades[0].valor || false )
 // const [numImage, setNumImage] = useState(image.propiedades[1].valor || 0)
  const classes = useStyles()
  
  const handleChangeCheckBox = (event) => {
    image.propiedades[0]['valor'] = event.target.checked
    console.log(event.target.checked)
   // setIsPrinciple(event.target.checked)
    handleChangeImageProperties(image)
  }

  const handleChangeInput = (event) => {
    image.propiedades[1].valor = event.target.value
   // setNumImage(event.target.value)
    handleChangeImageProperties(image)
  }

  return (
    <div className={classes.imageSection}>
      <img src={image.url} alt={image.nombre} width='100%' />
      <p style={{ marginBottom: '12px' }}>Nombre de imagen: {image.nombre}</p>
      <Divider  />
      <FormControlLabel
          control={
            <Checkbox
             // value={isTrueSet(isPrinciple)}
             // checked={isTrueSet(isPrinciple)}
              onChange={handleChangeCheckBox}
              name="EsFotoPrincipal"
              color="primary"
            />
          }
          label='Foto principal'
      />
      <ChildTextField
        name='OrdenFoto'
        label='Orden Foto'
      //  defaultValue={numImage}
        handleInputTextChange={handleChangeInput}
      />
    </div>
  )
}

export default MotoPhoto