import { useContext } from "react"
import { AccordionDetails, Grid, Chip } from "@mui/material"
import {
  StyledBox,
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  EyeIcon,
  StyledCheckBox,
  StyledCard,
  EuroIcon,
  CarIcon,
} from "./styles"
import { valid_colors } from "../../data"
/* import Prices from "./Prices"
import BodyWork from "./BodyWork"
import Warranty from "./Warranty" */

const Reviews = ({ context }) => {
  const { data, setDataFilter, active, setActive } = useContext(context)

  const activeStyle = (filter) => {
    console.log(filter)
    for (let property in active) {
      if (property === filter) {
        active[property] = true
      } else {
        active[property] = false
      }
    }
    setActive({ ...active })
  }

  const handleClickCard = (activeValue, filter) => {
    setDataFilter(setFilters(filter))
    // console.log(activeValue, filter)
    if (active[activeValue] === false) {
      activeStyle(activeValue)
    } else {
      setActive({ ...active, [activeValue]: false })
    }
  }

  const setColor = (colors, searchColor) => {
    if (searchColor.length) {
      const colorArray = searchColor.toUpperCase().split(" ")
      let key
      colors.forEach((e) => {
        colorArray.map((c) => {
          if (e.indexOf(c) !== -1) {
            key = e[e.length - 1]
          }
          return 0
        })
      })
      return key
    }
  }

  const setFilters = (type) => {
    const filters = {
      reviewsPrices: () => {
        return data.filter(
          (e) =>
            (e.precios && e.precios[0].valor) <= 100 ||
            (e.precios && e.precios[3].valor) <= 100
        )
      },
      reviewsWarranties: () => {
        return data.filter((e) => e.garantia < 12)
      },
      total: () => {
        return (
          setFilters("reviewsPrices").length +
          setFilters("reviewsWarranties").length +
          setFilters("reviewsColors").length
        )
      },
      reviewsColors: () => {
        return data.filter((e) => {
          return setColor(valid_colors, e.color) === undefined ? e : 0
        })
      },
    }
    return filters[type]()
  }

  return (
    <StyledBox>
      <StyledAccordion>
        <StyledSummary>
          <EyeIcon size='28px' color='#d13333' />
          <StyledSummaryTitle>Revisiones</StyledSummaryTitle>
          <StyledCheckBox>
            {setFilters("total") > 0 && (
              <Chip color='error' size='small' label={setFilters("total")} />
            )}
          </StyledCheckBox>
        </StyledSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <StyledCard
                active={active.reviewsPrices}
                onClick={() =>
                  handleClickCard("reviewsPrices", "reviewsPrices")
                }
              >
                <EuroIcon color='red' />
                <div>
                  <h3>
                    <span>{setFilters("reviewsPrices").length}</span>
                  </h3>
                  <p>Precios</p>
                </div>
              </StyledCard>
            </Grid>
            <Grid item xs={3}>
              <StyledCard
                active={active.reviewsWarranties}
                onClick={() =>
                  handleClickCard("reviewsWarranties", "reviewsWarranties")
                }
              >
                <CarIcon color='red' />
                <div>
                  <h3>
                    <span>{setFilters("reviewsWarranties").length}</span>
                  </h3>
                  <p>Garantías</p>
                </div>
              </StyledCard>
            </Grid>
            <Grid item xs={3}>
              <StyledCard
                active={active.reviewsColors}
                onClick={() =>
                  handleClickCard("reviewsColors", "reviewsColors")
                }
              >
                <CarIcon color='red' />
                <div>
                  <h3>
                    <span>{setFilters("reviewsColors").length}</span>
                  </h3>
                  <p>Color</p>
                </div>
              </StyledCard>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </StyledBox>
  )
}

export default Reviews
