const Peugeot = ({ width }) => {
  return (
    <img
      alt='Peugeot'
      src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1635937701/Multipublicador%20GrupOlivaMotor/peugeot_logo_black_my9zdv.svg'
      width={width}
    />
  )
}

export default Peugeot
