import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const read = (path) => {
  if (path) {
    const request = http.get(path)
    return request.then((response) => response.data)
  } else {
    const request = http.get('/publishing/api/v1/wallapop/vehicles')
    return request.then((response) => response.data)
  }
}

const create = (data) => {
  const request = http.post('/publishing/api/v1/wallapop/vehicles', data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/publishing/api/v1/wallapop/vehicles/${id}`)
  return request.then((response) => response.data)
}

/* const create = (path, data) => {
  if (path) {
    const request = http.post(path, data)
    return request.then((response) => response.data)
  }
  const request = http.post('/wallapop', data)
  return request.then((response) => response.data)
} */

const update = (path, id, data) => {
  if (path) {
    const request = http.put(`/wallapop/${path}/${id}`, data)
    return request.then((response) => response.data)
  } else {
    const request = http.put(`/wallapop/${id}`, data)
    return request.then((response) => response.data)
  }
}

/* const remove = (path, id) => {
  if (path) {
    const request = http.delete(`/wallapop/${path}/${id}`)
    return request.then((response) => response.data)
  } else {
    const request = http.delete(`/wallapop/${id}`)
    return request.then((response) => response.data)
  }
} */

const wallapopSync = () => {
  const request = http.get('/wallapop/sync')
  return request.then((response) => response.data)
}

const wallapopDrop = (path) => {
  const request = http.get(`/wallapop/${path}/drop`)
  return request.then((response) => response.data)
}

const WallapopService = {
  read,
  create,
  update,
  remove,
  wallapopSync,
  wallapopDrop,
}

export default WallapopService
