import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { useState } from "react"
import GomotorService from "../../../../../services/gomotor.service"
import ConfirmModal from "./ConfirmModal"

const EditPrices = ({ open, dataSelection, handleCloseModal }) => {
  const [typePrice, setTypePrice] = useState("")
  const [price, setPrice] = useState("")

  const handleChange = (event) => {
    setTypePrice(event.target.value)
  }

  const handleChangePrice = (event) => {
    setPrice(event.target.value)
  }

  const update = () => {
    console.log(price)
    console.log(dataSelection)
    const data = {
      dataSelection,
      update: typePrice,
      value: price
    }
    GomotorService.bulkIncDre(data)
    .then((result) => {
      window.location.reload()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <div>
      <FormControl>
        <FormGroup>
          <InputLabel>Seleccionar tipo de precio:</InputLabel>
          <Select
            style={{ width: "250px", marginRight: "45px" }}
            onChange={handleChange}
          >
            <MenuItem value='precios.0.valor'>Contado</MenuItem>
            <MenuItem value='precios.3.valor'>Financiado</MenuItem>
          </Select>
        </FormGroup>
      </FormControl>
      {typePrice ? (
        <TextField
          label='Sumar o Restar importe'
          style={{ marginRight: "45px" }}
          type='number'
          onChange={handleChangePrice}
        />
      ) : (
        ""
      )}
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Modificar precio de reserva`}
        handleCloseModal={handleCloseModal}
        handleActivate={update}
        handleDeactivate={update}
      />
    </div>
  )
}

export default EditPrices
