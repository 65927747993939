import { createContext, useState } from 'react'

export const AlertContext = createContext()

export const AlertContextProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState(undefined)
  const [alertMessage, setAlertMessage] = useState('')

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        setShowAlert,
        typeAlert,
        setTypeAlert,
        alertMessage,
        setAlertMessage,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}
