import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const read = () => {
  const request = http.get('/grupolivamotor')
  return request.then(response => response.data)
}

const create = (data) => {
  const request = http.post('/grupolivamotor', data)
  return request.then(response => response.data)
}

const deleteId = (id) => {
  const request = http.delete(`/grupolivamotor/${id}`)
  return request.then(response => response.data)
}

const update = (id, data) => {
  const request = http.put(`/grupolivamotor/${id}`, data)
  return request.then(response => response.data)
}

const getIDV = (idv) => {
  const request = http.get(`/grupolivamotor?filter={"_id": "${idv}"}`)
  return request.then(response => response.data)
}

const wooSync = () => {
  const request = http.get('/grupolivamotor/woocommerce-sync')
  return request.then(response => response.data)
}

const bulkCreate = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/grupolivamotor/bulk-create', { data }, { headers: header})
  return request.then(response => response.data)
}

const bulkUpdate = (data) => {
  const header = {
    'Content-Length': '200000',
    'Content-Type': 'application/json',
  }
  const request = http.post('/grupolivamotor/bulk-update', data, { headers: header})
  return request.then(response => response.data)
}

const updateDb = () => {
  const request = http.get('/grupolivamotor/update-db')
  return request.then(response => response.data)
}

const viewLog = () => {
  const request = http.get('/grupolivamotor/log')
  return request.then(response => response.data)
}

const GrupOlivaMotorService = {
  read,
  create,
  update,
  getIDV,
  deleteId,
  wooSync,
  bulkUpdate,
  bulkCreate,
  updateDb,
  viewLog
}


export default GrupOlivaMotorService
