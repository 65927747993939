import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ChildTextArea from "../../ChildTextArea"
import PageTopTitle from "../../PageTopTitle"
import ChildSwitch from "../../ChildSwicth"
import RentingService from "../../../services/renting.service"
import { Paper, Divider } from "@material-ui/core"
import VehicleImages from './../Renting/VehicleImages'
import AlertBox from "../../AlertBox"
import SnackBarAlert from "../../SnackbarAlert"
import UploadImageButton from "../../buttons/UploadImageButton"
import SubmitButton from "../../buttons/SubmitButton"
import UploadFileService from "../../../services/upload-file.service"
import InputsConsumptions from "./components/InputsConsumptions"
import InputsGenerals from "./components/InputsGenerals"
import InputsEmissions from "./components/InputsEmissions"
import InputsPrices from "./components/InputsPrices"
import InputDimensions from "./components/InputsDimensions"
import InputsContacte from "./components/InputsContacte"
import InputsEquipments from "./components/InputsEquipment"
import ImageCard from "./components/ImageCard"
import GomotorService from '../../../services/gomotor.service'

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text], input[type=date]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
    marginTop: "12px",
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  propTitle: {
    marginTop: "10px",
    marginBottom: "25px",
  },
  textAreaWrapper: {
    width: "90%",
    margin: "0 auto",
  },
}))

const GomotorAddCarForm = () => {
  const [data, setData] = useState({})
  const [expanded, setExpanded] = useState(true)
  const [open, setOpen] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [recursos, setRecursos] = useState([])
  const [consumption, setConsumption] = useState([])
  const [emissions, setEmissions] = useState([])
  const [prices, setPrices] = useState([])
  const [dimensions, setDimensions] = useState([])
  const [contacte, setContacte] = useState([])

  const classes = useStyles()

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const handleChangeReserved = (value) => {
    data.reservado = value
    setData({ ...data })
  }

  const handleChangeManuallyPublished = (value) => {
    data.publicacionManual = value
    setData({ ...data })
  }

  const handleChangeImportant = (value) => {
    data.destacado = value
    setData({ ...data })
  }

  const handleDateChange = (date) => {
    data.fechaMatriculacion = new Date(date).toISOString()
  }

  const handleInputTextChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputConsumptionChange = (event) => {
    const newConsumption = {
      tipoConsumo: event.target.name,
      valor: event.target.value
    }

    if(consumption.filter((e) => e.tipoConsumo === newConsumption.tipoConsumo).length > 0) {
      consumption.pop(newConsumption)
      consumption.push(newConsumption)
      setConsumption([...consumption])
    } else {
      consumption.push(newConsumption)
      setConsumption([...consumption])
    }
    data.consumos = consumption
    setData({...data})
  }

  const handleInputChangeEmissions = (event) => {
    const newEmission = {
      normativa: event.target.name === 'normativa' ? event.target.value : emissions.normativa,
      valor: event.target.name === 'valor' ? event.target.value : emissions.valor
    }
    setEmissions(newEmission)
    data.emisiones = newEmission
    setData({...data})
  }

  const handleInputChangePrice = (event) => {
    const newPrice = {
      tipo: event.target.name,
      valor: parseInt(event.target.value)
    }
 
    if (prices.filter((e) => e.tipo === newPrice.tipo).length > 0) {
      prices.pop(newPrice)
      prices.push(newPrice)
      setPrices([...prices])
    } else {
      prices.push(newPrice)
      setPrices([...prices])
      //setPricesList(pricesList.concat(price))
    }
    // setPrices(prices)
    data.precios = prices
    setData({ ...data })
  }

  const handleInputChangeDimensions = (event) => {
    const newDimension = {
      largo: event.target.name === 'largo' ? event.target.value : dimensions.largo,
      ancho: event.target.name === 'ancho' ? event.target.value : dimensions.ancho,
      alto: event.target.name === 'alto' ? event.target.value : dimensions.alto,
      volumenMaletero: event.target.name === 'volumenMaletero' ? event.target.value : dimensions.volumenMaletero,
      capacidadDeposito: event.target.name === 'capacidadDeposito' ? event.target.value : dimensions.capacidadDeposito,
      neumaticoDelantero: event.target.name === 'neumaticoDelantero' ? event.target.value : dimensions.neumaticoDelantero,
      neumaticoTrasero: event.target.name === 'neumaticoTrasero' ? event.target.value : dimensions.neumaticoTrasero,
      medidasNeumaticos: event.target.name === 'medidasNeumaticos' ? event.target.value: dimensions.medidasNeumaticos,
    }
    setDimensions(newDimension)
    data.dimensiones = newDimension
    setData({...data})
  }

  const handleInputChangeContacte = (event) => {
    const newContacte = {
      nombreVendedor: event.target.name === 'nombreVendedor' ? event.target.value : contacte.nombreVendedor,
      telefonoVendedor: event.target.name === 'telefonoVendedor' ? event.target.value : contacte.telefonoVendedor
    }
    setContacte(newContacte)
    data.vehiculoContacto = newContacte
    setData({...data})
  }
  
  const handleInputTextAreaChange = (event) => {
    data.observaciones = event.target.value
    console.log(data.observaciones)
    setData({ ...data })
  }
  
  const handleAddEquipment = (equipments) => {
    data.equipamiento = equipments
    setData({...data})
  }

  const handleSaveButton = (event) => {
    event.preventDefault()
    const isFormValidate = validateForm()
    if (isFormValidate) {
      GomotorService.create(data)
        .then((result) => {
          console.log(result)
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
            window.location.replace("/stock-gomotor")
          }, 3000)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setOpenError(true)
      setTimeout(() => {
        setOpenError(false)
      }, 3000)
    }
  }

  const handleUploadImage = (image, blob) => {
    const name = `${data.marca}-${data.modelo}-${image.lastModified}`
    
    const newImage = {
      nombre: name,
      url: process.env.REACT_APP_API_URL + `uploads/images/gomotorcars/${name}`,
      blob: blob,
      propiedades: [
        {
          nombre: "EsFotoPrincipal",
          valor: false,
        },
        {
          nombre: "OrdenFoto",
          valor: "",
        },
      ],
    }
    recursos.push(newImage)
    setRecursos(recursos)
    data.recursos = recursos
    setData({...data})

/*     UploadFileService.uploadImage(image, name)
      .then((result) => {
        if (result.status === 200) {
          const newImage = {
            nombre: name,
            url: process.env.REACT_APP_API_URL + `uploads/images/${name}`,
            tipo: "IMAGEN",
            propiedades: [
              {
                nombre: "EsFotoPrincipal",
                valor: false,
              },
              {
                nombre: "OrdenFoto",
                valor: "",
              },
            ],
          }
          setRecursos(recursos.concat(newImage))
        }
      })
      .catch((err) => {
        console.log(err)
      }) */
  }

  const handleChangeImageProperties = (image) => {
    console.log("Propiedades de imagen cambiadas!!!")
    console.log(image)

    for (let i = 0; i < recursos.length; i++) {
      if (recursos[i].nombre === image.nombre) {
        recursos[i].propiedades = image.propiedades
      }
    }
    data.recursos = recursos
    setData({ ...data })
  }

  const handleDeleteImage = (imageName) => {
    let filterImages = recursos.filter(
      (element) => element.nombre !== imageName
    )
    setRecursos(filterImages)
  }

  const validateForm = () => {
    if (data.marca === undefined || data.marca === "") {
      return false
    } else if (data.modelo === undefined || data.modelo === "") {
      return false
    } else {
      return true
    }
  }

  console.log("componente renderizado!!!")
  console.log(data)

  return (
    <div>
      <PageTopTitle title='Crear vehículo manual en GOmotor.com'>
        <ChildSwitch
          value={data.destacado}
          name='destacado'
          label='Destacado'
          handleChangeSwitch={handleChangeImportant}
        />
        <ChildSwitch
          value={data.publicacionManual}
          name='publicacionManual'
          label='Publicar Manual'
          handleChangeSwitch={handleChangeManuallyPublished}
        />
        <ChildSwitch
          value={data.reservado}
          name='reservado'
          label='Reservado'
          handleChangeSwitch={handleChangeReserved}
        />
      </PageTopTitle>
      <form
        className={classes.form}
        autoComplete='off'
        onSubmit={handleSaveButton}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            {/* --- DATOS GENERALES DEL VEHICULO --- */}
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={handleExpand}
              >
                <Typography variant='h6' component='h3'>
                  Datos generales del Vehículo
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputsGenerals
                  handleInputTextChange={handleInputTextChange}
                  handleDateChange={handleDateChange}
                />
              </AccordionDetails>
            </Accordion>
            {/* --- CONSUMOS --- */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Consumos
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputsConsumptions
                  handleInputConsumptionChange={handleInputConsumptionChange}
                />
              </AccordionDetails>
            </Accordion>
            {/*  --- EMISIONES --- */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Emisiones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputsEmissions
                  handleInputChangeEmissions={handleInputChangeEmissions}
                />
              </AccordionDetails>
            </Accordion>
            {/* --- PRECIOS --- */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Precios
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputsPrices
                  handleInputChangePrice={handleInputChangePrice}
                />
              </AccordionDetails>
            </Accordion>
            {/* --- DIMENSIONES */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Dimensiones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputDimensions
                  handleInputChangeDimensions={handleInputChangeDimensions}
                />
              </AccordionDetails>
            </Accordion>
            {/* --- VEHICULO CONTACTO */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Vehículo contacto
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InputsContacte
                  handleInputChangeContacte={handleInputChangeContacte}
                />
              </AccordionDetails>
            </Accordion>
            {/* --- OBSERVACIONES --- */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Observaciones
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={`${classes.textAreaWrapper}`}>
                <ChildTextArea
                  name='observaciones'
                  defaultValue='Observaciones aquí'
                  handleInputTextAreaChange={handleInputTextAreaChange}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6' component='h3'>
                  Equipamiento
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={`${classes.textAreaWrapper}`}>
                <InputsEquipments
                  handleAddEquipment={handleAddEquipment}
                  equipment={[]}
                />
              </AccordionDetails>
            </Accordion>
            <SubmitButton text='Guardar Vehículo' />
            <UploadImageButton
              handleUploadImage={handleUploadImage}
            />
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography
                variant='h6'
                component='h2'
                align='center'
                className={classes.propTitle}
              >
                Imágenes del vehículo
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
              {recursos.length === 0
                  ? <AlertBox type='warning' text='Este vehículo no tiene fotos' />
                  : (
                    recursos.map((image) => (
                      <ImageCard
                        key={image.name}
                        image={image}
                        handleChangeImageProperties={handleChangeImageProperties}
                        handleDeleteImage={handleDeleteImage}
                      />
                    ))
                  )
                }
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
      <SnackBarAlert isOpen={open} type='success' text='Vehículo guardado 😄' />
      <SnackBarAlert
        isOpen={openError}
        type='error'
        text='Todos los campos con * son necesarios 😒'
      />
    </div>
  )
}
export default GomotorAddCarForm
