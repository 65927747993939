import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL + 'signin'

const login = async credentials => {
  const { data } = await axios.post(API_URL, credentials)
  return data
}

const logout = () => {
  localStorage.removeItem('usuario')
  window.location.reload()
}

export default { login, logout }