import { AccordionDetails, Grid } from "@mui/material"
import Diesel from "./Diesel"
import Gasoline from "./Gasoline"
import Hybrid from "./Hybrid"
import Electric from "./Electric"
import { StyledAccordion, StyledSummaryTitle, StyledSummary, FuelIcon } from "./styles"

const BrandsLandings = ({
  dataGasoline,
  dataDiesel,
  dataHybrid,
  dataElectric,
  handleClickCard,
  active,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <FuelIcon size="28px" color="gray"/>
        <StyledSummaryTitle>Combustible</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Gasoline
              data={dataGasoline}
              active={active}
              handleClick={() => handleClickCard("activeGasolina", "gasolina")}
            />
          </Grid>
          <Grid item xs={3}>
            <Diesel
              data={dataDiesel}
              active={active}
              handleClick={() => handleClickCard("activeDiesel", "diesel")}
            />
          </Grid>
          <Grid item xs={3}>
            <Hybrid
              data={dataHybrid}
              active={active}
              handleClick={() => handleClickCard("activeHybrid", "hybrid")}
            />
          </Grid>
          <Grid item xs={3}>
            <Electric
              data={dataElectric}
              active={active}
              handleClick={() => handleClickCard("activeElectric", "electric")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default BrandsLandings
