import React, { useContext, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MSelect,
} from '@mui/material'
import SubmitButton from '../../../buttons/SubmitButton'
import CnetService from '../../services/cnet.service'
import { accounts } from '../../data'

const SelectCnetAccount = ({ context }) => {
  const { selectData, setShowAlert, setTypeAlert, setAlertMessage } =
    useContext(context)
  const [loading, setLoading] = useState(false)
  const [responses, setResponses] = useState([])
  const [account, setAccount] = useState('0')

  function delay(delay) {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay)
    })
  }

  const createLoop = async (data) => {
    const allResponses = []
    for (let i = 0; i < data.length; i++) {
      try {
        const response = await CnetService.createApi(data[i], account)
        setShowAlert(true)
        setTypeAlert('info')
        setAlertMessage(`Exportando vehículo: ${i + 1} de ${data.length}`)
        allResponses.push({
          status: 200,
          idv: response.internalReference,
          message: 'Vehicle created successfully',
        })
      } catch (error) {
        console.log(error.response)
        const message = error.response.data.error
          ? error.response.data.error
          : error.response.statusText
        allResponses.push({
          status: error.response.status,
          idv: data[i].idv,
          message,
        })
        setShowAlert(true)
        setTypeAlert('info')
        setAlertMessage(`Exportando vehículo: ${i + 1} de ${data.length}`)
      }
      await delay(4000)
    }
    setResponses(allResponses)
    await CnetService.createAction({
      type: 'create',
      account,
      data: allResponses,
    })
    return allResponses
  }

  const handleButton = () => {
    console.log(selectData)
    setLoading(true)
    console.log(selectData.length)
    if (selectData.length > 1) {
      createLoop(selectData).then((result) => {
        setLoading(false)
        console.log(result)
      })
    } else {
      CnetService.createApi(selectData[0], account)
        .then((result) => {
          console.log(result)
          setLoading(false)
          setShowAlert(true)
          setTypeAlert('success')
          setAlertMessage(`Vehículo creado correctamente: ${result.title}`)
        })
        .catch((err) => {
          alert(err.response.data.error)
          setLoading(false)
        })
    }
  }

  const handleAccount = (event) => {
    setAccount(event.target.value)
  }

  return (
    <React.Fragment>
      <b>Seleccionar cuenta de destino:</b>
      <FormControl style={{ width: '300px' }}>
        <InputLabel style={{ fontSize: '14px', left: '25px' }} id='account'>
          Cuenta
        </InputLabel>
        <MSelect
          name='account'
          labelId='account'
          id='action'
          label='account'
          size='small'
          value={account}
          style={{ margin: '5px 25px 5px 25px' }}
          onChange={handleAccount}
        >
          <MenuItem value='0'>
            <em>Cuenta</em>
          </MenuItem>
          {accounts.map((account) => {
            return (
              <MenuItem key={account.key} value={account.key}>
                {account.value}
              </MenuItem>
            )
          })}
        </MSelect>
      </FormControl>
      <SubmitButton
        loading={loading}
        text='Aplicar acción'
        handleClick={handleButton}
      />
    </React.Fragment>
  )
}

export default SelectCnetAccount
