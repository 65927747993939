import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: '100%'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    padding: theme.spacing(3),
    backgroundColor: 'whitesmoke'
  },
  pageContainer: {
    width: '100%',
    display: 'flex',
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "& p": {
      margin: '4px',
      color: '#535353'
    }
  }
}))

export default useStyles
