import { useState } from 'react'
import ConfirmModal from './ConfirmModal'
import GomotorService from '../../services'
import Label from '../../../../@core/components/Label'

const DisclaimerMessage = ({
  settings,
  open,
  dataSelection,
  handleCloseModal,
  isActivated,
}) => {
  const [value, setValue] = useState('')
  const [selectMessage, setSelectMessage] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
    console.log(event.target.value)
    setSelectMessage(event.target.value)
  }

  const enable = async () => {
    await Promise.all(
      dataSelection.map((e) =>
        GomotorService.update(e.idv, {
          $set: { 'marketing.disclaimerMessage': selectMessage },
        })
      )
    )

    handleCloseModal()
  }

  const disable = async () => {
    await Promise.all(
      dataSelection.map((e) =>
        GomotorService.update(e.idv, {
          $set: { 'marketing.disclaimerMessage': null },
        })
      )
    )

    handleCloseModal()
  }

  return (
    <div className='flex gap-4 w-1/2'>
      <div className='w-full'>
        <Label htmlFor='disclaimerMessage' value='Asignar mensaje' />
        <select
          className='ui-input'
          name='disclaimerMessage'
          onChange={(event) => handleChange(event)}
          value={value}
        >
          <option value='null'>Selecciona un mensaje</option>
          {settings[0]?.disclaimerMessages.map((element) =>
            element ? (
              <option value={element} key={element}>
                {element}
              </option>
            ) : (
              ''
            )
          )}
        </select>
      </div>
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Activar campaña`}
        handleCloseModal={handleCloseModal}
        handleActivate={enable}
        handleDeactivate={disable}
        isActivated={isActivated}
      />
    </div>
  )
}

export default DisclaimerMessage
