import { Button } from "@material-ui/core"
import VisibilityIcon from '@material-ui/icons/Visibility'



const columns = [
  {
    title: 'IMAGEN',
    field: 'images',
    render: rowData => (
      <img src={rowData.images[0]} style={{ width: 150 }} />
    ),
    /*     width: '15%',
        cellStyle: { whiteSpace: 'nowrap' }, */
    // lookup: { 'VO': 'VO', 'VN': 'VN'},
    // width: '15%',
  },
  {
    title: 'IDV',
    field: 'idv',
    width: '15%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'MATRICULA',
    field: 'plate',
    // hidden: true
  },
  {
    title: 'TIPO DE OFERTA',
    field: 'offerType',
    /* lookup: { 'KM0': 'VO', 'VN': 'VN'}, */
    width: '15%',
    cellStyle: { whiteSpace: 'nowrap' },
    hidden: true
  },
  {
    title: 'CARROCERIA',
    field: 'bodyType',
    render: rowData => (
      rowData.bodyType ? `${rowData.bodyType}`.toLocaleUpperCase() : rowData.bodyType = '0'
    ),
/*     width: '15%',
    cellStyle: { whiteSpace: 'nowrap' }, */
    // lookup: { 'VO': 'VO', 'VN': 'VN'},
    // width: '15%',
    hidden: true
  },
  {
    title: 'MARCA',
    field: 'brand',
    render: rowData => (
      rowData.brand ? `${rowData.brand}`.toLocaleUpperCase() : rowData.brand = '0'
    ),
  },
  {
    title: 'MODELO',
    field: 'model',
    render: rowData => (
      rowData.model ? `${rowData.model}`.toLocaleUpperCase() : rowData.model = '0'
    ),
  },
  {
    title: 'COMBUSTIBLE',
    field: 'fuel',
    hidden: true,
    render: rowData => (
      rowData.fuel ? `${rowData.fuel}`.toLocaleUpperCase() : rowData.fuel = '0'
    ),
  },
  {
    title: 'CAMBIO',
    field: 'transmissionType',
    // hidden: true
    render: rowData => (
      rowData.transmissionType ? `${rowData.transmissionType}`.toLocaleUpperCase() : rowData.transmissionType = '0'
    ),
  },
  {
    title: 'KM',
    field: 'km',
    hidden: true
  },
  {
    title: 'POT CV',
    field: 'powerCv',
    hidden: true
  },
  {
    title: 'CONTADO',
    field: 'price',
    width: '25%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'FINANCIADO',
    field: 'financedPrice',
    width: '25%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'GARANTIA',
    field: 'warranty',
    render: rowData => (
      rowData.warranty ? rowData.warranty : rowData.warranty = '0'
    ),
    width: '20%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'HOME',
    field: 'featuredHome',
    type: 'boolean',
    width: '10%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'RESERVADO',
    field: 'reserved',
    // hidden: true,
    type: 'boolean',
    width: '20%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
/*   {
    title: 'CAMPAÑA',
    field: 'campana.name',
    hidden: true
  }, */
  {
    title: "Acciones",
    field: "idv",
    render: (rowData) =>
      <a href={`/stock-gomotor/${rowData._id}`}><Button startIcon={<VisibilityIcon />} size='small' style={{ background: '#1976d2', color: 'white'}}>Ver</Button></a> 
  },

]

const courtesyTypes = [
  '21 CORTESIA PEUGEOT',
  'B10 BMW DEMO/CORTESIA',
  'B11 MINI DEMO/CORTESIA',
  'B12 MOTOS DEMO/CORTESIA',
  'C21 CORTESIA CITROEN',
  'H21 CORTESIA HONDA',
  'H22 CORTESIA HONDA',
  'P21 CORTESIA PEUGEOT'
]

const demoTypes = [
  '10 DEMO PEUGEOT',
  '19 DEMO HONDA',
  'B23 BMW MOBILITY',
  'C10 DEMO CITROEN',
  'DEM DEMO VO',
  'H10 DEMO HONDA',
  'P10 DEMO PEUGEOT',
  'B27 FLOTA INTERNA'
]

const km0Types = [
  'B2 BMW REMARKETING NSC',
  'B20 BMW TACTICO',
  'B21 MINI TACTICO',
  'B22 MOTOS TACTICO',
  'KM0 KM0 VO'
]

const ocasionTypes = [
  '',
  '11 V.O.COMPR EXT.MARCA',
  '11O VO COMPR-EXT OTRA MA',
  '12 VO PARTIC COMPRA DIR',
  '12O REBU DIRECT OTRA MAR',
  '13 VO EMPRESA',
  '13A ABONO EMP. DIRECTO',
  '13O EMPRESA DIRECT OT MA',
  '14 VO PREVER MARCA',
  '14O V.O. PREVER OTR MARC',
  '15 V.O. EMPRESAS(ANTER)',
  '16 VO REBU EN ESPERA',
  '17 VO EMPRESA COMPR DIR',
  '18 VO DEPOSITO BUYBACK',
  '2 VO EMPRESAS',
  '20 V.O.DEMO KIA',
  '2O V.O.  EMPRESA OTR MAR',
  '3 VO.COMERC.PART.MARC',
  '3O VO.COMERC.PART.OT MC',
  '4 V.O. COMERC.EMPRESAS',
  '4O V.O. COMC.EMP.OT. MC',
  '5 V.O.BUY-BACK REBU',
  '6 BUY BACK MOVE',
  '7 V.O. PREVER DIRECTO',
  '8 VO DEPOSITO PARTIC',
  '9 V.O. DEPOSITO EMPRE',
  'B1 RECOMPRA PARTICULAR VN',
  'B13 RECOMPRA EMPRESAS VN',
  //'B15 RECOMPRA DIRECTA PARTICULAR',
  'B15 COMPRA DIRECTA PARTICULAR',
  'B16 RECOMPRA EMPRESAS VN REBU',
  'B17 RECOMPRA PARTICULAR VO',
  //'B18 RECOMPRA EMPRESA VO',
  'B18 RECOMPRA EMPRESAS VO',
  'B19 RECOMPRA EMPRESAS VO REBU',
  'B24 MINI MOBILITY',
  'B26 COMPRA OTRA CONCESION',
  'B28 DEPOSITO MARCA',
  'B29 GESTION VENTA PARTICULAR',
  'B3 MINI NEXT REMARKETING',
  'B30 GESTION VENTAS EMPRESAS',
  'B31 RECOMPRA FLOTA RAC+RENTING',
  'B32 RECOMPRA ALPHABET',
  'B33 REMARKETING SUBASTA BMW',
  'B34 REMARKETING SUBASTA MINI',
  'B35 BMW CORTESIA',
  'B36 MINI CORTESIA',
  'B37 MOTOS CORTESIA',
  'B38 VO EMPRESA COMPRA DI',
  'B43 REMARKETING REBU BMW',
  'B44 REMARKETING REBU MIN',
  'B7 RECOMPRA BMW BANK PARTICULAR',
  'B8 RECOMPRA BMW BANK EMPRESA',
  'C1 VO PARTICULAR',
  'C12 VOP PARTIC COMPRA DIR',
  'C13 VO EMPRESA',
  'C14 FINANC ALTERN PARTIC',
  'C15 COMP DIR FIN ALT PAR',
  'C17 VO EMPRESA COMPR DIR',
  'C19 VO EMPRESA COMPR DIR',
  'C2 V.O. EMPRESAS',
  'C6 BUY BACK MOVE',
  'CF RETOMA FICTICIA',
  'DEP DEPOSITO',
  'F RETOMA FICTICIA',
  'H1 VO PARTICULAR',
  'H12 VO PARTIC COMPRA DIR',
  'H13 VO EMPRESA',
  'H14 HONDA OPTIONS PARTIC',
  'H15 HONDA OPTIONS EMPRES',
  'H16 HO OPTIONS PART DIRE',
  'H17 VO EMPRESA COMPRA DI',
  'H18 VO EMPRESA COMPRA DI',
  'H19 VO EMPRESA REBU',
  'H20 VO EMPRESA REBU DIRE',
  'P1 VO PARTICULAR',
  'P12 VO PARTIC COMPRA DIR',
  'P13 VP EMPRESA',
  'P14 EASY CREDIT PAR',
  'P15 EASY CREDIT PAR DIRECT',
  'P16 EASY CREDIT EMPRESA',
  'P17 VO EMPRESA COMPR DIR',
  'P18 EASY CREDIT EMP DIRE',
  'P19 RECOMPRA EMPRESA VO',
  'P6 BUY BACK MOVE',
  'PF RETOMA FICTICIA',
  'VIR STOCK VIRTUAL'
]

const Data = {
  columns,
  courtesyTypes,
  demoTypes,
  km0Types,
  ocasionTypes
}

export default Data