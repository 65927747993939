import http from '../../../utils/http-utils'

const endPoint = '/publishing/api/v1/campaign'

const read = () => {
  const request = http.get(endPoint)
  return request.then((response) => response.data)
}

const create = (data) => {
  const request = http.post(endPoint, data)
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.put(`${endPoint}/${id}`, data)
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`${endPoint}/${id}`)
  return request.then((response) => response.data)
}

const CampaignsService = {
  read,
  create,
  update,
  remove,
}

export default CampaignsService
