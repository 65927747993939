import { StyledPaperCard, FuelIcon } from "./styles"

const Diesel = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeDiesel}
      onClick={() => handleClick()}
    >
      <FuelIcon color="rgb(84 84 84)" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Diesel</p>
      </div>
    </StyledPaperCard>
  )
}

export default Diesel