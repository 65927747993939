import { Button as MuiButton } from "@mui/material"

const Button = ({ handleSubmit }) => {
  const handleClick = (event) => {
    handleSubmit(event)
  }

  return (
    <MuiButton variant='contained' size='small' onClick={handleClick}>
      Guardar
    </MuiButton>
  )
}

export default Button
