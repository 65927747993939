import { StyledPaperCard, CarIcon } from "./styles"

const New = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeNew}
      onClick={() => handleClick()}
    >
      <CarIcon color="#507fa8" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Nuevo</p>
      </div>
    </StyledPaperCard>
  )
}

export default New