import React from "react"
import ChildTextField from "../../../ChildTextField"

const InputsConsumptions = ({ handleInputConsumptionChange }) => {

  const handleInputChange = (event) => {
    handleInputConsumptionChange(event)
  }

  return (
    <React.Fragment>
      <ChildTextField
      label='URBANO'
      name='URBANO'
      handleInputTextChange={handleInputChange}
      defaultValue=''
    />
    <ChildTextField
      label='EXTRAURBANO'
      name='EXTRAURBANO'
      handleInputTextChange={handleInputChange}
      defaultValue=''
    />
    <ChildTextField
      label='MIXTO'
      name='MIXTO'
      handleInputTextChange={handleInputChange}
      defaultValue=''
    />
    </React.Fragment>
  )
}

export default InputsConsumptions
