import { useState } from "react"

const useAlert = () => {
  const [viewAlert, setViewAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState('')
  const [alertMessage, setAlertMessage] = useState('')

  return {
    viewAlert,
    setViewAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage
  }
}

export default useAlert
