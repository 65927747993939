import { useContext } from 'react'
import MaterialTables from '@material-table/core'
import { columns, columnsCNET } from '../../data/index'
import CnetService from '../../services/cnet.service'
import rowUpdateCnet from './utils/rowUpdateCnet'

const Table = ({ brands, context }) => {
  const {
    data,
    setSelectData,
    setData,
    isCnet,
    setShowAlert,
    setTypeAlert,
    setAlertMessage,
    dataFilter,
    setDataFilter,
    account,
  } = useContext(context)

  const deleteRowCnet = (data, row) => {
    const newData = data.filter((e) => e.id !== row.id)
    return CnetService.deleteApi(row.id, account)
      .then((response) => {
        console.log(response)
        setData(newData)
        setDataFilter(newData)
        setShowAlert(true)
        setTypeAlert('success')
        setAlertMessage(`Vehículo eliminado correctamente: ${response.title}`)
      })
      .catch((err) => {
        console.log(err)
        setShowAlert(true)
        setTypeAlert('error')
        setAlertMessage('Ha ocurrido un error en la eliminación del vehículo.')
      })
  }

  const deleteRow = (data, row) => {
    const newData = data.filter((e) => e._id !== row._id)
    return CnetService.remove(row._id)
      .then((response) => {
        console.log(response)
        setData(newData)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (data.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          margin: '0 auto',
          gap: '1rem',
        }}
      >
        <img
          src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1644939841/Multipublicador%20GrupOlivaMotor/empty-case-vehicle-deleted_siczem.svg'
          alt='Sin vehículos'
        />
        <div>
          <b>¡Ups!</b>
          <p>No hay datos que mostrar</p>
        </div>
      </div>
    )
  }

  console.log(data)

  return data ? (
    <MaterialTables
      style={{ width: '100%' }}
      title='Lista de resultados'
      columns={isCnet ? columnsCNET(brands) : columns}
      data={dataFilter.length ? dataFilter : data}
      options={{
        tableLayout: 'auto',
        columnsButton: true,
        filtering: true,
        pageSize: 10,
        selection: true,
      }}
      onSelectionChange={(rows) => {
        // showBulk ? setShowBulk(false) : setShowBulk(true)
        setSelectData(rows)
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Editar vehiculo',
          onClick: (event, rowData) =>
            (window.location = window.location + '/' + rowData._id),
        },
      ]}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            if (isCnet) {
              deleteRowCnet(data, oldData)
                .then((response) => {
                  resolve()
                })
                .catch((err) => {
                  reject()
                })
            } else {
              deleteRow(data, oldData)
                .then((response) => {
                  resolve()
                })
                .catch(reject)
            }
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            if (isCnet) {
              rowUpdateCnet(
                newData,
                oldData,
                account,
                data,
                setData,
                setDataFilter
              )
                .then((response) => {
                  resolve()
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }),
      }}
    />
  ) : (
    ''
  )
}

export default Table
