import { StyledBox, StyledPaper } from './style'
import Select from './Select'

const ActionsBar = ({ context }) => {
  return (
    <StyledBox>
      <StyledPaper>
      <b>Seleccionar acción:</b>
      <Select context={context} />
      </StyledPaper>
    </StyledBox>
  )
}

export default ActionsBar
