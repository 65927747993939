import http from './http-common'
import authHeaders from './auth-header'

const API_URL = process.env.REACT_APP_API_VEHICULOS_GENERAL

const getAll = () => {
  const request = http.get(API_URL, { headers: authHeaders()})
  return request.then(response => response.data)
}

const getForIDV = (idv) => {
  const request = http.get(API_URL + '/' + idv, { headers: authHeaders()})
  return request.then(response => response.data)
} 

const VehiculosService = {
  getAll,
  getForIDV
}

export default VehiculosService
