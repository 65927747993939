import { makeStyles } from "@material-ui/core/styles"
import { Toolbar, Typography } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
  mainTopHeader: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: '22px'
  },
}))

const MainTopHeader = (
  {
    title, published, handleChangePublished, optionForPublish
  }
  ) => {
  const classes = useStyles()
  const [state, setState] = useState(published || false)

  const handleChange = (event) => {
    console.log(event.target.checked)
    setState(event.target.checked)
    handleChangePublished(event.target.checked)
  }

  return (
    <div>
      <Toolbar />
      <div className={classes.mainTopHeader}>
        <Typography variant='h5' component='h2'>
          <b>{title}</b>
        </Typography>
        {!optionForPublish ? (
          ""
        ) : (
          <FormControlLabel
            style={{ marginLeft: "12px" }}
            control={
              <Switch
                checked={state}
                value={state}
                onChange={handleChange}
                name='publicar'
                color='primary'
              />
            }
            label='Publicar en WordPress'
          />
        )}
      </div>
    </div>
  )
}

export default MainTopHeader
