import styled from "@emotion/styled"

export const UploadContent = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 154px auto;
  grid-gap: 1rem;
`
export const UploadLabel = styled.label`
  display: flex ;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: #F5F3F8;
  border-radius: .8rem;
  padding: 2.5rem;
  cursor: pointer;
  transition: .2s ease-in-out;
  &:hover{
    background-color: #B3F6D8;
  }
  &.invalid{
    opacity: .8;
    background-color: #ffe9e9;
    cursor: default;
    color: #540000;
    &:hover{
      background-color: #ffe9e9;
    }
  }
  .icon-upload{
    margin-bottom: .5rem;
    opacity: .7;
  }
  input[type='file']{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;
  }
`
export const UploadImageContent = styled.div`
  position: relative; ;
  display: flex;
  background-color: #f8f8f8;
  border-radius: .8rem;
  overflow: hidden;
`
export const CloseUpload = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  border: none;
  cursor: pointer;
  color: white;
  transition: .3s all ease-in-out;
  &:hover{
    background-color: black;
  }
`

export const UploadIndex = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: .5rem;
  background: black;
  border-radius: 4px;
  color: white;
  z-index: 1;
  line-height: 17px;
`

export const UploadAlert = styled.div`
  background-color: #f5d6be;
  border-radius: 0.4rem;
  padding: 0.8rem 1.2rem;
  margin-top: 1rem;
  color: #850000;
`
export const Image = styled.img`
  object-fit: fill;
  width: 100%;
  height: auto;
`

export const IconClose = (props) => (
  <svg
    style={{
      width: 24,
      height: 24
    }}
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z'
    />
  </svg>
)