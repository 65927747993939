import axios from 'axios'
import authHeaders from '../../../services/auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth,
  },
})

const create = (data) => {
  const request = http.post('/publishing/api/v1/oferta-de-coches/vehicles', data)
  return request.then((response) => response.data)
}

const read = () => {
  const request = http.get('/publishing/api/v1/oferta-de-coches/vehicles')
  return request.then((response) => response.data)
}

const remove = (id) => {
  const request = http.delete(`/publishing/api/v1/oferta-de-coches/vehicles/${id}`)
  return request.then((response) => response.data)
}

const syncStock = () => {
  const request = http.get('/publishing/api/v1/oferta-de-coches/vehicles/sync')
  return request.then((response) => response.data)
}

const createXML = () => {
  const request = http.get('/publishing/api/v1/oferta-de-coches/vehicles/create-xml')
  return request.then((response) => response.data)
}

const update = (id, data) => {
  const request = http.put(`/cnet/vehicles/${id}`, data)
  return request.then((response) => response.data)
}

const ApiService = {
  create,
  read,
  remove,
  update,
  syncStock,
  createXML
}

export default ApiService