import { AccordionDetails, Grid } from "@mui/material"
import OlivaMotor from "./OlivaMotor"
import Tarrauto from "./Tarrauto"
import { StyledAccordion, StyledSummaryTitle, OfferIcon, StyledSummary } from "./styles"
import Tecnotarraco from "./Tecnotarraco"
import JoaquinOliva from "./JoaquinOliva"

const BrandsLandings = ({
  active,
  dataLpOlivaMotor,
  dataLpTarrauto,
  dataLpTecnotarraco,
  dataLpJoaquinOliva,
  handleClickCard,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <OfferIcon color="#507fa8" size="28px" />
        <StyledSummaryTitle>
          Páginas de ofertas (Concesionario)
        </StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <JoaquinOliva
              data={dataLpJoaquinOliva}
              active={active}
              handleClick={() =>
                handleClickCard("activeLpJoaquinOliva", "joaquinOliva")
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Tarrauto
              data={dataLpTarrauto}
              active={active}
              handleClick={() =>
                handleClickCard("activeLpTarrauto", "tarrauto")
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Tecnotarraco
              data={dataLpTecnotarraco}
              active={active}
              handleClick={() =>
                handleClickCard("activeLpTecnotarraco", "tecnotarraco")
              }
            />
          </Grid>
          <Grid item xs={3}>
            <OlivaMotor
              data={dataLpOlivaMotor}
              active={active}
              handleClick={() =>
                handleClickCard("activeLpOlivaMotor", "olivaMotor")
              }
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default BrandsLandings
