import { useContext, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MSelect,
} from '@mui/material'
import CnetService from '../../services/cnet.service'
import { accounts } from '../../data'

const Select = ({ context }) => {
  const [value, setValue] = useState('0')
  const {
    setData,
    setLoading,
    setShowAlert,
    setTypeAlert,
    setAlertMessage,
    setIsCnet,
    setSelectData,
    setDataFilter,
    setActive,
    account,
    setAccount,
  } = useContext(context)

  const handleChange = (event) => {
    setLoading(true)
    const keyAccount = event.target.value
    setAccount(event.target.value)

    const setStates = () => {
      setSelectData([])
      setActive({
        activeTotal: true,
        activeNew: false,
        activeKm0: false,
        activeOcasion: false,
      })
    }

    const getData = (keyAccount) => {
      const accounts = {
        0: () => {
          setIsCnet(false)
          const request = CnetService.read()
          return request
        },
        1: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        2: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        3: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        4: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        5: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        6: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
        7: () => {
          setIsCnet(true)
          const request = CnetService.readApi(keyAccount)
          return request
        },
      }

      return new Promise((resolve, reject) => {
        if (accounts[keyAccount]) {
          resolve(accounts[keyAccount]())
        } else {
          reject([])
        }
      })
    }

    getData(keyAccount)
      .then((result) => {
        setData(result)
        setStates()
        setDataFilter(result)
        setLoading(false)
        setShowAlert(true)
        setTypeAlert('success')
        setAlertMessage('Vehículos cargados correctamente 😎')
      })
      .catch((err) => {
        console.log(err)
        setData([])
        setLoading(false)
        setShowAlert(true)
        setTypeAlert('error')
        setAlertMessage('Ha ocurrido un error al obtener los vehículos  🤦‍♂️')
      })
  }

  return (
    <FormControl style={{ width: '300px', marginRight: '18px' }}>
      <InputLabel style={{ fontSize: '14px', left: '10px' }} id='account'>
        Cuenta
      </InputLabel>
      <MSelect
        name='account'
        labelId='account'
        id='account'
        value={account}
        label='Proveedor'
        size='small'
        style={{ margin: '5px' }}
        onChange={handleChange}
      >
        <MenuItem value='0'>
          <em>Sin seleccionar</em>
        </MenuItem>
        {accounts.map((account) => {
          return (
            <MenuItem key={account.value} value={account.key}>
              {account.value}
            </MenuItem>
          )
        })}
      </MSelect>
    </FormControl>
  )
}

export default Select
