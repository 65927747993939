import {
  Box,
  Button,
  Paper,
  // TextField,
  CircularProgress
} from '@material-ui/core'
import AlertBox from '../../AlertBox'
import useAlert from './hooks/useAlert'
import useAramis from './hooks/useAramis'

const IndexActions = ({ expand, handleExpand }) => {
  const {
    // sumToPrice,
    // setPriceToAramis,
    // setSumToPrice,
    showLoading,
    createCSV,
    dropDatabase,
    updateStock
  } = useAramis()
  const {
    viewAlert,
    setViewAlert,
    alertMessage,
    setAlertMessage,
    typeAlert,
    setTypeAlert
  } = useAlert()

  /* const handlePriceChange = (event) => {
    setSumToPrice(event.target.value)
  }

  const handleButton = (value) => {
    setPriceToAramis(value)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  } */

  const createCSVFile = () => {
    createCSV()
      .then((result) => {
        console.log(result)
        setTypeAlert('success')
        setAlertMessage(result.message)
        setViewAlert(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeleteStock = async () => {
    try {
      await dropDatabase()
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewAlert = (value) => {
    if (value === false) {
      setViewAlert(false)
    } else {
      setViewAlert(true)
    }
  }

  const handleUpdateStock = async () => {
    try {
      await updateStock()
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box style={{ paddingTop: '24px', paddingBottom: '24px' }}>
      <Paper elevation={3} style={{ padding: '24px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px'
              }}
            >
              <h2 style={{ margin: '0px' }}>Panel de Aramis Francia</h2>
            </div>
            {/* <TextField
              size='small'
              label='Valor'
              variant='outlined'
              defaultValue='1000'
              onChange={handlePriceChange}
            /> */}
            {/* <Button
              onClick={() => handleButton(sumToPrice)}
              variant='outlined'
              style={{
                marginLeft: '12px',
                backgroundColor: 'rgb(80, 127, 168)',
                color: 'white'
              }}
              disabled={showLoading}
            >
              Generar precio
            </Button> */}
            <Button
              onClick={createCSVFile}
              variant='outlined'
              color='default'
              style={{
                marginLeft: '24px',
                backgroundColor: 'green',
                color: 'white'
              }}
              disabled={showLoading}
            >
              Generar CSV
            </Button>
            <Button
              onClick={() => handleUpdateStock()}
              variant='outlined'
              style={{
                marginLeft: '12px',
                backgroundColor: 'rgb(80, 127, 168)',
                color: 'white'
              }}
              disabled={showLoading}
            >
              Actualizar stock
            </Button>
            <Button
              onClick={handleDeleteStock}
              variant='outlined'
              color='default'
              style={{
                marginLeft: '24px',
                backgroundColor: '#d13333',
                color: 'white'
              }}
              disabled={showLoading}
            >
              Eliminar stock
            </Button>
            {showLoading ? <CircularProgress style={{ marginLeft: '30px' }} size={28} /> : ''}
          </div>
        </div>
      </Paper>
      <br />
      <br />
      {viewAlert ? <AlertBox text={alertMessage} type={typeAlert} open={viewAlert} handleViewAlert={handleViewAlert} /> : ''}
    </Box>
  )
}

export default IndexActions
