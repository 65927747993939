import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useQuiter } from '../../hooks/useQuiter'
import { StyledAnimatedIcon } from './style'

import ApiService from '../../components/pages/QuiterStock/services'

const filters = [
  {
    text: 'VN',
    filter: (data) => data.filter((e) => e.tipo === 'VN'),
  },
  {
    text: 'VO',
    filter: (data) => data.filter((e) => e.tipo === 'VO'),
  },
  {
    text: 'KM0',
    filter: (data) =>
      data.filter((e) => e.tipo === 'VO' && e.tipoVO === 'KM0 KM0 VO'),
  },
  {
    text: 'Ocasión',
    filter: (data) =>
      data.filter((e) => e.tipo === 'VO' && e.tipoVO !== 'KM0 KM0 VO'),
  },
  {
    text: 'Disponibles',
    filter: (data) => data.filter((e) => e.reservado === false),
    color: { color: '#469346' },
  },
]

const filters2 = [
  {
    text: 'Gasolina',
    filter: (data) => data.filter((e) => e.combustible === 'GASOLINA'),
  },
  {
    text: 'Diesel',
    filter: (data) => data.filter((e) => e.combustible === 'DIESEL'),
  },
  {
    text: 'Eléctrico',
    filter: (data) => data.filter((e) => e.combustible === 'ELECTRICO'),
  },
  {
    text: 'Híbrido',
    filter: (data) => data.filter((e) => e.combustible === 'HIBRIDO'),
  },
  {
    text: 'Reservados',
    filter: (data) => data.filter((e) => e.reservado === true),
    color: { color: '#d56363' },
  },
]

const QuiterStats = () => {
  const [loading, setLoading] = useState(false)
  const { quiterData, setQuiterData, setQuiterFilterData } = useQuiter()

  const handleReloadIcon = async () => {
    setLoading(true)
    const response = await ApiService.getStock()
    console.log(response)
    setQuiterData(response)
    setLoading(false)
  }

  const handleFilterData = (event) => {
    event.preventDefault()
    setQuiterFilterData(quiterData)
  }

  const RenderFilter = ({ text, filter, data, setData, style }) => {
    return (
      <Grid item xs={12} md={2}>
        <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
          <b style={style}>{text}:</b>{' '}
          <span
            onClick={() => setData(filter(data))}
            style={{ color: '#1976d2', cursor: 'pointer' }}
          >
            {filter(data).length}
          </span>
        </Typography>
      </Grid>
    )
  }

  return (
    <Card sx={{ position: 'relative' }}>
      <CardContent>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h6'>Stock completo Quiter</Typography>
          <IconButton>
            <StyledAnimatedIcon
              spin={loading ? 'true' : 'false'}
              onClick={handleReloadIcon}
            />
          </IconButton>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
            Vehículos de la pasarela stock completo Quiter:
          </Typography>
          <Typography
            variant='h5'
            sx={{ color: 'primary.main', cursor: 'pointer' }}
            onClick={handleFilterData}
          >
            {quiterData.length}
          </Typography>
        </Stack>
        <Grid container spacing={1}>
          {filters.map((e) => (
            <RenderFilter
              text={e.text}
              filter={e.filter}
              data={quiterData}
              setData={setQuiterFilterData}
              style={e.color}
            />
          ))}
        </Grid>
        <Grid container spacing={1}>
          {filters2.map((e) => (
            <RenderFilter
              text={e.text}
              filter={e.filter}
              data={quiterData}
              setData={setQuiterFilterData}
              style={e.color}
            />
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default QuiterStats
