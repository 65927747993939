import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_CNET_URL
const username = process.env.REACT_APP_API_CNET_USERNAME
const password = process.env.REACT_APP_API_CNET_PASS

const getData = async (url) => {
  const token = await axios.post(`${apiUrl}/Login`, {
    username: username,
    password: password,
  })

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token.data },
  })

  return response.data
}

const getModels = async (brand, year, fuelType) => {
  const url = `https://api.carfactory.es/publishing/api/v3/Models?brand=${brand}&categoryId=250025002500&fuelType=${fuelType}&year=${year}`

  const token = await axios.post(`${apiUrl}/Login`, {
    username: username,
    password: password,
  })

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token.data },
  })

  return response.data
}

const getBodyTypes = async (brand, model, year) => {
  const url = `https://api.carfactory.es/publishing/api/v3/BodyTypes?brand=${brand}&model=${model}&year=${year}`

  const token = await axios.post(`${apiUrl}/Login`, {
    username: username,
    password: password,
  })

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token.data },
  })

  return response.data
}

const getNumOfDoors = async (brand, model, year) => {
  const url = `https://api.carfactory.es/publishing/api/v3/Doors?brand=${brand}&model=${model}&year=${year}`

  const numOfDoors = await getData(url)

  return numOfDoors
}

const getVersions = async (brand, model, bodyType, fuel, numOfDoors, year) => {
  const url = `https://api.carfactory.es/publishing/api/v3/Versions?bodytype=${bodyType}&numdoors=${numOfDoors}&fueltype=${fuel}&brand=${brand}&model=${model}&year=${year}&categoryId=2500`

  const versions = await getData(url)

  return versions
}

const newVehicle = async (vehicle) => {
  const url = `https://api.carfactory.es/publishing/api/v3/Vehicles`

  const token = await axios.post(`${apiUrl}/Login`, {
    username: username,
    password: password,
  })

  const response = await axios.post(url, vehicle, {
    headers: { Authorization: 'Bearer ' + token.data },
  })

  return response
}

const CnetApiServices = {
  getModels,
  getBodyTypes,
  getNumOfDoors,
  getVersions,
  newVehicle,
}

export default CnetApiServices
