import Utils from './utils';

const url = process.env.REACT_APP_API_URL

const getRecordsForDbName = (dbname) => {
  return Utils.secureHttp(url + 'records/syncs/' + dbname, 'GET')
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    })
}

const SyncRecords = {
  getRecordsForDbName
}

export default SyncRecords
