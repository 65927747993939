import { useState, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  Typography,
  TextField,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconMoney } from '../Icons'
import { listWarranty } from '../../data'

const PriceAndModel = ({ context }) => {
  const { setPrice, setFinancedPrice, setMonthlyFee, setWarranty } =
    useContext(context)

  const [expanded, setExpanded] = useState(false)
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getWarranty = (event) => {
    const warranties = event.target.value
    setWarranty(warranties)
    console.log(warranties)
  }

  return (
    <Accordion onChange={handleExpand('panel2')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel2a-content'
        id='panel2a-header'
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}>
          <IconMoney color='#8809ff' size={24} />
          <Typography variant='h6'>Precio y garantía</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction='row' spacing={2}>
          {/* Precio al contado */}
          <FormControl required sx={{ width: '250px' }}>
            <TextField
              id='input-price'
              label='Precio al contado'
              onChange={(event) => setPrice(event.target.value)}
              defaultValue=''
              type='number'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
          {/* Precio financiado */}
          <FormControl sx={{ width: '250px' }}>
            <TextField
              id='input-price'
              label='Precios financiado'
              onChange={(event) => setFinancedPrice(event.target.value)}
              defaultValue=''
              type='number'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
            <FormHelperText>Campo opcional</FormHelperText>
          </FormControl>
          {/* Cuota mensual */}
          <FormControl sx={{ width: '250px' }}>
            <TextField
              id='input-price'
              label='Cuota mensual'
              onChange={(event) => setMonthlyFee(event.target.value)}
              defaultValue=''
              type='number'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
          </FormControl>
          {/* Garantía */}
          <FormControl
            size='small'
            variant='outlined'
            sx={{ width: '250px' }}
            required
          >
            <InputLabel id='input-warranty'>Garantía</InputLabel>
            <Select
              labelId='input-warranty'
              id='demo-warranty'
              label='Garantía'
              onChange={(event) => getWarranty(event, event.target.value)}
              defaultValue=''
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {listWarranty.map((warranty) => {
                return (
                  <MenuItem key={warranty.key} value={warranty.key}>
                    {warranty.value}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Campo requerido</FormHelperText>
          </FormControl>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default PriceAndModel
