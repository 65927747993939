import JoaquinOlivaIcon from "../../../../Icons/JoaquinOliva"
import { StyledPaperCard } from "./styles"

const JoaquinOliva = ({ data, active, handleClick }) => {
  
  return (
    <StyledPaperCard
    active={active.activeLpJoaquinOliva}
    onClick={() => handleClick()}
    >
      <JoaquinOlivaIcon width={"48px"} />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>JoaquinOliva</p>
      </div>
    </StyledPaperCard>
  )
}

export default JoaquinOliva