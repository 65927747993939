import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TopHeader from "../../TopHeader/TopHeader"
import Sidebar from "../../Sidebar"
import { Button } from "@material-ui/core"
import MaterialTables from "@material-table/core"
import { useEffect } from "react"
import Utils from "../../../services/utils"
import AlertBox from "../../AlertBox"
import PageTopTitle from "../../PageTopTitle"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { ViewList, ViewModule, Visibility } from "@material-ui/icons"
import { Avatar, Stack } from "@mui/material"
import CardView from "./CardView"
import UploadCSVButton from "../../buttons/UploadCSVButton"
import RentingService from "../../../services/renting.service"
import Filters from "./components/Filters"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const RentingContext = React.createContext()

const RentingTable = () => {
  const [data, setData] = useState([])
  const [view, setView] = useState("card")
  const [openAlert, setOpenAlert] = useState(true)
  const [filterBrands, setFilterBrands] = useState("")
  const [filterOffers, setFilterOffers] = useState("")
  const [filterItems, setFilterItems] = useState([])

  const classes = useStyles()
  const url = process.env.REACT_APP_API_RENTING

  const columns = [
    {
      title: "PROVEEDOR",
      field: "provider.name",
    },
    {
      title: "ID CAMPAÑA",
      field: "idOferta",
    },
    {
      title: "MARCA",
      field: "marca",
    },
    {
      title: "MODELO",
      field: "modelo",
    },
    {
      title: "VERSION",
      field: "version",
    },
    {
      title: "UNIDADES",
      field: "stock",
    },
    {
      title: "PAR",
      field: "publicarParticular",
      type: "boolean",
      width: "10%",
      hiddenByColumnsButton: true,
      render: (data) => {
        return data.publicarParticular ? (
          <Avatar sx={{ bgcolor: "#467046", width: 24, height: 24 }}>P</Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "#c94b4b", width: 24, height: 24 }}>P</Avatar>
        )
      },
    },
    {
      title: "EMP",
      field: "publicarEmpresa",
      type: "boolean",
      width: "10%",
      hiddenByColumnsButton: true,
      render: (data) => {
        return data.publicarEmpresa ? (
          <Avatar sx={{ bgcolor: "#467046", width: 24, height: 24 }}>E</Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "#c94b4b", width: 24, height: 24 }}>E</Avatar>
        )
      },
    },
    {
      title: "AUT",
      field: "publicarAutonomos",
      type: "boolean",
      width: "10%",
      hiddenByColumnsButton: true,
      render: (data) => {
        return data.publicarAutonomos ? (
          <Avatar sx={{ bgcolor: "#467046", width: 24, height: 24 }}>A</Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "#c94b4b", width: 24, height: 24 }}>A</Avatar>
        )
      },
    },
    {
      title: "OTR",
      field: "publicarOtros",
      type: "boolean",
      hiddenByColumnsButton: true,
      width: "10%",
      render: (data) => {
        return data.publicarOtros ? (
          <Avatar sx={{ bgcolor: "#467046", width: 24, height: 24 }}>O</Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "#c94b4b", width: 24, height: 24 }}>O</Avatar>
        )
      },
    },
    {
      title: "Acciones",
      field: "idv",
      render: (rowData) =>
        <a href={`/stock-renting/${rowData._id}`}><Button startIcon={<Visibility />} size='small' style={{ background: '#1976d2', color: 'white'}}>Ver</Button></a> 
    },
  ]

  useEffect(() => {
    if (data.length === 0) {
      Utils.secureHttp(`${url}/vehicles`, "GET").then((result) => {
        setData(result)
      })
    }
  }, [url, data])

  const handleAddVehicle = (event) => {
    event.preventDefault()
    window.location = window.location + "/add-vehicle"
  }

  const handleRemoveCar = (data) => {
    setData(data)
  }

  const handleView = (event, newView) => {
    setView(newView)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCSVButton = (data) => {
    RentingService.downloadCSV(data)
      .then((result) => {
        console.log(result)
        // let url = URL.createObjectURL(result);
        let a = document.createElement("a")
        a.href = `${process.env.REACT_APP_API_URL}csv/renting-report.csv`
        a.target = "_blank"
        a.download = "renting-report.csv"
        a.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleClickImport = (filename) => {
    console.log(filename)
    RentingService.importFile(filename)
      .then((result) => {
        console.log(result)
        window.location.reload(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <RentingContext.Provider value={{ setFilterBrands, setFilterOffers, filterItems, setFilterItems }}>
      <div className={classes.root}>
        <TopHeader />
        <Sidebar />
        <main className={classes.content}>
          <PageTopTitle title='Stock de vehículos de renting' />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
              alignItems: "center",
            }}
            className={"main"}
          >
            <Stack direction='row' spacing={0} alignItems='center'>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={handleAddVehicle}
                style={{ backgroundColor: "rgb(80, 127, 168)" }}
              >
                Crear Vehículo
              </Button>
              {view === "list" ? (
                <UploadCSVButton
                  text='Importar CSV'
                  handleClickImport={handleClickImport}
                />
              ) : (
                ""
              )}
            </Stack>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleView}
              size='small'
            >
              <ToggleButton value='list'>
                <ViewList />
              </ToggleButton>
              <ToggleButton value='card'>
                <ViewModule />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {data.length === 0 ? (
            <AlertBox
              type='warning'
              text='No hay vehículos en el stock de renting, puedes crear uno desde CREAR VEHÍCULO'
              open={openAlert}
              handleViewAlert={handleCloseAlert}
            />
          ) : (
            ""
          )}
          { view === "card" ? <Filters context={RentingContext} /> : '' }
          {view === "card" ? (
            <CardView
              data={data}
              handleRemoveCar={handleRemoveCar}
              filterBrands={filterBrands}
              filterOffers={filterOffers}
              filterItems={filterItems}
              setFilterItems={setFilterItems}
            />
          ) : data ? (
            <MaterialTables
              title='Resultados'
              columns={columns}
              data={data}
              options={{
                tableLayout: "auto",
                columnsButton: true,
                filtering: true,
                pageSize: 10,
                selection: true,
                exportAllData: true,
              }}
              actions={[
                {
                  tooltip: "Descargar fichero CSV",
                  icon: "download",
                  onClick: (evt, data) => handleCSVButton(data),
                },
              ]}
            />
          ) : (
            ""
          )}
        </main>
      </div>
    </RentingContext.Provider>
  )
}

export default RentingTable
