import { Grid, Paper, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
  success : {
    color: 'green'
  },
  fail: {
    color: 'red'
  },
  paper: {
    padding: theme.spacing(3),
  },
}))


const PortalChanges = ({ quiterDbData, generalDbData }) => {
  const classes = useStyles()
  
  const getNewIDVsFromQuiter = (dataBaseQuiter, dataBaseGeneral) => {
    let filterIDVs = []
    let newIDVsFromQuiter = []
    
    quiterDbData.filter((d) => {
      generalDbData.filter((s) => {
        if(d.idv === s.idv) {
          filterIDVs.push(d)
        }
        return 0
      })
      return 0
    })
    newIDVsFromQuiter = quiterDbData.filter(d => !filterIDVs.includes(d))
    return newIDVsFromQuiter
  }
  
  const getDeleteIDVsFromGeneral = (dataBaseQuiter, dataBaseGeneral) => {
    let filterIDVs = []
    let deleteIDVsFromGeneral = []

    dataBaseGeneral.filter((d) => {
      dataBaseQuiter.filter((s) => {
        if(d.idv === s.idv) {
          filterIDVs.push(d)
        }
        return 0
      })
      return 0
    })
    deleteIDVsFromGeneral = dataBaseGeneral.filter(d => !filterIDVs.includes(d))
    return deleteIDVsFromGeneral
  }

  const newsIDVsFromQuiter = getNewIDVsFromQuiter(quiterDbData, generalDbData)
  console.log(newsIDVsFromQuiter)
  const deleteIDVsFromGeneral = getDeleteIDVsFromGeneral(quiterDbData, generalDbData)
  console.log(deleteIDVsFromGeneral)
  const handleButtonSync = () => {

  }

  return (
    <Grid item xs={6}>
      <Paper className={classes.paper}>
        <h1>Comparaciones BD Quiter con BD General</h1>
        <p>
          Total de vehículos en Base de Datos Quiter: {quiterDbData.length}
        </p>
        <p>
          Total de vehículos en Base de Datos General: {generalDbData.length}
        </p>
        <p>
          Nuevos IDV's en la Base de Datos de Quiter: {newsIDVsFromQuiter.length}
        </p>
        <p>
          IDV's para borrar en la base de datos general: {deleteIDVsFromGeneral.length}
        </p>
        <p>
{/*           <Button
            variant="contained"
            color="primary"
            onClick={handleButtonSync}
          >
            Guardar datos de Quiter a la BD de Quiter
          </Button> */}
        </p>
      </Paper>
    </Grid>
  )
}

export default PortalChanges