import { StyledPaperCard, WarrantyIcon } from "./styles"

const Warranty = ({ data, active, handleClick }) => {
  return (
    <StyledPaperCard
      active={active.activeCheckWarranty}
      onClick={() => handleClick()}
    >
      <WarrantyIcon color="red" />
      <div>
        <h3>
          <span>{data}</span>
        </h3>
        <p>Garantía</p>
      </div>
    </StyledPaperCard>
  )
}

export default Warranty