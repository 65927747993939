import { AccordionDetails, Grid } from "@mui/material"
import {
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  CarIcon,
} from "./styles"
import Compact from "./Compact"
import Sedan from "./Sedan"
import Family from "./Family"
import MiniVan from "./MiniVan"
import Cabrio from "./Cabrio"
import Coupe from "./Coupe"
import Suv from "./Suv"
import Van from "./Van"

const BodyType = ({
  handleClickCard,
  active,
  compact,
  berlina,
  family,
  miniVan,
  cabrio,
  coupe,
  suv,
  van,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <CarIcon color='#507fa8' size='28px' />
        <StyledSummaryTitle>Carrocería</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Compact
              data={compact}
              active={active}
              handleClick={() => handleClickCard("activeCompact", "compact")}
            />
          </Grid>
          <Grid item xs={3}>
            <Sedan
              data={berlina}
              active={active}
              handleClick={() => handleClickCard("activeBerlina", "berlina")}
            />
          </Grid>
          <Grid item xs={3}>
            <Family
              data={family}
              active={active}
              handleClick={() => handleClickCard("activeFamily", "family")}
            />
          </Grid>
          <Grid item xs={3}>
            <MiniVan
              data={miniVan}
              active={active}
              handleClick={() => handleClickCard("activeMiniVan", "miniVan")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Cabrio
              data={cabrio}
              active={active}
              handleClick={() => handleClickCard("activeCabrio", "cabrio")}
            />
          </Grid>
          <Grid item xs={3}>
            <Coupe
              data={coupe}
              active={active}
              handleClick={() => handleClickCard("activeCoupe", "coupe")}
            />
          </Grid>
          <Grid item xs={3}>
            <Suv
              data={suv}
              active={active}
              handleClick={() => handleClickCard("activeSuv", "suv")}
            />
          </Grid>
          <Grid item xs={3}>
            <Van
              data={van}
              active={active}
              handleClick={() => handleClickCard("activeVan", "van")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default BodyType
