import { useContext, useState } from 'react'
import { Stack, Button } from '@mui/material'
import { Sync, ListAlt, Settings } from '@mui/icons-material'
import Select from './Select'
import Modal from '../Modal'
import { StyledBox, StyledPaper, StyledTopBarBox, StyledImage } from './style'
import CnetService from '../../services/cnet.service'

const TopBar = ({ changeAccount, context }) => {
  const {
    setShowLog,
    isCnet,
    account,
    setLoading,
    setOpenModal,
    setShowSettings,
  } = useContext(context)

  const [modalData, setModalData] = useState({
    vehicles: undefined,
    length: undefined,
  })

  const handleClickButton = () => {
    setShowLog(true)
  }

  const handleUpdateAccount = () => {
    setLoading(true)
    CnetService.updateAccount(account)
      .then((result) => {
        setLoading(false)
        setModalData({
          vehicles: result.response.data,
          length: result.response.data.length,
        })
        setOpenModal(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleConfirmUpdateAccount = () => {
    setOpenModal(false)
    setLoading(true)
    CnetService.updateAccount(account, true)
      .then((result) => {
        setLoading(false)
        console.log(result.response.data.vehicles)
        // setData(result.response.data.vehicles)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSettingsButton = () => {
    setShowSettings(true)
  }

  return (
    <StyledBox>
      <StyledPaper elevation={1}>
        <StyledTopBarBox>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            marginRight='24px'
          >
            <StyledImage
              src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1644481746/Multipublicador%20GrupOlivaMotor/logo-cochesnet-800x400_hq1ov8.png'
              alt='coches.net'
              width='100'
              height='48'
            />
            {!isCnet && (
              <Button
                variant='contained'
                size='small'
                startIcon={<Sync />}
                onClick={() => changeAccount()}
              >
                Actualizar BBDD
              </Button>
            )}
          </Stack>
          <b>Seleccionar cuenta:</b>
          <Select context={context} />
          <Stack direction='row' spacing={1}>
            {isCnet && (
              <Button
                variant='contained'
                size='small'
                startIcon={<Sync />}
                onClick={handleUpdateAccount}
              >
                ELiminar no disponibles
              </Button>
            )}
            {isCnet && (
              <Button
                variant='contained'
                size='small'
                color='secondary'
                startIcon={<Settings />}
                onClick={handleSettingsButton}
              >
                Ajustes
              </Button>
            )}
            <Button
              variant='contained'
              size='small'
              startIcon={<ListAlt />}
              onClick={handleClickButton}
            >
              Ver Acciones
            </Button>
          </Stack>
        </StyledTopBarBox>
      </StyledPaper>
      <Modal
        context={context}
        message='Este proceso eliminara vehículos reservados o no disponibles'
        text={`Se eliminaran ${modalData.length} vehículos:`}
        title='Actualización de cuenta en Coches.net'
        dataList={modalData.vehicles}
        action={handleConfirmUpdateAccount}
      />
    </StyledBox>
  )
}

export default TopBar
