import http from './http-common'
import Utils from './utils'

const API_URL = process.env.REACT_APP_API_MOTOS_URL
const url = process.env.REACT_APP_API_MOTOS

const update = (id, data) => {
  const request = http.put(API_URL + '/' + id, data)
  return request.then(response => response.data)
}

const remove = (vehicle) => {
  const request = Utils.secureHttp(url + '/' + vehicle.idv, 'DELETE')
  return request.then(response => response.data)
}

const create = (vehicle) => {
  console.log('vehiculo')
  console.log(vehicle)
  const request = Utils.secureHttpPost(url, vehicle)
  return request.then(response => response.data)
}

const getForIdv = (idv) => {
  const request = Utils.secureHttp(`${url}/${idv}`, 'GET')
  return request.then(response => response)
}

const MotosService = {
  update,
  remove,
  create,
  getForIdv
}

export default MotosService
