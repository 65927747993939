const userExist = () => {
  return localStorage.getItem('usuario') ? true : false
}

const getUser = () => {
  return JSON.parse(localStorage.getItem('usuario'))
}

const deleteUser = () => {
  return localStorage.removeItem('usuario')
}

const UserUtils = {
  userExist,
  getUser,
  deleteUser,
}

export default UserUtils
