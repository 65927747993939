import styled from "@emotion/styled"

export const StyledContainer = styled.div`
  padding: 24px;
  & .cell-error {
    background-color: #d47483;
  }
  & .cell-success {
    background-color: #9dff767d;
  }
  & .cell-info {
    background-color: #74b1d4;
  }
  & .cell-warning {
    background-color: #f1ff7e;
  }
`

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledLogSection = styled.div`
  margin-bottom: 16px;
`

export const StyledCheckBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`
