//** React Imports */
import { useEffect, useState } from 'react'

//** @Core components */
import Modal from '../../@core/components/Modal'

//** MUI Imports*/
import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material'

//** Hooks Imports */
import { useQuiter } from '../../hooks/useQuiter'

//** Services Imports*/
import GomotorService from '../Gomotor/services'

//** Utils Imports */
import { getNotEquals } from '../../utils/filter-utils'

//** Styled Components */
import { StyledAnimatedIcon } from './style'

//** Icons Imports */
import InfoIcon from '@mui/icons-material/Info'

const GomotorStats = () => {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([])
  const { quiterData, setQuiterFilterData } = useQuiter()

  const filterQuiterData = (quiterData, connectorData) => {
    const quiterAvailableVehicles = quiterData.filter(
      (e) => e.reservado === false && e.tipo === 'VO'
    )

    const vehiclesToCreated = getNotEquals(
      quiterAvailableVehicles,
      connectorData,
      'idv'
    )

    return vehiclesToCreated
  }

  useEffect(() => {
    let mounted = true
    ;(async () => {
      try {
        const response = await GomotorService.read()
        if (mounted) {
          setData(response)
          setFilterData(filterQuiterData(quiterData, response))
        }
      } catch (error) {
        console.log(error)
      }
    })()
    return () => (mounted = false)
  }, [quiterData])

  const handleReloadIcon = async () => {
    setLoading(true)
    const response = await GomotorService.syncQuiterManually()
    setData(response.data.vehicles)
    console.log(response)
    setFilterData(filterQuiterData(quiterData, response.data.vehicles))
    setLoading(false)
  }

  const handleFilterData = (event) => {
    event.preventDefault()
    if (filterData.length > 0) {
      setQuiterFilterData(filterData)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <Card sx={{ position: 'relative' }}>
      <CardContent>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack direction='row' alignItems='center'>
            <IconButton onClick={handleCloseModal}>
              <InfoIcon color='disabled' />
            </IconButton>
            <Typography variant='h6'>Gomotor</Typography>
          </Stack>
          <IconButton>
            <StyledAnimatedIcon
              spin={loading ? 'true' : 'false'}
              onClick={handleReloadIcon}
            />
          </IconButton>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
            Vehículos en la base de datos:
          </Typography>
          <Typography variant='h5' sx={{ color: 'primary.main' }}>
            {data.length}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
            Vehículos no importados:
          </Typography>
          <Typography
            variant='h5'
            sx={{ color: 'error.main', cursor: 'pointer' }}
            onClick={handleFilterData}
          >
            {filterData.length}
          </Typography>
        </Stack>
      </CardContent>
      <Modal
        isOpen={openModal}
        title='Información sobre la pasarela de Gomotor'
        handleCloseModal={handleCloseModal}
      >
        <p>
          <b>Acción del botón de sincronización:</b> Elimina reservados y
          actualiza los disponibles.
        </p>
        <p>
          <b>Vehículos no importados:</b> Vehículos VO
        </p>
        <p>
          <b>
            Recuerda que para completar la sincronización debes acceder acceder
            a la sección de Gomotor y pulsar el botón de sincronización web.
          </b>
        </p>
      </Modal>
    </Card>
  )
}

export default GomotorStats
