import ConfirmModal from './ConfirmModal'
import ApiServices from '../../services'

const ManualEdit = ({ open, dataSelection, handleCloseModal, isActivated }) => {
  const activate = () => {
    const data = {
      dataSelection,
      update: 'publicacionManual',
      value: true,
    }
    ApiServices.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deactivate = () => {
    const data = {
      dataSelection,
      update: 'publicacionManual',
      value: false,
    }
    ApiServices.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <ConfirmModal
      open={open}
      dataSelection={dataSelection}
      action={'Publicacion manual'}
      handleCloseModal={handleCloseModal}
      handleActivate={activate}
      handleDeactivate={deactivate}
      isActivated={isActivated}
    />
  )
}

export default ManualEdit
