import ConfirmModal from "./ConfirmModal"
import GomotorService from "../../../../../services/gomotor.service"


const DeleteMany = ({ open, dataSelection, handleCloseModal }) => {
  const remove = () => {
    const data = { dataSelection }
    GomotorService.bulkDelete(data)
    .then((result) => {
      window.location.reload()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <ConfirmModal
      open={open}
      dataSelection={dataSelection}
      action={'Nº de vehículos a eliminar'}
      handleCloseModal={handleCloseModal}
      handleActivate={remove}
      handleDeactivate={remove}
    />
  )
}

export default DeleteMany
