import { AccordionDetails, Grid } from "@mui/material"
import Peugeot from "./Peugeot"
import Citroen from "./Citroen"
import Honda from "./Honda"
import Bmw from "./Bmw"
import Mini from "./Mini"
import {
  StyledAccordion,
  StyledSummaryTitle,
  StyledSummary,
  OfferIcon,
} from "./styles"

const BrandsLandings = ({
  dataPeugeot,
  dataCitroen,
  dataHonda,
  dataBmw,
  dataMini,
  handleClickCard,
  active,
}) => {
  return (
    <StyledAccordion>
      <StyledSummary>
        <OfferIcon color='#507fa8' size='28px' />
        <StyledSummaryTitle>Páginas de ofertas (Marcas)</StyledSummaryTitle>
      </StyledSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Peugeot
              data={dataPeugeot}
              active={active}
              handleClick={() => handleClickCard("activeLpPeugeot", "peugeot")}
            />
          </Grid>
          <Grid item xs={3}>
            <Citroen
              data={dataCitroen}
              active={active}
              handleClick={() => handleClickCard("activeLpCitroen", "citroen")}
            />
          </Grid>
          <Grid item xs={3}>
            <Honda
              data={dataHonda}
              active={active}
              handleClick={() => handleClickCard("activeLpHonda", "honda")}
            />
          </Grid>
          <Grid item xs={3}>
            <Bmw
              data={dataBmw}
              active={active}
              handleClick={() => handleClickCard("activeLpBmw", "bmw")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Mini
              data={dataMini}
              active={active}
              handleClick={() => handleClickCard("activeLpMini", "mini")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default BrandsLandings
