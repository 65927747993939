import TextareaAutosize from "@material-ui/core/TextareaAutosize"

const ChildTextArea = ({ defaultValue, handleInputTextAreaChange }) => {
  return (
    <TextareaAutosize
      defaultValue={defaultValue}
      rowsMin='6'
      style={{width: '100%'}}
      onChange={(event) => handleInputTextAreaChange(event)}
    />
  )
}

export default ChildTextArea
