import MaterialTables from '@material-table/core'

const Table = ({ title, columns, data }) => {
  return (
    <MaterialTables
      title={title}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        pageSize: 10,
      }}
/*       cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            rowData.garantia = newValue;
            console.log("newValue: " + newValue);
            console.log(rowData);
            console.log(data);
            setTimeout(resolve, 1000);
          });
        },
      }} */
    />
  );
};

export default Table;
