import axios from 'axios'
import authHeaders from './auth-header'

const url = process.env.REACT_APP_API_URL
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const remove = (id, data) => {
  const request = http.delete('renting/vehicles/' + id, { data })
  return request.then(response => response.data)
}

const create = (data) => {
  const request = http.post('renting/vehicles', data)
  return request.then(response => response.data)
}

const update = (id, data) => {
  const request = http.put(`renting/vehicles/${id}`, data)
  return request.then(response => response.data)
}

const getCarById = (id) => {
    const request = http.get(`renting/vehicles/${id}`)
    return request.then(response => response.data)
}

const deleteImg = (data) => {
  const request = http.post('renting/delete-image', data)
  return request.then(response => response.data)
}

const downloadPDF = (data, typeOffer) => {
  const obj = {
    data,
    typeOffer
  }
  const request = http.post('renting/download-pdf', obj)
  return request.then(response => response.data)
}

const providers = () => {
  const request = http.get(`renting/providers`)
  return request.then(response => response.data)
}

const downloadCSV = (data) => {
  const request = http.post('renting/download-csv', { data })
  return request.then(response => response.data)
  
}

const importFile = (data) => {
  const request = http.post('renting/utils/import-csv', { data })
  return request.then(response => response.data)
}

const RentingService = {
  create,
  remove,
  getCarById,
  update,
  deleteImg,
  downloadPDF,
  downloadCSV,
  providers,
  importFile
}

export default RentingService
