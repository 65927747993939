import React, { useContext, useState } from "react"
import SubmitButton from "../../../buttons/SubmitButton"
import CnetService from "../../services/cnet.service"

const Delete = ({ context }) => {
  const { setData, selectData, setSelectData, setDataFilter, setShowAlert, setTypeAlert, setAlertMessage, isCnet, account } =
    useContext(context)
  const [loading, setLoading] = useState(false)
  const [responses, setResponses] = useState([])

  const createLoop = async (data) => {
    const allResponses = []
    for (let i = 0; i < data.length; i++) {
      try {
        const response = await CnetService.deleteApi(data[i].id, account)
        allResponses.push({status: 200, idv: response.internalReference, message: 'Vehicle deleted successfully'})
        setShowAlert(true)
        setTypeAlert("info")
        setAlertMessage(
          `Eliminando vehículo: ${i + 1 } de ${data.length}`
        )
      } catch (error) {
        allResponses.push({status: error.response.status, idv: data[i].idv, message: error.response.data.error})
        setShowAlert(true)
        setTypeAlert("info")
        setAlertMessage(
          `Eliminando: ${i + 1} de ${data.length}`
        )
      }
    }
    setResponses(allResponses)
    await CnetService.createAction({type: 'delete', account, data: allResponses})
    return allResponses
  }

  const handleButton = () => {
    setLoading(true)
    if(isCnet) {
      createLoop(selectData)
        .then((result) => {
          CnetService.readApi(account)
            .then(response => {
              setData(response)
              setDataFilter(response)
              setSelectData([])
            })
          setLoading(false)
          console.log(result)
        })
    }
  }

  return (
    <React.Fragment>
      <SubmitButton
        loading={loading}
        text='Aplicar acción'
        handleClick={handleButton}
      />
    </React.Fragment>
  )
}

export default Delete
