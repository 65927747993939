import React, { useState } from 'react'
import {
  Paper,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
} from '@material-ui/core'
import GomotorService from '../../Gomotor/services'
import GrupOlivaMotorService from '../../../services/grupolivamotor.service'
import AramisService from '../Aramis/services/aramis.service'
import WallapopService from '../../../services/wallapop.service'
import CnetService from '../../CochesNet/services/cnet.service'
import OfertaDeCochesService from '../../OfertaDeCoches/services'
import MotoretoService from '../../Motoreto/services'
import ExportJsonButton from '../../buttons/ExportJsonButton'

const ExportErrorList = (data) => {
  console.log(data)
  return (
    <Paper
      elevation={3}
      style={{ backgroundColor: 'rgb(241 82 52 / 12%)', listStyle: 'none' }}
    >
      <Box p={2} marginBottom={3}>
        {data
          ? data.data.map((error) => (
              <li>
                <b>IDV:</b> {error.idv ? error.idv : error}{' '}
                {error.error ? error.error : ''}
              </li>
            ))
          : ''}
      </Box>
    </Paper>
  )
}

const SendSelectors = ({ data }) => {
  const [state, setState] = useState({
    gomotor: false,
    grupolivamotor: false,
    aramis: false,
    wallapop: false,
    cnet: false,
    motoreto: false,
  })
  const [countSuccess, setCountSuccess] = useState(0)
  const [countErrors, setCountErrors] = useState(0)
  const [showLog, setShowLog] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [errorList, setErrorList] = useState([])

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleSendButton = () => {
    // console.log(state)
    // console.log(data)
    for (const connect in state) {
      // console.log(`${connect}: ${state[connect]}`)
      /* if (connect === 'gomotor' && state[connect]) {
        // console.log("Sincronizar GOmotor")
        setCountSuccess(0)
        setCountErrors(0)
        GomotorService.bulkCreate(data)
          .then((result) => {
            setCountSuccess(result.numOfNews)
            setCountErrors(result.numOfExist)
            setErrorList(result.existIDVs)
            setShowLog(true)
            // console.log(result)
          })
          .catch((err) => {
            console.log(err)
          })
      } */

      if (connect === 'gomotor' && state[connect]) {
        console.log('Sincronizar Aramis')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creación de idv: ' + car.idv)

          console.log(car)

          GomotorService.create(car)
            .then((result) => {
              console.log(result)
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              errors++
              setCountErrors(errors)
              newErrors.push(car.idv)
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'grupolivamotor' && state[connect]) {
        console.log('Sincronizar GrupOlivaMotor')
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        GrupOlivaMotorService.bulkCreate(data)
          .then((result) => {
            setCountSuccess(result.numOfNews)
            setCountErrors(result.numOfExist)
            setErrorList(result.existIDVs)
            setShowLog(true)
            // console.log(result)
          })
          .catch((err) => {
            console.log(err)
          })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'aramis' && state[connect]) {
        console.log('Sincronizar Aramis')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creacion de idv: ' + car.idv)
          AramisService.create(car)
            .then((result) => {
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              errors++
              setCountErrors(errors)
              console.log(err.response)
              newErrors.push({
                idv: car.idv,
                error: err.response.data.error,
              })
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'wallapop' && state[connect]) {
        console.log('Sincronizar Wallapop')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creacion de idv: ' + car.idv)
          WallapopService.create(car)
            .then((result) => {
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              console.log(err.response)
              errors++
              setCountErrors(errors)
              newErrors.push({
                idv: car.idv,
                message: err.response.data.message,
              })
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'cnet' && state[connect]) {
        console.log('Actualizar la base de datos de cnet')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creacion de idv: ' + car.idv)
          CnetService.create(car)
            .then((result) => {
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              console.log(err.response)
              errors++
              setCountErrors(errors)
              newErrors.push({
                idv: car.idv,
                message: err.response.data.message,
              })
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'oferta-de-coches' && state[connect]) {
        console.log('Actualizar la base de datos de cnet')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creación de idv: ' + car.idv)
          OfertaDeCochesService.create(car)
            .then((result) => {
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              console.log(err.response)
              errors++
              setCountErrors(errors)
              newErrors.push({
                idv: car.idv,
                message: err.response.data.message,
              })
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
      if (connect === 'motoreto' && state[connect]) {
        console.log('Sincronizar Motoreto')
        let success = 0
        let errors = 0
        const newErrors = []
        setCountSuccess(0)
        setCountErrors(0)
        data.forEach((car) => {
          console.log('Preparando creacion de idv: ' + car.idv)
          MotoretoService.create(car)
            .then((result) => {
              success++
              setCountSuccess(success)
            })
            .catch((err) => {
              console.log(err.response)
              errors++
              setCountErrors(errors)
              newErrors.push({
                idv: car.idv,
                message: err.response.data.message,
              })
              console.log(errorList)
            })
        })
        setErrorList(newErrors)
        setShowLog(true)
      }
    }
  }

  const handleExport = () => {
    alert('Exportar a JSON')
  }

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <Box p={2} marginBottom={6}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>
              Enviar selección al stock de:
            </FormLabel>
            <FormGroup row style={{ alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox name='gomotor' onChange={handleChange} />}
                label='GOmotor'
              />
              <FormControlLabel
                control={
                  <Checkbox name='grupolivamotor' onChange={handleChange} />
                }
                label='GrupOlivaMotor'
              />
              <FormControlLabel
                control={<Checkbox name='aramis' onChange={handleChange} />}
                label='Aramis Francia'
              />
              <FormControlLabel
                control={<Checkbox name='wallapop' onChange={handleChange} />}
                label='Wallapop'
              />
              <FormControlLabel
                control={<Checkbox name='cnet' onChange={handleChange} />}
                label='Coches.net'
              />
              <FormControlLabel
                control={
                  <Checkbox name='oferta-de-coches' onChange={handleChange} />
                }
                label='ofertadecoches.com'
              />
              <FormControlLabel
                control={<Checkbox name='motoreto' onChange={handleChange} />}
                label='motoreto.com'
              />
              <Button
                variant='contained'
                color='primary'
                onClick={handleSendButton}
                size='small'
                style={{ backgroundColor: 'green' }}
              >
                Enviar
              </Button>
              <ExportJsonButton data={data} />
            </FormGroup>
          </FormControl>
        </Box>
      </Paper>
      {showLog ? (
        <div>
          <h3>Resultado de la importación</h3>
          <Paper elevation={3} style={{ backgroundColor: '#f5f5f5' }}>
            <Box p={2} marginBottom={3}>
              ✅ <b>Correctos:</b> {countSuccess} 💡 <b>Advertencias:</b>{' '}
              {countErrors}
              <Button
                variant='contained'
                color='secondary'
                onClick={() =>
                  showErrors ? setShowErrors(false) : setShowErrors(true)
                }
                style={{ marginLeft: 24 }}
              >
                {showErrors ? 'Ocultar Advertencias' : 'Ver Advertencias'}
              </Button>
            </Box>
          </Paper>
          {showErrors ? <ExportErrorList data={errorList} /> : ''}
        </div>
      ) : (
        ' '
      )}
    </React.Fragment>
  )
}

export default SendSelectors
