import React, { useState } from "react"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Button } from "@mui/material"
import styled from "@emotion/styled"
import UploadFileService from "../../services/upload-file.service"

const StyledButton = styled(Button)`
  margin-left: 12px;
`
const StyledInput = styled.input`
  display: none;
`

const StyledFileSelect = styled.span`
  margin-left: 12px;
`

const UploadCSVButton = ({ text, handleClickImport }) => {
  const [selectedFile, setSelectedFile] = useState()
  const [isSelected, setIsSelected] = useState(false)

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
    console.log(event.target.files[0])
    setIsSelected(true)
  }

  const handleClickUpload = async () => {
    if (isSelected) {
      const file = await UploadFileService.uploadCSV(selectedFile)
      const filename = file.data.filename
      handleClickImport(filename)
    } else {
      alert('No hay fichero seleccionado!!!')
    }
  }

  const handleCancel = () => {
    setSelectedFile(undefined)
    setIsSelected(false)
  }

  return (
    <React.Fragment>
      <StyledInput
        accept='.csv'
        id='contained-button-file'
        multiple
        type='file'
        onChange={changeHandler}
      />
      <label htmlFor='contained-button-file'>
        <StyledButton
          variant='contained'
          color='primary'
          component='span'
          size='small'
          startIcon={<CloudUploadIcon />}
        >
          {text}
        </StyledButton>
      </label>
      {selectedFile ? (
        <React.Fragment>
          <StyledFileSelect>{selectedFile.name}</StyledFileSelect>
          <StyledButton
            variant='contained'
            color='success'
            component='span'
            size='small'
            onClick={handleClickUpload}
          >
            Aceptar
          </StyledButton>
          <StyledButton
            variant='contained'
            color='error'
            component='span'
            size='small'
            onClick={handleCancel}
          >
            Cancelar
          </StyledButton>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default UploadCSVButton
