import Utils from './utils';

const url = process.env.REACT_APP_API_VEHICULOS_QUITER

const getDataToQuiterDataBase = () => {
  return Utils.secureHttp(url, 'GET')
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    })
}

const saveDataToDataBase = () => {
  return Utils.secureHttp(url, 'POST')
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    })
}

const QuiterService = {
  getDataToQuiterDataBase,
  saveDataToDataBase
}

export default QuiterService