import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ProfileButton from './ProfileButton'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#000000'
  },
  logoBar: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const TopHeader = () => {
  const classes = useStyles()

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid
            container spacing={3}
            direction="row"
            justify="space-between"
          >
            <Grid item xs={8} className={classes.logoBar}>
              <img src="https://res.cloudinary.com/grup-oliva-motor/image/upload/v1624441311/Multipublicador%20GrupOlivaMotor/logo_xv7uea.png" alt="Logo GrupOlivaMotor" />
              <Typography variant="h6" noWrap>
                Multi-Publicador
              </Typography>
            </Grid>
            <Grid
              item xs={4}
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <ProfileButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default TopHeader
