import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Chip } from "@material-ui/core/"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import TextField from "@material-ui/core/TextField"
import MainTopHeader from "./MainTopHeader"
import EditVehicleInfoBox from "./EditVehicleInfoBox"
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {},
    "& input[type=text]": {
      fontSize: "14px",
    },
    textArea: {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  photoSection: {
    height: "800px",
    overflow: "auto",
  },
  details: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  listContainer: {
    width: "100%",
    "& ul": {
      listStyle: "none",
    },
  },
  chip: {
    margin: "4px 4px 4px 0px",
    backgroundColor: "#e18b3e",
  },
}))

const VehicleEditForm = ({ data, optionForPublish }) => {
  const [expanded, setExpanded] = useState(true)
  const classes = useStyles()
  const emptyFields = []

  for (const element in data) {
    if (typeof data[element] === "object") {
      data[element].map((object) => {
        for (let ref in object) {
          if (typeof object[ref] === "object") {
            object[ref].map((object) => {
              for (let ref in object) {
                if (object[ref].length === 0) emptyFields.push(ref)
                // console.log(`${ref}: ${object[ref]}`)
              }
              return 0
            })
          } else {
            if (object[ref].length === 0) emptyFields.push(ref)
            // console.log(`${ref}: ${object[ref]}`)
          }
        }
        return 0
      })
    } else {
      if (data[element].length === 0) emptyFields.push(element)
      // console.log(`${element}: ${data[element]}`)
    }
  }

  const ChildTextField = ({ defaultValue, label }) => {
    return (
      <TextField
        id='standard-basic'
        label={label}
        defaultValue={defaultValue}
        variant='outlined'
        size='small'
      />
    )
  }

  const ChildTextArea = ({ defaultValue }) => {
    return (
      <TextareaAutosize
        defaultValue={defaultValue}
        rowsMin='6'
        style={{ width: "100%" }}
      />
    )
  }

  const handleExpand = (event) => {
    event.preventDefault()
    return expanded ? setExpanded(false) : setExpanded(true)
  }

  const title = `Ficha ${data.marca} ${data.modelo} ${data.version}`

  return (
    <div>
      <MainTopHeader title={title} reserved={data.reservado} />
      <form className={classes.form} noValidate autoComplete='off'>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper className={classes.paper}>
              <Accordion expanded={expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={handleExpand}
                >
                  <Typography variant='h6' component='h3'>
                    Datos generales del Vehículo
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <ChildTextField label='Marca' defaultValue={data.marca} />
                  <ChildTextField label='Modelo' defaultValue={data.modelo} />
                  <ChildTextField
                    label='Cod Modelo'
                    defaultValue={data.codigoModelo}
                  />
                  <ChildTextField label='Versión' defaultValue={data.version} />
                  <ChildTextField label='Tipo' defaultValue={data.tipo} />
                  <ChildTextField
                    label='Tipo Vehículo'
                    defaultValue={data.tipoVehiculo}
                  />
                  <ChildTextField label='País' defaultValue={data.pais} />
                  <ChildTextField label='Mercado' defaultValue={data.mercado} />
                  <ChildTextField
                    label='ID Concesión'
                    defaultValue={data.idConcesion}
                  />
                  <ChildTextField
                    label='Bastidor'
                    defaultValue={data.bastidor}
                  />
                  <ChildTextField
                    label='Carrocería'
                    defaultValue={data.carroceria}
                  />
                  <ChildTextField label='Color' defaultValue={data.color} />
                  <ChildTextField
                    label='Cod Color'
                    defaultValue={data.codigoColor}
                  />
                  <ChildTextField
                    label='Potencia CV'
                    defaultValue={data.potenciaCv}
                  />
                  <ChildTextField label='KM' defaultValue={data.km} />
                  <ChildTextField
                    label='Matricula'
                    defaultValue={data.matricula}
                  />
                  <ChildTextField
                    label='Combustible'
                    defaultValue={data.combustible}
                  />
                  <ChildTextField
                    label='Color Interior'
                    defaultValue={data.colorInterior}
                  />
                  <ChildTextField
                    label='Carrocería'
                    defaultValue={data.carroceria}
                  />
                  <ChildTextField label='Cambio' defaultValue={data.cambio} />
                  <ChildTextField
                    label='Transmisión'
                    defaultValue={data.transmision}
                  />
                  <ChildTextField
                    label='Potencia KW'
                    defaultValue={data.potenciaKw}
                  />
                  <ChildTextField
                    label='Aceleración'
                    defaultValue={data.aceleracion}
                  />
                  <ChildTextField
                    label='Velocidad MAX'
                    defaultValue={data.velocidadMaxima}
                  />
                  <ChildTextField label='Peso' defaultValue={data.peso} />
                  <ChildTextField
                    label='Normativa'
                    defaultValue={data.normativa}
                  />
                  <ChildTextField
                    label='Garantia'
                    defaultValue={data.garantia}
                  />
                  <ChildTextField
                    label='Categoria'
                    defaultValue={data.categoria}
                  />
                  <ChildTextField
                    label='Ubicación'
                    defaultValue={data.ubicacion}
                  />
                  <ChildTextField label='TipoVO' defaultValue={data.tipoVO} />
                  <ChildTextField
                    label='Nombre concesionario'
                    defaultValue={data.nombreConcesionario}
                  />
                  <ChildTextField
                    label='Imp Financiación'
                    defaultValue={data.impFinanciacion}
                  />
                  <ChildTextField
                    label='Liquidación'
                    defaultValue={data.liquidacion}
                  />
                  <ChildTextField
                    label='Actualizado'
                    defaultValue={data.actualizado}
                  />
                  <ChildTextField
                    label='Tracción'
                    defaultValue={data.traccion}
                  />
                  <ChildTextField
                    label='Pres Reacondicionamiento'
                    defaultValue={data.presReacondicionamiento}
                  />
                  <ChildTextField
                    label='Metalizado'
                    defaultValue={data.metalizado}
                  />
                  <ChildTextField
                    label='IVA Deducible'
                    defaultValue={data.ivaDeducible}
                  />
                  <ChildTextField
                    label='Reservado'
                    defaultValue={data.reservado}
                  />
                  <ChildTextField label='CarFax' defaultValue={data.carfax} />
                  <ChildTextField label='Puertas' defaultValue={data.puertas} />
                  <ChildTextField label='Plazas' defaultValue={data.plazas} />
                  <ChildTextField label='Marchas' defaultValue={data.marchas} />
                  <ChildTextField
                    label='Cilindros'
                    defaultValue={data.cilindros}
                  />
                  <ChildTextField
                    label='Reservado'
                    defaultValue={data.reservado}
                  />
                  <ChildTextField
                    label='Fecha Matriculación'
                    defaultValue={data.fechaMatriculacion}
                  />
                  <ChildTextField
                    label='Año Modelo'
                    defaultValue={data.anyoModelo}
                  />
                  <ChildTextField
                    label='Mes Modelo'
                    defaultValue={data.mesModelo}
                  />
                  <ChildTextField
                    label='Nº Propietarios Anteriores'
                    defaultValue={data.numPropietariosAnteriores}
                  />
                  <ChildTextField
                    label='Importación'
                    defaultValue={data.importacion}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Consumos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {data.consumos.map((element) => (
                    <ChildTextField
                      label={element.tipoConsumo}
                      defaultValue={element.valor}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Emisiones
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <ChildTextField
                    label='Normativa'
                    defaultValue={data.emisiones[0].normativa}
                  />
                  <ChildTextField
                    label='Valor'
                    defaultValue={data.emisiones[0].valor}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Precios
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {data.precios.map((element) => (
                    <ChildTextField
                      label={element.tipo}
                      defaultValue={element.valor}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Dimensiones
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <ChildTextField
                    label='Largo'
                    defaultValue={data.dimensiones[0].largo}
                  />
                  <ChildTextField
                    label='Ancho'
                    defaultValue={data.dimensiones[0].ancho}
                  />
                  <ChildTextField
                    label='Alto'
                    defaultValue={data.dimensiones[0].alto}
                  />
                  <ChildTextField
                    label='Volumen Maletero'
                    defaultValue={data.dimensiones[0].volumenMaletero}
                  />
                  <ChildTextField
                    label='Capacidad Deposito'
                    defaultValue={data.dimensiones[0].capacidadDeposito}
                  />
                  <ChildTextField
                    label='Neumático Delantero'
                    defaultValue={data.dimensiones[0].neumaticoDelantero}
                  />
                  <ChildTextField
                    label='Neumático Trasero'
                    defaultValue={data.dimensiones[0].neumaticoTrasero}
                  />
                  <ChildTextField
                    label='Medidas Neumáticos'
                    defaultValue={data.dimensiones[0].medidasNeumaticos}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Vehículo Contacto
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <ChildTextField
                    label='Nombre Vendedor'
                    defaultValue={data.vehiculoContacto[0].nombreVendedor}
                  />
                  <ChildTextField
                    label='Nombre Vendedor'
                    defaultValue={data.vehiculoContacto[0].telefonoVendedor}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Observaciones
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <ChildTextArea defaultValue={data.observaciones} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' component='h2'>
                    Equipamiento
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Grid container className={classes.listContainer}>
                    <Grid item md={6}>
                      <Typography component='p'>
                        <b>Serie</b>
                        {
                        !data.equipamiento
                        ? ''
                        : (
                          <ul>
                            {data.equipamiento.map((element) => {
                              if (element.tipo !== "SERIE") {
                                return (
                                  <li>
                                    {element.codigo} - {element.descripcion}
                                  </li>
                                )
                              }
                              return ""
                            })}
                          </ul>
                        )
                      } 
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <b>Extra</b>
                      {
                        !data.equipamiento
                        ? ''
                        : (
                          <ul>
                            {data.equipamiento.map((element) => {
                              if (element.tipo !== "SERIE") {
                                return (
                                  <li>
                                    {element.codigo} - {element.descripcion}
                                  </li>
                                )
                              }
                              return ""
                            })}
                          </ul>
                        )
                      } 
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <EditVehicleInfoBox
              emptyFields={emptyFields}
              title='Campos sin valor:'
            >
              {emptyFields.map((field) => {
                return (
                  <Chip
                    size='small'
                    icon={<ErrorOutlineRoundedIcon />}
                    label={field}
                    className={classes.chip}
                    color='primary'
                  />
                )
              })}
            </EditVehicleInfoBox>
            <Paper className={classes.paper}>
              <Typography variant='h6' component='h2'>
                Fotos
              </Typography>
              <Divider />
              <div className={classes.photoSection}>
                {data.recursos.map((foto) => (
                  <img src={foto.url} alt={foto.nombre} width='100%' />
                ))}
              </div>
              <br />
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default VehicleEditForm
