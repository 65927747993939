const bulkActions = [
  {
    id: 1,
    name: 'Asignar contador',
    actionName: 'enableCounter',
  },
  {
    id: 2,
    name: 'Destacar home',
    actionName: 'destacar',
  },
  {
    id: 3,
    name: 'Aplicar precio campaña',
    actionName: 'enableDiscount'
  },
  {
    id: 4,
    name: 'Aplicar disclaimer',
    actionName: 'enableDisclaimer'
  },
/*   {
    id: 3,
    name: 'Modificar precio',
    actionName: 'modificarPrecio',
  },
  {
    id: 4,
    name: 'Asignar tipo de anuncio', // TODO: por implementar
    actionName: 'tipoAnuncio',
  },
  {
    id: 5,
    name: 'Activar edición manual',
    actionName: 'ActivarManual',
  }, */
/*   {
    id: 6,
    name: 'Modificar importe reserva',
    actionName: 'modificarImporteReserva',
  },
  {
    id: 7,
    name: 'Sincronización Web',
    actionName: 'webSync'
  }, */
  {
    id: 8,
    name: 'Eliminar vehículo', // TODO: por implementar
    actionName: 'deleteCar',
  }
]

const SelectActionsData = {
  bulkActions
}

export default SelectActionsData
