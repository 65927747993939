import ConfirmModal from './ConfirmModal'
import ApiServices from '../../services'

const ShowInHome = ({ open, dataSelection, handleCloseModal, isActivated }) => {
  const bulkUpdate = async (data) => {
    for (let i = 0; i < dataSelection.length; i++) {
      const result = await ApiServices.update(dataSelection[i].id, data)
    }
    return
  }

  const activate = () => {
    const data = { featuredHome: true }

    bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deactivate = () => {
    const data = { featuredHome: false }
    bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ConfirmModal
      open={open}
      dataSelection={dataSelection}
      action={'Destacados home'}
      handleCloseModal={handleCloseModal}
      handleActivate={activate}
      handleDeactivate={deactivate}
      isActivated={isActivated}
    />
  )
}

export default ShowInHome
