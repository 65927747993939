import { useEffect, useState } from "react"
import UserUtils from "../../utils/User.utils"
import { makeStyles } from "@material-ui/core/styles"
import MaterialTables from "@material-table/core"
import Sidebar from "../Sidebar"
import TopHeader from "../TopHeader"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import { Redirect } from "react-router"
import Utils from "../../services/utils"
import MotosService from "../../services/motos.service"
import AlertBox from "../AlertBox"
import axios from "axios"

const url = process.env.REACT_APP_API_URL + "api/motos"

const columns = [
  {
    title: "IDV",
    field: "idv",
  },
  {
    title: "MARCA",
    field: "marca",
  },
  {
    title: "MODELO",
    field: "modelo",
  },
  {
    title: "PRECIO VENTA",
    field: "precioVenta",
  },
  {
    title: "PVP PUBLICACION",
    field: "pvpPublicacion",
    render: (rowData) =>
      rowData.pvpPublicacion
        ? rowData.pvpPublicacion
        : (rowData.pvpPublicacion = "0"),
  },
  {
    title: "PVO NUEVO",
    field: "pvoNuevo",
    render: (rowData) =>
      rowData.pvoNuevo ? rowData.pvoNuevo : (rowData.pvoNuevo = "0"),
  },
  {
    title: "KM",
    field: "km",
  },
  {
    title: "POTENCIA CV",
    field: "potenciaCv",
    render: (rowData) =>
      rowData.potenciaCv ? rowData.potenciaCv : (rowData.potenciaCv = "0"),
  },
  {
    title: "POTENCIA KW",
    field: "potenciaKw",
    render: (rowData) =>
      rowData.potenciaKw ? rowData.potenciaKw : (rowData.potenciaKw = "0"),
  },
  {
    title: "COLOR",
    field: "color",
    render: (rowData) =>
      rowData.color ? rowData.color : (rowData.color = "0"),
  },
  {
    title: "MATRICULA",
    field: "matricula",
  },
  {
    title: "COMBUSTBLE",
    field: "combustible",
    render: (rowData) =>
      rowData.combustible ? rowData.combustible : (rowData.combustible = "0"),
  },
  {
    title: "CILINDRADA",
    field: "cilindrada",
    render: (rowData) =>
      rowData.cilindrada ? rowData.cilindrada : (rowData.cilindrada = "0"),
  },
  {
    title: "GARANTIA",
    field: "garantia",
  },
  {
    title: "RESERVADO",
    field: "reservado",
    render: (rowData) => (rowData.reservado === "1" ? "Si" : "No"),
  },
  {
    title: "AÑO MODELO",
    field: "anyoModelo",
    render: (rowData) =>
      rowData.anyoModelo ? rowData.anyoModelo : (rowData.anyoModelo = "0"),
  },
  {
    title: "FECHA MAT",
    field: "fechaMatriculacion",
    type: 'date'
  },
    {
    title: "PUBLICAR",
    field: "publicar",
    type: 'boolean',
    
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const MotosTable = () => {
  const [data, setData] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [showProgress, setShowProgress] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    Utils.secureHttp(url, "GET")
      .then((initialData) => {
        setData(initialData)
        setShowProgress(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleCharger = () => {
    axios
      .get(
        "https://grupolivamotor.com/wp-content/themes/flatsome-child/grupolivamotor/quiter-client/quiter_chain_execution.php"
      )
      .then((result) => {
        return axios.get(
          "https://grupolivamotor.com/wp-load.php?import_key=zWzqDnG76&import_id=16&action=trigger"
        , {headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }})
      })
      .then((result) => {
        setAlertMessage("Se ha lanzado la exportación de motos a WordPress")
        setShowMessage(true)
        setTimeout(() => {
          setShowMessage(false)
        }, 2000)
      })
  }

  if (!UserUtils.userExist()) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        {showMessage ? <AlertBox type='success' text={alertMessage} /> : ""}
        <Button variant='contained' color='primary' onClick={handleCharger}>
          Importar a WordPress
        </Button>
        <MaterialTables
          style={{
            fontSize: "14px",
          }}
          title='Stock de Motos'
          columns={columns}
          data={data}
          isLoading={showProgress}
          options={{
            filtering: true,
            pageSize: 10,
            searchFieldAlignment: "left",
            columnsButton: true,
            grouping: true,
            headerStyle: {
              padding: "5px",
              borderTop: "1px solid #a2a2a2",
              borderBottom: "1px solid #a2a2a2",
              fontSize: "12px",
            },
            cellStyle: {
              padding: "5px",
            },
            rowStyle: {
              fontSize: "12px",
            },
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Editar vehiculo",
              onClick: (event, rowData) =>
                (window.location = window.location + "/" + rowData.idv),
            },
          ]}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                const dataDelete = [...data]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                console.log(dataDelete)
                console.log(oldData)
                MotosService.remove(oldData)
                  .then(response => {
                    console.log(response)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                setData(dataDelete)
                setTimeout(resolve, 1000)
              }),
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
/*                 axios.post(process.env.REACT_APP_API_MOTOS, newData)
                  .then(result => {
                    console.log(result.data)
                  })
                  .catch(err => {
                    console.log(err)
                  }) */
                MotosService.create(newData)
                  .then(result => {
                    console.log(result)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                setTimeout(() => {
                  setData([...data, newData])
                  console.log(newData)

                  resolve()
                }, 1000)
              }),
          }}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                console.log(columnDef.field)
                rowData[columnDef.field] = newValue
                console.log("newValue: " + newValue)
                console.log(rowData)
                //console.log(data);
                console.log({ [columnDef.field]: newValue })
                // motosService.update(rowData._id, { [columnDef.field ]: newValue})
                MotosService.update(rowData._id, {
                  [columnDef.field]: newValue,
                })
                  .then((response) => {
                    if (response) {
                      setAlertMessage("Registro actualizado con éxito")
                      setShowMessage(true)
                      setTimeout(() => {
                        setShowMessage(false)
                      }, 2000)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                setData(data)
                setTimeout(resolve, 1000)
              })
            },
          }}
        />
      </main>
    </div>
  )
}

export default MotosTable
