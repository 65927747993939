import { useState } from "react"
import {
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  FormControl,
} from "@material-ui/core"
import ConfirmModal from "./ConfirmModal"
import GomotorService from "../../../../../services/gomotor.service"

const ActivateCampaigns = ({
  campaigns,
  open,
  dataSelection,
  handleCloseModal,
  isActivated
}) => {
  const [value, setValue] = useState("")
  const [ selectCampaign, setSelectCampaign] = useState([])

  const handleChange = (event) => {
    setValue(event.target.value)
    setSelectCampaign(campaigns.filter(campaign => campaign._id === value))
  }

  const activate = () => {
    const campaign = campaigns.filter(campaign => campaign._id === value)
    const data = {
      dataSelection,
      update: "campana",
      value: campaign[0]
    }
    GomotorService.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const deactivate = () => {
    const data = {
      dataSelection,
      update: "campana",
      value: {},
    }
    GomotorService.bulkUpdate(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  console.log(selectCampaign)

  return (
    <div>
      <FormControl component='fieldset'>
        <FormGroup row style={{ display: "flex", alignItems: "end" }}>
          <InputLabel>Seleccionar campaña</InputLabel>
          <Select
            style={{ width: "250px", marginRight: "45px" }}
            onChange={handleChange}
            value={value}
          >
            {campaigns.map((element) =>
              element.active ? (
                <MenuItem value={element._id} key={element._id}>
                  {element.name}
                </MenuItem>
              ) : (
                ""
              )
            )}
          </Select>
        </FormGroup>
      </FormControl>
      <ConfirmModal
        open={open}
        dataSelection={dataSelection}
        action={`Activar campaña`}
        handleCloseModal={handleCloseModal}
        handleActivate={activate}
        handleDeactivate={deactivate}
        isActivated={isActivated}
      />
    </div>
  )
}

export default ActivateCampaigns
