const Citroen = ({ width }) => {
  return (
    <svg version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 49.9 49.9' width={width ? width : ''}>
      <g id='citroen'>
        <path
          id='Citroen'
          d='M41.2,38.7c0-0.4,0-0.9,0-1.4c0-1.1-0.9-2.1-0.9-2.1c-4.5-5-12.1-12.4-15-12.6c-0.1,0-0.1,0-0.1,0
c0,3.4,0.1,3.3-1.9,4.1c-0.2,0.1-7,2.9-13.4,8.8c-0.8,0.9-0.7,1.8-0.7,1.8c0,0.5,0,0.9,0,1.5c3-2.5,11.8-9.8,16-9.8
C29.4,28.9,38.2,36.1,41.2,38.7z M41.1,34.5c0,0.1,1.1,1.3,1.1,2.8c0,1.1-0.1,2.3-0.1,2.3c0,0.1,0,0.5-0.3,0.7h-0.1
c-0.1,0-0.2,0-0.3-0.1S29.8,29.9,25.3,29.9S9.3,40.1,9.1,40.2c-0.1,0.1-0.2,0.1-0.3,0.1H8.7c-0.3-0.1-0.3-0.6-0.3-0.7
c0,0-0.1-1.2-0.1-2.3c0-1.5,1.1-2.7,1.1-2.8l0,0c0.5-0.5,11.8-13,15.9-13C29.3,21.5,40.6,34,41.1,34.5z M41.2,26.8
c0-0.4,0-0.9,0-1.4c0-1.1-0.9-2.1-0.9-2.1c-4.5-5-12.1-12.4-15-12.6c-0.1,0-0.1,0-0.1,0c0,3.4,0.1,3.2-1.9,4.1
c-0.2,0.1-7,2.9-13.4,8.8c-0.8,0.9-0.7,1.8-0.7,1.8c0,0.5,0,0.9,0,1.5c3-2.5,11.8-9.8,16-9.8C29.4,17.1,38.2,24.3,41.2,26.8z
M41.1,22.7c0,0.1,1.1,1.3,1.1,2.8c0,1.1-0.1,2.3-0.1,2.3c0,0.1,0,0.5-0.3,0.7h-0.1c-0.1,0-0.2,0-0.3-0.1S29.8,18.2,25.3,18.2
S9.3,28.3,9.2,28.4S9,28.5,8.9,28.5H8.8c-0.3-0.1-0.3-0.6-0.3-0.7c0,0-0.1-1.2-0.1-2.3c0-1.5,1.1-2.7,1.1-2.8l0,0
c0.5-0.5,11.8-13,15.8-13C29.3,9.7,40.6,22.1,41.1,22.7z'
        />
      </g>
    </svg>
  )
}

export default Citroen
