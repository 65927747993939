import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTables from '@material-table/core'
import Sidebar from '../Sidebar'
import TopHeader from '../TopHeader/TopHeader'
import Toolbar from '@material-ui/core/Toolbar'
import { Redirect } from 'react-router'
import AlertBox from '../AlertBox'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Data from '../../utils/data'
import InfoResume from './InfoResume'
import IndexActions from './IndexActions'
import useCampaigns from '../pages/Campaigns/hooks/useCampaigns'
// import CardView from "./CardView"
import SelectActions from './components/BulkActions/SelectActions'
import SelectedFilters from './components/SelectedFilters'

//** Services Imports */
import ApiServices from './services'
import CampaignsService from '../Campaigns/services'
const user = localStorage.getItem('usuario')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: 'whitesmoke',
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Gomotor = () => {
  const [data, setData] = useState([])
  const [alertMessage, setAlertMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)
  const [showProgress, setShowProgress] = useState(true)
  const [typeAlert, setTypeAlert] = useState('')
  const [expand, setExpand] = useState(false)
  const [dataFilter, setDataFilter] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [settings, setSettings] = useState([])
  const [selectData, setSelectData] = useState([])
  const [showBulk, setShowBulk] = useState(false)
  const [selectedFilters, setSelectedFilter] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if (data.length === 0) {
      ApiServices.getSettings().then((result) => {
        setSettings(result)
      })

      ApiServices.read()
        .then((result) => {
          setData(result)
          console.log(result)
          setShowProgress(false)

          return
        })
        .then(() => {
          CampaignsService.read().then((result) => {
            setCampaigns(result)
            return
          })
        })
        .catch((err) => {
          setAlertMessage(
            'Error Code: 1 - Parece que el servidor del multipublicador no esta respondiendo'
          )
          setTypeAlert('error')
          setShowMessage(true)
          setShowProgress(false)
        })
    }
  }, [data])

  const handleExpand = (value) => {
    setExpand(value)
  }

  const handleLoading = (value) => {
    setShowProgress(value)
  }

  const handleFilter = (filter) => {
    const setData = (data) => {
      setDataFilter(data)
      setShowBulk(false)
      setExpand(true)
    }

    const isFilterExist = (filter) => {
      return selectedFilters.find((f) => f === filter)
    }

    const setFilters = (filter) => {
      if (!isFilterExist(filter)) {
        setSelectedFilter([...selectedFilters, filter])
      } else {
        setSelectedFilter(selectedFilters.filter((e) => e !== filter))
      }
    }

    const setFilter = (type) => {
      const filters = {
        total: () => {
          setData()
        },
        available: () => {
          setData(data.filter((e) => e.reserved !== true))
        },
        reserved: () => {
          setData(data.filter((e) => e.reserved === true))
        },
        offer: () => {
          setData(data.filter((e) => e.featuredHome === true))
        },
        /*         km0: () => {
          setData(data.filter((e) => e.offerType === 'KM0'))
          setFilters(type)
        },
                courtesy: () => {
          setData(data.filter((e) => e.tipoVO === 'CORTESIA'))
          setFilters(type)
        },
        demo: () => {
          setData(data.filter((e) => e.tipoVO === 'DEMO'))
          setFilters(type)
        },
        ocasion: () => {
          setData(data.filter((e) => e.offerType === 'Ocasión'))
          setFilters(type)
        }, */
        gasolina: () => {
          setData(data.filter((e) => e.fuel === 'gasolina'))
        },
        diesel: () => {
          setData(data.filter((e) => e.fuel === 'diesel'))
        },
        hybrid: () => {
          setData(data.filter((e) => e.fuel === 'hibrido'))
        },
        electric: () => {
          setData(data.filter((e) => e.fuel === 'electrico'))
        },
        /*         campaign: () => {
          setData(data.filter((e) => e.campana))
        }, */
        compact: () => {
          setData(data.filter((e) => e.bodyType === 'compacto'))
        },
        berlina: () => {
          setData(data.filter((e) => e.bodyType === 'berlina'))
        },
        family: () => {
          setData(data.filter((e) => e.bodyType === 'familiar'))
        },
        miniVan: () => {
          setData(data.filter((e) => e.bodyType === 'monovolumen'))
        },
        cabrio: () => {
          setData(data.filter((e) => e.bodyType === 'cabrio'))
        },
        coupe: () => {
          setData(data.filter((e) => e.bodyType === 'coupe'))
        },
        suv: () => {
          setData(data.filter((e) => e.bodyType === 'suv-4x4'))
        },
        van: () => {
          setData(data.filter((e) => e.bodyType === 'furgoneta'))
        },
        /*         checkBodyWork: () => {
          setData(data.filter((e) => e.bodyType === 'revisar'))
        },
        checkPrices: () => {
          setData(
            data.filter((e) => e.financedPrice <= 100 || e.financedPrice <= 100)
          )
        },
        checkWarranty: () => {
          setData(data.filter((e) => e.garantia < 12))
        }, */
        km0Web: () => {
          setData(data.filter((e) => e.offerType === 'KM0'))
        },
        ocasionWeb: () => {
          setData(data.filter((e) => e.offerType === 'Ocasión'))
        },
        // Filter by marketing counter
        counterActivated: () => {
          setData(data.filter((e) => e.marketing?.counter?.active === true))
        },
        // Filter by discount is active
        discountActivated: () => {
          setData(
            data.filter(
              (e) =>
                e.discount?.active === 'true' || e.discount?.active === true
            )
          )
        },
        // Filter by disclaimers is active
        disclaimerActivated: () => {
          setData(
            data.filter((e) => e.marketing?.disclaimerMessage?.length > 0)
          )
        },
      }

      return filters[type]()
    }

    setFilter(filter)
  }

  console.log(settings, 'settings')

  if (!user) return <Redirect to='/login' />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Toolbar />
        {showMessage ? <AlertBox type={typeAlert} text={alertMessage} /> : ''}
        <Backdrop className={classes.backdrop} open={showProgress}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <IndexActions
          expand={expand}
          handleExpand={handleExpand}
          handleLoading={handleLoading}
        />
        {data.length > 0 ? (
          <div>
            <InfoResume
              data={data}
              expand={expand}
              handleExpand={handleExpand}
              handleFilter={handleFilter}
            />
            <SelectedFilters filters={selectedFilters} />
          </div>
        ) : (
          ''
        )}
        <br />
        {selectData.length > 0 ? (
          <SelectActions
            dataSelection={selectData}
            campaigns={campaigns}
            settings={settings}
          />
        ) : (
          ''
        )}
        {!expand ? (
          ''
        ) : (
          <MaterialTables
            title='Lista de resultados'
            columns={Data.columns}
            data={dataFilter ? dataFilter : data}
            options={{
              tableLayout: 'auto',
              columnsButton: true,
              filtering: true,
              pageSize: 10,
              selection: true,
            }}
            onSelectionChange={(rows) => {
              showBulk ? setShowBulk(false) : setShowBulk(true)
              setSelectData(rows)
            }}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar vehiculo',
                onClick: (event, rowData) =>
                  (window.location = window.location + '/' + rowData._id),
              },
            ]}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  console.log(oldData)
                  ApiServices.deleteById(oldData.id)
                    .then((response) => {
                      console.log(response)
                      setTimeout(resolve, 1000)
                      window.location.reload()
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data]
                  const index = oldData.tableData.id
                  dataUpdate[index] = newData
                  const filter = { _id: newData._id }
                  const update = newData
                  console.log(filter)
                  console.log(newData)
                  ApiServices.update(newData.id, update)
                    .then((result) => {
                      console.log(result)
                      if (result) {
                        setData(data)
                        setTimeout(resolve, 1000)
                        window.location.reload()
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }),
            }}
          />
        )}
      </main>
    </div>
  )
}

export default Gomotor
