//** React Imports */
import { useCallback, useEffect, useState } from 'react'

//** MUI Imports */
import { Alert, Box, Button, Card, CardHeader, Snackbar } from '@mui/material'
import {
  DataGrid,
  esES,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid'

//** Icons Imports */
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import ObjectID from 'bson-objectid'

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const _id = `${ObjectID()}`
    setRows((oldRows) => [...oldRows, { _id, isNew: true }])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'accountId' },
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Añadir
      </Button>
    </GridToolbarContainer>
  )
}

const TableDataSticky = ({
  data,
  defaultColumns,
  loading,
  title,
  apiService,
}) => {
  const columns = [
    ...defaultColumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ]
      },
    },
  ]

  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [snackbar, setSnackbar] = useState(null)
  const handleCloseSnackbar = () => setSnackbar(null)

  useEffect(() => {
    let mounted = true
    ;(() => {
      if (mounted) {
        setRows(data)
      }
    })()

    return () => (mounted = false)
  }, [data])

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row._id !== id))
    apiService
      .remove(id)
      .then(() => {
        setSnackbar({ children: 'Eliminado con éxito', severity: 'success' })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row._id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row._id !== id))
    }
  }

  const processRowUpdate = async (newRow) => {
    let updatedRow = newRow
    if (newRow.isNew) {
      updatedRow = { ...newRow, isNew: false }
      setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)))
      await apiService.create(newRow)
      setSnackbar({ children: 'Guardado con éxito', severity: 'success' })
    } else {
      updatedRow = { ...newRow, isNew: false }
      setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)))
      await apiService.update(newRow._id, newRow)
      setSnackbar({ children: 'Guardado con éxito', severity: 'success' })
    }
    return updatedRow
  }

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' })
  }, [])

  return (
    <Card>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
      <Box sx={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row._id}
          editMode='row'
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          sx={{
            border: 0,
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Card>
  )
}

export default TableDataSticky
