import { useState } from "react"

const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState(undefined)
  const [alertMessage, setAlertMessage] = useState('')

  return {
    showAlert,
    setShowAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage
  }
}

export default useAlert
