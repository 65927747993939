import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

import DashboardIcon from '@material-ui/icons/Dashboard'
import StorageIcon from '@material-ui/icons/Storage'
import PublicIcon from '@material-ui/icons/Public'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import { Link } from 'react-router-dom'

import SettingsIcon from '@mui/icons-material/Settings'
import PersonIcon from '@mui/icons-material/Person'

import UserUtils from '../utils/User.utils'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: '#000000',
  },
}))

const RouterLinks = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    slug: '/dashboard',
  },
  {
    text: 'Campañas',
    icon: <LoyaltyIcon style={{ color: '#33add1' }} />,
    slug: '/campaigns',
  },
]

const Sidebar = () => {
  const [openStockCoches, setOpenStockCoches] = useState(false)
  const [openExternWebs, setOpenExternWebs] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (!user) {
      setUser(UserUtils.getUser().user)
    }
  }, [user])

  const handleClickStockCoches = () => {
    setOpenStockCoches(!openStockCoches)
  }

  const handleClickExternWebs = () => {
    setOpenExternWebs(!openExternWebs)
  }

  const handleClickSettings = () => {
    setOpenSettings(!openSettings)
  }

  const classes = useStyles()

  return (
    <div>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {RouterLinks.map((link) => (
              <ListItem button key={link.text}>
                <ListItemIcon className={classes.icon}>
                  {link.icon}
                </ListItemIcon>
                <Link to={link.slug}>
                  <ListItemText primary={link.text} />
                </Link>
              </ListItem>
            ))}
            {/*             <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <MotorcycleOutlinedIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='Stock Motos' />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Link to='/motos'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ListIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='BBDD' />
                </ListItem>
              </Link>
              <Link to='/subir-csv'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ListIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Subir CSV' />
                </ListItem>
              </Link>
            </Collapse> */}
            <ListItem button onClick={handleClickStockCoches}>
              <ListItemIcon>
                <PublicIcon
                  className={classes.icon}
                  style={{ color: 'rgb(80, 127, 168)' }}
                />
              </ListItemIcon>
              <ListItemText primary='Webs Propias' />
              {openStockCoches ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openStockCoches} timeout='auto' unmountOnExit>
              {/*               <Link to='/stock-gomotor'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='GOmotor' />
                </ListItem>
              </Link> */}
              <Link to='/gomotor'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Gomotor' />
                </ListItem>
              </Link>
              <Link to='/stock-grupoliva'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Grup Oliva Motor' />
                </ListItem>
              </Link>
              {/* <Link to='/stock-renting'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='GOmotor Renting' />
                </ListItem>
              </Link> */}
            </Collapse>
            <ListItem button onClick={handleClickExternWebs}>
              <ListItemIcon>
                <PublicIcon
                  className={classes.icon}
                  style={{ color: '#d13333' }}
                />
              </ListItemIcon>
              <ListItemText primary='Webs Externas' />
              {openExternWebs ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openExternWebs} timeout='auto' unmountOnExit>
              <Link to='/aramis-francia'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Aramis Francia' />
                </ListItem>
              </Link>
              <Link to='/coches-net'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Coches.net' />
                </ListItem>
              </Link>
              {/* <Link to='/wallapop'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Wallapop' />
                </ListItem>
              </Link> */}
              {/* <Link to='/oferta-de-coches'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Oferta de Coches' />
                </ListItem>
              </Link> */}
              {/* <Link to='/motoreto'>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StorageIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary='Motoreto' />
                </ListItem>
              </Link> */}
            </Collapse>
            <Link to='/stock-general-quiter'>
              <ListItem button>
                <ListItemIcon>
                  <StorageIcon
                    className={classes.icon}
                    style={{ color: 'rgb(255, 165, 0)' }}
                  />
                </ListItemIcon>
                <ListItemText primary='Stock Quiter' />
              </ListItem>
            </Link>
            {user?.email === 'rserrano@grupolivamotor.com' && (
              <>
                <ListItem button onClick={handleClickSettings}>
                  <ListItemIcon>
                    <SettingsIcon
                      className={classes.icon}
                      sx={{ color: 'rgb(0, 0, 0)' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Ajustes' />
                  {openSettings ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSettings} timeout='auto' unmountOnExit>
                  <Link to='/settings/users'>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <PersonIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Usuarios' />
                    </ListItem>
                  </Link>
                  <Link to='/settings/wallapop'>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <PersonIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Wallapop' />
                    </ListItem>
                  </Link>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  )
}

export default Sidebar
