import axios from 'axios'
import authHeaders from '../../../../services/auth-header'

const url = process.env.REACT_APP_API_V1
const auth = authHeaders()

const http = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    ...auth
  }
})

const getStock = () => {
  const request = http.get('/quiter')
  return request.then(response => response.data)
}

const ApiService = {
  getStock
}

export default ApiService