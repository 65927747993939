import ApiServices from '../../services'
import ConfirmModal from './ConfirmModal'

const WebSync = ({ open, dataSelection, handleCloseModal }) => {
  const update = () => {
    console.log(dataSelection)

    const data = {
      dataSelection,
    }

    ApiServices.bulkUpdateWP(data)
      .then((result) => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <ConfirmModal
      open={open}
      dataSelection={dataSelection}
      action={`Actualizar selección en Web GOmotor`}
      handleCloseModal={handleCloseModal}
      handleActivate={update}
      handleDeactivate={update}
    />
  )
}

export default WebSync
