import { FETCH_PROCESSES, UPDATE_PROCESS, GET_STATE_PROCESS } from '../actions/constants'

const processReducer = (state = { isLoading: true, process: [], isActivated: undefined}, action) => {
  switch (action.type) {
    case FETCH_PROCESSES:
      return {
        ...state,
        process: action.payload
      }
    case UPDATE_PROCESS:
        return {
          ...state,
          process: state.process.map((process) => (process._id === action.payload._id ? action.payload : process ))
        }
    case GET_STATE_PROCESS:
        return {
          ...state,
          isActivated: action.payload
        }
    default:
      return state
  }
}

export default processReducer
