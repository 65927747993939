import { Button } from "@material-ui/core"
import VisibilityIcon from '@material-ui/icons/Visibility'

export const Columns = [
  {
    title: 'IDV',
    field: 'idv',
    width: '15%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'MATRICULA',
    field: 'matricula',
    // hidden: true
  },
  {
    title: 'TIPO',
    field: 'tipo',
    lookup: { 'VO': 'VO', 'VN': 'VN'},
    width: '15%',
    cellStyle: { whiteSpace: 'nowrap' },
    hidden: true
  },
  {
    title: 'TIPO VO',
    field: 'tipoVO',
/*     width: '15%',
    cellStyle: { whiteSpace: 'nowrap' }, */
    // lookup: { 'VO': 'VO', 'VN': 'VN'},
    // width: '15%',
    hidden: true
  },
  {
    title: 'MARCA',
    field: 'marca'
  },
  {
    title: 'MODELO',
    field: 'modelo'
  },
  {
    title: 'COMBUSTIBLE',
    field: 'combustible',
    hidden: true
  },
  {
    title: 'CAMBIO',
    field: 'cambio',
    // hidden: true
  },
  {
    title: 'KM',
    field: 'km',
    hidden: true
  },
  {
    title: 'POT CV',
    field: 'potenciaCv',
    hidden: true
  },
  {
    title: 'CILINDRADA',
    field: 'cilindrada',
    hidden: true
  },
  {
    title: 'CONTADO',
    field: 'precios[0].valor',
    width: '25%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'FINANCIADO',
    field: 'precios[3].valor',
    width: '25%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'GARANTIA',
    field: 'garantia',
    render: rowData => (
      rowData.garantia ? rowData.garantia : rowData.garantia = '0'
    ),
    width: '20%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'HOME',
    field: 'vehiculosHome',
    type: 'boolean',
    width: '10%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'RESERVADO',
    field: 'reservado',
    // hidden: true,
    type: 'boolean',
    width: '20%',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'CAMPAÑA',
    field: 'campana.name',
    hidden: true
  },
  {
    title: 'CARROCERIA',
    field: 'carroceria',
    hidden: true
  },
  {
    title: 'CARROCERIA WEB',
    field: 'tipoCarroceriaWeb',
    hidden: true
  },
  {
    title: "Acciones",
    field: "idv",
    render: (rowData) =>
      <a href={`/stock-grupoliva/${rowData._id}`}><Button startIcon={<VisibilityIcon />} size='small' style={{ background: '#1976d2', color: 'white'}}>Ver</Button></a> 
  },

]