import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%"
  }
}));

const Header = ({ downloadFile, handleUrlImportChange, JSONimports }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={3}>
          <Box mb={3}>
            {JSONimports ?
              <FormControl className={classes.formControl}>
                <InputLabel>Fichero de importación de Quiter</InputLabel>
                <Select onChange={handleUrlImportChange}>
                  {JSONimports.map(json =>
                    <MenuItem key={json.url} value={json.url}>{json.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
              : ''
            }
          </Box>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
          <Box mb={3} alignSelf="center">
            <Button variant="contained" color="primary" onClick={downloadFile} className={classes.button} startIcon={<SaveIcon />}>
              Descargar JSON
            </Button>
            <Button variant="contained" color="primary" startIcon={<Icon>send</Icon>}>
              Guardar en BD
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default Header