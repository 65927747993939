//** React imports */
import { useEffect, useState } from 'react'

//** Core components imports */
import TopHeader from '../../TopHeader/TopHeader'
import Sidebar from '../../Sidebar'
import PageTitle from '../../../@core/components/PageTitle'
import TableDataSticky from '../../../@core/components/TableDataSticky'

//** MUI components imports */
import { Toolbar, CircularProgress, Grid, Card } from '@mui/material'

//** Styled Components imports */
import {
  StyledMain,
  StyledContent,
  StyledBackDrop,
} from '../../../@core/styles/style'

//** Services imports */
import UserService from './services'

const User = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    let mounted = true
    setLoading(true)
    ;(async () => {
      const users = await UserService.read()
      if (mounted) {
        setUsers(users)
        setLoading(false)
      }
    })()

    return () => (mounted = false)
  }, [])

  const columns = [
    { field: '_id', headerName: 'ID', width: 220 },
    { field: 'name', headerName: 'Nombre', width: 150 },
    { field: 'lastname', headerName: 'Apellidos', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'telephone', headerName: 'Teléfono', width: 150 },
  ]

  return (
    <StyledMain>
      <TopHeader />
      <Sidebar />
      <StyledContent>
        <Toolbar />
        <StyledBackDrop open={loading}>
          <CircularProgress color='inherit' />
        </StyledBackDrop>
        <div style={{ padding: '24px' }}>
          <PageTitle title='Gestión de usuarios' />
          <TableDataSticky
            data={users}
            columns={columns}
            loading={loading}
            title='Lista de usuarios'
          />
        </div>
      </StyledContent>
    </StyledMain>
  )
}

export default User
