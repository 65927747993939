import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Button } from "@mui/material"
import styled from '@emotion/styled'
import React from "react"

const StyledButton = styled(Button)`
  margin-left: 12px;
`
const StyledInput = styled.input`
  display: none;
`

const UploadImageButton = ({ handleUploadImage, text }) => {

  const selectImage = (event) => {
    let binaryData = []
    binaryData.push(event.target.files[0])
    const image = event.target.files[0]
    const blob = window.URL.createObjectURL(new Blob(binaryData))
    // window.URL.revokeObjectURL()
    handleUploadImage(image, blob)
  }

  return (
    <React.Fragment>
      <StyledInput
        accept='image/*'
        id='contained-button-file'
        multiple
        type='file'
        onChange={selectImage}
      />
      <label htmlFor='contained-button-file'>
        <StyledButton
          variant='contained'
          color='primary'
          component='span'
          size='small'
          startIcon={<CloudUploadIcon />}
        >
          {text}
        </StyledButton>
      </label>
    </React.Fragment>
  )
}

export default UploadImageButton
