import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))
const AlertBox = ({ type, text, open, handleViewAlert }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          variant="filled"
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                handleViewAlert(false)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          severity={type}
        >
          {text}
        </Alert>
      </Collapse>
    </div>
  )
}
export default AlertBox
