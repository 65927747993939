import { makeStyles } from "@material-ui/core/styles"
import TextField from '@material-ui/core/TextField'
import { useState } from "react"

const useStyles = makeStyles((theme) => ({
  customTextField: {
    fontSize: 14
  }
}))
const ChildTextField = ({ name, defaultValue, label, handleInputTextChange, type, required }) => {
  // const  [value, setValue] = useState('')
  const classes = useStyles()

/*   const handleChange = (event) => {
    console.log(event.target.value)
    console.log(event.target.name)
    handleInputTextChange(event.target.name, event.target.value)
  } */

  return (
    <TextField style={{margin: '5px'}}
      name={name}
      id="standard-basic"
      label={label}
      defaultValue={defaultValue}
      variant="outlined"
      size="small"
/*       onChange={handleChange} */
      onChange={(event) => handleInputTextChange(event)}
      InputLabelProps={{ className: classes.customTextField}}
      type={type ? type : 'text'}
      required={required ? required : false}
      inputProps={{
        step: '0.01'
      }}
    />
  )
}

export default ChildTextField