import { useParams, Redirect } from "react-router-dom"
import { useState, useEffect } from "react"
import UserUtils from "../../../utils/User.utils"
import { makeStyles } from "@material-ui/core/styles"
import MotosService from '../../../services/motos.service'
import TopHeader from "../../TopHeader"
import Sidebar from "../../Sidebar"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress"
import MotoEditForm from "./MotoEditForm"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fafafa'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const MotoEdit = () => {
  const [data, setData] = useState(false)
  const [showProgress, setShowProgress] = useState(true)

  const classes = useStyles()
  const { idv } = useParams()

  useEffect(() => {
    if(!data) {
      MotosService.getForIdv(idv)
        .then(result => {
          console.log(result)
          setData(result)
          setShowProgress(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [data, idv])

  if(!UserUtils.userExist) <Redirect to="/login" />
  return (
    <div className={classes.root}>
      <TopHeader />
      <Sidebar />
      <main className={classes.content}>
        <Backdrop className={classes.backdrop} open={showProgress}>
                <CircularProgress color="inherit" />
        </Backdrop>
        {data
            ? <MotoEditForm data={data} />
            : ''
        }
      </main>
    </div>
  )

}

export default MotoEdit